import { StyleSheet } from 'react-native';

const COLUMN_HEIGHT = 78;

export default StyleSheet.create({
	columnWebView: {
		flexDirection: 'column',
		alignItems: 'center',
	},
	columnWebHeader: {
		flexDirection: 'row',
		height: COLUMN_HEIGHT,
		backgroundColor: '#E3F0E4',
		justifyContent: 'center',
	},
	columnHeaderText: {
		display: 'flex',
		alignSelf: 'center',
		flexDirection: 'row',
		justifyContent: 'center',
	   	alignItems: 'center',
	},
	defaultAccountFont: {
		fontSize: 18,
		lineHeight: 32,
		letterSpacing: 1.1925,
		color: '#414141',
	},
	Persoonsgegevens: {
		fontWeight: 'bold',
		color: '#7E9591',
		width: '100%',
	},
	errorText: {
		padding: 5,
		backgroundColor: 'red',
		borderRadius: 5,
		color: 'white',
		fontWeight: 'bold',
		margin: 10
	},
	detailLink: {
		color: '#7E9591',
		fontSize: 12,
		lineHeight: 32,
		letterSpacing: 1.1925,
		fontWeight: 'bold',
	},
	adresBottomMargin: {
		marginBottom: 19,
	},
	arrow: {
		width: 24,
		height: 14,
		marginTop: 5,
		position: 'absolute',
		right: 11,
		top: -7,
		transform: [{ rotate: '180deg' }],
	},
	dateSelectorArrow: {
		width: 14,
		height: 8,
		transform: [{ rotate: '90deg' }],
	},
	dateArrowPositionFlipped: {
	    transform: [{ rotate: '270deg' }],
	},
	arrowPositionFlipped: {
	    transform: [{ rotate: '0deg' }],
	}
});
