import * as React from 'react';
import { View } from 'react-native';
import MakeText from '../Common/MakeText/MakeText';


export default (props: any) => (

    <View style={{flexDirection: props.width < 600 ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', margin: 5}}>
        <View style={{width: props.width < 600 ? props.width * 0.8 : props.width * 0.4, backgroundColor: '#B9DCBF', margin: 5, padding: 20}}>
            <MakeText style={{fontSize: 14, letterSpacing: 0.9275, color: '#414141'}}>{props.function} - {props.country}</MakeText>
        </View>
        <View style={{width:props.width < 600 ? props.width * 0.8 : props.width * 0.15, backgroundColor: '#B9DCBF', margin: 5, padding: 20}}>
            <MakeText style={{fontSize: 14, letterSpacing: 0.9275, color: '#414141'}}>{props.city} - {props.time}</MakeText>
        </View>
    </View>
);
