import * as React from 'react';
import { View, StyleSheet, TextInput, ActivityIndicator, TouchableOpacity, Linking } from 'react-native';

import NewButton from '../CrossPlatform/Button/Button'
import MakeText from '../Common/MakeText/MakeText';
import { calculateMargin, phone } from '../../Util/screen/HandleLayout';
import { Color } from '../../Util/Color';
import globalStyles from '../../Styles/styles';
import { contactRequest } from '../../API/Http/Requests';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';

export default class Contact extends React.Component<any> {

	color: Color = Color.Gray;

	state = {
		cname: '',
		cemail: '',
		cphone: '',
		cmessage: '',

		statusMessage: '',
		loading: false,
		statusMessageAvailable: false,
	}

    async contactRequest() {

		let error = false;

		this.setState({loading: true, statusMessage: undefined, statusMessageAvailable: false,});

        await contactRequest(this.state.cname, this.state.cemail, this.state.cphone, this.state.cmessage).catch( () => {
			// TODO uncomment the code below to turn on error handling. This is currently off because DataService.contactRequest can return an error when there is none.
			// this.setState({statusMessage: this.createErrorMessage(), statusMessageAvailable: true, loading: false});
			// error = true;
			// return console.log(e);
			this.setState({cname: '', cemail: '', cphone: '', cmessage: '', statusMessage: this.createSuccesMessage(), statusMessageAvailable: true, loading: false});
		});

		if(!error) {
			this.setState({cname: '', cemail: '', cphone: '', cmessage: '', statusMessage: this.createSuccesMessage(), statusMessageAvailable: true, loading: false});
		}
    };

	// The error message created a mailto link for both native and web, with the contents of the form inside the email.
	createErrorMessage() {
		return (
			<View style={[globalStyles.row, {backgroundColor: Color.Gray, padding: 4, borderRadius: 5}]}>
				<MakeText style={{color: Color.White}}>Er is iets fout gegaan tijdens het versturen van uw bericht. Uw bericht is niet verzonden. Klik </MakeText>
				<TouchableOpacity style={{borderBottomWidth: 1, borderBottomColor: 'white'}}
					onPress={() => Linking.openURL(`mailto:info@theflowerfamily.nl?subject=Contact formulier: ${this.state.cname}&body=${this.state.cmessage} %0D%0A %0D%0A ${this.state.cemail} %0D%0A ${this.state.cphone} %0D%0A ${this.state.cname}`)}
				>
					<MakeText style={{color: Color.White}}>Hier</MakeText>
				</TouchableOpacity>
				<MakeText style={{color: Color.White}}> om uw bericht per mail te verzenden.</MakeText>
			</View>
		);
	}

	createSuccesMessage() {
		return <MakeText style={{backgroundColor: Color.Green, color: Color.White, padding: 4, borderRadius: 5}}>Uw bericht is verzonden.</MakeText>
	}

	render() {

		const styles = StyleSheet.create({
	        textInput: {
	            borderWidth: 1,
	            borderColor: '#414141',
	            margin: 5,
	            fontSize: 18,
	            padding: 15,
	            paddingHorizontal: 30,
	            width: '100%',
	            alignSelf: 'center'
	        }
	    });

		const margin = calculateMargin(this.props.width);
		const mobileView = phone(this.props.width);

	    return (

		    <View style={{width: mobileView ? '90%' : '50%', marginLeft: mobileView ? '5%' : '25%'}}>
	            <View style={{width: '100%', alignSelf: 'center'}}>
	                <MakeText style={{textAlign: 'center', fontSize: 36, letterSpacing: 2.13, color: '#414141', marginTop: margin}}  fontFamily='Crimson Text'>Contact</MakeText>
	                { routingName === "amsterdam" ?
						<MakeText style={{fontSize: 14, lineHeight: 32, letterSpacing: 0.9275, color: '#414141', marginVertical: margin, width: '60%', alignSelf: 'center'}}>
							Heeft u een vraag of een suggestie om onze service te verbeteren? Of bent u benieuwd naar de mogelijkheden wat betreft de aankleding van evenementen? Vul dan onderstaand formulier in en we nemen zo snel mogelijk met u contact op. Of wilt u liever even telefonisch contact opnemen? Dat kan via +31 20 245 5887.
						</MakeText>
					:
						<MakeText style={{fontSize: 14, lineHeight: 32, letterSpacing: 0.9275, color: '#414141', marginVertical: margin, width: '60%', alignSelf: 'center'}}>
							Heb jij een vraag of een suggestie om onze service te verbeteren? Vul dan onderstaand formulier in en we nemen zo snel mogelijk met jou contact op. Of wil je liever even telefonisch contact opnemen? Dat kan via +31 20 245 5887.
						</MakeText>
					}

	                <TextInput placeholder='Naam*' style={styles.textInput} value={this.state.cname} onChangeText={ (cname) => { this.setState({cname}) }} />
	                <TextInput placeholder='E-mail*' style={styles.textInput} value={this.state.cemail} onChangeText={ (cemail) => { this.setState({cemail}) }} />
	                <TextInput placeholder='Telefoon*' style={styles.textInput} value={this.state.cphone} onChangeText={ (cphone) => { this.setState({cphone}) }} />
	                <TextInput placeholder='Bericht*' multiline={true} style={[styles.textInput, { height: 150 }]} value={this.state.cmessage} onChangeText={ (cmessage) => { this.setState({cmessage}) }} />

					{this.state.loading &&
						<View style={{display: 'flex', alignSelf: 'center', marginTop: 15}}>
							<ActivityIndicator size="large" color="#B9DCBF" />
						</View>
					}

					{ this.state.statusMessageAvailable &&
						this.state.statusMessage
					}

	                <NewButton style={{ alignSelf: 'center', margin: margin }} disabled={this.state.loading ? true: false} textStyle={{ paddingTop: 5, paddingBottom: 6, fontSize: 16, textAlign: 'center', letterSpacing: 2 }} text='Verstuur' color='blackOpacity' onPress={() => this.contactRequest()} />
	            </View>
		    </View>
	    );
	}
}
