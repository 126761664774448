import * as React from 'react';
import { View, StyleSheet, Image, Platform, ActivityIndicator } from 'react-native';

import Storage from './CrossPlatform/Storage/Storage';

import globalStyles from '../Styles/styles';
import MakeText from './Common/MakeText/MakeText';
import { mobile } from '../Util/screen/HandleLayout';
// import GoogleAnalytics from '../Util/analytics/GoogleAnalytics';
import { getMe, logout, paymentStatus, remakePayment } from '../API/Http/Requests';
import FacebookAnalytics from '../Util/analytics/FacebookAnalytics.web';

const storage = new Storage;

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setProducts } from '../store/actions/ProductActions';
import Button from './CrossPlatform/Button/Button';
import config from '../config/config.selector';


class BedankVoorBestelling extends React.Component<any> {

    state = {
        refStatus: 'created',
        earnedPoints: 0,
        currentPoints: 0,
        paymentId: 0,
        haveAccount: false,
        remaking: false,
        remakingFailed: false,
    }

    async componentDidMount() {
        // const analytics = GoogleAnalytics.getInstance();
        // analytics.finishedOrderProcess();
        const ref = this.props.navigation.getParam('ref', undefined);
        const payment = await paymentStatus(ref);
        this.setState({paymentId: payment.id});
        if(ref === undefined || ref.length === undefined) {
        	this.props.navigation.navigate('Home');
        }
        this.handleTransaction(ref, 0);
    }

    handleTransaction(ref, count) {
        paymentStatus(ref).then(async (res) => {
            if (!res.status) {
                this.props.navigation.navigate('Home');
                return;
            }
            if (res.status === 'paid') {
                // analytics.transactionFromOrderRef(res, ref);
                // DataService.orderSuccessfull(res[0].user.email);
                const me = await getMe();
                let totalPoints = 0;
                me.points.forEach(p => {
                    if (p.status === 'available') {
                        totalPoints += p.amount - p.amountSpent;
                    }
                })


                const earnedPoints = me.points[me.points.length - 1] ? me.points[me.points.length - 1].amount : 0;
                this.setState({ refStatus: 'accepted', haveAccount: me.account, earnedPoints, currentPoints: totalPoints });

                // get all data of the packages in the shoppingcart (from the global localStorage), and trigger pixel event Purchase with the result.
                const r = await storage.retrieveData('products');
                if (r) { FacebookAnalytics.getInstance().purchase(r); }

                storage.removeData('products');
                this.props.setProducts([]);
                if (!me.account) {
                    logout();
                    storage.removeData('user');
                    storage.removeData('auth');
                }
            } else if (res.status === 'open') {
                count = count + 1;
                if (count < 12) {
                    setTimeout(() => this.handleTransaction(ref, count), 5000);
                } else {
                    this.setState({ refStatus: 'rejected' });
                }
            } else if (res.status === 'rejected' || res.status === 'canceled' || res.status === 'failed') {
                this.setState({ refStatus: 'rejected' });
            }
        });
    }

    async remakePayment() {
        this.setState({remaking: true, remakingFailed: false});
        try {
            const paymentId = this.state.paymentId;
            const redirect = await remakePayment(paymentId);
            window.open(redirect, '_self');
        } catch (e) {
            console.log(e);
            this.setState({remaking: false, remakingFailed: true});
        }
    }

    render() {
        const mobileView = this.props.width > mobile;

        const styles = StyleSheet.create({
            backgroundImage: {
                alignSelf: 'center',
                marginBottom: 20,
                height: mobileView ? this.props.height / 10 * 9 : (this.props.width > 600 ? 300 : 200),
                width: this.props.width - 40,

            },
            titleColor: {
                color: 'white',
            },
            imgTextView: {
                width: this.props.width * 0.9,
                alignSelf: 'center'
            },
            imgTextViewBigWeb: {
                position: 'absolute' as any,
                right: this.props.width * 0.55,
                top:  this.props.height * 0.10,
                justifyContent: 'center' as any,
                alignItems: 'center' as any,
                width: this.props.width * 0.3,
            },
            imgHead: {
                // fontFamily: 'Arno Pro',
                textAlign: 'center',
                fontSize: 36,
                letterSpacing: 2.2,
                ...Platform.select({
                    ios: {},
                    android: {},
                }),
            },
            imgText: {
                fontSize: 14,
                marginTop: 30,
                lineHeight: 32,
                color: 'black',
                letterSpacing: 0.9275
                // fontFamily: 'Open Sans',
            },
            pointsText: {
                fontSize: 14,
                marginTop: 10,
                lineHeight: 32,
                color: 'black',
                letterSpacing: 0.9275
            },
            imgButton: {
                alignSelf: 'center',
                marginTop: 50
            },
            imgButtonText: {
                // fontFamily: 'Open Sans',
                fontSize: 20
            },
            imgIcons: {
                height: 50,
                width: 325,
                margin: 'auto',
                marginTop: 40
            },
			textBackground: {
				padding: mobileView ? 30 : 0,
				backgroundColor: 'white',
			}
        })

        const textViewStyle: any[] = [styles.imgTextView];
        mobileView ? textViewStyle.push(styles.imgTextViewBigWeb) : null;

        if(!this.props.app.config.collection) {
            return (
                <ActivityIndicator />
            );
        }

        if(this.state.refStatus === 'rejected') {
            return (
                <View style={{width: this.props.width}}>
                    <Image
                        style={styles.backgroundImage}
                        source={{uri: this.props.app.config.collection.image}}
                    />

                    <View style={mobileView ? styles.imgTextViewBigWeb : styles.imgTextView}>
                        <View style={styles.textBackground}>
                            <MakeText fontFamily='Crimson Text' style={[globalStyles.title, styles.imgHead]}>De bestelling is mislukt</MakeText>
                            {this.state.refStatus === 'rejected' ?
                                [<MakeText style={styles.imgText}>
                                    De betaling is geannuleerd of mislukt, probeer het nogmaals.
                                </MakeText>,
                                <MakeText style={styles.imgText}>
                                    {this.state.remakingFailed ? 'Opnieuw proberen is mislukt.' : ''}
                                </MakeText>]
                            : undefined}
                            <Button
                                color={'greenWhite'}
                                style={{marginTop: 25}}
                                text={'Probeer opnieuw'}
                                disabled={this.state.remaking}
                                textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: mobileView ? 15 : 16, letterSpacing: 2, alignSelf: 'center'}}
                                onPress={() => this.remakePayment()}
                            />
                        </View>
                        { /*
                        <View>
                            <Image source={require('./../images/download_app.svg')} style={styles.imgIcons}/>
                        </View>
                        */ }
                    </View>
                </View>
            );
        }

        return (
            <View style={{width: this.props.width}}>
                <Image
                    style={styles.backgroundImage}
                    source={{uri: this.props.app.config.collection.image}}
                />

                <View style={mobileView ? styles.imgTextViewBigWeb : styles.imgTextView}>
                    <View style={styles.textBackground}>
                        <MakeText fontFamily='Crimson Text' style={[globalStyles.title, styles.imgHead]}>Bedankt voor je bestelling! </MakeText>
                        <MakeText style={styles.imgText}>
                            Wat leuk dat je samen met ons de wereld een beetje mooier maakt! Je bestelling {this.state.refStatus === 'created' ? 'wordt nu' : 'is'} verwerkt en {this.state.refStatus === 'accepted' && 'wordt'} bezorgd op jouw gewenste dag en adres. Je ontvangt een dag vóór jouw bezorging een mail met daarin een track en trace code, zodat je precies weet hoe laat de bezorging zal plaatsvinden. Een lieve bloemengroet namens The Flower Family.
                        </MakeText>
                        {((config as any).showExclusive && this.state.refStatus === 'accepted') &&
                            <MakeText style={styles.pointsText}>
                                Met deze bestelling heb je {this.state.earnedPoints} TFF Exclusive punten gekregen. Je huidige puntenbalans is nu {this.state.currentPoints}.{!this.state.haveAccount && ' Maak een account aan om deze punten te claimen.'}
                            </MakeText>
                        }
                        {this.state.refStatus === 'created' &&
                            <ActivityIndicator style={{alignSelf: 'center', marginTop: 10}} />
                        }
                    </View>
                    { /*
                    <View>
                        <Image source={require('./../images/download_app.svg')} style={styles.imgIcons}/>
                    </View>
                    */ }
                </View>
            </View>
        );
    }
};

const mapStateToProps = (state: any) => {
    const { app } = state
    return { app }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        setProducts,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(BedankVoorBestelling);