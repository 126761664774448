import * as React from 'react';
import { View, ActivityIndicator } from 'react-native';
import {getOrdersForUser} from "../../API/Http/Requests";

import AccountItemHeader from './AccountItemHeader';
import MakeText from '../Common/MakeText/MakeText';
import styles from './AccountStyles';
import Button from '../CrossPlatform/Button/Button';
import moment from 'moment';
import statusToMessage from '../../Util/statusToMessage';
import { Package } from '../../API/Interfaces/Bundle';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';

const findTitle = (flower: Package) => {
    let locale = flower.locale.find(l => l.locale === 'nl-NL' && l.key === 'title');
    if (locale && locale.value) {
        return locale.value;
    }
    return flower.category;
}

export default class OrderData extends React.Component<any> {

	state = {
		orders: undefined as any,
	}

	componentDidMount() {
		if (this.props.orders) {
			this.setState({orders: this.props.orders.reverse()});
		} else {
			getOrdersForUser().then( user => {
				this.setState({orders: user.orders.reverse()});
			});
		}
	}


	render() {

        if (!this.state.orders) {
            return (
                <View style={{ flexDirection: 'column', alignItems: 'center', margin: 5 }}>
                    <View style={{ marginTop: 20, marginBottom: 20 }}>
                        <ActivityIndicator size="large" color="#B9DCBF" />
                    </View>
                </View>
            );
        }

		if(!this.state.orders.length) {
			return (
				<View style={{ flexDirection: 'column', alignItems: 'center', margin: 5 }}>
                    <View style={{ marginTop: 20, marginBottom: 20, alignItems: 'center', justifyContent: 'center' }}>
						<MakeText style={{marginBottom: 10}} fontFamily="Open Sans Italic">Je hebt nog geen bestelling geplaatst.</MakeText>
						<Button text="Doe nu hier jouw eerste bestelling!" color="blackOpacity" route='/bestel' onPress={() => this.props.navigation.navigate('webshop')} />
                    </View>
                </View>
			);
		}

		return (
            <View>

				{this.state.orders.map( (order: any) => {
					let title = findTitle(order.package);
					const loc = order.delivery.location;
					if (order.package.category !== 'Overig' && order.package.category !== 'Plant' && order.package.category !== 'E-Flora-Plant') {
						title += ` - ${order.package.size}${(order.vase === 'large' || order.vase === 'medium') ? ' + vaas' : ''}`;
					}
					if (order.package.category.substring(0, 13) === 'E-Flora-Plant' && order.package.packageIntname && order.package.packageIntname.split('|')[2]) {
						title = `${order.package.packageIntname.split('|')[2].trim()}`;
						title += `${(order.vaseAmount > 0) ? ' + vaas' : ''}`;
					}
					return (
						<View key={order.id} style={styles.adresBottomMargin}>
							<AccountItemHeader testID='orderData' title={title} onPress={() => this.props.navigation.navigate('Order Details', {id: order.id})} message={routingName === 'amsterdam' ? 'Details' : undefined} />

							{ routingName === 'amsterdam' &&
								<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Ordernummer: {order.id}</MakeText>
							}
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Besteldatum: {moment(order.createdAt).format('dddd D MMM YYYY')}</MakeText>
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Bezorgdatum: {moment(order.delivery.windowStart).format('dddd D MMM YYYY')}</MakeText>
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Bezorgadres: {`${loc.street} ${loc.housenumber}${loc.addition}, ${loc.city}`}</MakeText>
							{ order.status !== 'delivery' ?
								<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Status: {statusToMessage(order.status)}</MakeText>
								: <MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Status: {statusToMessage(order.delivery.status)}</MakeText>
							}
						</View>
					);
				})}
			</View>
		)
	}
}
