import { StyleSheet } from 'react-native';

export default (width: number, height: number) => {
	const bannerHeightFigma = 535;
    const screenWidthFigma = 1600
    const imgHeight = bannerHeightFigma * width/screenWidthFigma;
	return StyleSheet.create({
		image: {
			height: imgHeight,
			width: width
		},
		header1: {
			// textAlign: 'center',
			marginBottom: 10,
			color: '#414141',
			fontWeight: 'bold',
			fontSize: 24,
			letterSpacing: 2.13,
		},
		headerText: {
			// width: width < 600 ? width * 0.9 : width * 0.5,
			// alignSelf: 'center',
			marginBottom: 50,
			color: '#414141',
			lineHeight: 32,
			fontSize: 18,
			letterSpacing: 1.1925,
		},
		header2: {
			fontSize: 36,
			// textAlign: 'center',
			marginBottom: 15,
			color: '#414141',
			lineHeight: 42,
			letterSpacing: 3
		},
		headerText2: {
			fontSize: 20,
			// textAlign: 'center'
		},
		headerText3: {
			fontSize: 14,
			margin: 5,
			color: '#414141',
			// alignSelf: 'center'
		},
		zoDoenWij: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',
			justifyContent: 'center',
			alignItems: 'flex-start',
		},
		zoBlock: {
			flexGrow: 1,
			maxWidth: 300,
			margin: 20
		},
		zoPicca: {
			width: 300,
			height: 300,
			margin: 'auto',
		},
		werkplekText: {
			lineHeight: 32,
			fontSize: 14,
			color: '#414141',
			letterSpacing: 0.9275
		},
		werkplekHeader: {
			fontSize: 36,
			marginBottom: 15,
			color: '#414141',
			lineHeight: 42,
			letterSpacing: 3,
		},
		imgButton: {
			alignSelf: 'center',
			marginTop: 20
		},
		imgButtonText: {
			// fontFamily: 'Open Sans',
			color: '#414141',
			lineHeight: 22,
			fontSize: 16,
			textAlign: 'center',
			letterSpacing: 2
		},
		imgText: {
			fontSize: 72 - 72 * (1 - (height / 1080)),
			textAlign: 'center',
			color: '#FFFFFF',
			position: 'absolute',
			left: width * 0.6,
			top: height * 0.35
		},
		textInput: {
			borderWidth: 1,
			borderColor: '#414141',
			margin: 5,
			fontSize: 16,
			padding: 10,
			paddingLeft: 30,
			width: '80%',
			alignSelf: 'center'
		}
	});
}
