import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import FAQItem from './FAQItem';
import MakeText from '../Common/MakeText/MakeText';
import { phone } from '../../Util/screen/HandleLayout';

export default (props: any) => {

	const mobileView: boolean = phone(props.width);

    const styles = StyleSheet.create({
		main: {
			maxWidth: props.width,
			marginBottom: 47,
		},
        imgRow: {
            flexDirection: props.width < 880 ? 'column' : 'row',
            justifyContent: props.width < 880 ? 'flex-start' : 'center',
            alignItems: props.width < 880 ? 'center' : 'flex-start'
        },
        imgContainer: {
			marginTop: 54,
			marginLeft: mobileView ? 0 : 71,
            width: props.width < 880 ? props.width * 0.8 : props.width * 0.3
        },
        sizeText: {
            margin: 5
        },
        faqListHeader: {
            fontSize: mobileView ? 32 : 36,
            lineHeight: 42,
            letterSpacing: 2.2,
            color: '#414141',
            marginBottom: 5
        }
    });

    return (

	    <View style={styles.main}>
            <MakeText fontFamily="Crimson Text" style={{fontSize: 56, alignSelf: 'center', letterSpacing: 2.2, color: '#414141', textAlign: 'center', marginTop: 50, marginBottom: mobileView ? 20 :50}}>F.A.Q.</MakeText>
            <View style={styles.imgRow}>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Bestellen</MakeText>
                    <FAQItem mobileView={mobileView} title='Hoe kan ik bloemen bestellen?' body='Dat is heel eenvoudig via onze webshop. Ga naar de webshop, kies wat moois uit en volg de 4 stappen in het bestelproces. Dan komen wij je binnenkort die mooie bloemen bezorgen.'/>
                    <FAQItem mobileView={mobileView} title='Kan ik ook eenmalig bloemen bestellen?' body='Ja, dat kan ook. Dit doe je door een bos bloemen te selecteren en vervolgens op ‘bestel direct’ te klikken. Op deze manier abonneer je je niet op het Family Membership, maar worden de bloemen eenmalig bezorgd.'/>
                    <FAQItem mobileView={mobileView} title='Krijg ik ook een bevestiging van mijn bestelling?' body='Ja, je ontvangt een mailtje met daarin de bezorggegevens. Die bevestigingsmail ontvang je op het e-mailadres dat je hebt opgegeven. Let op: het is mogelijk dat dit bericht in je spambox terecht komt.'/>
                    <FAQItem mobileView={mobileView} title='Hoe werkt het Family Membership?' body='Vooraf: het  Family Membership is volledig flexibel. Je kunt opzeggen wanneer je wilt. Als family member kun je kiezen tussen onze Family Bundle, de Seasonal Flowers en de Royal Roses. Alle drie wisselen ze  wekelijks van soort en kleur. Heb je je keuze gemaakt, dan bepaal je vervolgens jouw bezorgdag; de interval (wekelijks, twee-wekelijks, maandelijks of eenmalig), en als laatste je bezorgmoment in de loop van de dag. In jouw account kan je vervolgens onder ‘Mijn memberships’ de gegevens van je membership wijzigen. Je kunt dan bijvoorbeeld ook annuleren of een pauze inlassen.'/>
                </View>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text"style={styles.faqListHeader}>Betalingen</MakeText>
                    <FAQItem mobileView={mobileView} title="Wat kosten de bloemen en planten?" body={`Family Bundle\r\nSmall: € 19.95\r\nMedium: € 24.95\r\nLarge: € 29.95\r\nXL: € 49.95\r\n\r\nSeasonal Flowers\r\nSmall: € 19.95\r\nMedium: € 24.95\r\nLarge: € 29.95\r\n\r\nRoyal Roses\r\nSmall: € 19.95\r\nMedium: € 24.95\r\nLarge: € 29.95\r\n\r\nMonstera\r\n€ 39.95\r\n\r\nAreca palm\r\n€ 49.95\r\n\r\nStrelitzia\r\n€ 59.95\r\n\r\nDe orchideeën\r\nSingle: € 22.95\r\nTrio:     € 64.95\r\n\r\n* De prijzen van de bloemen zijn exclusief € 8.10 bezorgkosten. De prijzen van de planten zijn wel inclusief bezorgkosten.`} />
                    <FAQItem mobileView={mobileView} title='Hoe kan ik betalen en wanneer vindt de betaling plaats?' body='Nadat jij je eerste bloemen hebt besteld, vindt de betaling direct plaats via de betalingsmethode die jij hebt gekozen. Als je een membership hebt afgesloten, volgt de betaling automatisch een dag nadat jij de bloemen hebt ontvangen.' />
                    <FAQItem mobileView={mobileView} title='Zijn er nog extra kosten aan verbonden, zoals bezorgkosten of membership kosten?' body='De prijzen van de bloemen zijn inclusief BTW en exclusief € 8.10 (of € 4.99 voor brievenbuspakketten/-bloemen) bezorgkosten. De prijzen van de planten zijn inclusief BTW en bezorgkosten. Aan het membership zijn geen kosten verbonden.' />
                    <FAQItem mobileView={mobileView} title='Kan ik mijn bestelling annuleren en krijg ik dan ook mijn geld terug?' body='Als je de annulering doorgeeft tenminste 24 uur voor de geplande bezorging, zullen wij er voor zorgen dat je geen bloemen krijgt en zal het geld worden teruggestort op jouw rekening.' />
                </View>
            </View>
            <View style={styles.imgRow}>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Bestellingen</MakeText>
                    <FAQItem mobileView={mobileView} title='Is het mogelijk om zelf mijn boeket samen te stellen of mijn voorkeuren door te geven?' body='Nee, dat is helaas niet mogelijk. We garanderen iedere week een bijzondere bos bloemen. Onze bundels worden samengesteld door Dennis Kneepkens, een wereldtopper op het gebied van floral design. Hij en zijn partner Mick Stubbe ontwerpen de meest bijzondere designs, passend bij elkseizoen. Ze werken met bloemen die je niet elke dag tegenkomt. Om een indruk te krijgen, zie onze Instagram-pagina.--> https://www.instagram.com/theflowerfamily/?hl=nl' />
                    <FAQItem mobileView={mobileView} title='Wat zijn de deadlines voor het bestellen?' body='Door ons directe contact met onze kwekers moeten wij met strakke deadlines werken. Daarom vragen wij je om je bloemen te bestellen twee dagen van te voren en dan vóór 22.00 uur. Zo kun je zondag tot 22.00 uur de bloemen bestellen voor de dinsdag; en dinsdag tot 22:00 uur voor de bloemen ’die bezorgd worden op donderdag.' />
                    <FAQItem mobileView={mobileView} title='Kan ik zelf nog inzien wat ik heb besteld?' body='Dat kan zeker. Ga in jouw account naar ‘ordergeschiedenis’.' />
                </View>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Bezorging</MakeText>
                    <FAQItem mobileView={mobileView} title='Waar en wanneer bezorgt The Flower Family?' body='Op dinsdag en donderdag bezorgt The Flower Family door heel Nederland.' />
                    <FAQItem mobileView={mobileView} title='Kan ik aangeven hoe laat ik mijn bloemen wil ontvangen?' body="Ja, tijdens het bestelproces is heel eenvoudig aan te geven of je de bloemen overdag (09.00 – 18.00 uur) of 's avonds bezorgd wilt krijgen (18.00 – 22.00 uur)" />
                    <FAQItem mobileView={mobileView} title='Wat gebeurt er met mijn bloemen als ik niet thuis ben?' body='De bloemen worden bezorgd bij de buren en zullen dit via een kaartje in de brievenbus laten weten.' />
                    <FAQItem mobileView={mobileView} title='Kan ik mijn bloemen (eenmalig) op een ander adres laten bezorgen?' body='Ja, stuur dan even een mailtje minimaal een dag van te voren naar: info@theflowerfamily.nl met daarin het juiste adres.' />
                </View>
            </View>
            <View style={styles.imgRow}>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Spaarprogramma TFF Exclusive</MakeText>
                    <FAQItem mobileView={mobileView} title='Wat is het spaarprogramma TFF Exclusive?' body='We bouwen graag duurzame relaties op met onze Family members. Als member ontvang je daarom bij iedere bestelling een x aantal punten die te verzilveren zijn voor mooie cadeaus. Hoe meer bloemen en planten jij bestelt, hoe meer punten je ontvangt.' />
                    <FAQItem mobileView={mobileView} title='Hoe spaar ik punten?' body='Je spaart punten bij elke bestelling die bezorgd wordt. Dus wanneer je een twee-wekelijks membership hebt, ontvang je elke twee weken een bos bloemen én 20 punten.' />
                    <FAQItem mobileView={mobileView} title='Wat kan ik met mijn gespaarde punten?' body='Je tegoed kun je verzilveren voor mooie cadeaus' />
                    <FAQItem mobileView={mobileView} title='Hoe kan ik mijn punten verzilveren?' body='' />
                    <FAQItem mobileView={mobileView} title='Waar kan ik mijn puntentegoed inzien?' body='Op de TFF Exclusive/spaarprogramma pagina.' />
                    <FAQItem mobileView={mobileView} title='Is er een maximaal aantal punten wat ik tegelijk mag verzilveren en na hoelang komen mijn punten te vervallen?' body='Ja, per keer mag je maximaal 500 punten verzilveren en de punten die je spaart zijn 6 maanden geldig, dan komen ze te vervallen.' />

                </View>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Betekenisvol ondernemen</MakeText>
                    <FAQItem mobileView={mobileView} title='Wat bedoelen jullie met betekenisvol ondernemen?' body={`In alles wat The Flower Family doet hebben wij oog voor onze medemens, het milieu en de maatschappij. Wij zijn ervan overtuigd dat ondernemen in de nabije toekomst alleen nog maatschappelijk geaccepteerd wordt als de onderneming effectief bijdraagt aan de bloei van mens, milieu en maatschappij.\r\nThe Flower Family doet dat op de volgende twee manieren:\r\n\r\nOnze bloemen zijn direct afkomstig van NL’se kwekers die allemaal op een duurzame manier telen. Zij gebruiken zoveel mogelijk biologische bestrijdingsmiddelen en gaan circulair om met water en energie. Wij leveren de meest duurzame en dagverse bloemen direct van ‘t land.\r\n\r\nAl deze bloemen worden samengesteld tot stijlvolle veldboeketten door mensen met een achterstand tot de arbeidsmarkt - onze ‘talenten’ genaamd. Deze talenten - denk aan mensen met lichamelijke of verstandelijke beperkingen, of vluchtelingen - worden bij ons opgeleid en kunnen zo weer opbloeien in de maatschappij.`} />
                    <FAQItem mobileView={mobileView} title='Wat houdt ‘duurzame bloemen’ in bij The Flower Family?' body='Al onze kwekers hebben oog voor zowel goede  arbeidsomstandigheden als voor het milieu. Daarom zijn onze kwekers, die wij zo dicht mogelijk bij huis zoeken, gecertificeerd met een MPS-A certificering. Het keurmerk geeft aan hoe de teler ten opzichte van andere bedrijven scoort op de aspecten mest, bestrijdingsmiddelen, water- en energieverbruik.' />
                    <FAQItem mobileView={mobileView} title='Waar komen jullie bloemen vandaan?' body='Wij werken met gecertificeerde kwekers die zoveel mogelijk lokaal kweken en die de missie van The Flower Family voluit omarmen. Zo word je bij The Flower Family beloond met mooie, duurzame bloemen van de hoogste kwaliteit.' />
                    <FAQItem mobileView={mobileView} title='Wat betekent voor jullie ‘het transparant maken van de bloemenwereld’?' body='Met het transparant maken van de bloemensector bedoelen wij dat we willen bevorderen dat alle schakels in het proces een  eerlijke prijs krijgt, dat de prijs-kwaliteitsverhouding van de bloemen controleerbaar goed is.' />
                </View>
            </View>
            <View style={styles.imgRow}>
                <View style={styles.imgContainer}>
                    <MakeText fontFamily="Crimson Text" style={styles.faqListHeader}>Overig</MakeText>
                    <FAQItem mobileView={mobileView} title='Hoe kan ik contact met jullie opnemen?' body={`Bellen: +31 20 245 5887, whatsappen:\r\n\r\nJos -> +316 43 75 64 82\r\nLaurens -> +316 24 28 60 28\r\n\r\nMailen naar: info@theflowerfamily.nl`} />
                    <FAQItem mobileView={mobileView} title="Zijn mijn gegevens veilig bij jullie?" body='Vanzelfsprekend! Je kunt er altijd van uitgaan dat we jouw gegevens – zoals je adres, e-mailadres en telefoonnummer – uitsluitend gebruiken voor onze eigen dienstverlening. Met die gegevens houden we je bijvoorbeeld op de hoogte van jouw aanmelding of de bezorging. We zullen je gegevens nooit verstrekken aan derde partijen en gaan vertrouwelijk om met je gegevens in e-mails en andere berichten aan jou.' />
                    <FAQItem mobileView={mobileView} title="Hoe lang staan de bloemen?" body='Aangezien bloemen natuurproducten zijn, is het erg lastig om hier een juist antwoord op te geven. Wat we wel kunnen vertellen is dat de bloemen van The Flower Family direct van onze duurzame kwekers afkomstig zijn en er dus geen schakel tussen jouw en de kweker is – verser kan dus niet! Een dikke week moeten de meeste bloemen zeker staan te shinen!' />
                    <FAQItem mobileView={mobileView} title="Ik heb een suggestie/feedback om jullie bloemenservice te verbeteren, hoe geef ik dit door?" body='Wat fijn dat je suggesties voor ons hebt, we zijn erg blij met elk ideeom onze service te verbeteren. Stuur ons een mailtje naar info@theflowerfamily.nl. Alvast super bedankt!' />
                    <FAQItem mobileView={mobileView} title="Wat zijn de maten van de vazen? En kan ik deze ook los bestellen?" body={`De kleine maat vaas = 21 cm. hoog bij 14.5 cm. breed op de bodem.\r\nDe grote maat vaas = 30 cm. hoog bij 18.0 cm. breed op de bodem.\r\nDe vazen zijn alleen te bestellen als accessoire samen met de bloemen, dus niet als los product.`} />
                </View>
            </View>
	    </View>
    );
}
