import * as React from 'react';
import MakeText from '../Common/MakeText/MakeText';
import TouchableOpacity from '../CrossPlatform/TouchableOpacity/TouchableOpacity';

export default class Collapsible extends React.Component<{title: string, titleStyle?: React.CSSProperties, containerStyle?: React.CSSProperties}, {show: boolean}> {
    state = {
        show: false,
    };

    render() {
        const show = this.state.show;
        let rotateArrow = this.state.show ? '' : 'rotateArrow';
        return (
            <div className='col' style={this.props.containerStyle}>
                <TouchableOpacity onPress={() => this.setState({show: !show})} style={{...this.props.titleStyle, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <MakeText style={this.props.titleStyle}>{this.props.title}</MakeText>
                    <img className={rotateArrow} src={'/images/popup_arrow.svg'} alt="Toggle window" width="24" height="14" />
                </TouchableOpacity>
                {show ?
                    this.props.children
                : undefined
                }
            </div>
        )
    }
}