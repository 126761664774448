import * as React from 'react';

import BasicPage from './Template/BasicPage';
import Vacature from './../Components/Vacatures/Vacature';

export default (props: any) => (
  <BasicPage navigation={props.navigation}>
		<Vacature />
	</BasicPage>
)
