import * as React from 'react';
import { phone } from '../../Util/screen/HandleLayout';
import MakeText from '../Common/MakeText/MakeText';

export default (props: any) => {
    const bannerHeightFigma = 150;
    const screenHeightFigma = 900;
    const h = bannerHeightFigma/screenHeightFigma * props.height;
    const mobileView = phone(props.width);
    const styles = {
        container: {
            backgroundColor: '#B9DCBF',
            width: '95%',
            minHeight: h,
            display: 'flex',
            padding: '2.5%',
            flexDirection: mobileView ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
        } as React.CSSProperties,
        quote: {
            // 1570 is aprox. screenwidth at which tff exclusive quote goes to two lines
            fontSize:  (props.width < 1570) ? 20 : 30,
            fontStyle: 'italic',
            color: 'white',
            textAlign: 'center',
            letterSpacing: 2.72,
        } as React.CSSProperties,
        quoter: {
            fontFamily: 'Arno Pro',
            fontSize: mobileView ? 18 : 18,
            color: 'white',
            textAlign: 'center',
            letterSpacing: 2.72,
        } as React.CSSProperties,
    };
    return (
        <div style={styles.container}>
            <MakeText fontFamily='Arno Pro' style={styles.quote}>“{props.quote}”</MakeText>
            <p style={styles.quoter}> - {props.quoter}</p>
        </div>
    );
}