import * as React from 'react';
import { View, ActivityIndicator } from 'react-native';
import AccountItemHeader from './AccountItemHeader';
import MakeText from '../Common/MakeText/MakeText';
import moment from 'moment';

import styles from './AccountStyles';
import { getUserCoupons } from '../../API/Http/Requests';
import Button from '../CrossPlatform/Button/Button';

export default class CouponData extends React.Component<any> {

	state = {
		coupons: undefined as any,
		totalPoints: 0,
	}

	componentDidMount() {
		getUserCoupons().then( async coupons => {
			coupons = coupons.filter(c => !c.expired && c.max > c.uses)
			for (const c of coupons) {
				const applicable = JSON.parse(c.applicableToPackages);
				if (applicable.length === 1 && applicable[0] !== 0 && typeof applicable[0] === "number") {
					c.goToProduct = () => this.props.navigation.navigate('Product details', {pId: applicable[0]});
				}
			}
			this.setState({ coupons });
		});
		const user = this.props.user;
		let totalPoints = 0;
		user.points.forEach(p => {
			if (p.status === 'available') {
				totalPoints += p.amount - p.amountSpent;
			}
		})
		this.setState({totalPoints});
	}

	render() {

        if (!this.state.coupons) {
            return (
                <View style={{ flexDirection: 'column', alignItems: 'center', margin: 5, width: this.props.width }}>
                    <View style={{ marginTop: 20, marginBottom: 20 }}>
                        <ActivityIndicator size="large" color="#B9DCBF" />
                    </View>
                </View>
            );
        }

		if(!this.state.coupons.length) {
			return (
				<View style={{ flexDirection: 'column', alignItems: 'center', margin: 5 }}>
                    <View style={{ marginTop: 20, marginBottom: 20, alignItems: 'center', justifyContent: 'center' }}>
                        <MakeText style={{marginBottom: 10}} fontFamily="Open Sans Italic">Je hebt nog geen kortingscodes.</MakeText>
                    </View>
					<Button text="Punten verzilveren" color="greenWhite" textStyle={{paddingTop: 5, paddingBottom: 6, marginTop: 15, fontSize: 15}} onPress={() => this.props.navigation.navigate('Exclusive')} />
                </View>
			);
		}

		return (
            <View>
				{this.state.coupons.map(coupon => {
					return (
						<View key={coupon.id} style={styles.adresBottomMargin}>
							<AccountItemHeader title={`Kortingscode`} message={coupon.goToProduct ? 'Naar product' : undefined} onPress={coupon.goToProduct ? coupon.goToProduct : undefined} />
			
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Code: {coupon.code}</MakeText>
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Nog {coupon.max - coupon.uses} keer te gebruiken</MakeText>
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Geldig tot: {moment.unix(coupon.enddate).format('DD-MM-YYYY')}</MakeText>
						</View>
					);
				})}
			</View>
		);
	}
}
