import moment from 'moment';
import * as React from 'react';
import { updateDeliveryReport } from '../../../API/Http/Requests';
import Collapsible from '../../ProductDetails/Collapsible';

interface IDeliveryReportProps {
    delivery: any,
}

interface IDeliveryReportState {
    actualDelivery: Date | undefined,
    duurzaamVervoer: boolean,
    binnenRing: boolean,
    sending: boolean,
    checkmark: boolean,
    status: string,
}

export default class DeliveryReportDelivery extends React.Component<IDeliveryReportProps, IDeliveryReportState> {
    state = {
        sending: false,
        actualDelivery: undefined,
        duurzaamVervoer: false,
        binnenRing: false,
        checkmark: false,
        status: '',
    }

    async componentDidMount() {
        // Set values in state if they are already set in the delivery object
        const toSet = {};
        const delivery = this.props.delivery;

        const checkmark = delivery.duurzaamVervoer !== null && delivery.binnenRing !== null && delivery.actualDelivery !== null;
        toSet['checkmark'] = checkmark;

        if (!this.props.delivery.actualDelivery) {
            toSet['actualDelivery'] = moment(this.props.delivery.windowStart).toDate();
        } else {
            toSet['actualDelivery'] = this.props.delivery.actualDelivery;
        }
        if (this.props.delivery.duurzaamVervoer) {
            toSet['duurzaamVervoer'] = this.props.delivery.duurzaamVervoer;
        }
        if (this.props.delivery.binnenRing) {
            toSet['binnenRing'] = this.props.delivery.binnenRing;
        }
        this.setState(toSet);
    }

    async saveChanges(): Promise<void> {
        try {
            await updateDeliveryReport(this.props.delivery.id, {
                actualDelivery: this.state.actualDelivery,
                duurzaamVervoer: this.state.duurzaamVervoer,
                binnenRing: this.state.binnenRing,
            });
            this.setState({status: 'Veranderingen succesvol opgeslagen', checkmark: true});
        } catch {
            this.setState({status: 'Opslaan mislukt'});
        }
    }

    render() {
        const delivery = this.props.delivery;
        const order = this.props.delivery.order;
        const location = this.props.delivery.location;

        const adresHeader = `${location.street} ${location.housenumber}, ${location.postalcode} ${location.city}`;
        const timeHeader = `${moment(delivery.windowStart).format('HH:mm')}-${moment(delivery.windowEnd).format('HH:mm')}`;
        return (
            <div style={{alignSelf: 'center', textAlign: 'center', paddingTop: 10, paddingBottom: 10, paddingLeft: 5, paddingRight: 5}}>
                <Collapsible title={`${this.state.checkmark ? '✔️' : ''} Order ${this.props.delivery.order.id}: ${timeHeader} te ${adresHeader}`} titleStyle={{paddingRight: 10}}>
                    <div style={{marginTop: 5, marginLeft: 5}}>
                        {/* Full order information */}
                        <div className='col' style={{textAlign: 'left'}}>
                            Order ID: {order.id}<br />
                            Aantal boeketten: {order.amount}<br />
                            Gewenste datum: {moment(delivery.windowStart).format('DD-MM-YYYY')}<br />
                            Bezorgvenster: {timeHeader}<br />
                            Zakelijk of privé locatie: {location.isPublicLocation === undefined ? 'Niet bekend' : (location.isPublicLocation ? 'Privé' : 'Zakelijk')}<br />
                            Postcode: {location.postalcode}
                        </div>
                        {/* Fields that should be filled in */}
                        <div className='col mt-2'>
                            <div className='row mb-1'>
                                Gerealiseerde bezorgtijd:
                                <input type="datetime-local" id="datepick" name="real"
                                    value={`${moment(this.state.actualDelivery).format('YYYY-MM-DD[T]HH:mm')}`}
                                    onChange={(e) => this.setState({actualDelivery: moment(e.currentTarget.value).toDate()})}
                                />
                            </div>
                            <div className='row mb-1'>
                                Vervoersmiddel duurzaam:
                                <input type="checkbox" id="duurzaam" name="duurzaam"
                                    checked={this.state.duurzaamVervoer}
                                    onChange={(e) => this.setState({duurzaamVervoer: e.currentTarget.checked})}
                                />
                            </div>
                            <div className='row mb-1'>
                                Bezorging binnen de ring:
                                <input type="checkbox" id="ring" name="ring"
                                    checked={this.state.binnenRing}
                                    onChange={(e) => this.setState({binnenRing: e.currentTarget.checked})}
                                />
                            </div>
                        </div>
                        {/* Save button */}
                        <button onClick={() => this.saveChanges()}>Opslaan</button>
                        <div>
                            {this.state.status}
                        </div>
                    </div>
                </Collapsible>
            </div>
        )
    }
}
