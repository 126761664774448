import * as React from 'react';
import { View, Platform, StyleSheet, Dimensions, ActivityIndicator } from 'react-native';
import {getConfig} from "../../API/Http/Requests";
import Storage from './../../Components/CrossPlatform/Storage/Storage';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setConfig, setLogin } from './../../store/actions/AppActions';
import { validBrowserCheck } from '../screen/HandleLayout';
import UpdateBrowser from '../../Containers/UpdateBrowser';
import ConnectionFailed from '../../Containers/ConnectionFailed';
import { SlackLiveness } from '../slackLiveness/slackLiveness';

class LoadAppData extends React.PureComponent<any> {

	state = {
		validBrowser: true,
		mqttError: false
	}

	storage = new Storage;

	componentWillMount() {

		// Check if the browser is a supported version.
		if(!validBrowserCheck()) {
			// this.setState({validBrowser: false});
			// GoogleAnalytics.getInstance().oldBrowserError();
			// return;
		}

		this.getConfig();
		this.checkLogin();
	}

	// Set timeout to 0 if on the web, to 3 second when on mobile.
	// This is done because on mobile the Buffer, needed for using the mqtt
	// server needs time to load.
	getConfig() : void {
		const timeout = Platform.OS === 'web' ? 0 : 3000;
		setTimeout( () => {
			getConfig().then(result => {
				this.props.setConfig(result);
				this.props.setLoadAppData(true);
			}).catch( async e => {
				console.log(e.code, 'Getting config');
				if (process.env.NODE_ENV !== 'development') {
					// Send a message in the slack that the back-end might not be available
					SlackLiveness.getInstance().reportProblem();
				}
				// window.location.pathname = '/public/UpdateBrowser' as any;
			})}, timeout);
	}

	checkLogin() {
		// const timeout = Platform.OS === 'web' ? 0 : 3000;

		// setTimeout( async () => {
		// 	let loggedIn = await isLoggedIn();
		// 	if(loggedIn) {
		// 		this.props.setLogin(true);
		// 	}
		// }, timeout);
	}

	attemptLogout(DataService: any) {
		DataService.logout().then( result => {
			if(result === true) {
				// Remove login data from localStorage.
				this.storage.removeData('user');
				// Set login in the Redux store to false, so the UI will update.
				this.props.setLogin(false);
				// Redirect to home so the page reloads.
				// if(Platform.OS === 'web') {
				// 	window.location.pathname = '/';
				// }
			}
		}).catch( e => {
			console.log(e);
		});
	}

	height = Dimensions.get('window').height;

	render() {

		if(!this.state.validBrowser) {
			return (
				<View style={[styles.alignCenter, {height: this.height}]}>
					<UpdateBrowser />
				</View>
			);
		}

		if(this.state.mqttError) {
			return (
				<View style={[styles.alignCenter, {height: this.height}]}>
					<ConnectionFailed />
				</View>
			);
		}

		return (
			<View>
				{!this.state.mqttError &&
					<ActivityIndicator style={{alignSelf: 'center', marginTop: 50}} />
				}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	alignCenter: {
		flex: 1,
		alignItems: 'center',
    	justifyContent: 'center',
	}
});

const mapStateToProps = (state: any) => {
    const { app } = state
    return { app }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        setConfig,
		setLogin,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(LoadAppData);
