import { Package } from "./Interfaces/Bundle";

export interface ILocation {
    street: string;
    housenumber: string;
    postalcode: string;
    addition?: string;
    type?: string;
    fname: string;
    lname: string;
    id?: number;
    active?: boolean;
    description?: string;
    glong?: number;
    glat?: number;
    city: string;
    country?: string;
    invoice?: boolean;
    company?: string;
    phone?: string;
    isPublicLocation?: boolean;
}

export enum shippingCountries {
    NL = 'NL',
    BE = 'BE',
}

export interface IEmployee {
    phone: number;
    role: string;
    id?: number;
    glong: number;
    glat: number;
}

export interface IDelivery {
    timestamp: number;
    date: number
    day: number;
    time: number;
    comment: string;
    windowstart: number;
    windowend: number;
    id?: number;
    status: string;
    glong: number;
    glat: number;
    location: ILocation;
    employee: IEmployee;
}
export interface IProduct {
    id?: number;
    startdate: number;
    enddate: number;
    category: string;
    priority: number;
    intname: string;
    published: number;
	packages?: any;
	// This is added in the front end. Used to determine the amount to order.
	amount?: number;
}

export interface IPackage {
    id: number;
    price: number;
    vasePrice: number;
    wishcardPrice: number;
    chocolonelyPrice: number;
    size: string;
    flowers: number;
    image: string;
    category: string;
    product: IProduct;
    locale: any;
	// This is added in the front end. Used to determine the amount to order.
	amount?: number;
    packageIntname: string;
}

export interface IClient {
    id?: number;
    role: string;
    phone: string;
    business: boolean;
    taxNr: string;
    notifications: boolean;
    emails: boolean;
}

export interface IUser {
	company_name: string | undefined;
	business: any;
    companyName?: string;
    emails?: boolean;
    notifications?: boolean;
    vat_number?: string;
    coc?: string;
    is_company: boolean;
    id?: number;
    firstname: string;
    firstName?: string;
    lastname: string;
    email: string;
    type: string;
    mollieID: string;
    pushId: string;
    createdAt: number;
    appAccount: boolean;
    client: IClient;
	locations?: any;
	phone?: string;
	password? : string;
}

export interface ISubscription {
    id?: number;
    package: Package;
    startdate: number;
    enddate: number;
    status: string;
    repetition: string;
    lastdelivery: number;
    nextdelivery: string;
    invoice: string;
    comment: string;
    orders: number;
    windowstart: number;
    windowend: number;
    amount: number;
    isBusiness: false;
    category: string;
    size: string;
    time: number;
    day: number;
	location: ILocation;
}

export interface IOrderPayment {
    business: boolean;
    collection_date: number;
    id: number;
    invoiced: boolean;
    molliecode: string;
    payed: boolean;
    paytype: string;
    price: number;
    reference: string;
    status: string;
}

export interface IOrder {
    id?: number;
    status: string;
    statusHistory: string;
    vase: boolean;
    amount: number;
    type: string;
    repetition: string;
    reference: string;
    timestamp: number;
    payment: null,
	product: string;
    delivery: IDelivery;
    package: IPackage;
    user: IUser;
    subscription: ISubscription;
	date: string;
}

export interface IProfile {
	appAccount: boolean;
	client: any;
	createdAt: number;
	email: string;
	firstname: string;
	id: number;
	lastname: string;
	locations: ILocation[];
	mollieID: string;
	pushId: string;
	type: string;
}

export interface ICreateOrder {
    billingAddress: number;
    deliveryAddress: number;
    orders: ICreateOrderOrder[];
    payment: {
        coupon: string;
        paytype: string;
    }
    flowerPrice: number;
}

export interface ICreateOrderOrder {
    product: number;
    packageId: number;
	// de type is de repitition.
    type: string;
    delivery: {
        day: number; // dag van de week
        date: number; // dag als timestamp
        windowstart: number; // seconds after 00:00 (08:06)
        windowend: number; // seconds after 00:00 (12:30)
        comment: string; // opmerking
        vaseCode?: string;
        wishcardCode?: string;
        chocolonelyCode?: string;
    };
    amount: number;
    vase: boolean;
}

export interface ICreateOrderResult {
    status: string;
    payment: string;

}
