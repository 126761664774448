import * as React from 'react';
import { Package } from '../../API/Interfaces/Bundle';
import { EURO } from '../../Util/currency';
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';
import TouchableOpacity from '../CrossPlatform/TouchableOpacity/TouchableOpacity';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';

const vaseModal = (props: { package: Package, vase: boolean | undefined, bubbles: boolean | undefined, chocolonely: boolean | undefined, vaseCode: string, staticVasePrice?: number,
        setVase: (v: boolean) => void, setVaseCode: (c: string) => void, setBubbles: (v: boolean) => void, setChocolonely: (v: boolean) => void, nextPage: () => void, mobileView: boolean }) => {
    const mobileView = props.mobileView;
    const efloraVaseCodeIsM180 = ['AC0004', 'AC0005', 'AC0006', 'AC0010', 'AC0011', 'AC0012', 'AC0013', 'AC0014', 'AC0015'];
    const efloraProductCode = props.package.packageIntname ? props.package.packageIntname.replace(/\s/g, '').split(/\|/g)[1] : '';
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
            <div>
                <div>
                    { props.package.category.substring(0, 13) !== 'E-Flora-Plant' &&
                        <div>
                            <MakeText style={{ fontSize: mobileView ? 24 : 36 }} fontFamily='Arno Pro'>Vaas toevoegen?</MakeText>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <TouchableOpacity testID='yesVase' onPress={() => props.setVase(false)} style={{ display: 'flex', flexDirection: 'row', marginTop: mobileView ? 25 : 25, alignItems: 'center' }}>
                                        <div style={{ width: 100, height: 20 }} />
                                        <input type="checkbox" id="vaas" name="vaas" style={{ marginLeft: 20, marginRight: 10 }} defaultChecked={props.vase == false} />
                                        <MakeText style={{ fontSize: 16, width: 150 }} fontFamily='Open Sans'>Zonder vaas</MakeText>
                                    </TouchableOpacity>
                                    <TouchableOpacity testID='noVase' onPress={() => props.setVase(true)} style={{ display: 'flex', flexDirection: 'row', marginTop: mobileView ? 25 : 50, alignItems: 'center' }}>
                                        { props.package.category.substring(0, 7) === 'E-Flora' &&
                                            <img src={ efloraVaseCodeIsM180.includes(efloraProductCode) ? 'https://websyn.nl/img/M180.jpg' : 'https://websyn.nl/img/M181.jpg'} style={{ width: 100, height: 100 }} />
                                        }
                                        { (props.package.category === 'Seasonal Flowers' || props.package.category === 'Royal Roses') &&
                                            <img src={ 'https://websyn.nl/img/M180.jpg'} style={{ width: 100, height: 100 }} />
                                        }
                                        <input type="checkbox" id="vaas" name="vaas" style={{ marginLeft: 20, marginRight: 10 }} defaultChecked={props.vase}
                                            onChange={() => {
                                                const result = efloraVaseCodeIsM180.includes(efloraProductCode) ? 'M180' : 'M181';
                                                return props.setVaseCode(result);
                                            }}
                                        />
                                        <MakeText style={{ fontSize: 16, width: 150 }} fontFamily='Open Sans'>Met vaas + {EURO(props.staticVasePrice ? props.staticVasePrice : props.package.vasePrice)}</MakeText>
                                    </TouchableOpacity>
                                </div>
                            </div>
                        </div>
                    }
                    <div style={{ height: '100%' }}>
                        <Button testID='nextPage' textStyle={{ fontSize: 18, width: '100%', height: 40, fontFamily: 'Open Sans' }} style={{ width: 120 }} disabled={ props.vase === undefined && props.package.category.substring(0, 13) !== 'E-Flora-Plant' ? true : false} text={'Volgende'} color={'greenWhite'} onPress={() => props.nextPage()} />
                    </div>
                </div> 
                { routingName !== 'amsterdam' &&
                    <div>
                        <div className="divider" style={{width: '100%', height: '3em', borderBottom: '1px solid lightgrey', margin: '0 0 1em 0'}}/>
                        <MakeText style={{ fontSize: mobileView ? 24 : 36 }} fontFamily='Arno Pro'>Cadeau toevoegen?</MakeText>
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                {/* <TouchableOpacity testID='yesVase' onPress={() => props.setBubbles(!props.bubbles)} style={{ display: 'flex', flexDirection: 'row', marginBottom: mobileView ? 25 : 25, marginTop: mobileView ? 35 : 35, alignItems: 'center' }}>
                                    <div style={{ width: 100, height: 20 }} />
                                    <input type="checkbox" id="bubbles" name="bubbles" style={{ marginLeft: 20, marginRight: 10 }} checked={props.bubbles} />
                                    <MakeText style={{ fontSize: 16, width: '100%' }} fontFamily='Open Sans'>Bubbles of andere drank, ook alcoholvrij</MakeText>
                                    </TouchableOpacity> */}
                                <TouchableOpacity testID='yesVase' onPress={() => props.setChocolonely(!props.chocolonely)} style={{ display: 'flex', flexDirection: 'row', marginBottom: mobileView ? 25 : 25, marginTop: mobileView ? 25 : 25, alignItems: 'center' }}>
                                    <div style={{ width: 100, height: 20 }} />
                                    <input type="checkbox" id="chocolonely" name="chocolonely" style={{ marginLeft: 20, marginRight: 10 }} checked={props.chocolonely} />
                                    <MakeText style={{ fontSize: 16, width: '100%' }} fontFamily='Open Sans'>Chocolonely + {EURO(4.0)}</MakeText>
                                </TouchableOpacity>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}
vaseModal.displayName = 'VaseModal';
export default vaseModal;