import axios from 'axios';

export class SlackLiveness {
    public static inst: SlackLiveness;
    public static getInstance(): SlackLiveness {
        if (!this.inst) {
            this.inst = new SlackLiveness();
        }
        return this.inst;
    }

    // Prevent sending to slack twice in a row due to re-renders. Does not prevent more sending if page is refreshed.
    private backOffTimeMs = 10000;
    private lastSend = 0;

    public async reportProblem() {
        const now = new Date().getTime();

        if (now < this.lastSend + this.backOffTimeMs) {
            return;
        }

        this.lastSend = now;

        const url = 'https://hooks.slack.com/services/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';
        const data = {text: `User unable to reach ${process.env.NODE_ENV} back-end to get config.`};
        try {
            await axios.post(url, JSON.stringify(data), {
                // Following config is required to prevent CORS error. Is hacky but necessary.
                withCredentials: false,
                transformRequest: [(data, headers) => {
                    delete headers.post["Content-Type"];
                    return data;
                }]
            });
        } catch {
            console.log('Reporting config error failed.');
        }
    }
}
