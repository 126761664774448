import ITranslations from './../ITranslations';

var EN: ITranslations = {
    welcomeMessage: "Welcome to the MakeTek starter kit!",
    currentLanguage: "The currently selected language is:",
    counterPage: "This is the todos page.",

    // Todo component
    id: "id",
    userid: "User id",
    title: "title",
}

export default EN;