import * as React from 'react';

import BasicPage from './Template/BasicPage';
import TffForBusinessComponent from './../Components/TffForBusiness/TffForBusiness';
import { Helmet } from "react-helmet";


export default class TffForBusiness extends React.Component<any> {

    render() {

        const jsonld = {
            "@context": "http://schema.org/",
            "@type": "Webpage",
            "name": "Bloemen voor kantoor",
            "description": "Als family member krijg je periodiek ��n of meerdere boeketten aan jouw kantoor, winkel of co-working space geleverd. Dit doen we op basis van een flexibel abonnement. Dus jij bepaalt wanneer en hoe vaak we langskomen en welke bloemen we voor jouw bedrijf meenemen.",
            "url": "https://www.theflowerfamily.nl/kantoor"
        };

        return (
            <BasicPage navigation={this.props.navigation}>
                <Helmet>
                    <title> Kantoor </title>
                    <link rel="canonical" href="https://www.theflowerfamily.nl/kantoor" />
                    <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
                </Helmet>
				<TffForBusinessComponent />
			</BasicPage>
        );
    }
}
