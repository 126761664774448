const checkUrl = (url: string) => {

	if(url.substr(0, 5) === 'https') {
		return url;
	}

	return `http://theflowerfamily.nl/storage/stories/${url.split('/')[2]}`;
}

export default checkUrl;
