import * as React from 'react';
import MakeText from '../Common/MakeText/MakeText';

export default (props: any) => (
	<div style={{display: 'flex', flexDirection: 'column'}}>
		{props.company &&
			<MakeText style={{letterSpacing: 0.72875, fontSize: 14, marginBottom: 5}}>{props.company}</MakeText>
		}
		<div className='col'>
			{(props.tav && props.tav.split('|')[0]) &&
				<MakeText style={{letterSpacing: 0.72875, fontSize: 14, marginRight: 5}}>
					Voor: {props.tav.split('|')[0].substring(0, 19)}{props.tav.split('|')[0].length > 20 ? '...' : ''}
				</MakeText>
			}
			{(props.tav && props.tav.split('|')[1]) &&
				<MakeText style={{letterSpacing: 0.72875, fontSize: 14}}>
					Door: {props.tav.split('|')[1].substring(0, 19)}{props.tav.split('|')[1].length > 20 ? '...' : ''}
				</MakeText>
			}
		</div>
		<MakeText style={{fontSize: 15}}>{props.street} {props.housenumber}{(props.addition !== '' && props.addition !== undefined) && `-${props.addition}`}</MakeText>
		<MakeText style={{fontSize: 15}}>{props.postalcode.toUpperCase()}, {props.city}</MakeText>
	</div>
);
