declare const global: any;
if (typeof global.__dirname === 'undefined') global.__dirname = '/'
if (typeof global.__filename === 'undefined') global.__filename = ''
if (typeof global.process === 'undefined') {
    global.process = require('process')
} else {
    const bProcess = require('process')
    for (var p in bProcess) {
        if (!(p in global.process)) {
            global.process[p] = bProcess[p]
        }
    }
}
global.process.browser = false
if (typeof global.Buffer === 'undefined') global.Buffer = require('buffer').Buffer

import * as React from 'react';

// Redux
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import appReducer from './store/CombinedReducers';

import LoadAppData from './Util/loadAppData/LoadAppData';

let Routing = require('./Components/CrossPlatform/Routing/Routing');

// Create a new store
const store = createStore(appReducer);

import { useScreens } from 'react-native-screens';
useScreens();

export default class App extends React.Component {

	state = {
		loadedAppData: false,
	}

	setLoadAppData(state: boolean) {
		this.setState({loadedAppData: state});
	}

	public render() {

		return (
			<Provider store={store}>

				{/* Before the website is rendered, the config file is fetched from the backend. */}
				{!this.state.loadedAppData && <LoadAppData setLoadAppData={this.setLoadAppData.bind(this)} />}

				{/* If the config file has been fetched successfully, render the website. */}
				{this.state.loadedAppData && <Routing.default />}
			</Provider>
		);
	}
}
