import * as React from 'react';
import { View, ActivityIndicator } from 'react-native';
import { getUsers, updateUser } from "../../API/Http/Requests";
import MakeText from '../Common/MakeText/MakeText';

import styles from './AccountStyles';
import globalStyles from '../../Styles/styles';
import moment from 'moment';
// import { IUser } from '../../API/IDataService';
/*
interface IState {
	users: any[];
	edit: boolean;
	errorText: string;
	loading: boolean;
}
*/

moment.locale('nl');

export default class EditUsers extends React.Component<any> {

	state: any = {
		profile: undefined as any,
		editedFields: {} as any,
		users: [],
		oldPass: '',
		edit: false,
		errorText: '',
		loading: false,
	}

	async componentDidMount() {
		const users = await getUsers();
		for (const user of users) {
			user.products = 0;
			user.status = 0;
			user.blocked = user.blocked ? 'blocked' : 'active';
		}
		// this.birthDateToReadable(profile);
		this.setState({ users });
	}

	toggle(index: number, id: number): void {
		const users = this.state.users;
		if (id === 0) {
			users[index].products = (this.state.users[index].products + 1) % 2;
		}
		if (id === 1) {
			if (users[index].type === 'inkoper_admin') {
				users[index].type = 'inkoper';
			} else {
				users[index].type = 'inkoper_admin'
			}
		}
		if (id === 2) {
			users[index].status = (this.state.users[index].status + 1) % 3;
		}
		this.setState({ users });
	}

	setUserType(index: number, event: any) {
		this.setState({loading: true});
		const users = this.state.users;
		const user = users[index];
		user.type = event.currentTarget.value;

		updateUser(user).then(_ => {
			this.setState({users, loading: false});
		}).catch(_ => {
			this.setState({errorText: 'Gebruiker updaten is mislukt.', loading: false});
		});
	}

	setUserStatus(index: number, event: any) {
		this.setState({loading: true});
		const users = this.state.users;
		const user = users[index];
		user.blocked = event.currentTarget.value === 'blocked';

		updateUser(user).then(_ => {
			user.blocked = user.blocked ? 'blocked' : 'active';
			this.setState({users, loading: false});
		}).catch(_ => {
			this.setState({errorText: 'Gebruiker updaten is mislukt.', loading: false});
		});
	}

	userRow(user: any, index: number): JSX.Element {
		return (
			<View style={[globalStyles.row, globalStyles.fullWidth, globalStyles.spaceBetween, { marginBottom: 2 }]}>
				<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{user.firstName} {user.lastName}</MakeText>
				<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{user.email}</MakeText>
				<select value={user.type} onChange={(e) => this.setUserType(index, e)} style={{marginRight: 15, width: '100%'}}>
					<option value='inkoper-admin'>Admin</option>
					<option value='inkoper'>Gebruiker</option>
				</select>
				<select value={user.blocked} onChange={(e) => this.setUserStatus(index, e)} style={{width: '100%'}}>
					<option value={'blocked'}>Geblokkeerd</option>
					<option value={'active'}>Actief</option>
				</select>
			</View>
		)
	}

	render() {

		if (!this.state.users) {
			return (
				<View style={styles.columnWebView}>
					<View style={{ marginTop: 20, marginBottom: 20 }}>
						<ActivityIndicator size="large" color="#B9DCBF" />
					</View>
				</View>
			);
		}

		return (
			<View>
				{this.state.errorText.length ? <MakeText style={styles.errorText} fontFamily="Open Sans">{this.state.errorText}</MakeText> : undefined}
				{this.state.loading && <ActivityIndicator color="#B9DCBF" />}
				<View style={[globalStyles.row, globalStyles.fullWidth, globalStyles.spaceBetween]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Naam </MakeText>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">E-mail </MakeText>
					{/* <MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Geboortedatum </MakeText>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Producten </MakeText> */}
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Rol </MakeText>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Status </MakeText>
				</View>
				{this.state.users.map((usr, index) => this.userRow(usr,index))}
			</View>
		);
	}
}
