import * as React from 'react';
import { StyleSheet, View, ActivityIndicator, Platform, Image } from 'react-native';
import MakeText from '../Common/MakeText/MakeText';
import WebView from '../CrossPlatform/WebView/WebView';
import Stories from './../Home/Stories/Stories';
import checkImageUrl from './../../Util/checkImageUrl';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';
import { Helmet } from "react-helmet";
import { getStories } from '../../API/Http/Requests';

export default class StoryPage extends React.Component<any>{
    state = {
        story: {} as any,
        loading: true,
        error: undefined,
    }

    componentWillReceiveProps(newProps) {
        if(this.props.navigation.state.params.storyID !== newProps.navigation.state.params.storyID) {
            this.setStoryState(newProps.navigation.state.params.storyID, false)
        }
    }

    componentWillMount() {
        if(this.props.navigation.state.params.storyID === undefined) {
        	this.props.navigation.navigate('Stories');
        }
    }

    componentDidMount() {
        this.setStoryState(this.props.navigation.state.params.storyID, false)
    }

    setStoryState(id: any, fromStoryPage: boolean) {

        getStories(id).then((story) => {
            if(story === undefined) {
                throw new Error;
            }
            this.setState({
                story: story,
                loading: false
            });
            if(fromStoryPage && Platform.OS === 'web') {
                window.scrollTo(0,0);
                window.history.pushState(null, 'story', `/story/${id}/${encodeURIComponent(story.title.replace(/\s/g, '-'))}`);
            }
            GoogleAnalytics.getInstance().hit('Story', {id, title: encodeURIComponent(story.title.replace(/\s/g, '-'))})
        }).catch( e => {
            console.log(e);
            this.setState({
                loading: false,
                error: 'Story ophalen is mislukt.'
            })
		});
    }

    render() {


        const styles = StyleSheet.create({
            image: {
                width: this.props.width,
                height: 200,
                ...Platform.select({
                    ios: {
                        height: 200,
                    },
                    android: {
                        height: 200,
                    },
                    web: {
                        height: this.props.height * 0.6
                    }
                })
            },
            header: {
                fontSize: 56,
                marginTop: 30,
                letterSpacing: 2.13,
                color: '#414141',
                textAlign: 'center'
            },
            subHeader: {
                fontSize: 26,
                lineHeight: 32,
                margin: 15,
                letterSpacing: 0.9275,
                color: '#414141',
                textAlign: 'center',
                fontStyle: 'italic'
            }
        });

        if(this.state.error) {
            return (
                <View style={{marginVertical: this.props.height / 4}}>
                    <MakeText style={{fontSize: 14, lineHeight: 32, marginTop: 100, letterSpacing: 0.9275, color: '#414141', textAlign: 'center'}}>{this.state.error}</MakeText>
                </View>
            );
        }

		if(this.state.loading) {
            return (
				<View style={{marginVertical: this.props.height / 4}}>
	                <MakeText style={{fontSize: 14, lineHeight: 32, marginTop: 100, letterSpacing: 0.9275, color: '#414141', textAlign: 'center'}}>Story ophalen...</MakeText>
	                <ActivityIndicator size="large" />
	            </View>
			);
        }

        // Test with rich data for one story
        const testLD = {
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": "Boeket op vaas verzorgen - Tips & Tricks - Bloemen verzorgingstips ",
            "description": "Iedereen heeft een beetje liefde en verzorging nodig - dus ook jouw bloemen.",
            "thumbnailUrl": "https://theff.nl/services/media/1a6eaa470c7d3699d530fa950aa6eb659eaa74c4260ca76f2def4842289ebc7d/1827defe5a547cc43c2ca4d7b648dd0161462de126ff417f639099e4c45febb5.jpg",
            "contentUrl": "https://www.youtube.com/watch?v=CjLx8OedrL8",
            "embedUrl": "https://www.youtube.com/embed/CjLx8OedrL8",
            "uploadDate": "2019-01-05T08:00:00+08:00",
            "duration": "P2MT43S",
            "hasPart": [
                {
                    "@type": "Clip",
                    "@id": "Clip1",
                    "name": "Stap 1 - Vaas Schoonmaken",
                    "startOffset": 52,
                    "endOffset": 83,
                    "url": "https://www.youtube.com/watch?v=4AOI1tZrgMI&t=52"
                },
                {
                    "@type": "Clip",
                    "@id": "Clip2",
                    "name": "Stap 2 - Verzorging van bloemen",
                    "startOffset": 83,
                    "endOffset": 132,
                    "url": "https://www.youtube.com/watch?v=4AOI1tZrgMI&t=83"
                },
                {
                    "@type": "Clip",
                    "@id": "Clip3",
                    "name": "Stap 3 - De Juiste plek in huis",
                    "startOffset": 133,
                    "endOffset": 151,
                    "url": "https://www.youtube.com/watch?v=4AOI1tZrgMI&t=133"
                }
            ]
        };

        return (
            <View>
                <Helmet>
                    <title> The Flower Family Stories - {this.state.story.title} </title>
                    <link rel="canonical" href={`https://www.theflowerfamily.nl/story/${this.state.story.id}/${encodeURIComponent(this.state.story.title.replace(/\s/g, '-'))}`} />
                    {this.state.story.id === 7 &&
                         <script className='structured-data-list' type="application/ld+json">{JSON.stringify(testLD)}</script>
                    }
                </Helmet>
	            <View>
	                <Image source={{uri: checkImageUrl(this.state.story.img_header)}} style={styles.image}/>
	            </View>

				<View style={{ marginTop: 60}}>
	            	<MakeText fontFamily='Crimson Text' style={styles.header}>{this.state.story.title}</MakeText>
				</View>

	            <MakeText style={styles.subHeader}>"{this.state.story.quote}"</MakeText>

	            <WebView html={this.state.story.body} width={this.props.width} />

				<View style={{marginTop: 54, marginBottom: 34}}>
					<Stories width={this.props.width} height={this.props.height} navigation={this.props.navigation} setStory={(id) => this.setStoryState(id, true)} title="Meer stories" buttonText="Lees al onze stories" />
				</View>

        	</View>
		);
    }
}
