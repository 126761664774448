// Checks what environment is running (dev or production) and returns the correct config file.
let config: any;
let testConfig: JSON;

const pathSplit = window.location.pathname.split('/');
const domain = pathSplit.length > 2 ? window.location.pathname.split('/')[2] : undefined


if (domain === 'consumersite' ) {
    config = require('./config.production.json');
} else if (process.env.REACT_APP_NODE_ENV === "portal") {
    config = require('./config.portal.json');
} else {
    config = require('./config.development.json');
}

if(process.env.REACT_APP_TEST_ENV === "dev") {
    testConfig = require('./testConfig.development.json');
} else {
    testConfig = require('./testConfig.build.json');
}

export default config;
export {testConfig};
