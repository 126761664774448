import * as React from 'react';
import { View, StyleSheet, Platform, Image } from 'react-native';
import { phone } from '../../Util/screen/HandleLayout';

import EnterEmail from './EnterEmail';
import EnterCode from './EnterCode';
import Button from '../CrossPlatform/Button/Button';
import EnterNewPassword from "./EnterNewPassword";

export default class ForgotPassword extends React.Component<any> {

	state = {
		emailEntered: false,
		codeEntered: false,
		email: '',
		code: '',
	}

	setEmailEntered(emailEntered: boolean, email: string) {
		this.setState({emailEntered, email});
	}

	setCodeEntered(codeEntered: boolean, code: string) {
		this.setState({codeEntered, code});
	}

	render() {
		const mobileView = phone(this.props.width);
		const imgHeight = mobileView ? 0 : this.props.height / 10 * 8;
		const styles = StyleSheet.create({
			backgroundImage: {
				position: 'absolute',
				width: this.props.width,
				height: 200,
				top: 0,
				alignSelf: 'center',
				...Platform.select({
						ios: {
								height: 200,
						},
						android: {
								height: 200,
						},
						web: {
								height: imgHeight,
						}
					}),
			},
			orText: {
				textAlign: 'center',
        		fontSize: 12,
				marginVertical: 10,
			},
		});

		return (

			<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', minHeight: imgHeight }}>
				<Image
					style={styles.backgroundImage}
					source={require('./../../images/TffForBusiness.jpg')}
				/>

				<View style={{borderWidth: 1, borderColor: 'white', shadowOffset: {width: 1, height: 1}, shadowOpacity: 0.2, shadowRadius: 5, paddingVertical: 50, paddingHorizontal: 50, marginHorizontal: 0, marginTop: mobileView ? 30 : 0, backgroundColor: 'white', width: mobileView ? this.props.width * 0.9 : 500}}>

					{ !this.state.emailEntered &&
						<EnterEmail setEmailEntered={this.setEmailEntered.bind(this)} />
					}

					{this.state.emailEntered && !this.state.codeEntered &&
						<EnterCode email={this.state.email} setCodeEntered={this.setCodeEntered.bind(this)} />
					}

					{ this.state.emailEntered && this.state.codeEntered &&
						<EnterNewPassword email={this.state.email} code={this.state.code} navigation={this.props.navigation} />
					}

					{this.props.back &&
						<Button text='Terug' color='greenWhite' style={{alignSelf: 'center', marginTop: 15}} onPress={() => this.props.navigation.navigate(this.props.back)} />
					}

				</View>
			</View>
		);
	}
}
