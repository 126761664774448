import * as React from 'react';
import validator from 'validator';
import { View, TextInput } from 'react-native';
import {deleteLocation, updateLocation} from "../../API/Http/Requests";

import styles from './AccountStyles';
import AccountItemHeader from './AccountItemHeader';
import MakeText from '../Common/MakeText/MakeText';
import { ILocation } from '../../API/IDataService';
import globalStyles from '../../Styles/styles';
import Button from '../CrossPlatform/Button/Button';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';

interface IAddress {
	location: ILocation;
	number: number;
	navigation: any;
	width: number;
	refresh: () => void;
}

interface IState {
	OGLocation: ILocation;
	location: ILocation;
	edit: boolean;
	errMsg: string;
}

export default class Address extends React.Component<IAddress, IState> {

	state = {
		OGLocation: this.props.location,
		location: this.props.location,
		edit: false,
		errMsg: '',
	}

	flipEdit() : void {
		this.setState({edit: !this.state.edit, location: this.state.OGLocation});
	}

	onChange(varName: string, value: any) : void {
		let tmpLocation = Object.assign({}, this.state.location);

		tmpLocation[varName] = value;

		this.setState({location: tmpLocation});
	}

	updateData(location: ILocation) {
		if (!this.validateLocation(location)) {
			this.setState({errMsg: 'Een of meerdere van de velden zijn niet correct ingevuld. Alle velden zijn verplicht.'});
			return;
		} else {
			this.setState({errMsg: ''});
		}
		if(location.id) {
			updateLocation(location as any).then( result => {
				this.setState({location: result as any, edit: false});
			}).catch( e => {
				console.log(e);
			})
		}
	}

	validateLocation(location: ILocation): boolean {
		if(validator.isLength(location.city, {min: 2, max: 128})
			&& validator.isLength(location.street, {min: 2, max: 128})
			&& validator.isLength(location.housenumber, {min: 1, max: 128})
			&& validator.isLength(location.postalcode, {min: 1, max: 128})
			&& validator.isLength(location.fname, {min: 0, max: 128})
			&& validator.isLength(location.lname, {min: 1, max: 128})
			&& (routingName !== 'amsterdam' || validator.isLength(location.phone, {min: 6, max: 16}))
		) return  true;

		return false;
	}

	deleteAddress() {
		if(this.state.location === undefined || this.state.location.id === undefined) {
			return;
		}
		deleteLocation(this.state.location.id).then(_ =>
			this.props.refresh()
		).catch(() => this.props.refresh() );
	}

	render() {

		if(this.state.edit) return this.editableAddress();

		return (
			<View style={styles.adresBottomMargin}>

				<AccountItemHeader title={"Adres " + this.props.number} onPress={() => this.flipEdit() } message='aanpassen' onPress2={() => this.deleteAddress()} message2='verwijderen' showMessage2={routingName === 'amsterdam'}/>

				<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">{this.state.location.fname} {this.state.location.lname}</MakeText>
				{routingName === 'amsterdam' && <MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">{this.state.location.phone}</MakeText>}
				<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">{this.state.location.street} {this.state.location.housenumber}{this.state.location.addition !== '' && `-${this.state.location.addition}`}</MakeText>
				<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">{this.state.location.postalcode} {this.state.location.city.replace('&#x27;', "'")}</MakeText>

			</View>
		);
	}

	editableAddress() : JSX.Element {
		return (
			<View style={styles.adresBottomMargin}>

				<AccountItemHeader title={"Adres " + this.props.number} onPress={() => this.flipEdit() } message='annuleer' />

				<View style={[globalStyles.row, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Voornaam: </MakeText>
					<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("fname", e)} value={this.state.location.fname}></TextInput>
				</View>

				<View style={[globalStyles.row, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Achternaam: </MakeText>
					<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("lname", e)} value={this.state.location.lname}></TextInput>
				</View>

				{routingName === 'amsterdam' &&
					<View style={[globalStyles.row, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Telefoonnummer: </MakeText>
						<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("phone", e)} value={this.state.location.phone}></TextInput>
					</View>
				}

				<View style={[globalStyles.row, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Straat: </MakeText>
					<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("street", e)} value={this.state.location.street}></TextInput>
				</View>

				<View style={[globalStyles.row, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Huisnummer: </MakeText>
					<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("housenumber", e)} value={this.state.location.housenumber}></TextInput>
				</View>

				<View style={[globalStyles.row, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Toevoeging: </MakeText>
					<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("addition", e)} value={this.state.location.addition}></TextInput>
				</View>

				<View style={[globalStyles.row, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Postcode: </MakeText>
					<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("postalcode", e)} value={this.state.location.postalcode}></TextInput>
				</View>

				<View style={[globalStyles.row, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Stad: </MakeText>
					<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("city", e)} value={this.state.location.city.replace('&#x27;', "'")}></TextInput>
				</View>

				<Button text={this.state.edit ? "Opslaan" : "Bewerken"} color="blackOpacity" style={{alignSelf: 'center', marginTop: 30, marginBottom: 33}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} onPress={() => this.updateData(this.state.location)} />

				{this.state.errMsg.length > 0 && <View><MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">{this.state.errMsg}</MakeText></View>}
			</View>
		);
	}
}
