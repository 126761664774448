import { StyleSheet } from 'react-native';

const MOBILE: number = 1100;
const MOBILE_MARGIN: number = 30;

const styles = (width: number) => {
	return StyleSheet.create({
		main: {
			alignSelf: 'center',
			marginTop: 55,
			width: width > MOBILE ? 1025 : width - MOBILE_MARGIN,
		},
		dash: {
			height: 1,
			width: width > MOBILE ? 1025 : width - MOBILE_MARGIN,
			marginTop: 26
		},
		rightDash: {
			width: width > MOBILE ? 1025 - 261 : width - MOBILE_MARGIN,
			marginBottom: 25,
		},
		image: {
			width: 235,
			height: 283,
		},
		left: {
			marginRight: width > MOBILE ? 26 : 0,
			display: width > MOBILE ? 'flex' as any : 'none',
		},
		right: {

		},
		content: {
			alignItems: 'flex-start',
			marginTop: 32.9,
			flexDirection: width > MOBILE ? 'row' : 'column',
		},
		buttonViewMargin: {
			marginTop: 10,
			marginBottom: 10,
		},
		buttonViewMarginOrderDetail: {
			marginTop: 0,
			marginBottom: 50,
		},
		statusSwitchMessage: {
			marginTop: 15,
			color: 'white',
			fontWeight: 'bold',
			borderRadius: 5,
			padding: 10,
			fontSize: 14,
		},
		memershipDetailsHeaderMargin: {
			marginBottom: 19, 
		}
	});
}

export default styles;
