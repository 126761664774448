import * as React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import {getMe, isLoggedIn, logout, resendConfirmRegistration} from "../../API/Http/Requests";

import AccountColumnTemplate from './../../Containers/Template/AccountColumnTemplate';
import ProfileData from './ProfileData';
import OrderData from './OrderData';
// import CouponData from './CouponData';
import MembershipData from './MembershipData';

import Storage from './../CrossPlatform/Storage/Storage';
import Button from './../CrossPlatform/Button/Button';
import LocationData from './../Account/LocationData';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLogin } from './../../store/actions/AppActions';
import { setProducts } from './../../store/actions/ProductActions';
import CompanyData from './CompanyData';
import CouponData from './CouponData';
import MakeText from '../Common/MakeText/MakeText';
import TFFExclusiveData from './TFFExclusiveData';
import config from '../../config/config.selector';
import EditUsers from './EditUsers';
import InkoopDashbaord from './InkoperDashboard';
import OrderComments from './OrderComments';
import DeliveryReport from './DeliveryReport/DeliveryReport';
import { IProfile } from '../../API/IDataService';
import { routingName } from './../CrossPlatform/OrderProcess/NewOrderProcess.web';

class Account extends React.Component<any> {

	storage = new Storage;

	MOBILE = 1100;

	state: {
		loggingOut: boolean,
		profile: IProfile | undefined,
		unconfirmed: boolean,
		resendMsg: undefined | string
	} = {
		loggingOut: false,
		profile: undefined,
		unconfirmed: false,
		resendMsg: undefined,
	}

	componentDidMount() {
		// First, check if the user is logged in.
		isLoggedIn().then( (result: boolean) => {
			if (!result) return this.props.navigation.navigate('Login');
			getMe().then( async (profile) => {
				if(!profile || !profile.account) return this.props.navigation.navigate('Login');
				this.setState({ profile });
			}).catch( _ => {
				return this.props.navigation.navigate('Login');
			});

		}).catch(e => {
			if (e.response.status === 403) {
				this.setState({unconfirmed: true});
			}
		})
	}

	async confirmRegistration() {
		this.setState({loggingOut: true});
		resendConfirmRegistration((await this.storage.retrieveData('user')).username).then(_ => {
			this.setState({loggingOut: false, resendMsg: 'Bevestigingsmail is opnieuw verstuurd.'});
		}).catch(_ => {
			this.setState({loggingOut: false, resendMsg: 'Bevestigingsmail sturen is mislukt.'});
		});
	}

	async attemptLogout() {
		this.setState({loggingOut: true});
		try {
			await logout()
		} catch {}

		this.setState({loggingOut: false});
		// Remove login data from localStorage.
		this.storage.removeData('user');
		this.storage.removeData('auth');
		if (process.env.REACT_APP_NODE_ENV !== "production") {
			this.storage.removeData('products');
			this.props.setProducts([]);
		}
		// Navigate to the home screen.
		this.props.navigation.navigate('Login');
	}

	calculateColumnWidth(width: number) : number {
		if(width > this.MOBILE) return 500;

		return this.props.width - 20;
	}

	render() {
		if (this.state.unconfirmed) {
			return (
				<div className='col mt-5' style={{alignItems: 'center'}}>
					<p className='largeText' style={{textAlign: 'center'}}>
						Het e-mailadres van dit account is nog niet bevestigd.<br />
						<span className='smallText' style={{textAlign: 'center'}}>
							Bij het registeren heb je een mail ontvangen met een bevestigingslink. Controleer ook je spam/ongewenste mails.
						</span>
					</p>

					<Button text="Bevestigingsmail opnieuw versturen" color="blackOpacity" disabled={this.state.loggingOut} textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: 16, letterSpacing: 2}} style={{alignSelf: 'center'}} onPress={() => this.confirmRegistration()} />
					{this.state.resendMsg &&
						<p className='smallText'>
							{this.state.resendMsg}
						</p>
					}
					<Button text="Uitloggen" color="blackOpacity" textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: 16, letterSpacing: 2}} style={{alignSelf: 'center', marginTop: 20}} onPress={() => this.attemptLogout()} />
					{this.state.loggingOut &&
						<ActivityIndicator style={{alignSelf: 'center', marginTop: 5}} />
					}
				</div>
			)
		}

		if (!this.state.profile) {
			return <div />
		}

		let styles: any = undefined;

		// Select the mobile, or big screen styles.
		if(this.props.width > this.MOBILE) {
			styles = webStyles;
		} else {
			styles = mobileStyles;
		}
		const COLUMNWIDTH: number = this.calculateColumnWidth(this.props.width);

		const videoTab = (
			<View style={generalStyles.generalColumnStyle}>
				<AccountColumnTemplate testID='tips' title="How-to video's" width={COLUMNWIDTH}>
					<div style={{position: 'relative'}}>
						<iframe
							style={{width: '100%', height: 300}}
							src="https://www.youtube.com/embed/CjLx8OedrL8"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
						<iframe
							style={{width: '100%', height: 300}}
							src="https://www.youtube.com/embed/13uzbBSPonc"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						></iframe>
					</div>
				</AccountColumnTemplate>
			</View>
		);

		return (
			<View>
				{this.state.profile.type === 'chef_bezorgtijden' && <DeliveryReport />}
				<MakeText testID='accountHeader' style={{fontSize: 36, letterSpacing: 2.2, alignSelf: 'center', marginTop: 30}} fontFamily='Arno Pro'>Mijn account</MakeText>
				{/* routingName === "amsterdam" &&
					<View style={{ alignSelf: 'center', marginTop: 49, marginBottom: 28}}>
						<AccountColumnTemplate testID='toelichting' title="Toelichting portal Amsterdam" width={this.props.width > this.MOBILE ? COLUMNWIDTH * 2 : COLUMNWIDTH}>
							<MakeText style={{fontSize: 16, alignSelf: 'center', marginTop: 15, width: '95%' }} fontFamily='Open Sans'>
								Welkom op The Flower Family Amsterdam portal! Op deze pagina zijn voor "besteller" accounts de ordergeschiedenis en memberships zichtbaar. Voor contractmanagers staan er ook overzichten van de gebruikers en een rapportage van alle geplaatste orders.<br/><br/>
								Bloemen kunnen besteld worden door op de "Webshop" knop te klikken. Deze staat rechtsbovenin het scherm op desktop. Op mobiel staat deze knop in het menu dat opent als linksboven op het hamburgermenu wordt geklikt. Op de "webshop" pagina.<br/>
								Op de webshop pagina kan je een product selecteren en met de Bestel nu knop naar de detailpagina van dit product.<br/>
								Hier kan je nog een keuze maken in de grote en het aantal, waarna gekozen kan worden voor een vaas en of een kaartje.<br/>
								Hierna wordt de bestelling in het winkelmandje geplaatst.<br/>
								Vanuit het winkelmandje kunnen de 4 bestelstappen doorlopen worden, waarbij stap 2 wordt overgeslagen.<br/>
								Na het doorlopen van deze stappen kan de order worden doorgevoerd, en  verschijnt deze bij de ordergeschiedenis op de account pagina.<br/><br/>
								Wil je een bestelling in het buitenland plaatsen? Plaats dan de bestelling en neem daarna contact op met +31 20 245 5887. Voor een bestelling in het buitenland worden extra kosten in rekening gebracht. <br /><br />
								Wil je een spoedbestelling plaatsen? Plaats dan de bestelling voor de eerstvolgende leverdatum en neem daarna contact op met +31 20 245 5887. Voor een spoedbestelling worden extra kosten in rekening gebracht.
							</MakeText>
						</AccountColumnTemplate>
					</View>
				*/}

				{/* This row is displayed as a column when the screen becomes small. */}
				<View style={styles.row}>
					{/* The columns on the left side of the page. */}
					<View style={generalStyles.left}>
						<View style={this.props.width > this.MOBILE ? generalStyles.columnMarginRight : {}}>

							{/* Aanpassen om bedrijfsgegevens te laten zien */}
							<View style={generalStyles.generalColumnStyle}>
								<AccountColumnTemplate title={(this.state.profile && (this.state.profile as any).business) ? "Bedrijfsgegevens" : "Persoonsgegevens"} width={COLUMNWIDTH}>
									{(this.state.profile && (this.state.profile as any).businesses.length > 0) ?
										<CompanyData navigation={this.props.navigation} width={COLUMNWIDTH} height={this.props.height} />
										:
										<ProfileData navigation={this.props.navigation} width={COLUMNWIDTH} height={this.props.height} />
									}
								</AccountColumnTemplate>
							</View>

							<View style={generalStyles.generalColumnStyle}>
								<AccountColumnTemplate testID='memberships' title="Webshop" width={COLUMNWIDTH}>
									<MembershipData width={COLUMNWIDTH} height={this.props.height} navigation={this.props.navigation} />
								</AccountColumnTemplate>
							</View>

							{(config as any).showExclusive && <View style={generalStyles.generalColumnStyle}>
								<AccountColumnTemplate testID='exclusive' title="TFF Exclusive" width={COLUMNWIDTH}>
									<TFFExclusiveData width={COLUMNWIDTH} height={this.props.height} navigation={this.props.navigation} />
								</AccountColumnTemplate>
							</View>}

							{ routingName ===  'amsterdam' &&
								<View style={generalStyles.generalColumnStyle}>
									<AccountColumnTemplate testID='comments' title="Opmerkingen/klachten" width={COLUMNWIDTH}>
										<OrderComments width={COLUMNWIDTH} height={this.props.height} navigation={this.props.navigation} />
									</AccountColumnTemplate>
								</View>
							}

							{ routingName !== 'amsterdam' && videoTab}

						</View>
					</View>

					{/* The columns on the right side of the page. */}
					<View style={generalStyles.right}>

						<View style={generalStyles.generalColumnStyle}>
							<AccountColumnTemplate title="Adressenboek" width={COLUMNWIDTH}>
								<LocationData width={COLUMNWIDTH} height={this.props.height} navigation={this.props.navigation} />
							</AccountColumnTemplate>
						</View>

						{/*<View style={generalStyles.generalColumnStyle}>*/}
						{/*	<AccountColumnTemplate title="Coupons" width={COLUMNWIDTH}>*/}
						{/*		<CouponData width={COLUMNWIDTH} height={this.props.height} navigation={this.props.navigation} />*/}
						{/*	</AccountColumnTemplate>*/}
						{/*</View>*/}

						<View style={generalStyles.generalColumnStyle}>
							<AccountColumnTemplate testID='orders' title="Ordergeschiedenis" width={COLUMNWIDTH}>
								<OrderData width={COLUMNWIDTH} height={this.props.height} navigation={this.props.navigation} />
							</AccountColumnTemplate>
						</View>

						{(config as any).showExclusive && <View style={generalStyles.generalColumnStyle}>
							<AccountColumnTemplate testID='coupons' title="Mijn kortingscodes" width={COLUMNWIDTH}>
								<CouponData width={COLUMNWIDTH} height={this.props.height} navigation={this.props.navigation} user={this.state.profile} />
							</AccountColumnTemplate>
						</View>}

						{ routingName ===  'amsterdam' && videoTab}

					</View>

				</View>

				{/*  */}
				{(this.state.profile && (this.state.profile as any).type === 'inkoper_admin') &&
					[<View style={{ alignSelf: 'center', marginTop: 49, marginBottom: 28}}>
						<AccountColumnTemplate testID='users' title="Gebruikers" width={this.props.width > this.MOBILE ? COLUMNWIDTH * 2 : COLUMNWIDTH}>
							<EditUsers width={COLUMNWIDTH} height={this.props.height} navigation={this.props.navigation} user={this.state.profile} />
						</AccountColumnTemplate>
					</View>,

					<View style={{ alignSelf: 'center', marginTop: 25, marginBottom: 28 }}>
						<AccountColumnTemplate testID='inkoperDash' title="Real-time rapportage" width={this.props.width > this.MOBILE ? COLUMNWIDTH * 2 : COLUMNWIDTH}>
							<InkoopDashbaord width={COLUMNWIDTH} height={this.props.height} navigation={this.props.navigation} />
						</AccountColumnTemplate>
					</View>
					]
				}

				{/* Logout button */}
				<View style={{alignSelf: 'center', marginTop: 49, marginBottom: 28}}>
					<Button text="Uitloggen" color="blackOpacity" textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: 16, letterSpacing: 2}} onPress={() => this.attemptLogout()} />
					{this.state.loggingOut &&
						<ActivityIndicator style={{alignSelf: 'center', marginTop: 5}} />
					}
				</View>

			</View>
		);
	}
}

const webStyles = StyleSheet.create({
	row: {
		flexDirection: 'row',
		alignSelf: 'center',
	},
});

const mobileStyles = StyleSheet.create({
	row: {
		flexDirection: 'column',
		alignSelf: 'center',
	},
});

const generalStyles = StyleSheet.create({
	generalColumnStyle: {
		marginTop: 29.9,
	},
	columnMarginRight: {
		marginRight: 24,
	},
	left: {
		flexDirection: 'column',
	},
	right: {
		flexDirection: 'column',
	}
})


const mapStateToProps = (state: any) => {
    const { app } = state
    return { app }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
		setLogin, setProducts
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Account);
