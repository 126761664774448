import * as React from 'react';
import { View, ActivityIndicator } from 'react-native';
import {getMe} from "../../API/Http/Requests";

// import { DataService } from './../../API/DataService';
import MakeText from '../Common/MakeText/MakeText';
import { ILocation } from '../../API/IDataService';
import Address from './Address';

export default class LocationData extends React.Component<any> {

    state = {
        locations: undefined as any,
    }

    componentDidMount() {
        this.refresh();
    }

    refresh() {
        getMe().then( profile => {
            this.setState({ locations: profile.locations });
        });
    }

    render() {

        if (!this.state.locations) {
            return (
                <View style={{ flexDirection: 'column', alignItems: 'center', margin: 5 }}>
                    <View style={{ marginTop: 20, marginBottom: 20 }}>
                        <ActivityIndicator size="large" color="#B9DCBF" />
                    </View>
                </View>
            );
        }

		if(!this.state.locations.length) {
			return (
				<View style={{ flexDirection: 'column', alignItems: 'center', margin: 5 }}>
                    <View style={{ marginTop: 20, marginBottom: 20, alignItems: 'center', justifyContent: 'center' }}>
                        <MakeText style={{marginBottom: 10}} fontFamily="Open Sans Italic">Je hebt nog geen adressen toegevoegd. Een adres kan worden aangemaakt tijdens het bestellen. <a href='/bestel' style={{textDecoration: 'underline'}}>Doe nu hier je eerste bestelling!</a></MakeText>
                    </View>
                </View>
			);
        }

        let bezorgIndex = 1;
        let factuurIndex = 1;

        return (
            <View>
                <MakeText fontFamily="Crimson Text" style={{fontSize: 26, letterSpacing: 2.2, fontWeight: 'bold'}}>Bezorgadressen</MakeText>

				{this.state.locations.map( (location: ILocation, index: number) => {
                    if(!location.active || (location !== undefined && location.invoice)) {
                        return;
                    }
					return (
						<Address location={location} number={bezorgIndex++} key={`${location.id} ${index}`} navigation={this.props.navigation} refresh={() => this.refresh()} width={this.props.width} />
					)
                })}

                <MakeText fontFamily="Crimson Text" style={{fontSize: 26, letterSpacing: 2.2, fontWeight: 'bold', marginTop: 10}}>Factuuradressen</MakeText>

				{this.state.locations.map( (location: ILocation, index: number) => {
                    if(!location.active || (location !== undefined && !location.invoice)) {
                        return;
                    }
					return (
						<Address location={location} number={factuurIndex++} key={`${location.id} ${index}`} navigation={this.props.navigation} refresh={() => this.refresh()} width={this.props.width} />
					)
				})}
            </View>
        )
    }
}
