import * as React from 'react';
import { Bundle, Package } from '../../../API/Interfaces/Bundle';
import { EURO } from '../../../Util/currency';
import MakeText from '../../Common/MakeText/MakeText';
import Button from '../../CrossPlatform/Button/Button';
import './ProductCard.css';

const findTitle = (flower: Package) => {
    let locale = flower.locale.find(l => l.locale === 'nl-NL' && l.key === 'title');
    if (locale && locale.value) {
        return locale.value;
    }
    return flower.category;
}

const capitalize = (s: string) => {
    return s[0].toUpperCase() + s.slice(1);
}

const getUrl = (partial: string) => {
    if (/^(https)/g.test(partial)) {
        return partial;
    } else {
        return 'https://theff.nl/services/media/1a6eaa470c7d3699d530fa950aa6eb659eaa74c4260ca76f2def4842289ebc7d/' + partial;
    }
}

export default  class SelectableSizeProductCard extends React.Component<{bundle: Bundle, navigation: any, mobileView: boolean}> {
    state = {
        selectedSize: 'medium',
    }
    render() {
        const bundle = this.props.bundle;
        const mobileView = this.props.mobileView;
        const idx = bundle.packages.findIndex(f => f.size === this.state.selectedSize);
        const selectedPackage: Package = bundle.packages[Math.max(0, idx)];
        const title = selectedPackage && selectedPackage.locale ? findTitle(selectedPackage) : '';

        const style = {
            container: {
                display: 'flex',
                flexDirection: 'column',
                margin: 25,
            } as React.CSSProperties,
            imgStyle: {
                height: mobileView ? 279 : 279,
                width: mobileView ? 279 : 279,
                objectFit: 'contain',
            } as React.CSSProperties,
            title: {
                marginTop: 15,
                color: '#414141',
                fontSize: mobileView ? 24 : 36,
                width: 279,
            } as React.CSSProperties,
            price: {
                marginTop: 15,
                color: '#414141',
                fontSize: mobileView ? 15 : 24,
            } as React.CSSProperties,
            select: {
                height: mobileView ? 40 : 60,
                fontSize: mobileView ? 15 : 18,
                fontFamily: 'Open Sans',
                marginTop: 10,
                // Center for firefox
                textAlign: 'center',
                // Center for chrome
                textAlignLast: 'center',
                textTransform: 'capitalize',
            } as React.CSSProperties,
        };
        const img = getUrl(selectedPackage.image);
        return (
            <div className='productCardContainer' style={{...style.container, cursor: 'default'}}>
                <img src={img} style={style.imgStyle} />
                <MakeText style={style.title} fontFamily='Arno Pro'>
                    {title}
                </MakeText>
                <MakeText style={style.price} fontFamily='Open Sans'>
                    {EURO(selectedPackage.price)}
                </MakeText>
                <select name="size" id="size" value={this.state.selectedSize} onChange={(e) => this.setState({selectedSize: e.target.value})} style={style.select}>
                    {bundle.packages.map(p => {
                        if ((p.size !== 'small' && p.category !== 'E-Flora-Plant') ||
                            p.category === 'Familiebundel' ||
                            (p.category === 'E-Flora-A1' && p.packageIntname.split('|')[3] && p.packageIntname.split('|')[3] === 'A1') ||
                            p.category === 'Royal Roses' ||
                            p.category === 'Seizoensbloem' ||
                            (title === 'Tederheid' && p.size !== 'small') ||
                            (title === 'Feest' && (p.size !== 'small' && p.size !== 'large'))) {
                            return (<option key={p.id + ' ' + p.size} value={p.size}>{capitalize(p.size)}</option>);
                        }
                    })}
                </select>
                <Button testID='orderFlower' style={{alignSelf: 'center', marginTop: 15, width: '100%'}} textStyle={{fontSize: 18, width: '100%', height: mobileView ? 40 : 70}} text={'Bestel nu!'} color={'lightGreen'} onPress={() => this.props.navigation.navigate('Product details noquery', {pId: selectedPackage.id, intname: bundle.intname.replace(/ /g, '_'), size: selectedPackage.size})} />
            </div>
        );
    }
}
