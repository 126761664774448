export default [
    {
        body: 'Een groot maatschappelijk hart, aandacht voor detail en prachtige boeketten!',
        source: 'Nina Capalija | Event manager Rabobank',
    },
    {
        body: 'We maken naar tevredenheid gebruik van de diensten van The Flower Family. Ze leveren kwalitatief goede en mooie boeketten. Ze leveren snel en zijn goed bereikbaar!',
        source: 'Joost Heiser | HR Allen & Overy',
    },
    {
        body: 'Wij krijgen regelmatig complimenten van onze gasten over de prachtige boeketten die wij hebben staan. Daarnaast is de samenwerking met de medewerkers van The Flower Family prima, superaardige gasten!',
        source: 'Caroline Kwakkernaat | Operationeel Manager CIRCL-ABN',
    },
    {
        body: 'Met veel tevredenheid is de Gemeente Amsterdam lid van The Flower Family!',
        source: 'Egon van Wees | Projectleider Amsterdam Impact',
    },
]