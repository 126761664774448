import * as React from 'react';

import BasicPage from './Template/BasicPage';
import Account from './../Components/Account/Account';

export default (props: any) =>  (
	<BasicPage navigation={props.navigation}>
		<Account />
	</BasicPage>
);
