import * as React from 'react';
import { View } from 'react-native';
import { watchResize, orderProcessMobile } from '../../Util/screen/HandleLayout';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addProduct, removeProduct, setProducts } from './../../store/actions/ProductActions';
import { setDeliveryAddress, setBillingAddress, setActiveStep, setDeliveryWindow, setDeliveryText, updatePayment, updateCoupon, addOrder, setOrders, setLeftTitle, setRightTitle, setCenterTitle, setTotalPrice, setOriginalPrice, setDiscount, setShippingCosts, setPONumber, setDeliveryNote } from './../../store/actions/OrderProcessActions';
import { setProfile } from './../../store/actions/ProfileActions';
import { setLogin } from '../../store/actions/AppActions';
import OrderProcessNavbar from '../../Components/OrderProcessnew/OrderProcessNavbar/MobileOrderProcessNavbar';

/**
 * This file is also used for the web mobile view of the order process.
 * Therefore it has survived the native purge even though the name of the file contains "native".
 */

/**
 * This template contains a child component and displays it
 *
 * When no children props are passed, the element displays nothing.
 */
class OrderProcessNativeTemplate extends React.Component<any>{
	state = {
		width: 0,
		height: 0
	}

	componentDidMount() {
		watchResize( result => {
			this.setState(result);
		}, this.state.width);
	}

	render() {
		if(!this.props.children) {
			return null;
		}

		if(!orderProcessMobile(this.state.width, this.state.height)) {
			this.props.navigation.navigate('Home');
		}

		const props = {
			navigation: this.props.navigation,
			width: this.state.width,
			height: this.state.height,
			screenWidth: this.state.width,
			mobileView: true,
			back: this.props.back,
			store: {
				app: this.props.app,
				profile: this.props.profile,
				product: this.props.product,
				orderProcess: this.props.orderProcess,
				addProduct: this.props.addProduct.bind(this),
				setProducts: this.props.setProducts.bind(this),
				removeProduct: this.props.removeProduct.bind(this),
				setActiveStep: this.props.setActiveStep.bind(this),
				addOrder: this.props.addOrder.bind(this),
				setProfile: this.props.setProfile.bind(this),
				setOrders: this.props.setOrders.bind(this),
				setPONumber: this.props.setPONumber.bind(this),
				setDeliveryAddress: this.props.setDeliveryAddress.bind(this),
				setBillingAddress: this.props.setBillingAddress.bind(this),
				setDeliveryWindow: this.props.setDeliveryWindow.bind(this),
				setDeliveryText: this.props.setDeliveryText.bind(this),
				updatePayment: this.props.updatePayment.bind(this),
				updateCoupon: this.props.updateCoupon.bind(this),
				setLeftTitle : this.props.setLeftTitle.bind(this),
				setRightTitle: this.props.setRightTitle.bind(this),
				setCenterTitle: this.props.setCenterTitle.bind(this),
				setTotalPrice: this.props.setTotalPrice.bind(this),
				setDiscount: this.props.setTotalPrice.bind(this),
                setShippingCosts: this.props.setShippingCosts.bind(this),
				setOriginalPrice: this.props.setOriginalPrice.bind(this),
				setLogin: this.props.setLogin.bind(this),
				setDeliveryNote: this.props.setDeliveryNote.bind(this),
			}
		}

	    return (
            <View style={{width: '100%'}}>
				<OrderProcessNavbar navigation={this.props.navigation} panelOpen={true} activeStep={this.props.orderProcess.activeStep} />
				{this.props.children && React.cloneElement(this.props.children as React.ReactElement<any>, props)}
            </View>
	    );
	}
};


const mapStateToProps = (state: any) => {
    const { product, orderProcess, profile, app } = state
    return { product, orderProcess, profile, app }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        addProduct,
		setProducts,
		removeProduct,
		setDeliveryAddress,
		setBillingAddress,
		setDeliveryWindow,
		setDeliveryText,
		updatePayment,
		updateCoupon,
		setActiveStep,
		setPONumber,
		addOrder,
		setOrders,
		setProfile,
		setLeftTitle,
		setRightTitle,
		setCenterTitle,
		setTotalPrice,
		setDiscount,
        setShippingCosts,
		setOriginalPrice,
		setLogin,
		setDeliveryNote,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(OrderProcessNativeTemplate);
