import * as React from 'react';
import { View, TextInput, StyleSheet, Image } from 'react-native';

import Button from './../../CrossPlatform/Button/Button';
import FooterLink from './FooterLink';
import Logo from './../Logo';
import MakeText from '../MakeText/MakeText';
import { calculateMargin, phone, PHONE_TEXT_WIDTH_LARGE } from '../../../Util/screen/HandleLayout';
import moment from 'moment';
import { subscribe } from '../../../API/Http/Requests';
import config from '../../../config/config.selector';

export default class Footer extends React.Component<any> {

	state = {
		email: '',
	}

	async subscribeNewsletter() {
		this.setState({ email: '' });
		await subscribe(this.state.email).catch(e => {
			console.log(e);
		});
	};

	render() {

		const mobileView: boolean = phone(this.props.width);

		const margin = mobileView ? 0 : calculateMargin(this.props.width);

		const styles = StyleSheet.create({
			main: {
				flexDirection: this.props.width > 1050 ? 'row' : 'column',
				backgroundColor: '#E3F0E4',
				width: mobileView ? this.props.width * PHONE_TEXT_WIDTH_LARGE : this.props.width - (margin * 2),
				alignSelf: 'center',
				paddingTop: 2 * margin,
				paddingBottom: 2 * margin,
				justifyContent: 'space-around' as any,
				flexWrap: 'wrap',
			},
			left: {
				marginBottom: margin,
				marginLeft: margin,
				alignSelf: this.props.width < 1051 ? 'center' : 'auto',
				alignItems: 'center',
			},
			leftTop: {
				flexDirection: 'row',
				paddingTop: mobileView ? 20 : undefined,
				alignSelf: mobileView ? 'center' : undefined,
			},
			leftBottom: {
				flex: 1,
				flexDirection: 'row',
				marginTop: 20,
				alignSelf: mobileView ? 'center' : undefined,
			},
			right: {
				flexDirection: 'row',
				alignSelf: 'center',
			},
			rightChild: {
				marginLeft: margin,
			},
			BottomLogo: {
				flex: 1,
				flexDirection: 'row',
				alignItems: 'center',
				backgroundColor: 'white',
			},

			icon: {
				width: 30,
				height: 30,
				marginRight: 15,
			},
		});
		const conf = config as any;
		if (!conf.showFooter) {
			return <View></View>;
		}

		return (
			<View>
				<View style={styles.main}>
					<View style={styles.left}>
						<View style={styles.leftTop}>
							<Logo />
						</View >
						<MakeText style={{marginTop: 10, textAlign: 'center'}}>Wilt u als bedrijf bestellen?</MakeText>
						<MakeText style={{marginTop: 10, textAlign: 'center'}}>Neem dan contact op voor een bedrijfsportaal (facturatie)</MakeText>
						<MakeText style={{marginTop: 40, textAlign: 'center'}}>Altijd op de hoogte? Schrijf je nu in voor de nieuwsbrief!</MakeText>
						<View style={styles.leftBottom}>
							<TextInput
								style={{ backgroundColor: 'white', height: 36, padding: 6, marginRight: 2, width: this.props.width > 1050 ? 250 : 150 }}
								editable={true}
								placeholder="Jouw e-mailadres"
								value={this.state.email}
								onChangeText={(email) => { this.setState({ email }) }}
							/>
							<Button style={{ height: 40 }} color="greenWhite" text="Verstuur" onPress={() => this.subscribeNewsletter()} />
						</View>

						<View style={{ marginTop: 20 }}>
							<MakeText style={{ textAlign: 'center' }}>{moment().format('YYYY')} The Flower Family, Alle rechten voorbehouden</MakeText>
						</View>

					</View>
					<View style={{flexDirection: 'row', flexWrap: 'wrap'}}>
						<View style={{ flexDirection: 'column', marginTop: 20, marginBottom: 20 }}>
							<FooterLink width={this.props.width} text="Webshop" route='/webshop' link={() => this.props.navigation.navigate('webshop')} />
							<FooterLink width={this.props.width} text="Meet the Family" route='/family' link={() => this.props.navigation.navigate('The Family')} />
							<FooterLink width={this.props.width} text="Stories" route='/stories' link={() => this.props.navigation.navigate('Stories')} />
							<FooterLink width={this.props.width} text="Verzorgingstips" route='/verzorging' link={() => this.props.navigation.navigate('Verzorgingstips')} />
							<FooterLink width={this.props.width} text="Pers" route='/pers' link={() => this.props.navigation.navigate('Pers')} bottom={true} />
						</View>

						<View style={{ flexDirection: 'column', marginTop: 20, marginBottom: 20 }}>
							<FooterLink width={this.props.width} text="Onze filosofie" route='/filosofie' link={() => this.props.navigation.navigate('About')} />
							<FooterLink width={this.props.width} text="Voor kantoor" route='/kantoor' link={() => this.props.navigation.navigate('TFF for business')} />
							<FooterLink width={this.props.width} text="F.A.Q." link={() => this.props.navigation.navigate('FAQ')} />
							<FooterLink width={this.props.width} text="Voorwaarden" route='/voorwaarden' link={() => this.props.navigation.navigate('Voorwaarden')} />
							<FooterLink width={this.props.width} text="Privacy" route='/privacy' link={() => this.props.navigation.navigate('Privacy')} bottom={true} />
						</View>

						<View style={{ flexDirection: 'column', marginTop: 20, marginBottom: 20 }}>
							{/* <FooterLink width={this.props.width} text="TFF Exclusive" route='/exclusive' link={() => this.props.navigation.navigate('Exclusive')} /> */}
							<FooterLink width={this.props.width} text="Facebook" route='https://www.facebook.com/TheFlowerFamily/' link={() => window.open('https://www.facebook.com/TheFlowerFamily/', '_self')} />
							<FooterLink width={this.props.width} text="Instagram" route='https://www.instagram.com/theflowerfamily' link={() => window.open('https://www.instagram.com/theflowerfamily', '_self')} />
							<FooterLink width={this.props.width} text="Vacatures" route='/vacatures' link={() => this.props.navigation.navigate('Vacatures')} />
							<FooterLink width={this.props.width} text="Contact" route='/contact' link={() => this.props.navigation.navigate('Contact')} bottom={true} />
						</View>
					</View>

					<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
						<View style={{flexDirection: 'column', marginHorizontal: 10 }}>
							<Image source={require('./../../../images/LOGO_DOEN_ZWART.png')} style={{ width: 100, height: 90, marginBottom: this.props.width < 1225 ? 15 : 0 }} />
						</View>

						<View style={{flexDirection: 'column', marginHorizontal: 10 }}>
							<Image source={require('./../../../images/se_logo.png')} style={{ width: 100, height: 90, marginBottom: this.props.width < 1225 ? 15 : 0 }} />
						</View>

						<View style={{marginHorizontal: 10 }}>
							<Image source={require('./../../../images/rbf_logo.jpg')} style={{ width: 95, height: 90, marginBottom: this.props.width < 1225 ? 15 : 0 }} />
						</View>
					</View>
				</View>
			</View>
		);
	}
}
