import * as React from 'react';
import './StoryThumbnail.css';
import checkImageUrl from '../../../Util/checkImageUrl';

import { Helmet } from "react-helmet";

export default (props: any) => {

    const story = props.story;

    const ldStory = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.theflowerfamily.nl/story/" + story.id + "/" + encodeURIComponent(story.title.replace(/\s/g, '-')),
        },
        "headline": story.title,
        "image": [
            story.img_thumb,
            story.img_header,
        ],
        "datePublished": "2019-05-16T08:37:44.000Z",
        "dateModified": "2019-05-16T08:37:44.000Z",
        "author": {
            "@type": "Person",
            "name": "Jos van Beuningen"
        },
        "publisher": {
            "@type": "Organization",
            "name": "The Flower Family",
            "logo": {
                "@type": "ImageObject",
                "url": require('../../../images/logo_FC_green_small.png')
            }
        },
        "description": story.card_desc,
    }

    return (
        <div>
            <Helmet>
                <script className='structured-data-list' type="application/ld+json">{JSON.stringify(ldStory)}</script>
            </Helmet>
		    <div className="storyImage" onClick={() => props.setStory ? props.setStory(story.id) : props.navigation.navigate(`Story`, {storyID: story.id, title: story.title.replace(/\s/g, '-')})}>
		        <img src={checkImageUrl(story.img_thumb)} style={{objectFit: 'cover'}} alt='Story' />
		        <div className="imgBackground">
		            <p>{story.title}</p>
		        </div>
		    </div>
		</div>
	);
}
