import * as Actions from './../ActionTypes';

export interface IAddress {
	id: number;
    postalcode: string;
    street: string;
    housenumber: string;
    city: string;
	phone?: String;
	isPublicLocation?: boolean;
}

export interface IDeliveryWindow {
	day: number;
	deliveryTimes: number;
	deliveryDate: number;
	recurring: string;
}

export interface IOrder {
	product: number;
	packageId: number;
	type: string;
	amount: number;
	vase: boolean;
}

export interface IPayment {
	coupon: string;
	paytype: string;
	issuer: string;
}

export interface ICreateOrderOrder {
    product: number;
	packageId: number;
	delivery: {
        day: number; // dag van de week
        date: number; // dag als timestamp
        windowstart: number; // seconds after 00:00 (08:06)
        windowend: number; // seconds after 00:00 (12:30)
		comment: string; // opmerking
		ticket: string;
		vaseCode?: string;
		wishcardCode?: string;
		chocolonelyCode?: string;
    };
	// de type is de repitition.
    type: string;
    amount: number;
    vase: boolean;
}

export const setLeftTitle = (title: string) => ({ type: Actions.setLeftTitle, payload: title });
export const setRightTitle = (title: string) => ({ type: Actions.setRightTitle,	payload: title });
export const setCenterTitle = (title: string) => ({	type: Actions.setCenterTitle, payload: title });
export const setBillingAddress = (billingAddress: IAddress) => ({ type: Actions.setBillingAddress, payload: billingAddress })
export const setDeliveryAddress = (deliveryAddress: IAddress) => ({	type: Actions.setDeliveryAddress, payload: deliveryAddress });
export const setDeliveryWindow = (delivery: IDeliveryWindow) => ({ type: Actions.setDeliveryWindow,	payload: delivery });
export const setDeliveryText = (deliveryText: any) => ({ type: Actions.setDeliveryText, payload: deliveryText });
export const setPONumber = (po_number: string | undefined) => ({ type: Actions.setPONumber, payload: po_number });
export const addOrder = (order: IOrder) => ({ type: Actions.addOrder, payload: order });
export const setActiveStep = (a: string) => ({ type: Actions.setActiveStep, payload: a });
export const setOrders = (orders: IOrder[]) => ({ type: Actions.setOrders, payload: orders });
export const updateOrder = (order: IOrder) => ({ type: Actions.updateOrder, payload: order });
export const removeOrder = (order: IOrder) => ({ type: Actions.removeOrder,	payload: order });
export const updateCoupon = (coup: string) => ({ type: Actions.updateCoupon, payload: coup });
export const updatePayment = (pay: string) => ({ type: Actions.updatePaymentType, payload: pay });
export const setTotalPrice = (totalPrice: number) => ({ type: Actions.setTotalPrice, payload: totalPrice });
export const setDiscount = (discount: number) => ({ type: Actions.setDiscount, payload: discount });
export const setShippingCosts = (shippingCosts: number) => ({ type: Actions.setShippingCosts, payload: shippingCosts });
export const setOriginalPrice = (price: number) => ({ type: Actions.setOriginalPrice, payload: price });
export const setDeliveryNote = (note: string) => ({ type: Actions.setDeliveryNote, payload: note });