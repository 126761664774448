import * as React from 'react';
import TouchableOpacity from '../../CrossPlatform/TouchableOpacity/TouchableOpacity';
import './carousel.css';

export default class Caroussel extends React.Component<{style: React.CSSProperties}> {
    state = {
        index: 0
    };

    nextItem(autoNext?: NodeJS.Timeout) {
        if (autoNext) {
            clearTimeout(autoNext);
        }
        const children = React.Children.toArray(this.props.children);
        if (this.state.index === children.length - 1) {
            this.setState({index: 0});
        } else {
            this.setState({index: this.state.index + 1})
        }
    }

    previousItem(autoNext: NodeJS.Timeout) {
        clearTimeout(autoNext);
        const children = React.Children.toArray(this.props.children);
        if (this.state.index === 0) {
            this.setState({index: children.length - 1})
        } else {
            this.setState({index: this.state.index - 1})
        }
    }

    setItem(i: number, autoNext: NodeJS.Timeout) {
        clearTimeout(autoNext);
        this.setState({index: i});
    }

    render() {
        const children = React.Children.toArray(this.props.children);
        if (children.length < 1) {
            return <div></div>;
        }
        const autoNext = setTimeout(() => this.nextItem(), 10000)
        return(
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', ...this.props.style}}>
                <TouchableOpacity onPress={() => this.previousItem(autoNext)} style={{marginRight: 25}}>
                    <img src='/images/popup_arrow.svg' style={{transform: 'rotate(-90deg)'}} />
                </TouchableOpacity>
                <div className='col' style={{width: '100%', alignItems: 'center'}}>
                    <div style={{width: '100%', height: 200, position: 'relative'}}>
                        {children.map((c, i) => <div key={i} className={`carousel${i === this.state.index ? ' active' : ''}`}>{c}</div>)}
                    </div>
                    <div style={{width: 250, display: 'flex', justifyContent: 'space-between', marginTop: 25}}>
                        {children.map((_, i) => <TouchableOpacity key={i} style={{borderRadius: '50%', backgroundColor: i === this.state.index ? 'grey' : 'black', height: 10, width: 10}} onPress={() => this.setItem(i, autoNext)} />)}
                    </div>
                </div>
                <TouchableOpacity onPress={() => this.nextItem(autoNext)} style={{marginLeft: 25}}>
                    <img src='/images/popup_arrow.svg' style={{transform: 'rotate(90deg'}} />
                </TouchableOpacity>
            </div>
        );
    }
}