import * as React from 'react';
import { View, StyleSheet, TextInput, ActivityIndicator } from 'react-native';
import {forgotPasswordCodeLogin} from "../../API/Http/Requests";
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';
import globalStyles from '../../Styles/styles';

interface IEnterCode {
	email: string;
	setCodeEntered: Function;
	// currently not used. Can be used to redirect to a page.
	navigation?: any;
	// currently not used. Can be used to redirect to a page.
	navigateTo?: string;
}

export default class EnterCode extends React.Component<IEnterCode> {

	state = {
		code: '',

		loading: false,
		errorMessage: '',
	}

	// Clean the input and send a reste password request to the server.
	async loginAttempt(code: string) {

		if(!code.length) {
			this.setState({errorMessage: 'Geen code ingevuld.'});
			return;
		}

		this.setState({loading: true, errorMessage: ''});

		const result = await forgotPasswordCodeLogin(this.props.email, code);

		if (result) {
			if(result.code === 200) {
				// this.props.navigation.navigate(this.props.navigateTo, {});
				this.props.setCodeEntered(true, code);
			}
		}

		this.setState({loading: false, errorMessage: 'Er is iets misgegaan, probeer het later nog een keer.'});
	}

	render() {

		const styles = StyleSheet.create({
			text: {
				color: '#414141',
				alignSelf: 'center',
			},
			textInput: {
				flex: 1,
				alignSelf: 'center',
				borderColor: '#414141',
				borderWidth: 1,
				padding: 10,
				fontSize: 18,
				color: '#414141',
				marginTop: 15,
				fontWeight: 'bold',
				textAlign: 'center',
			},
			marginTop15: {
				marginTop: 15,
			}
		});

		return (

			<View>

				<MakeText style={[globalStyles.h2, globalStyles.alignSelfCenter]} fontFamily="Crimson Text">Code</MakeText>
				<MakeText style={[globalStyles.alignSelfCenter]}>
					Naar dit e-mailadres is zojuist een mail verstuurd, met daarin een tijdelijk wachtwoord die je hieronder kan invullen om in te loggen.
				</MakeText>

				<TextInput style={styles.textInput} value={this.state.code} onChangeText={(code) => this.setState({code})} />

				<View style={[globalStyles.alignSelfCenter, styles.marginTop15]}>
					<Button
						disabled={this.state.loading ? true : false}
						text="Inloggen"
						color="greenWhite"
						onPress={() => this.loginAttempt(this.state.code)}
					/>
				</View>

				{this.state.loading &&
					<View style={[styles.marginTop15, globalStyles.alignSelfCenter]}>
						<ActivityIndicator size="large" color="#B9DCBF" />
					</View>
				}

				{this.state.errorMessage.length ?
					<MakeText style={[globalStyles.alignSelfCenter, globalStyles.error, globalStyles.marginTop15]}>{this.state.errorMessage}</MakeText>
				:
					null
				}

			</View>
		);
	}
}
