import * as React from 'react';
import { Image, View, StyleSheet, Platform, ActivityIndicator } from 'react-native';

import MakeText from '../Common/MakeText/MakeText';

import OrderBundle from './OrderBundle/OrderBundle';
import globalStyles from '../../Styles/styles';
import { PHONE_TEXT_WIDTH_LARGE, phone } from '../../Util/screen/HandleLayout';
import { getProducts } from '../../API/Http/Requests';

export default class GrowerSale extends React.Component<any> {

	_hasCanceled = false;
    state = {
        loading: true,
        bundles: {active: []},
        text: '',
		errorText: '',
	}

	componentDidMount() {
		this._hasCanceled = false;
        this.fetchData();
	}

	componentWillUnmount() {
		this._hasCanceled = true;
	}

    async fetchData() {
        const bundles = await getProducts().catch(e => {
			console.log(e);
		});
		if (!this._hasCanceled) {
			this.setState({ bundles });

			if (bundles.active.length) {
				this.setState({ loading: false });
			} else {
				this.setState({ loading: false, errorText: 'Op dit moment ervaren wij problemen bij het ophalen van de bloemen, onze excuses voor het ongemak!' });
			}
		}
    }

	renderBundles(id: number) {
		let result: any[] = [];
		this.state.bundles.active.map((bundle: any, index) => {
			if (bundle.category === "Orchid" && id === 1) {
				result.unshift(<OrderBundle key={index} bundle={bundle}
					category="Your Natural Orchid "
					text="Een speciale orchidee, geteeld met respect voor de aarde, direct afkomstig van kwekerij Stolk Flora - Your Natural Orchid uit Bleiswijk." />);
			}

			if (bundle.category === "Plants" && id === 2) {
				result.unshift(<OrderBundle key={index} bundle={bundle}
					category="You & Me in a Green World"
					text="Duurzame en tropische kamerplanten, direct bij jou thuis bezorgd vanaf onze kweker Geest Potplanten." />);
			}
        });

		return (
			<View>
				{result}
			</View>
		);
    }

	calculateTextWidth() {
		let width : number;

		if(this.props.width > 1500) width = this.props.width / 4 * 2;
		else if(this.props.width < 900) width = this.props.width * PHONE_TEXT_WIDTH_LARGE;
		else width = this.props.width * 0.6;

		return width;
	}

    render() {

		const mobileView : boolean = phone(this.props.width);

        const styles = StyleSheet.create({
            image: {
                width: this.props.width,
                height: 200,
                ...Platform.select({
                    ios: {
                        height: 200,
                    },
                    android: {
                        height: 200,
                    },
                    web: {
                        height: mobileView ? 350 : this.props.height * 0.45,
                        width: this.props.width,
                    }
                })
            },
            header1: {
				fontSize: 56,
				textAlign: 'center',
				letterSpacing: 2.13,
				lineHeight: 42,
				color: '#414141',
				marginBottom: 49,
            },
            headerText: {
                fontSize: 18,
				lineHeight: 32,
				letterSpacing: 1.1925,
                textAlign: mobileView ? 'center' : undefined,
                width: this.calculateTextWidth(),
				alignSelf: 'center',
				color: '#414141',
            },
			headerTextSmall: {
				fontSize: 14,
				lineHeight: 32,
				letterSpacing: 0.9275,
				textAlign: mobileView ? 'center' : undefined,
				width: this.calculateTextWidth(),
				alignSelf: 'center',
				color: '#414141',
			},
            header2: {
				lineHeight: 42,
				fontSize: 36,
				textAlign: 'center',
				letterSpacing: 2.2,
				color: '#414141',
            },
            headerText2: {
                fontSize: 14,
                textAlign: 'center',
				color: '#414141',
				letterSpacing: 0.9275,
				width: this.calculateTextWidth(),
				alignSelf: 'center',
            },
            zoDoenWij: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'flex-start',
            },
            zoBlock: {
                flexGrow: 1,
                textAlign: 'center',
                maxWidth: 300,
                margin: 20
            },
            zoPicca: {
                width: 300,
                height: 300,
                margin: 'auto',
            },
			marginTop50: {
				marginTop: 50,
			},
        });

        if(!this.state.bundles || !this.props.app.config.collection) {
            return <View style={{height: this.props.height / 4, marginTop: this.props.height / 4}}><ActivityIndicator size="large" color="#B9DCBF" /></View>
        }

		if(this.state.errorText.length) {
            return (
				<View>
					<View>
						<Image source={{uri: this.props.app.config.collection.image}} style={styles.image} />
					</View>

					<MakeText style={[styles.headerText, {alignSelf: 'center', marginVertical: 50}]}>{this.state.errorText}</MakeText>
				</View>
			);
        }

        return (
        	<View>

				<View>
					<Image source={{ uri: 'https://theflowerfamily.s3.eu-west-3.amazonaws.com/website/public/action_orchid_banner.jpg'}} style={styles.image} />
	            </View>

				<View>

					<MakeText style={[globalStyles.OrderFlowerHeader, { marginTop: 48 }]} fontFamily="Crimson Text">
						{/*{this.props.app.config.collection.name}*/}
						Red onze Nederlandse kwekers en zet jouw held in het zonnetje!
	                </MakeText>
					<MakeText style={styles.headerText} fontFamily="Open Sans Light Italic">
						Onze kwekers hebben het momenteel zwaar te verduren. Met passie geteelde bloemen en planten zien zij direct de compostbak in gaan en ze kunnen er niks aan doen...
						Maar wij SAMEN wel! Daarom zijn wij met onze kwekers de Samen Sterk actie gestart, zodat zij door kunnen gaan met het opfleuren van onze wereld! Alle planten worden op de kwekerij verpakt
						en direct bij jou thuisbezorgd. Bestel nu hier een mooie plant voor thuis, of zet jouw held in het zonnetje. Samen redden we onze Nederlandse kwekers, want samen staan we sterk!
						</MakeText>

					{this.state.loading ?
						<View>
							<MakeText style={[globalStyles.h2, styles.marginTop50, globalStyles.textAlignCenter]} fontFamily="Crimson Text">Bloemen laden...</MakeText>
							<ActivityIndicator style={{ marginTop: 10 }} size="large" color="#B9DCBF" />
						</View>
						:
						this.renderBundles(2)
					}

					<MakeText style={{ textAlign: 'center', marginVertical: 15, color: '#414141', fontSize: 17 }}> {`* Prijs is inclusief waterdichte rieten mand (\u2300 21 cm.) en bezorgkosten`} </MakeText>

					{ this.state.loading ?
	                    <View>
	                        <MakeText style={[globalStyles.h2, styles.marginTop50, globalStyles.textAlignCenter]} fontFamily="Crimson Text">Bloemen laden...</MakeText>
	                        <ActivityIndicator style={{marginTop: 10}} size="large" color="#B9DCBF" />
	                    </View>
	                    :
	                    this.renderBundles(1)
	                }

					<MakeText style={{ textAlign: 'center', marginVertical: 15, color: '#414141', fontSize: 17 }}> {'* Prijs is inclusief duurzame jute pot en bezorgkosten'} </MakeText>
	            </View>
        	</View>
        );
    }
}
