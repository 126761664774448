import * as React from 'react';
import VoorwaardenHtml from '../../assets/voorwaarden'
import WebView from '../CrossPlatform/WebView/WebView';

export default (props: any) => {
    return (
        <WebView
            html={VoorwaardenHtml.html}
            width={props.width} height={props.height * 0.9}
            filter={() => undefined}
        />
    )
}