import * as React from 'react';
import { View, ActivityIndicator, StyleSheet, TouchableOpacity, Linking } from 'react-native';
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';
import WebView from '../CrossPlatform/WebView/WebView';
import { mobile } from '../../Util/screen/HandleLayout';
import { getVacancies } from '../../API/Http/Requests';

interface IVacature {
    id: number,
    body: string,
    contact_name: string,
    contact_email: string,
    contact_phone: string,
    created_at: string,
    date_release: string,
    location: string,
    title: string,
    type: string,
    uploaded_at: string
}

export default class Vacature extends React.Component<any>{
    state = {
       vacature: {} as IVacature,
       loading: true
    }

    componentWillMount() {
        if(this.props.navigation.state.params.id === undefined) {
            this.props.navigation.navigate('Vacatures');
        }
    }

    componentDidMount() {
        getVacancies(this.props.navigation.state.params.id).then((vacancy) => {
            this.setState({
                vacature: vacancy,
                loading: false
            })
        });
    }

	renderMailTo() {
		return (
			<TouchableOpacity style={{borderBottomWidth: 1, borderBottomColor: 'white'}}
				onPress={() => Linking.openURL(`mailto:${this.state.vacature.contact_email}?subject=Vragen vacature: ${this.state.vacature.title}`)}
			>
				<MakeText>{this.state.vacature.contact_email}</MakeText>
			</TouchableOpacity>
		)
	}

    render() {
        const styles = StyleSheet.create({
            header: {
                fontSize: 56,
                textAlign: 'center',
                letterSpacing: 2.13,
                color: '#414141',
                margin: 50
            },
            subHeader: {
                lineHeight: 38,
                fontSize: 24,
                textAlign: 'center',
                letterSpacing: 0.06,
                color: '#7E9591',
                margin: 30,
            },
            body: {
                lineHeight: 32,
                fontSize: 14,
                letterSpacing: 0.9275,
                color: '#414141',
                margin: 10
            },
            bodyView: {
                width: this.props.width < mobile ? this.props.width * 0.9 : this.props.width * 0.4,
                alignSelf: 'center'
            },
            meerWetenView: {
                width: this.props.width < mobile ? this.props.width * 0.9 : this.props.width * 0.4,
                alignSelf: 'center',
                margin: 30,
                backgroundColor: '#B9DCBF',
                padding: 30
            },
            meerWetenHeader: {
                lineHeight: 38,
                fontSize: 24,
                textAlign: 'center',
                letterSpacing: 0.06,
                color: 'white',
                marginBottom: 10
            },
            meerWetenText: {
                lineHeight: 32,
                fontSize: 14,
                letterSpacing: 0.9275,
                color: 'white'
            }
        });
        if(this.state.loading) {
            return <View>
                <MakeText style={{fontSize: 14, lineHeight: 32, marginTop: 100, letterSpacing: 0.9275, color: '#414141', textAlign: 'center'}}>Vacature ophalen...</MakeText>
                <ActivityIndicator />
            </View>;
        }
        const vacature = this.state.vacature;
        const released = new Date(vacature.date_release);
        const datePassed = released.getTime() < Date.now();
        const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

        return <View>
            <MakeText fontFamily='Crimson Text' style={styles.header}>{vacature.title}</MakeText>
            <MakeText style={styles.subHeader}>{vacature.location} - {vacature.type} - open {datePassed ? 'sinds' : 'vanaf'} {released.toLocaleDateString('nl', dateOptions)}</MakeText>
            <View style={styles.bodyView}>
                <WebView
                    html={vacature.body}
                    width={this.props.width} height={this.props.height * 0.9}
                    filter={() => undefined}
                />
            </View>

            <View style={styles.meerWetenView}>
                <MakeText style={styles.meerWetenHeader}>Wil je meer weten over deze vacature?</MakeText>
                <MakeText style={styles.meerWetenText}>{`Voor vragen over deze vacature kun je terecht bij ${this.state.vacature.contact_name}. Bellen kan op ${this.state.vacature.contact_phone} of stuur je vragen naar `}{this.renderMailTo()}.</MakeText>
            </View>

            <Button style={{alignSelf: 'center', marginTop: 30}} textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: 16, textAlign: 'center', letterSpacing: 2, marginBottom: 80}} color="blackOpacity" text="Soliciteer op deze vacature" onPress={() => console.log('pressed')} />
        </View>
    }
};
