import { IProfile } from "../../API/IDataService";

const INITIAL_STATE: IProfile = {
	appAccount: false,
	client: null,
	createdAt: 0,
	email: "",
	firstname: "",
	id: 0,
	lastname: "",
	locations: [],
	mollieID: "",
	pushId: "",
	type: "",
};

const ProfileReducer = (state = INITIAL_STATE, action: any) => {
	switch(action.type) {
		case 'SET_PROFILE':
			let tempState = Object.assign({}, state);

			tempState = action.payload;

			return tempState;

		default:
			return state;
	}
}

export default ProfileReducer;
