import * as React from 'react';
import { View, Image, StyleSheet, Platform } from 'react-native';

import Button from './../CrossPlatform/Button/Button';
import Login from './../OrderProcess/Login';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLogin } from './../../store/actions/AppActions';
import MakeText from '../Common/MakeText/MakeText';
import { phone } from '../../Util/screen/HandleLayout';
import globalStyles from '../../Styles/styles';
import { isLoggedIn } from '../../API/Http/Requests';

class LoginPage extends React.Component<any> {
	async componentDidMount() {
		let logged = false;
		try {
			logged = await isLoggedIn();
		} catch {
			logged = false;
		}
		if (logged) {
			this.props.navigation.navigate('Account');
		}
	}

	render() {
		const mobileView = phone(this.props.width);
		const imgHeight = mobileView ? 0 : this.props.height / 10 * 8;
		const styles = StyleSheet.create({
			backgroundImage: {
				position: 'absolute',
				width: this.props.width,
				height: 200,
				top: 0,
				alignSelf: 'center',
				...Platform.select({
						ios: {
								height: 200,
						},
						android: {
								height: 200,
						},
						web: {
								height: imgHeight,
						}
					}),
			},
			orText: {
				textAlign: 'center',
        		fontSize: 12,
				marginVertical: 10,
			}
		});

		return (
			<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', minHeight: imgHeight }}>
				<Image
					style={styles.backgroundImage}
					source={require('./../../images/TffForBusiness.jpg')}
				/>

				<View style={{borderWidth: 1, borderColor: 'white', shadowOffset: {width: 1, height: 1}, shadowOpacity: 0.2, shadowRadius: 5, paddingVertical: 50, paddingHorizontal: 50, marginHorizontal: 0, marginTop: mobileView ? 30 : 0, backgroundColor: 'white', width: mobileView ? this.props.width * 0.7 : 500}}>

					<MakeText style={[globalStyles.h2, globalStyles.alignSelfCenter, {marginBottom: 30}]} fontFamily="Crimson Text">Inloggen</MakeText>

					<Login navigation={this.props.navigation} route="Account" store={{ setLogin: this.props.setLogin.bind(this)}} width={mobileView ? this.props.width * 0.7 : 489} />
					<MakeText style={styles.orText}>of</MakeText>
					<Button testID='newAccount' style={{alignSelf: 'center'}} textStyle={{fontSize: 14, letterSpacing: 0.9275}} text="Account aanmaken" color="greenWhite" route='/nieuw-account' onPress={() => this.props.navigation.navigate('CreateNewAccount')}/>
				</View>
			</View>
		)
	}
}

const mapStateToProps = (state: any) => {
    const { app } = state
    return { app }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
		setLogin,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
