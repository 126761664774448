import * as React from 'react';

import './../../../assets/LoadFonts/OpenSans/OpenSansFont.css';
import { mobile } from '../../../Util/screen/HandleLayout';

import './WebView.css';

export default (props: any) => {
    return (
	    <div style={{margin: 20, width: props.width < mobile ? props.width * 0.9 : props.width * 0.5, alignSelf: 'center', fontFamily: 'Open Sans', fontSize: 14, lineHeight: 2.1, letterSpacing: 0.9275, color: '#414141'}} dangerouslySetInnerHTML={{__html: props.html}}>
	    </div>
    )
}
