export default [
    "Overig",
    "Aeves Interim Management",
    "Benefit Inkoopadviesgroep B.V.",
    "Boer & Croon Management Solutions B.V.",
    "Het NIC B.V.",
    "NewDawn B.V.",
    "Procumulator B.V.", 
    "proQure B.V.",
    "Van Der Kruijs",
    "YESS",
    "AB&C Groep B.V."
];
