import {sha3_256} from "js-sha3";
import {SubscriptionStatusType} from "../Interfaces/Enums/SubscriptionStatusType";
import {IAuthenticateResponse} from "../Interfaces/IAuthenticateResponse";
import {ICreateUser} from "../Interfaces/ICreateUser";
import {ILoginAttempt} from "../Interfaces/ILoginAttempt";
import {ILocation, IProfile} from "../Interfaces/IProfile";
import {instance} from "./HttpSetup";

import { routingName } from '../../Components/CrossPlatform/OrderProcess/NewOrderProcess.web';
import Storage from '../../Components/CrossPlatform/Storage/Storage';
const storage = new Storage;
import moment from 'moment';

export const authenticate = async (body: ILoginAttempt): Promise<IAuthenticateResponse> => {

    const result = await instance.get(`users/authenticate?env=${process.env.REACT_APP_NODE_ENV}`, {
        auth: {
            username: body.username,
            password: body.password,
        }
    });

    if (result.data.code) {
        storage.storeData('user', { username: body.username, password: sha3_256(body.password) });
        storage.storeData('auth', `Basic ${btoa(`${body.username}:${body.password}`)}`);
    }

    return result.data;
};

export const resendConfirmRegistration = async (username: string): Promise<string> => {
    const result = await instance.post('users/resendConfirmRegistration', {username});
    return result.data;
}

export const isLoggedIn = async (): Promise<boolean> => {
    const result = await instance.get('users/login-check');
    return result.data;
}

export const logout = async (): Promise<void> => {
    const result = await instance.get('users/logout');
    return result.data;
}

export const getMe = async (): Promise<IProfile> => {
    const result = await instance.get('users/me');
    return result.data;
}

export const sendOrderComment = async (comment: string, orderId: string): Promise<any> => {
    const result = await instance.post('users/sendfeedback', {comment, orderId});
    return result.data;
}

export const createUser = async (user: ICreateUser): Promise<IProfile> => {
    const makeAccount = user.password.length > 0;
    user.account = makeAccount;
    const pw = makeAccount ? user.password : Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    user.password = pw;
    const result = await instance.post(`users?env=${process.env.REACT_APP_NODE_ENV}`, user, {
        auth: {
            username: user.email,
            password: pw,
        }
    });
    if (result.data) {
        storage.storeData('user', { username: user.email, password: sha3_256(pw) });
        storage.storeData('auth', `Basic ${btoa(`${user.email}:${pw}`)}`);
    }

    return result.data;
}

export const forgotPasswordEmail = async (email: string): Promise<string> => {
    const result = await instance.post('users/request-reset-password-email', {email});
    return result.data;
}

export const forgotPasswordCodeLogin = async (email: string, code: string): Promise<{ code: number, success: string } | { code: number, error: string }> => {
    const result = await instance.post('users/login-with-code', {email, code});
    return result.data;
}

export const resetPasswordWithCode = async (email: string, code: string, password: string): Promise<IProfile> => {
    const result = await instance.post('users/reset-password-with-code', {email, code, password});
    return result.data;
}

export const addLocationToMe = async (location: ILocation): Promise<ILocation> => {
    const result = await instance.post('users/me/add-location', location);
    return result.data;
}

export const updateLocation = async (location: ILocation): Promise<ILocation> => {
    const result = await instance.put(`locations/${location.id}`, location);
    return result.data;
}

export const deleteLocation = async (id: number): Promise<any> => {
    const result = await instance.delete(`locations/${id}`);
    return result.data;
}

export const getSubscriptionsForUser = async (id?: number): Promise<any> => {
    const result = await instance.get(`users/user-subscriptions${id ? `/${id}` : ''}`);
    return result.data;
}

export const updateSubscriptionStatus = async (id: number, status: SubscriptionStatusType): Promise<any> => {
    const result = await instance.put(`users/user-subscriptions/update-status${id ? `/${id}` : ''}`, {status});
    return result.data;
}

export const getOrdersForUser = async (id?: number): Promise<any> => {
    const result = await instance.get(`users/user-orders${id ? `/${id}` : ''}`);
    return result.data;
}

export const updateUser = async (profile: IProfile): Promise<ILocation> => {
    const result = await instance.put('users/' + profile.id, profile);
    return result.data;
}

export const createOrder = async (order: any, shop: string): Promise<any> => {
    order.shop = shop;
    const result = await instance.post(`order-management/order?env=${process.env.REACT_APP_NODE_ENV}`, order);
    return result.data;
}

export const cancelOrder = async (orderId: number): Promise<any> => {
    const result = await instance.put(`order-management/order/${orderId}/status`, { status: 'cancelled' });
    return result.data;
}

export const paymentStatus = async (paymentId: number): Promise<any> => {
    const result = await instance.get(`order-management/order/${paymentId}/payment`);
    return result.data;
}

export const remakePayment = async (paymentId: number): Promise<string> => {
    const result = await instance.post(`order-management/order/${paymentId}/remakePayment`);
    return result.data;
}

let cacheTimer = 0;
let cacheValue: any = undefined;
let retrieving = false;
export const getProducts = async (ids?: number[]): Promise<any> => {
    if (retrieving || moment().unix() < cacheTimer + 15 ) {
        return cacheValue;
    }
    retrieving = true;
    cacheValue = (async () => {
        const res = (await (instance.get(`public/products`, {params: {env: process.env.REACT_APP_NODE_ENV, portal: routingName, productId: JSON.stringify(ids)}}))).data;
        retrieving = false;
        cacheTimer = moment().unix();
        return res;
    })();
    return cacheValue;
}

let packageCacheTimer = 0;
let packageCacheValue: any = undefined;
let packageRetrieving = false;
let packageCacheParam = 0;
export const getPackageById = async (id: number, portal?: string): Promise<any> => {
    if ((packageRetrieving || moment().unix() < packageCacheTimer + 15 ) && packageCacheParam === id) {
        return packageCacheValue;
    }
    packageRetrieving = true;
    packageCacheParam = id;
    packageCacheValue = (async () => {
        let res = portal ? (await (instance.get(`public/package/${id}?env=${process.env.REACT_APP_NODE_ENV}&portal=${routingName}`))).data :
            (await (instance.get(`public/package/${id}?env=${process.env.REACT_APP_NODE_ENV}`))).data;
        packageRetrieving = false;
        packageCacheTimer = moment().unix();
        return res;
    })();
    return packageCacheValue;
}

let deliveryCacheTimer = 0;
let deliveryCacheValue: any = undefined;
let deliveryRetrieving = false;
let deliveryCacheParam = 0;
export const getDeliveryOptionsByPackageId = async (id: number): Promise<any> => {
    if ((deliveryRetrieving || moment().unix() < deliveryCacheTimer + 15 ) && deliveryCacheParam === id) {
        return deliveryCacheValue;
    }
    deliveryRetrieving = true;
    deliveryCacheParam = id;
    deliveryCacheValue = (async () => {
        const res = (await (instance.get(`public/packageDelivery/${id}?env=${process.env.REACT_APP_NODE_ENV}&portal=${routingName}`))).data;
        deliveryRetrieving = false;
        deliveryCacheTimer = moment().unix();
        return res;
    })();
    return deliveryCacheValue;
}

export const getFavorites = async (): Promise<any> => {
    const products = (await instance.get(`public/products/favorites`)).data;
    return products;
}

export const getSlots = async (): Promise<any> => {
    const result = await instance.get(`public/slots`);
    return result.data;
}

export const getConfig = async (): Promise<any> => {
    const result = await instance.get(`public/config?env=${process.env.REACT_APP_NODE_ENV}&portal=${routingName}`);
    return result.data;
}

export const getStories = async (id?: any, amount?: number): Promise<any> => {
    let data = (await instance.get(`public/stories`)).data;
    data = data.sort((a, b) => {
        return -moment(a.date_release).diff(moment(b.date_release));
    });
    if (id) {
        for (const vac of data) {
            if (vac.id === parseInt(id)) {
                return vac;
            }
        }
        return undefined;
    }
    if (!id && amount) {
        let stories = data.slice(Math.max(0, data.length) - amount, data.length);
        return stories;
    }
    return data;
}

export const getVacancies = async (id?: any): Promise<any> => {
    if (id) {
        const data = (await instance.get(`public/vacancies`)).data;
        for (const vac of data) {
            if (vac.id === parseInt(id)) {
                return vac;
            }
        }
    }
    return (await instance.get(`public/vacancies`)).data;
}

export const getPress = async (): Promise<any> => {
    const result = await instance.get(`public/press`);
    return result.data;
}

export const getFamilygroups = async (): Promise<any> => {
    const result = await instance.get(`public/familygroups`);
    return result.data;
}

export const getFamilygroups_profiles = async (): Promise<any> => {
    const result = await instance.get(`public/familygroups_profiles`);
    return result.data;
}

export const contactRequest = async (name: string, email: string, phone: string, message: string): Promise<any> => {
    const result = await instance.post(`public/contact/contactform`, {
        name,
        email,
        phone,
        message,
    });
    return result.data;
}

export const businessRequest = async (name: string, email: string, phone: string, company: string): Promise<any> => {
    const result = await instance.post(`public/contact/business`, {
        name,
        email,
        phone,
        company,
    });
    return result.data;
}

export const subscribe = async (email: string): Promise<any> => {
    const result = await instance.post(`public/contact/subscribe`, {
        email,
    });
    return result.data;
}

export const getUserCoupons = async (): Promise<any> => {
    const result = await instance.get(`/coupons`);
    return result.data;
}

export const getBuyableCoupons = async (): Promise<any> => {
    const result = await instance.get(`/coupons/buyable`);
    return result.data;
}

export const buyCoupon = async (id: string): Promise<any> => {
    const result = await instance.put(`/coupons/buy?id=` + id);
    return result.data;
}

export const getUserBalance = async (): Promise<any> => {
    const result = await instance.get(`/coupons/balance`);
    return result.data;
}

export const getCoupon = async (coupon: string, price: number, order: any): Promise<any> => {
    for (const o of order) {
        delete o.bundle.packages;
        delete o.flower.deliveryOptions;
    }
    const result = await instance.post(`public/coupon`, {
        code: coupon,
        price,
        order,
    });
    return result.data;
}

export const getUsers = async (): Promise<any[]> => {
    const result = await instance.get('users');
    return result.data;
}

export const setUser = async (id: any, body:any): Promise<any> => {
    return instance.put(`users/${id}`, body);
}

export const getInkoopOrders = async (options: any): Promise<any> => {
    const result = await instance.put(`inkopers`, options);
    return result.data;
}

export const getInkoopDashboard = async (options: any): Promise<any> => {
    const result = await instance.put(`inkopers/metrics`, options);
    return result.data;
}

export const getDashboardOrderCsvToken = async (): Promise<any> => {
    const result = await instance.get(`orders/reports/csvtoken`);
    return result.data;
}

export const getDeliveryReportDeliveries = async (date: Date): Promise<any> => {
    const result = await instance.put('deliveryreport/deliveries', {date});
    return result.data;
}

export const updateDeliveryReport = async (id: number, body: any): Promise<any> => {
    const result = await instance.put('deliveryreport/deliveries/' + id, body);
    return result.data;
}