const checkFamilyUrl = (url: string) => {

	if(url.substr(0, 5) === 'https') {
		return url;
	}

	return `http://theflowerfamily.nl/storage/familygroups/profiles/${url.split('/')[3]}`;
}

const checkTheFamilyUrl = (url: string) => {

	if(url.substr(0, 5) === 'https') {
		return url;
	}

	return `http://theflowerfamily.nl/storage/familygroups/${url.split('/')[2]}`
}

export {
	checkFamilyUrl,
	checkTheFamilyUrl,
}
