import * as React from 'react';
import { View } from 'react-native';
import {getSubscriptionsForUser} from "../../API/Http/Requests";

import Button from '../CrossPlatform/Button/Button';

export default class MembershipData extends React.Component<any> {

	state = {
		subscriptions: undefined as any,
	}

	componentDidMount() {
		getSubscriptionsForUser().then( subscriptions => {
			this.setState({ subscriptions });
		});
	}

	render() {
		return (
			<View style={{ flexDirection: 'column', alignItems: 'center', margin: 5 }}>
				<View style={{ marginTop: 20, marginBottom: 20, alignItems: 'center', justifyContent: 'center' }}>
					<Button text="Ga hier naar de webshop" color="blackOpacity" route='/webshop' onPress={() => this.props.navigation.navigate('webshop')} />
				</View>
			</View>
		);
	}
}
