/**
 * A global styles module for web.
 */

import { StyleSheet } from 'react-native';

const globalStyles = StyleSheet.create({
    main: {
		alignItems: 'center',
		justifyContent: 'center',
	},
	fullWidth: {
		width: '100%',
	},
	todo: {
		marginTop: 25,
		padding: 10,
		justifyContent: 'center',
		backgroundColor: 'lightgray',
		borderRadius: 5,
	},
	todoContainer: {
		marginTop: 25,
		padding: 10,
		justifyContent: 'center',
		borderTopColor: 'black',
		borderWidth: 1,
		borderRadius: 5,
	},
	link: {
		color: 'white',
		fontSize: 22,
	},

	// Text alignment
	textAlignCenter: {
		textAlign: 'center',
	},
	textAlignRight: {
		textAlign: 'right',
	},

	// Text styles
	title: {
		fontSize: 30,
	},
	error: {
		color: 'red',
	},



	/**
	 * Set Direction of items inside a View.
	 *
	 * Default is Column.
	 *
	 */
	row: {
		flexDirection: 'row',
	},
	column: {
		flexDirection: 'column',
	},

	// Set Position
	absolute: {
		position: 'absolute',
	},
	relative: {
		position: 'relative',
	},

	// Set Flex
	flex0: {
		flex: 0,
	},
	flex1: {
		flex: 1,
	},
	flex2: {
		flex: 2,
	},
	flex3: {
		flex: 3,
	},
	flex4: {
		flex: 4,
	},
	flex5: {
		flex: 5,
	},
	flex6: {
		flex: 6,
	},

	/**
	 * Justify Content
	 * Aligns all of the items inside a View that for example has a Flex1 to the left, center or right side of the View.
	 * It will space items inside that view with the SpaceAround, Evenly and Between.
	 *
	 * Default is flexStart
	 *
	 */
	spaceBetween: {
		justifyContent: 'space-between',
	},
	spaceAround: {
		justifyContent: 'space-around',
	},
	spaceEvenly: {
		justifyContent: 'space-evenly'
	},
	justifyStart: {
		justifyContent: 'flex-start',
	},
	justifyCenter: {
		justifyContent: 'center',
	},
	justifyEnd: {
		justifyContent: 'flex-end',
	},

	/**
	 * Align Items
	 * This aligns the items inside a View that for example has a Flex1 to the top, bottom, or center of that View.
	 */
	alignStretch: {
		alignItems: 'stretch',
	},
	alignStart: {
		alignItems: 'flex-start',
	},
	alignEnd: {
		alignItems: 'flex-end',
	},
	alignCenter: {
		alignItems: 'center',
	},

	backgroundRed: {
		backgroundColor: 'red',
	},

	/**
	 * Align self
	 * This aligns the component itself at a certain position on the horizontal line.
	 */
	alignSelfCenter: {
		alignSelf: 'center'
	},

	/**
	 * Margins
	 */
	margin1: {
		margin: 10,
	},
	margin2: {
		margin: 20,
	},
	margin3: {
		margin: 30,
	},
	marginTop1: {
		marginTop: 10,
	},
	marginTop15: {
		marginTop: 15,
	},
	marginTop2: {
		marginTop: 20,
	},
	marginTop3: {
		marginTop: 30,
	},
	marginBottom1: {
		marginBottom: 10,
	},
	marginBottom2: {
		marginBottom: 20,
	},
	marginBottom3: {
		marginBottom: 30,
	},

	h1: {
		fontSize: 56,
		letterSpacing: 2.13,
		color: '#414141',
	},
	h2: {
		fontSize: 36,
		letterSpacing: 2.2,
		color: '#414141',
	},
	h3: {
		fontSize: 24,
		letterSpacing: 0.06,
		color: '#414141',
	},
	OrderFlowerHeader: {
		fontSize: 52,
		textAlign: 'center',
		letterSpacing: 2.13,
		lineHeight: 42,
		color: '#414141',
		marginBottom: 49,
	}

})

export default globalStyles;
