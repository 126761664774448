import * as React from 'react';
import { CartPackage, Package } from '../../../API/Interfaces/Bundle';
import MakeText from '../../Common/MakeText/MakeText';
import TouchableOpacity from '../../CrossPlatform/TouchableOpacity/TouchableOpacity';
import Storage from '../../CrossPlatform/Storage/Storage';
import moment from 'moment';
import { recurrencyToString } from '../../../API/Interfaces/DeliveryOption';
import { EURO } from '../../../Util/currency';

// Possible keys: title, desc, details
const findLocaleText = (flower: Package, key: string) => {
    let locale = flower.locale.find(l => l.locale === 'nl-NL' && l.key === key);
    if (locale && locale.value) {
        return locale.value;
    }
    if (key === 'title') {
        return flower.category;
    }
    return undefined;
}

const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

const getUrl = (partial: string) => {
    if (/^(https)/g.test(partial)) {
        return partial;
    } else {
        return 'https://theff.nl/services/media/1a6eaa470c7d3699d530fa950aa6eb659eaa74c4260ca76f2def4842289ebc7d/' + partial;
    }
}

const storage = new Storage;
export default (props: {package: CartPackage, updateProduct?: (p: CartPackage) => void, removeProduct?: (p: number) => void, staticVasePrice?: number, mobileView: boolean, navigation?: any, cardPrice: number, chocolonelyPrice: number}) => {

    const removeFlower = (id: number) => {
        if (props.removeProduct === undefined) {
            return;
        }
		storage.removeItemFromList('products', id, 'flower');
		props.removeProduct(id);
    }

    moment().locale('nl');
    const img = getUrl(props.package.image);
    const mobileView = props.mobileView;
    const title = findLocaleText(props.package, 'title');
    const middleBlockWidth = mobileView ? '65%' : 350;

    return (
        <div style={{position: 'relative', width: mobileView ? '90%' : 650, backgroundColor: 'rgba(196,196,196,0.2)', borderRadius: 10 ,padding: '5%', marginBottom: 25}}>
            {/* Image, description and amount row */}
            {mobileView ?
                <div className='row' style={{alignItems: 'center', justifyContent: 'space-between', marginBottom: 5}}>
                    <div style={{maxWidth: 320, marginRight: 20}}>
                        <MakeText style={{fontSize: 15, fontWeight: 'bold', marginRight: 5}}>{props.package.amount} x</MakeText>
                        <MakeText style={{fontSize: 18}}>{title}{(props.package.category.toLowerCase().indexOf('plant') === -1 && props.package.category !== 'Overig') ? `- ${props.package.size}`: ''}</MakeText>
                    </div>
                    <div>
                        <MakeText style={{fontSize: 15, fontWeight: 'bold'}}>{EURO(props.package.price * props.package.amount)}</MakeText>
                    </div>
                    {props.removeProduct &&
                        <div className='row' style={{alignItems: 'center', position: 'absolute', right: 5, top: 5}}>
                            <TouchableOpacity onPress={() => removeFlower(props.package.id)}>
                                <img src={'/images/trash.svg'} style={{width: 16, height: 16}} />
                            </TouchableOpacity>
                        </div>
                    }
                </div> : undefined}
            <div style={{position:'relative', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginBottom: mobileView ? 10 : 0}}>
                <img src={img} width={133} height={'auto'} style={{objectFit: props.package.category.substring(0, 7) === 'E-Flora' ? 'contain' : 'cover'}} />
                <div className='col' style={{marginLeft: 15, width: mobileView ? '90%' : '100%'}}>
                    {!mobileView ? <div className='row' style={{alignItems: 'center', justifyContent: 'space-between'}}>
                        <div style={{width: middleBlockWidth, marginRight: 20}}>
                            <MakeText style={{fontSize: 15, fontWeight: 'bold', marginRight: 5}}>{props.package.amount} x</MakeText>
                            <MakeText style={{fontSize: 24}}>{title}{(props.package.category.toLowerCase().indexOf('plant') === -1 && props.package.category !== 'Overig') ? `- ${props.package.size}`: ''}</MakeText>
                        </div>
                        <div>
                            <MakeText style={{fontSize: 15, fontWeight: 'bold'}}>{EURO(props.package.price * props.package.amount)}</MakeText>
                        </div>
                        {props.removeProduct &&
                            <div className='row' style={{alignItems: 'center', position: 'absolute', right: 0, top: -20}}>
                                <TouchableOpacity onPress={() => removeFlower(props.package.id)}>
                                    <img src={'/images/trash.svg'} style={{width: 16, height: 16}} />
                                </TouchableOpacity>
                            </div>
                        }
                    </div> : undefined}
                    <div className='col' style={{marginTop: 10}}>
                        <MakeText style={{fontSize: 15, fontWeight: 'bold'}} fontFamily='Open Sans'>{props.package.subscription ? 'Eerste b' : 'B'}ezorging</MakeText>
                        {props.package.delivery &&
                            <MakeText style={{fontSize: 15}}>
                                {capitalizeFirstLetter(moment(props.package.delivery.windowStart).format('dddd D MMMM'))}
                                {props.package.delivery.sameDay && ` - Spoed (+ ${EURO(15 * props.package.amount)})`}
                            </MakeText>
                        }
                    </div>
                    {props.package.subscription ?
                        <div className='col' style={{marginTop: 10}}>
                            <MakeText style={{fontSize: 15, fontWeight: 'bold'}} fontFamily='Open Sans'>Membership</MakeText>
                            {props.package.delivery &&
                                <MakeText style={{fontSize: 15}}>
                                    {recurrencyToString(props.package.type)}
                                </MakeText>
                            }
                        </div>
                    : undefined}
                    <div className='row' style={{alignItems: 'center', marginTop: 10, justifyContent: 'space-between'}}>
                        <div style={{width: middleBlockWidth}}>
                            <MakeText style={{fontSize: 15, marginRight: props.updateProduct ? 15 : 0}}>{props.package.note ? 'K' : 'Geen k'}aartje</MakeText>
                        </div>
                        <div>
                            <MakeText style={{fontSize: 15, fontWeight: 'bold'}}>{props.package.note ? EURO(props.cardPrice) : '-'}</MakeText>
                        </div>
                    </div>
                    {props.package.note &&
                        <div style={{width: middleBlockWidth}}>
                            <MakeText style={{fontSize: 15, marginRight: props.updateProduct ? 15 : 0}}>
                                {props.package.note && props.package.note.length > 1 ? `${props.package.note.substring(0, 149)}${props.package.note.length > 150 ? '... (etc.)' : ''}` : '-'}
                            </MakeText>
                        </div>
                    }

                    {props.package.category.substring(0, 7) === 'E-Flora' ?
                        <div className='row' style={{alignItems: 'center', marginTop: 10, justifyContent: 'space-between'}}>
                            <div style={{width: middleBlockWidth}}>
                                <MakeText style={{fontSize: 15, marginRight: props.updateProduct ? 15 : 0}}>{props.package.vaas ? 'V' : 'Geen v'}aas</MakeText>
                            </div>
                            <div>
                                <MakeText style={{fontSize: 15, fontWeight: 'bold'}}>{props.package.vaas ? EURO((props.staticVasePrice ? props.staticVasePrice : props.package.vasePrice) * props.package.amount) : '-'}</MakeText>
                            </div>
                        </div>
                    : undefined}

                    {props.chocolonelyPrice && props.chocolonelyPrice > 0 ?
                        <div className='row' style={{alignItems: 'center', marginTop: 10, justifyContent: 'space-between'}}>
                            <div style={{width: middleBlockWidth}}>
                                <MakeText style={{fontSize: 15, marginRight: props.updateProduct ? 15 : 0}}>{props.chocolonelyPrice && props.chocolonelyPrice > 0 ? 'C' : 'Geen c'}hocolonely</MakeText>
                            </div>
                            <div>
                                <MakeText style={{fontSize: 15, fontWeight: 'bold'}}>{props.package.chocolonely ? EURO((props.chocolonelyPrice || 0)) : '-'}</MakeText>
                            </div>
                        </div>
                    : undefined
                }
                </div>
            </div>
        </div>
    );
};
