import * as React from 'react';

import BasicPage from './Template/BasicPage';
import HomeComponent from './../Components/HomeNew/HomeNew';
import { Helmet } from "react-helmet";

export default class Home extends React.Component<any> {

    componentWillMount() {
        console.log('home container mounting');
    }

    shouldComponentUpdate(nextProps, nextState): boolean {
        console.log('Should update home container');
        console.log({
            props: this.props,
            state: this.state,
            nextProps,
            nextState,
        });
        return true;
    }

    render() {
        console.log('render home container');
        const jsonld = {
            "@context": "http://schema.org/",
            "@type": "Webpage",
            "name": "The Flower Family",
            "description": "The Flower Family is meer dan zomaar een online bloemenservice. Wij zijn de eerste bloemenservice die zich inzet voor mens, milieu en maatschappij. Als family member ontvang je niet alleen stijlvolle bloemen met een verhaal, maar maak je ook samen met ons de wereld een stukje mooier.",
            "url": "https://www.theflowerfamily.nl/"
        };

        return (
            <BasicPage navigation={this.props.navigation} key='homeBasic'>
                <Helmet key='homeHelmet'>
                    <title> The Flower Family </title>
                    <link rel="canonical" href="https://www.theflowerfamily.nl/" />
                    <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
                </Helmet>
	           <HomeComponent key='homeComponent' />
			</BasicPage>
        );
    }
}
