import * as React from 'react';

import BasicPage from './Template/BasicPage';
import TheFamily from './../Components/TheFamily/TheFamily';
import { Helmet } from "react-helmet";

export default class TheFamilyContainer extends React.Component<any> {

    render() {

        const jsonld = {
            "@context": "http://schema.org/",
            "@type": "Webpage",
            "name": "Meet The Family",
            "description": "Bij The Flower Family staan mens en milieu centraal. Binnen onze familie zijn we allemaal gelijkwaardig en werken we gezamenlijk aan onze missie: Flowering a better world!",
            "url": "https://www.theflowerfamily.nl/family"
        };

        return (
            <BasicPage navigation={this.props.navigation}>
                <Helmet>
                    <title> The Flower Family - Meet The Family </title>
                    <link rel="canonical" href="https://www.theflowerfamily.nl/family" />
                    <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
                </Helmet>
				<TheFamily />
			</BasicPage>
        );
    }
}
