import * as React from 'react';
import { EURO } from '../../Util/currency';
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';
// import TouchableOpacity from '../CrossPlatform/TouchableOpacity/TouchableOpacity';
// import Pagination from '../CrossPlatform/Pagination/Pagination';

const chocolonelyModal = (props: {chocolonely: boolean | undefined, chocolonelyCode: string, activePage: number, mobileView: boolean, 
        setChocolonelyCode: (c: string) => void, onChange: (p: number) => void,  prevPage: (() => void) | undefined, nextPage: () => void}) => {
    const mobileView = props.mobileView;
    const chocolonelyArr:any = [{
        id: 1, name: 'melk', chocolonelyCode: 'M177', price: 4.00, }, {
        /* id: 2, name: 'melk chocolate chip cookie', chocolonelyCode: '2', price: 4.00, }, {
        id: 3, name: 'melk donker', chocolonelyCode: '3', price: 4.00, }, {
        id: 4, name: 'melk donker brownie', chocolonelyCode: '4', price: 4.00, }, {
        id: 5, name: 'melk donker pretzel toffee', chocolonelyCode: '5', price: 4.00, }, {
        id: 6, name: 'melk hazelnoot', chocolonelyCode: '6', price: 4.00, }, {
        id: 7, name: 'melk hazelnoot crunch', chocolonelyCode: '7', price: 4.00, }, {
        id: 8, name: 'melk karamel amandel noga pretzel zeezout', chocolonelyCode: '8', price: 4.00, }, {
        id: 9, name: 'melk karamel biscuit', chocolonelyCode: '9', price: 4.00, }, {*/
        id: 10, name: 'melk karamel zeezout', chocolonelyCode: 'M178', price: 4.00, },/*  {
        id: 11, name: 'melk noga', chocolonelyCode: '11', price: 4.00, }, {
        id: 12, name: 'melk pecan crunch karamel', chocolonelyCode: '12', price: 4.00, }, {
        id: 13, name: 'melk pennywafel', chocolonelyCode: '13', price: 4.00, }, {
        id: 14, name: 'puur 51perc chocokoek citroenkaramel', chocolonelyCode: '14', price: 4.00, }, {
        id: 15, name: 'puur 70perc', chocolonelyCode: '15', price: 4.00, }, {
        id: 16, name: 'puur amandel zeezout', chocolonelyCode: '16', price: 4.00, }, {
        id: 17, name: 'puur romige hazelnoot crunch', chocolonelyCode: '17', price: 4.00, }, {
        id: 18, name: 'wit', chocolonelyCode: '18', price: 4.00, }, {
        id: 19, name: 'wit aardbei cheesecake', chocolonelyCode: '19', price: 4.00, }, {
        id: 20, name: 'wit framboos biscuit discodip', chocolonelyCode: '20', price: 4.00, }, {
        id: 21, name: 'wit framboos knettersuiker', chocolonelyCode: '21', price: 4.00, }, */
];
    chocolonelyArr.sort((a:any, b:any) => a.name.charCodeAt(0) - b.name.charCodeAt(0));
    const itemsCountPerPage = 4;
    const startIndexChocolonely = (itemsCountPerPage * (props.activePage - 1));

    return (
    <div>
        <div style={{ overflow: 'hidden'}}>
            <div style={{ display: 'flex', flexDirection: 'row', margin: !props.chocolonely ? '20px 0 20px 0' : '-40px 0 20px 0', width: '100%', justifyContent: 'space-between' }}>
            {props.prevPage ?
                    <Button
                        style={{width: 120, marginRight: mobileView ? '38px' : '0', marginTop: mobileView ? '50px' : '60px', marginBottom: '20px'}}
                        textStyle={{fontSize: 18, height: 40, fontFamily: 'Open Sans'}}
                        text={'Vorige'}
                        color={'greenWhite'}
                        onPress={() => (props.prevPage as any)()}
                    /> :
                    <div />
                }
                <Button
                    testID='nextPage'
                    style={{width: 120, marginRight: mobileView ? '38px' : '0', marginTop: mobileView ? '50px' : '60px', marginBottom: '20px'}}
                    textStyle={{fontSize: 18, height: 40, fontFamily: 'Open Sans'}}
                    disabled={props.chocolonely === undefined}
                    text={'Volgende'}
                    color={'greenWhite'}
                    onPress={() => props.nextPage()}
                />
                
            </div>
            {/* (props.chocolonely && !mobileView) &&
                <Pagination
                    activePage={props.activePage}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={chocolonelyArr.length}
                    pageRangeDisplayed={8}
                    onChange={(page) => props.onChange(page)}
                />
            */}
            <div style={{ padding: '0 40px' }}><MakeText style={{ fontSize: 16, width: 360 }} fontFamily='Open Sans'>Klik op een reep om toe te voegen + {EURO(4.0)}</MakeText></div>
            { (props.chocolonely && !mobileView) &&
                <div style={{width: '100%', height: '170px', display: 'grid', margin: '0 20px 20px 20px', gridTemplateColumns: 'auto auto', gridGap: '32px', alignItems: 'center', justifyContent: 'center'}}> {/*auto auto*/}
                    { chocolonelyArr.map((chocolonely, i) => {
                        if (i >= startIndexChocolonely && i < startIndexChocolonely + itemsCountPerPage) {
                            return chocolonely.chocolonelyCode === props.chocolonelyCode ?
                            (<div className={'image ' + i.toString()} style={{border: chocolonely.chocolonelyCode === props.chocolonelyCode ? '2px solid rgb(20, 20, 20)' : '2px solid rgba(0,0,0,0)', width: '90px', height: '90px', backgroundRepeat: 'no-repeat', backgroundSize: '90%', backgroundImage: 'url(https://websyn.nl/img/' + chocolonely.name.replace(/\s/g, '%20') + '.png)', backgroundColor: 'white', textAlign: 'left', backgroundPosition: 'center'}}>
                                <h5 style={{display: 'inline', position: 'absolute', maxWidth: '6em', margin: '70px 20px 0 0', fontFamily: 'Open Sans', fontSize: 13, fontWeight: 200, zIndex: 3}}>{chocolonely.name}</h5>
                            </div>) :
                            (<div
                                className={'image ' + i.toString()}
                                style={{border: chocolonely.chocolonelyCode === props.chocolonelyCode ? '2px solid rgb(20, 20, 20)' : '2px solid rgba(0,0,0,0)', width: '90px', height: '90px', backgroundRepeat: 'no-repeat', backgroundSize: '90%', backgroundImage: 'url(https://websyn.nl/img/' + chocolonely.name.replace(/\s/g, '%20') + '.png)', backgroundColor: 'white', textAlign: 'left', backgroundPosition: 'center'}}
                                onClick={() => props.setChocolonelyCode(chocolonely.chocolonelyCode)}
                            >
                                <h5 style={{display: 'inline', position: 'absolute', maxWidth: '6em', margin: '70px 20px 0 0', fontFamily: 'Open Sans', fontSize: 13, fontWeight: 200, zIndex: 3}}>{chocolonely.name}</h5>
                            </div>
                            )
                        }
                        
                        
                    })}
                </div>
            }
            { (props.chocolonely && mobileView) &&
                <div style={{display: 'grid', margin: '0 100px 20px 100px', gridTemplateColumns: 'auto auto', gridGap: '32px', alignItems: 'center', justifyContent: 'center'}}>{/* auto auto */}
                    { chocolonelyArr.map((chocolonely, i) => {
                        return chocolonely.chocolonelyCode === props.chocolonelyCode ?
                            (<div className={'image ' + i.toString()} style={{border: chocolonely.chocolonelyCode === props.chocolonelyCode ? '2px solid rgb(20, 20, 20)' : '2px solid rgba(0,0,0,0)', width: '45px', height: '45px', backgroundRepeat: 'no-repeat', backgroundSize: '90%', backgroundImage: 'url(https://websyn.nl/img/' + chocolonely.name.replace(/\s/g, '%20') + '.png)', backgroundColor: 'white', textAlign: 'left', backgroundPosition: 'center'}}>
                                <h5 style={{display: 'inline', position: 'absolute', margin: '45px 10px 0px 5px', fontFamily: 'Open Sans', fontSize: 13, fontWeight: 200, zIndex: 3}}>{chocolonely.name}</h5>
                            </div>) :
                            (<div
                                className={'image ' + i.toString()}
                                style={{border: chocolonely.chocolonelyCode === props.chocolonelyCode ? '2px solid rgb(20, 20, 20)' : '2px solid rgba(0,0,0,0)', width: '45px', height: '45px', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: 'url(https://websyn.nl/img/' + chocolonely.name.replace(/\s/g, '%20') + '.png)', backgroundColor: 'white', textAlign: 'left', backgroundPosition: 'center'}}
                                onClick={() => props.setChocolonelyCode(chocolonely.chocolonelyCode)}
                            >
                                <h5 style={{display: 'inline', position: 'absolute', margin: '45px 10px 0px 5px', fontFamily: 'Open Sans', fontSize: 13, fontWeight: 200, zIndex: 3}}>{chocolonely.name}</h5>
                            </div>)
                    })}
                </div>
            }
        </div>
    </div>
    );
}
chocolonelyModal.displayName = 'ChocolonelyModal';
export default chocolonelyModal