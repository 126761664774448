import * as React from 'react';
import TestimonialsText from '../../../text/home/Testimonials/TestimonialsText';
import { phone } from '../../../Util/screen/HandleLayout';
import MakeText from '../../Common/MakeText/MakeText';
import Carousel from './Carousel';

export default (props: any) => {
    const text = TestimonialsText;
    const mobileView = phone(props.width);
    return (
        <Carousel style={{width: mobileView ? '90%' : '50%', marginLeft: mobileView ? '5%' : '25%', alignItems: 'center'}}>
            {text.map((t, i) => (
                <div key={t.source + i} style={{display: 'flex', flexDirection: 'column', height: 200, justifyContent: 'center'}}>
                    <MakeText style={{fontSize: mobileView ? 15 : 18}}>"{t.body}"</MakeText>
                    <MakeText style={{fontWeight: 'bold', fontSize: mobileView ? 15 : 18, marginTop: 5}}>{t.source}</MakeText>
                </div>
            ))}
        </Carousel>
    );
}