import * as React from 'react';

export default class CouponField extends React.Component<{coupon: string, setCoupon: (c: string) => void, checkCoupon: () => void}> {
    state = {
        open: false,
    }
    render() {
        return (
        <div style={{marginTop: 20}}>
            <a style={{fontFamily: 'Open Sans', fontSize: 15, textDecoration: 'underline', cursor: 'pointer'}} onClick={() => this.setState({open: !this.state.open})}>Ik heb een kortingscode</a>
            { this.state.open &&
                <input type="text" style={{fontSize: 15, padding: 5, marginTop: 10, width: '90%'}} value={this.props.coupon} onBlur={() => this.props.checkCoupon()} onChange={(e) => this.props.setCoupon(e.target.value)}></input>
            }
        </div>
        );
    }
}