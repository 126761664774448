import * as React from 'react';
import { View, ActivityIndicator, TextInput } from 'react-native';
import {authenticate, getMe, updateUser} from "../../API/Http/Requests";
import {IProfile} from "../../API/Interfaces/IProfile";
import Button from './../../Components/CrossPlatform/Button/Button';
// import { DataService } from './../../API/DataService';
import MakeText from '../Common/MakeText/MakeText';

import styles from './AccountStyles';
import globalStyles from '../../Styles/styles';
import moment from 'moment';
import NieuwsBriefSwitch from './ProfileData/NieuwsBriefSwitch';
import { routingName } from '../../Components/CrossPlatform/OrderProcess/NewOrderProcess.web';
// import { IUser } from '../../API/IDataService';

interface IState {
	profile: IProfile;
	edit: boolean;
	editedFields: any;
	oldPass: string;
	errorText: string;
	loading: boolean;
}

moment.locale('nl');

export default class ProfileData extends React.Component<any> {

    state : IState = {
		profile: undefined as any,
		editedFields: {} as any,

		oldPass: '',
		edit: false,
		errorText: '',
		loading: false,
    }

    async componentDidMount() {
		const profile = await getMe();
		this.birthDateToReadable(profile);
        this.setState({ profile });
	}

	birthDateToReadable(profile: IProfile) {
		if (profile.birthDate) {
			const bd = moment(profile.birthDate);
			profile.birthDate = bd.isValid() ? bd.format('DD-MM-YYYY') : '';
		}
	}

	onChange(varName: string, value: any) : void {
		let tmpProfile = Object.assign({}, this.state.profile);

		tmpProfile[varName] = value;

		const edited = this.state.editedFields;
		edited[varName] = value;

		this.setState({profile: tmpProfile, editedFields: edited});
	}


	async updateData(): Promise<void> {
		if (this.state.editedFields.password && !this.state.oldPass) {
			this.setState({errorText: 'Om je wachtwoord te wijzigen moet je ter controle je oude wachtwoord invullen.'});
			return;
		}
		this.setState({loading: true});

		updateUser({id: this.state.profile.id, ...this.state.editedFields, oldPassword: this.state.oldPass}).then(async () => {
			// If password was changed, first authenticate again
			if (this.state.editedFields.password) {
				await authenticate({username: this.state.profile.email, password: this.state.editedFields.password});
			}
			const us = await getMe();
			this.birthDateToReadable(us);
			this.setState({errorText: '', loading: false, profile: us});
			this.flipEdit();
		}).catch( e => {
			this.setState({errorText: 'Woops, something went wrong!', loading: false});
			console.log(e);
		})
	}

	flipEdit() : void {
		this.setState({edit: !this.state.edit});
	}

    render() {

        if (!this.state.profile) {
            return (
                <View style={styles.columnWebView}>
                    <View style={{ marginTop: 20, marginBottom: 20 }}>
                        <ActivityIndicator size="large" color="#B9DCBF" />
                    </View>
                </View>
            );
        }
		if(this.state.edit) return this.editableProfile();

        return (
            <View style={{width: '100%'}}>
				<View style={[globalStyles.column, globalStyles.fullWidth]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Naam: </MakeText>
					<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{this.state.profile.firstName} {this.state.profile.lastName}</MakeText>
				</View>

				<View style={[globalStyles.column, globalStyles.fullWidth]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">E-mail: </MakeText>
					<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{this.state.profile.email}</MakeText>
				</View>

				<View style={[globalStyles.column, globalStyles.fullWidth]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Telefoonnummer: </MakeText>
					<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{this.state.profile.phone}</MakeText>
				</View>

				<View style={[globalStyles.column, globalStyles.fullWidth]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Geboortedatum: </MakeText>
					<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{this.state.profile.birthDate && this.state.profile.birthDate}</MakeText>
				</View>

				<View style={[globalStyles.column, globalStyles.fullWidth]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Wachtwoord: </MakeText>
					<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>***********</MakeText>
				</View>

				{routingName !== 'amsterdam' &&
					<View style={[globalStyles.column, globalStyles.fullWidth]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Nieuwsbrief: </MakeText>
						<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{this.state.profile.emails ? 'Ja' : 'Nee'}</MakeText>
					</View>
				}

            	<Button text="Bewerken" color="blackOpacity" style={{alignSelf: 'center', marginTop: 30, marginBottom: 33}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} onPress={() => this.flipEdit()} />

            </View>
        )
    }

	editableProfile() {

		return (
			<View>

				{this.state.errorText.length ? <MakeText style={styles.errorText}>{this.state.errorText}</MakeText> : undefined}

				<View>

					<View style={[globalStyles.column, {backgroundColor: '#E3F0E4', width: this.props.width}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Voornaam: </MakeText><TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("firstName", e)} value={this.state.profile.firstName}></TextInput>
					</View>

					<View style={[globalStyles.column, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Achternaam: </MakeText><TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("lastName", e)} value={this.state.profile.lastName}></TextInput>
					</View>

					<View style={[globalStyles.column, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Telefoonnummer: </MakeText><TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("phone", e)} value={this.state.profile.phone === null ? undefined : this.state.profile.phone}></TextInput>
					</View>

					<View style={[globalStyles.column, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Geboortedatum (dd-mm-yyyy): </MakeText>
						<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("birthDate", e)} value={this.state.profile.birthDate}></TextInput>
					</View>

					<View style={[globalStyles.column, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Huidig wachtwoord: </MakeText><TextInput secureTextEntry={true} style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.setState({oldPass: e})} value={this.state.oldPass ? this.state.oldPass : ""}></TextInput>
					</View>

					<View style={[globalStyles.column, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Nieuw wachtwoord: </MakeText><TextInput secureTextEntry={true} style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("password", e)} value={this.state.profile.password ? this.state.profile.password : ""}></TextInput>
					</View>

					{routingName !== 'amsterdam' &&
						<View style={[globalStyles.row, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Nieuwsbrief: </MakeText>
							<NieuwsBriefSwitch
								setNieuwsbrief={(n: boolean) => {
									let tmpState = Object.assign({}, this.state.profile);
									tmpState.emails = n;
									const edited = this.state.editedFields;
									edited.emails = tmpState.emails;
									this.setState({profile: tmpState, editedFields: edited});
								}}
								nieuwsbrief={this.state.profile.emails}
							/>
						</View>
					}

				</View>

				<View style={[globalStyles.row, {alignSelf: 'center', marginTop: 30, marginBottom: 33}]}>
					{this.state.edit && <Button color="blackOpacity" text="Annuleren" textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} onPress={() => this.flipEdit()} />}
					<Button text={this.state.edit ? "Opslaan" : "Bewerken"} color="blackOpacity" style={{marginLeft: 10}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} onPress={() => this.updateData()} />
				</View>

				{this.state.loading ? <ActivityIndicator size='large' style={{marginBottom: 33}} /> : undefined}

			</View>
		)
	}
}
