import * as React from 'react';

import PrivacyComponent from './../Components/Privacy/Privacy';
import BasicPage from './Template/BasicPage';

import { Helmet } from "react-helmet";

export default (props: any) => {

    const jsonld = {
        "@context": "http://schema.org/",
        "@type": "Webpage",
        "name": "The Flower Family - Privacy",
        "url": "https://www.theflowerfamily.nl/privacy"
    };

    return (<BasicPage navigation={props.navigation}>
        <Helmet>
            <title> Privacy </title>
            <link rel="canonical" href="https://www.theflowerfamily.nl/" />
            <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
        </Helmet>
        <PrivacyComponent />
    </BasicPage>);
}
