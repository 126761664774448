import * as React from 'react';
import { View, TextInput, ActivityIndicator, TouchableOpacity, ScrollView } from 'react-native';
import validator from 'validator';
import {authenticate, getMe} from "../../API/Http/Requests";

import Button from '../CrossPlatform/Button/Button';
import OrderProcessStyles from './OrderProcessStyles';

import MakeText from '../Common/MakeText/MakeText';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';
import moment from 'moment';

export default class Login extends React.Component<any> {

	state = {
		username: '',
		password: '',
		errorText: '',
		loadingWheel: null,
	}

	componentDidMount() {
		GoogleAnalytics.getInstance().hit(this.props.navigation.state);
	}

	async attemptLogin() {
		// Removed HTML from input.
		let username = this.state.username;
		let password = this.state.password;

		this.setState({loadingWheel: <ActivityIndicator size="large" color="#B9DCBF" />});

		// Checks the min and max lengths of the input.
		if (validator.isLength(username, { min: 1, max: 128 }) && validator.isLength(password, { min: 1, max: 128 })) {
			// If the validation checks out, attempt to login.
			// DataService.login(username, password).then( result => {
			authenticate({ username, password }).then(async (result) => {
				await getMe();
				// If the login was succesfull, continue to the next page.
				if (result.code === 200) {
					this.setState({ loadingWheel: null });
					return this.props.route ? this.props.navigation.navigate(this.props.route) : this.props.navigation.navigate('pickDeliveryAddress');
				}
			}).catch(e => {
				console.log(e.response.data.message);
				if (e.response.status === 403) {
					this.setState({ errorText: `Dit account is geblokkeerd tot ${moment(e.response.data.message.split(';')[1]).format('HH:mm')} vanwege gefaalde login pogingen.`, loadingWheel: null });
					return;
				}
				this.setState({
					errorText: 'Verkeerde email en/of wachtwoord', loadingWheel: null });
				// DataService.logout();
			});
		} else {
			this.setState({
				errorText: 'Geen gegevens ingevuld', loadingWheel: null
			});
		}
	}

	async loginWithStorage(username: string, password: string) : Promise<any> {

		// Removed HTML from input.
		username = this.escapeInput(username);
		password = this.escapeInput(password);

		// TODO Login with http
		// let loginAttempt = await DataService.login(username, password)
		// If the login was succesfull, continue to the next page.
		//if(loginAttempt.code === 200) return this.props.route ? this.props.navigation.navigate(this.props.route) : this.props.navigation.navigate('pickDeliveryAddress');
		// else return null;
		return undefined;
	}

	escapeInput(input: any) {
		return validator.escape(input);
	}

    render() {
		const styles = OrderProcessStyles;
		let backButton : JSX.Element | undefined;

		if (this.props.back) {
            backButton = (
				<View style={{flexDirection: 'row', justifyContent: 'space-between', width: '80%'}}>
					<View style={styles.backButt}>
						<Button color='greenWhite' text='terug' onPress={() => this.props.navigation.navigate(this.props.back)} />
					</View>
					<View style={styles.buttonSeparator}/>
					<View style={styles.forwardButt}>
					</View>
				</View>
			);
		}

        return (
            <View style={[styles.mainView]}>
                <ScrollView style={[{height: this.props.mobileView ? this.props.height * 0.7 : this.props.height * 0.6, width: this.props.width}, this.props.mobileView ? styles.pickLoginMobileTopMargin : undefined]}>
					<TextInput testID='username' placeholder={'E-mail'} autoCapitalize='none' onChangeText={(username) => this.setState({username})} value={this.state.username} style={[styles.textInput, {alignSelf: 'center', padding: 7, width: this.props.width * 0.9, marginBottom: 9, color: '#414141'}]} />
					<TextInput testID='password' secureTextEntry={true} autoCapitalize='none' onChangeText={(password) => this.setState({password})} value={this.state.password} placeholder={'Wachtwoord'} style={[styles.textInput, {alignSelf: 'center', padding: 7, width: this.props.width * 0.9, color: '#414141'}]} />

					<MakeText style={{alignSelf: 'center', color: 'red'}}>{this.state.errorText}</MakeText>

					<TouchableOpacity onPress={() => this.props.navigation.navigate( this.props.forgotPasswordRoute ? 'orderProcessForgotPassword' : 'ForgotPassword')}>
						<MakeText style={[styles.forgotPW, {marginRight: this.props.width * 0.05, marginTop: 7, letterSpacing: 0.72875, fontSize: 11, color: '#7E9591'}]} textStyle={{color: '#414141'}}>Wachtwoord vergeten?</MakeText>
					</TouchableOpacity>

					<View style={{marginVertical: 10}}>{this.state.loadingWheel}</View>

					<View style={{marginTop: 20}}>
						<Button testID='loginNext' style={{alignSelf: 'center'}} textStyle={{fontSize: 14, letterSpacing: 0.9275}} color='greenWhite' text='Log in' onPress={() => this.attemptLogin()} />

						{/* <MakeText style={styles.orText}>of</MakeText>

						<Button style={{alignSelf: 'center'}} color='blueWhite' text='Met Facebook inloggen' onPress={() => console.log('pressed!')} /> */}
					</View>
				</ScrollView>

				{backButton}
            </View>
        );
    }
}
