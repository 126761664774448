import { ILocation } from "../../API/IDataService";
import axios from 'axios';

export default async (location: ILocation) => {
    const res = await axios.get(`https://theff.nl/services/zip/1a6eaa470c7d3699d530fa950aa6eb659eaa74c4260ca76f2def4842289ebc7d/` +
        `${location.postalcode.replace(' ', '')}/${location.housenumber}`).catch(() => {
            return undefined;
        });
    if(res === undefined || res.data === undefined || res.data === '') {
        return undefined;
    }
    return {
        street: res.data.street,
        city: res.data.city,
    };
}