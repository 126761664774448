import * as React from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import MakeText from '../Common/MakeText/MakeText';

interface IFAQItem {
	title: string,
	body: string,
	mobileView: boolean,
}

export default class FAQItem extends React.Component<IFAQItem> {

    state = {
        collapsed: true
    }

    collapse() {
        const coll = this.state.collapsed;
        this.setState({
            collapsed: !coll
        })
    }

    render() {
        const styles = StyleSheet.create({
            mainView: {
				marginTop: 22,
            },
            title: {
                fontSize: this.props.mobileView ? 20 : 24,
                lineHeight: 33,
                letterSpacing: 0.06,
                color: 'black',
				marginLeft: 20,
            },
            titlePlus: {
                fontSize: 32,
                lineHeight: 33,
                letterSpacing: 0.06,
                color: '#B9DCBF'
            },
            titleMinus: {
                fontSize: 18,
                lineHeight: 33,
                letterSpacing: 0.06,
                color: '#B9DCBF',
            },
            bodyView: {

            },
            bodyText: {
                color: '#414141',
                fontSize: 15,
                lineHeight: 32,
                letterSpacing: 0.9275,
                margin: 25,
				marginLeft: 34,
                marginBottom: 0,
            },
            row: {
                flexDirection: 'row',
            }
        });
        return (
        <View style={styles.mainView}>
            <TouchableOpacity onPress={() => this.collapse()}>
                <View style={styles.row}>
                {this.state.collapsed ?
                    <MakeText style={styles.titlePlus}>+</MakeText>
                    :
                    <MakeText style={styles.titleMinus}>&#8212;</MakeText>
                }

                    <MakeText style={styles.title}>{this.props.title}</MakeText>
                </View>
            </TouchableOpacity>
            {
                !this.state.collapsed &&
                    <View style={styles.bodyView}>
                        <MakeText style={styles.bodyText}>{this.props.body}</MakeText>
                    </View>
            }
        </View>
        );
    }
}
