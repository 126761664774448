import * as React from 'react';
// import { unescape } from 'validator';
import MakeText from '../../Common/MakeText/MakeText';
import TouchableOpacity from '../../CrossPlatform/TouchableOpacity/TouchableOpacity';

/**
 * Displays a selectable address.
 * Use in the following way:
 *
 * <Location style={{alignSelf: 'center'}} location={location} type={type} setSelected={this.setSelected.bind(this)} selected={selected} />
 */
export default (props: any) => {

	return (
		<TouchableOpacity testID='location' style={{ position: 'relative', padding: 10, backgroundColor: props.selected ? '#D9DCBF' : 'white', display: 'flex', flexDirection:'column', alignItems: 'center', width: '80%', ...props.style, borderColor: 'lightgrey', borderWidth: 1, borderStyle: 'solid'}} onPress={() => props.setSelected(props.location, props.type)}>
			<MakeText style={{color: 'black', fontSize: 15, marginTop: 10}} fontFamily={'Open Sans'}>{props.location.fname} {props.location.lname}</MakeText>
			<MakeText style={{color: 'black', fontSize: 15}} fontFamily={'Open Sans'}>{props.location.street} {props.location.housenumber}{props.location.addition !== '' && `-${props.location.addition}`}</MakeText>
			<MakeText style={{color: 'black', fontSize: 15, marginTop: 10}} fontFamily={'Open Sans'}>{props.location.postalcode.toUpperCase()} {props.location.city.replace('&#x27;', "'")}</MakeText>
			{props.selected ?
				<img src='/images/check.svg' style={{position: 'absolute', top: 10, left: 10, height: 20, width: 20}} />
			: undefined}
		</TouchableOpacity>
	);
};
