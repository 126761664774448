import * as React from 'react';
import OrderProcessNativeTemplate from './../../Containers/Template/OrderProcessNativeTemplate';
import Overview from './1-Overview';
import NewOrderProcessLogin from './2-Login';
import Register from './3-Register';
import PickAddress from './4-PickAddress';
import NewAddress from './5-NewAddress';
import AddDeliveryAddress from './AddDeliveryAddress';
import AddBillingAddress from './AddBillingAddress';
import Payment from './6-Payment';


/**
 * This file is also used for the web mobile view of the order process.
 * Therefore it has survived the native purge even though the name of the file contains "native".
 */

export const overview = (props: any) => (
  	<OrderProcessNativeTemplate navigation={props.navigation}>
		  <Overview />
	</OrderProcessNativeTemplate>
);
export const pickLogin = (props: any) => (
	<OrderProcessNativeTemplate navigation={props.navigation} back={'orderOverview'}>
		<NewOrderProcessLogin />
	</OrderProcessNativeTemplate>
);

export const createAccount = (props: any) => (
	<OrderProcessNativeTemplate navigation={props.navigation} back={'pickLogin'}>
		<Register />
	</OrderProcessNativeTemplate>
);

export const pickDeliveryAddress = (props: any) => (
	<OrderProcessNativeTemplate navigation={props.navigation}>
		<PickAddress />
	</OrderProcessNativeTemplate>
);

export const deliveryAddress = (props: any) => (
	<OrderProcessNativeTemplate navigation={props.navigation}>
		<NewAddress />
	</OrderProcessNativeTemplate>
);

export const addDeliveryAddress = (props: any) => (
	<OrderProcessNativeTemplate navigation={props.navigation}>
		<AddDeliveryAddress />
	</OrderProcessNativeTemplate>
);

export const addBillingAddress = (props: any) => (
	<OrderProcessNativeTemplate navigation={props.navigation}>
		<AddBillingAddress />
	</OrderProcessNativeTemplate>
);

export const payment = (props: any) => (
	<OrderProcessNativeTemplate navigation={props.navigation}>
		<Payment />
	</OrderProcessNativeTemplate>
);

