import * as React from 'react';
import {View, TextInput, ActivityIndicator, ScrollView} from 'react-native';
import validator from 'validator';
import {createUser} from "../../API/Http/Requests";

import Button from './../CrossPlatform/Button/Button';
import OrderProcessStyles from './OrderProcessStyles';
import MakeText from '../Common/MakeText/MakeText';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';
import MakeTabs from '../CrossPlatform/MakeTabs/MakeTabs';

import globalStyles from './../../Styles/styles';
import config from '../../config/config.selector';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';


export default class CreateAccount extends React.Component<any> {

    styles = OrderProcessStyles;

	state = {
		company_account: 'Particulier',
		email: '',
		companyName: '',
		vat_number: '',
		phone: '',
		fname: '',
		lname: '',
		password: '',
		repeatPassword: '',
		errorText: '',

		loadingWheel: null,
	}

	componentDidMount() {
		if(this.props.store.setRightTitle) {
			this.props.store.setRightTitle('Nieuw account');
		}

		if(this.props.company_account) {
			this.setState({company_account: 'Bedrijf'});
		}
		GoogleAnalytics.getInstance().hit(this.props.navigation.state);
	}

	async prepareRegisterRequest() {

		// Show a loading wheel to the user.
		this.setState({loadingWheel: <ActivityIndicator size="large" color="#B9DCBF" />})

		// Escape input, check if valid data has been entered.
		let cleanInput = this.checkInput();

		// return if an error was found.
		if(cleanInput.error) return 0;

		// Finally, if the data is correct and clean, attempt the login request.
		this.state.company_account === 'Bedrijf' ?
			this.attemptRegister(cleanInput.name, cleanInput.fname, cleanInput.lname, cleanInput.email, cleanInput.password, cleanInput.phone, cleanInput.companyData)
		:
			this.attemptRegister(cleanInput.name, cleanInput.fname, cleanInput.lname, cleanInput.email, cleanInput.password, cleanInput.phone);

		return 1;
	}

	// Takes data needed from the state for creating an account and cleans it.
	checkInput() {

		let cleaned: any;

		if(this.state.company_account === 'Bedrijf') {
			cleaned = this.checkCompanyInput();
		} else {
			cleaned = this.checkPersonInput();
		}

		return cleaned;

	}

	checkCompanyInput() {
		let name = validator.escape(this.state.fname + ' ' + this.state.lname);
		let fname = validator.escape(this.state.fname);
		let lname = validator.escape(this.state.lname);
		let company_name = validator.escape(this.state.companyName);
		let email = validator.escape(this.state.email);
		let phone = validator.escape(this.state.phone);
		let vat_number = validator.escape(this.state.vat_number.replace(/\s/g, ''));
		let password = validator.escape(this.state.password);
		let repeatPassword = validator.escape(this.state.repeatPassword);

		if(!validator.isLength(name, {min: 2, max: 128})) {
			this.setState({loadingWheel: null, errorText: 'Ongeldige voornaam'});
			return {error: true};
		}
		else if(!validator.isLength(company_name, {min: 1, max: 128})) {
			this.setState({loadingWheel: null, errorText: 'Ongeldige Bedrijfsnaam'});
			return {error: true};
		}
		else if(!validator.isLength(password, {min: 1, max: 128})) {
			this.setState({loadingWheel: null, errorText: 'Ongeldig wachtwoord'});
			return {error: true};
		}
		else if(!validator.isLength(email, {min: 2, max: 128})) {
			this.setState({loadingWheel: null, errorText: 'Ongeldig e-mail adres'});
			return {error: true};
		}
		else if(!validator.isLength(phone, {min:8, max: 15})) {
			this.setState({loadingWheel: null, errorText: 'Ongeldig telefoonnummer'});
			return {error: true};
		}
		else if(!validator.isLength(vat_number, {min: 10, max: 20})) {
			this.setState({loadingWheel: null, errorText: 'Ongeldig BTW-nummer'});
			return {error: true};
		}

		if(this.checkEmail(email)) {
			return {error: true};
		}

		// Make sure the passwords that were entered are the same.
		if(this.checkIfPasswordsMatch(password, repeatPassword)) {
			return {error: true};
		}

		const companyData = {
			is_company: true,
			company: company_name,
			email,
			phone,
			vatNumber: vat_number,
		};

		return {name, fname, lname, email, password, phone, companyData, error: false}
	}

	checkPersonInput() {
		// Removed HTML from input.
		let name = validator.escape(this.state.fname + ' ' + this.state.lname);
		let fname = validator.escape(this.state.fname);
		let lname = validator.escape(this.state.lname);
		let email = validator.escape(this.state.email);
		let phone = validator.escape(this.state.phone);
		let password = validator.escape(this.state.password);
		let repeatPassword = validator.escape(this.state.repeatPassword);

		if(this.checkEmail(email)) {
			return {error: true};
		}

		// Checks the min and max lengths of the input.
		if(!validator.isLength(name, {min: 2, max: 128}) || !validator.isLength(password, {min: 1, max: 128}) || !validator.isLength(email, {min: 2, max: 128}) || !validator.isLength(phone, {min: 2, max: 20})) {
			this.setState({loadingWheel: null, errorText: 'Oops! Er is verkeerde data ingevoerd. Controleer alstublieft het formulier.'});
			return {error: true};
		}

		// Make sure the password is secure enough
		if(this.checkPasswordStrength(password)) {
			this.setState({loadingWheel: null, errorText: 'Het wachtwoord moet minimaal bestaan uit 10 karakters, met minimaal één hoofdletter en één cijfer.'});
			return {error: true};
		}

		// Make sure the passwords that were entered are the same.
		if(this.checkIfPasswordsMatch(password, repeatPassword)) {
			return {error: true};
		}

		return {name, fname, lname, email, password, phone, error: false}
	}

	checkEmail(email: string): boolean {
		if(!email.includes('@')) {
			this.setState({loadingWheel: null, errorText: 'Het opgegeven mailadres is niet geldig.'});
			return true;
		}

		if (routingName === "amsterdam" && !/^.+@(ggd.)?amsterdam\.nl$/g.test(email)) {
			this.setState({loadingWheel: null, errorText: 'Dit e-mail adres is niet geldig in deze omgeving.'});
			return true;
		}

		if (process.env.REACT_APP_NODE_ENV === "portal")
		{
			const pathSplit = window.location.pathname.split('/');
			const domain = pathSplit.length > 2 ? window.location.pathname.split('/')[2] : undefined
			const portals = config.portals;
			console.log(pathSplit);
			console.log(domain);
			console.log(portals);
			if (domain === 'consumersite') {
				return false;
			}
			if(domain && portals && portals[domain] && !email.includes(portals[domain].domain)){				
				this.setState({loadingWheel: null, errorText: 'Dit e-mail adres is niet geldig in deze omgeving.'});
				return true;
			} else if(!domain || !portals || !portals[domain]){
				this.setState({loadingWheel: null, errorText: 'Portal omgeving incorrect.'});
				return true;
			}
			
		}

		return false;
	}

	// Checks password strength
	checkPasswordStrength(password: string): boolean {
		return !(/^(?=.*[A-Z])(?=.*[0-9]).{10,}$/).test(password);
	}

	// Make sure the passwords that were entered are the same.
	checkIfPasswordsMatch(password: string, repeatPassword: string): boolean {
		if(password !== repeatPassword) {
			this.setState({loadingWheel: null, errorText: 'The passwords you entered do not match!'});
			return true;
		}

		return false;
	}

	attemptRegister(name: string, firstName: string, lastName: string, email: string, password: string, phone: string, companyData?: any) {

		createUser({email, name, firstName, lastName, password, companyData, phone, birthDate: ''}).then( profile => {
			// If the login was succesfull, continue to the next page.
			if (profile.email) {

				if(this.props.route) {
					return this.props.navigation.navigate(this.props.route);
				} else {
					return this.props.navigation.navigate('deliveryAddress');
				}
			}

			// If something went wrong, display the correct error message.
			this.setErrorMessage();

		}).catch( e => {
			console.log(e);
			this.setState({loadingWheel: null, errorText: 'Er lijkt iets fout te gaan, probeer het later opnieuw.'});
		});
	}

	setErrorMessage() : void {
		return this.setState({errorText: 'Sorry, er is iets foutgegaan!'});
	}

    render() {
		const conf = config as any;
        return (
            <View style={[this.styles.mainView]}>
				<ScrollView style={{height: this.props.mobileView ? this.props.height * 0.7 : this.props.height * 0.6, width: this.props.width}}>
					{ !this.props.store.setRightTitle &&
						<View>
							<MakeText fontFamily='Crimson Text' style={[globalStyles.h2, globalStyles.alignSelfCenter, {marginBottom: 30}]}>
								Nieuw account
							</MakeText>
						</View>
					}

					<View style={this.styles.inputView}>
						{!conf.hideRegisterTabs &&
							<View style={this.styles.accountType}>
								<MakeTabs
									textStyles={{
										fontSize: 18,
										letterSpacing: 0.06,
										color: '#414141',
										marginHorizontal: 8,
									}}
									activeTab={this.state.company_account}
									options={['Particulier', 'Bedrijf']}
									style={{
										flexDirection: 'row',
										alignSelf: 'center',
										justifyContent: 'space-evenly',
										flexWrap: 'wrap',
										marginBottom: 8,
										display: 'flex'
									}}
									route='stories'
									changeTab={(option) => this.setState({company_account: option})}
								/>
							</View>
						}

						<View>
							{ this.state.company_account === 'Bedrijf' &&
								<View style={{flexDirection: 'row', alignSelf: 'center'}}>
									<View style={{width: this.props.width * 0.9}}>
										<TextInput testID='company_name' placeholder={'Bedrijfsnaam'} value={this.state.companyName}
												   style={[this.styles.textInput, {fontSize: 15, padding: 7}]}
												   onChangeText={(companyName) => this.setState({companyName})}/>
									</View>
								</View>
							}

							<View style={{flexDirection: 'row', alignSelf: 'center'}}>
								<View style={{width: this.props.width * 0.45}}>
									<TextInput testID='voornaam' placeholder={'Voornaam'} value={this.state.fname}
											   style={[this.styles.textInput, {fontSize: 15, padding: 7}]}
											   onChangeText={(fname) => this.setState({fname: fname})}/>
								</View>
								<View style={{width: this.props.width * 0.45}}>
									<TextInput testID='achternaam' placeholder={'Achternaam'}
											   value={this.state.lname}
											   style={[this.styles.textInput, {fontSize: 15, padding: 7}]}
											   onChangeText={(lname) => this.setState({lname: lname})}/>
								</View>
							</View>

							<TextInput testID='email' placeholder={'E-mail'} autoCapitalize='none' onChangeText={(email) => this.setState({email})} value={this.state.email} style={this.styles.textInput}/>
							<TextInput testID='phone' placeholder={'Telefoonnummer'} autoCapitalize='none' onChangeText={(phone) => this.setState({phone})} value={this.state.phone} style={this.styles.textInput} />

							{this.state.company_account === 'Bedrijf' ?
								<View>
									<TextInput testID='vat_number' placeholder={'BTW-nummer'} autoCapitalize='none' onChangeText={(vat_number) => this.setState({vat_number})} value={this.state.vat_number} style={this.styles.textInput} />
								</View>
							:
								null
							}

							<TextInput testID='password' secureTextEntry={true} autoCapitalize='none' onChangeText={(password) => this.setState({password})} value={this.state.password} placeholder={'Wachtwoord'} style={this.styles.textInput} />
							<TextInput testID='confirmPassword' secureTextEntry={true} autoCapitalize='none' onChangeText={(repeatPassword) => this.setState({repeatPassword})} value={this.state.repeatPassword} placeholder={'Herhaal wachtwoord'} style={this.styles.textInput}/>
						</View>

						<View style={{marginTop: 10, alignItems: 'center'}}>

							<View style={{marginTop: 5, marginBottom: 15}}>{this.state.loadingWheel}</View>

							<Button testID='registerNext' color={'greenWhite'} text={"Verstuur"} onPress={() => this.prepareRegisterRequest()} />
							<MakeText style={{color: 'red', marginTop: 20, marginBottom: 20}}>{this.state.errorText}</MakeText>
						</View>

					</View>
				</ScrollView>

				{ this.props.back &&
                <View style={{flexDirection: 'row', justifyContent: 'space-between', width: '80%', alignSelf: 'center'}}>
                    <View style={this.styles.backButt}>
                        <Button color={'greenWhite'} text={"terug"} onPress={() => this.props.navigation.navigate('pickLogin')} />
                    </View>
					<View style={this.styles.buttonSeparator}/>
					<View style={this.styles.forwardButt} />
                </View>
				}
            </View>
        );
    }
}
