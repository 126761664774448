import * as React from 'react';
import { Image, View, StyleSheet } from 'react-native';

import FooterWeeklyFlowers from './../Common/FooterWeeklyFlowers/FooterWeeklyFlowers';
import MakeText from '../Common/MakeText/MakeText';
import { phone } from '../../Util/screen/HandleLayout';

export default (props: any) => {

	const mobileView: boolean = phone(props.width);

    const styles = StyleSheet.create({
        header1: {
            textAlign: 'center',
            marginBottom: 30,
            color: '#414141',
            fontSize: 36,
            letterSpacing: 2.13,
        },
        headerText: {
            width: mobileView ? props.width * 0.9 : props.width * 0.5,
            alignSelf: 'center',
            marginBottom: 30,
            color: '#414141',
            lineHeight: 32,
            fontSize: 14,
            letterSpacing: 1.1925
        },
        header2: {
            fontSize: 36,
            textAlign: 'center',
            marginBottom: 15,
            color: '#414141',
            lineHeight: 42,
            letterSpacing: 4.2
        },
        headerText2: {
            fontSize: 20,
            textAlign: 'center'
        },
        headerText3: {
            fontSize: 20,
            maxWidth: props.width,
            margin: 10,
            color: '#414141'
        },
        zoDoenWij: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        zoBlock: {
            flexGrow: 1,
            maxWidth: 300,
            margin: 20
        },
        zoPicca: {
            width: 300,
            height: 300,
            margin: 'auto',
        },
        werkplekText: {
            lineHeight: 32,
            fontSize: 14,
            letterSpacing: 0.9275
        },
        werkplekHeader: {
            fontSize: 30,
            marginBottom: 15,
            color: '#414141',
            lineHeight: 42,
            fontStyle: 'italic',
            letterSpacing: 2.38875
        },
        imgButton: {
            alignSelf: 'center',
            marginTop: 20
        },
        imgButtonText: {
            // fontFamily: 'Open Sans',
            color: '#414141',
            lineHeight: 22,
            fontSize: 16,
            textAlign: 'center',
            letterSpacing: 2
        }
    });


    return (

	    <View>
	        <View>
	            <img src={'/images/Foto verzorging header.jpg'} height={mobileView ? 310 : props.height * 0.6} width={props.width} style={{objectFit: 'cover'}} />
	        </View>
	        <View style={{alignSelf: 'center', marginTop: 30}}>
	            <MakeText fontFamily='Crimson Text' style={styles.header1}>Verzorgingstips</MakeText>
	            <MakeText style={styles.headerText}>
                    Met de juiste verzorging houd je jouw bloemen zo lang mogelijk mooi. Er is één ding waar ze echt niet tegen kunnen en dat zijn bacteriën. Als je deze vier eenvoudige stappen volgt blijven je bloemen gegarandeerd langer gelukkig.
	            </MakeText>
	        </View>

            <View style={{flexDirection: mobileView ? 'column' : 'row', justifyContent: 'center', marginHorizontal: mobileView ? 10 : 40, marginVertical: -20, marginTop: 40, alignSelf: 'center'}}>
                <Image source={require('./../../images/tips_1.jpg')} style={{height: 300, width: 300, margin: 20, alignSelf: 'center'}} />
                <View style={{margin: 20, flexDirection: 'column', justifyContent: 'center', width: mobileView ? props.width * 0.9 : props.width * 0.5}}>
                    <MakeText fontFamily='Crimson Text' style={styles.werkplekHeader}>
                        Stap 1: Maak je vaas schoon
                    </MakeText>
                    <MakeText style={styles.werkplekText}>
                        Doe een paar druppeltjes afwasmiddel op een schone spons en ga daarmee de vaas grondig boenen om alle bacteriën te verwijderen. Spoel de vaas vervolgens goed af met water. Kijk op het zakje snijbloemenvoeding hoeveel water je nodig hebt en los de voeding in de juiste hoeveelheid koud kraanwater op door even te roeren.
                    </MakeText>
                </View>
            </View>

            {mobileView ?
                <View style={{flexDirection:'column', justifyContent: 'center', marginHorizontal: 10, alignSelf: 'center', marginVertical: -20}}>
                    <Image source={require('./../../images/tips_2.jpg')} style={{height: 300, width: 300, margin: 20, alignSelf: 'center'}} />
                    <View style={{margin: 20, flexDirection: 'column', justifyContent: 'center', width: props.width * 0.9}}>
                        <MakeText fontFamily='Crimson Text' style={styles.werkplekHeader}>
                            Stap 2: Verzorg je bloemen
                        </MakeText>
                        <MakeText style={styles.werkplekText}>
                        Haal de bloemen uit de verpakking en snij met een schoon en scherp mes minstens 2 cm van de stelen schuin af. Let op dat je geen bot of vies mes gebruikt want anders kunnen de bloemen niet lekker drinken. Verwijder de bladeren onder de vaasrand, dan blijft het water langer vers.
                        </MakeText>
                    </View>
                </View>
                :
                <View style={{flexDirection: 'row', justifyContent: 'center', marginHorizontal: 40, alignSelf: 'center', marginVertical: -20}}>
                    <View style={{margin: 20, flexDirection: 'column', justifyContent: 'center', width: props.width * 0.5}}>
                        <MakeText fontFamily='Crimson Text' style={styles.werkplekHeader}>
                            Stap 2: Verzorg je bloemen
                        </MakeText>
                        <MakeText style={styles.werkplekText}>
                            Haal de bloemen uit de verpakking en snij met een schoon en scherp mes minstens 2 cm van de stelen schuin af. Gebruik een scherp mes anders komt er geen voeding bij de bloemen. Verwijder de bladeren onder de vaasrand, dan blijft het water langer vers.
                        </MakeText>
                    </View>
                    <Image source={require('./../../images/tips_2.jpg')} style={{height: 300, width: 300, margin: 20, alignSelf: 'center'}} />
                </View>
            }

            <View style={{flexDirection: mobileView ? 'column' : 'row', justifyContent: 'center', margin: mobileView ? 10 : 40, alignSelf: 'center', marginVertical: -20}}>
                <Image source={require('./../../images/tips_3.jpg')} style={{height: 300, width: 300, margin: 20, alignSelf: 'center'}} />
                <View style={{margin: 20, flexDirection: 'column', justifyContent: 'center', width: mobileView ? props.width * 0.9 : props.width * 0.5}}>
                    <MakeText fontFamily='Crimson Text' style={styles.werkplekHeader}>
                        Stap 3: Schik de bloemen en ververs het water
                    </MakeText>
                    <MakeText style={styles.werkplekText}>
                        Doe de bloemen in de vaas en schik ze tot je tevreden bent. Zet de vaas op een koele plek neer, niet in direct zonlicht, op de tocht of naast fruit.
                    </MakeText>
                </View>
            </View>

            {mobileView ?
                <View style={{flexDirection: mobileView ? 'column' : 'row', justifyContent: 'center', margin: mobileView ? 10 : 40, alignSelf: 'center', marginVertical: -20}}>
                    <div style={{width: 300, height: 300, margin: 20}}></div>
                    <View style={{margin: 20, flexDirection: 'column', justifyContent: 'center', width: mobileView ? props.width * 0.9 : props.width * 0.5}}>
                        <MakeText fontFamily='Crimson Text' style={styles.werkplekHeader}>
                            Stap 4: Ververs het water
                        </MakeText>
                        <MakeText style={styles.werkplekText}>
                            Ververs het water om de drie dagen, maak de vaas schoon en doe er nieuwe voeding bij (zie stap 1). Snij ook weer een klein stukje van de stelen af.
                        </MakeText>
                    </View>
                </View>
                :
                <View style={{flexDirection: 'row', justifyContent: 'center', marginHorizontal: 40, alignSelf: 'center', marginVertical: -20}}>
                    <View style={{margin: 20, flexDirection: 'column', justifyContent: 'center', width: props.width * 0.5}}>
                        <MakeText fontFamily='Crimson Text' style={styles.werkplekHeader}>
                            Stap 4: Ververs het water
                        </MakeText>
                        <MakeText style={styles.werkplekText}>
                            Ververs het water om de drie dagen, maak de vaas schoon en doe er nieuwe voeding bij (zie stap 1). Snij ook weer een klein stukje van de stelen af.
                        </MakeText>
                    </View>
                    <div style={{width: 300, height: 300, margin: 20}}></div>
                </View>
            }

            <View style={{alignSelf: 'center', marginTop: 30}}>
	            <MakeText fontFamily='Crimson Text' style={styles.header1}>Tips & Tricks</MakeText>
	            <MakeText style={styles.headerText}>
                    Kijk hoe ons talent Hasan met liefde uitlegt hoe je maximaal kunt genieten van je bloemen!
	            </MakeText>
	        </View>

            <iframe style={{width: mobileView ? '100%' : '50%', alignSelf: 'center', height: mobileView ? 300 : 600}} src="https://www.youtube.com/embed/CjLx8OedrL8" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>

	        <FooterWeeklyFlowers width={props.width} height={props.height} navigation={props.navigation} noText={true}/>
	    </View>
    );
}
