import * as React from 'react';
import { View, Image } from 'react-native';

import Storage from './../../CrossPlatform/Storage/Storage';
import Button from './../../CrossPlatform/Button/Button';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addProduct } from './../../../store/actions/ProductActions';
import MakeText from '../../Common/MakeText/MakeText';

class Flowerpackage extends React.Component<any> {

	storage = new Storage;

	// Loops over props to see if a product has already been added to the shipping cart.
	// If it has been: Change the text and color of the button.
	checkIfAddedText() : string {

		let text = 'voeg toe';

		this.props.product.products.map( product => {
			if(this.props.flower.id === product.flower.id) {
				text = 'toegevoegd';
			}
		});

		return text;
	}

	// Loops over props to see if a product has already been added to the shipping cart.
	// If it has been: Change the text and color of the button.
	checkIfAddedStyle() : string {

		let style = 'whiteGreen';

		this.props.product.products.map( product => {
			if(this.props.flower.id === product.flower.id) {
				style = 'greenWhite';
			}
		});

		return style;
	}

	addFlower(flower) : void {
		// Check if the product already exists in the store.
		let exists = false;
		for(const product of this.props.product.products) {
			if(product.flower.id === flower.id) {
				exists = true;
			}
		}

		// If it does not, add it to the store and the localstorage.
		if(!exists) {
			this.storage.storeDataToList('products', {flower: Object.assign(flower, {amount: 1}), bundle: this.props.bundle});
			this.props.addProduct({flower, bundle: this.props.bundle});
		}

		// Change the display of the button, so the user get's feedback about succesfully adding the item.
		this.setState({text: 'toegevoegd', style: 'greenWhite'});
	}

	render() {
		return (
            <View style={{ margin: 13, width: 300 / 1.1 }}>

				<Image source={{uri: this.props.images[this.props.flower.size]}} style={{height:500/1.1, width: 300/1.1}}/>
				<MakeText style={{textAlign: 'center', marginTop: 15, color: '#414141', fontSize: 18}} fontFamily='Crimson Text'>
					{this.props.bundle.intname + '\r\n' + this.props.flower.size}
				</MakeText>
				<MakeText style={{textAlign: 'center', marginTop: 15, color: '#414141'}}>€ {this.props.flower.price.toString().replace('.', ',')}</MakeText>
				<Button style={{alignSelf: 'center', marginTop: 15}} textStyle={{fontSize: 14}} text={this.checkIfAddedText()} color={this.checkIfAddedStyle()} onPress={() => this.addFlower(this.props.flower)} />
			</View>
		);
	}
}

const mapStateToProps = (state: any) => {
    const { product } = state
    return { product }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        addProduct,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Flowerpackage);
