import * as React from 'react';

import BasicPage from './Template/BasicPage';
import Subscription from './../Components/Subscription/Subscription';

export default (props: any) =>  (
	<BasicPage navigation={props.navigation}>
		<Subscription />
	</BasicPage>
);
