import validator from 'validator';

/**
 * This function cleans both objects and seperate variables.
 * It does NOT clean arrays.
 * In order to clean arrays, use escapeArray(inputArray);
 */
export let escape = (cleanMe: any) => {

	if(typeof cleanMe === 'object') {
		Object.keys(cleanMe).map( key => {
			if(typeof cleanMe[key] === 'string') {
				cleanMe[key] = validator.escape(cleanMe[key]);
			}
		});
	} else {
		cleanMe = validator.escape(cleanMe);
	}

	return cleanMe;
}

export let escapeObject = (cleanMe: any) => {
	Object.keys(cleanMe).map( key => {
		if(typeof cleanMe[key] === 'string') {
			cleanMe[key] = validator.escape(cleanMe[key]);
		}
	});

	return cleanMe;
}

/**
 * Loop through an array and escape the values inside it.
 * Does NOT accept an array filled with objects.
 * In order to escape an array filled with object loop through the array in your
 * code and then use the escape() function.
 */
export let escapeArray = (cleanMe: any) => {

	cleanMe.map( item => {
		item = validator.escape(item);
	});

	return cleanMe;
}
