import config from './../../config/general.json';

interface IState {
	current: string;
	config: any;
	languages: string[];
	loggedIn: boolean;
}

const INITIAL_STATE : IState = {
	current: "en",
	config,
	languages: [
		'en',
		'nl',
	],
	loggedIn: false,
};

const AppReducer = (state = INITIAL_STATE, action: any) => {

	switch (action.type) {

		case 'SET_LANGUAGE':
			let tmpState3 = Object.assign({}, state);

			tmpState3.current = action.payload;

			return tmpState3;

		case 'SET_CONFIG':
			let tmpState = Object.assign({}, state);

			tmpState.config = action.payload;

			return tmpState;

		case 'SET_LOGIN':
			let tmpState2 = Object.assign({}, state);

				tmpState2.loggedIn = action.payload;

				return tmpState2;

    	default:
      		return state

  	}
};

export default AppReducer;
