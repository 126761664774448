import * as React from 'react';

import StoryPage from '../Components/Stories/StoryPage';
import BasicPage from './Template/BasicPage';

export default (props: any) => (
    <BasicPage navigation={props.navigation}>
		<StoryPage />
	</BasicPage>
)
