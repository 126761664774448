import * as React from 'react';

import BasicPage from './Template/BasicPage';
import StoriesComponent from './../Components/Stories/Stories';

import { Helmet } from "react-helmet";

export default class Stories extends React.Component<any> {

    render() {

        const jsonld = {
            "@context": "http://schema.org/",
            "@type": "Webpage",
            "name": "Onze Verhalen",
            "description": "Net als in elke familie vinden we het leuk om onze stories te vertellen en te delen. Daarom schrijven we in deze stories over onze unieke talenten, onze inspiratie, trends rondom de bloemenmarkt en nog veel meer.",
            "url": "https://www.theflowerfamily.nl/stories"
        };

	    return (
            <BasicPage navigation={this.props.navigation}>
                <Helmet>
                    <title> Onze Verhalen </title>
                    <link rel="canonical" href="https://www.theflowerfamily.nl/stories" />
                    <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
                </Helmet>
				<StoriesComponent />
			</BasicPage>
		);
    }
}
