import * as React from 'react';
import { View, ActivityIndicator, ScrollView } from 'react-native';

import Vacature from './VacaturePreview';
import MakeText from '../Common/MakeText/MakeText';
import TouchableOpacity from '../CrossPlatform/TouchableOpacity/TouchableOpacity';
import { getVacancies } from '../../API/Http/Requests';

export default class Vacatures extends React.Component<any>{
    state = {
        vacatures: [],
        loading: true
    }

    componentDidMount() {
        getVacancies().then((vacancies) => {
            this.setState({
                vacatures: vacancies,
                loading: false
            })
        });
    }

    renderVacature(vacature) {
        let bod = vacature.body;
        if (vacature.body.length > 30) {
            bod = [...vacature.body].splice(0, 27);
            bod = bod.join('');
            bod += '...';
        }
        return <TouchableOpacity route={`vacature/${vacature.id}/${vacature.title.replace(/\s/g, '-')}`} onPress={() => this.props.navigation.navigate('Vacature', {id: vacature.id, title: vacature.title.replace(/\s/g, '-')})}><Vacature
            function = {vacature.title}
            country = {bod}
            city = {vacature.location}
            time = {vacature.type}
            width={this.props.width}
            height={this.props.height}
        /></TouchableOpacity>;
    }

    render() {
        return <ScrollView style={{flex: 1, alignSelf: 'center', marginTop: '7%', marginBottom: '8%'}}>

            <MakeText fontFamily='Crimson Text' style={{fontSize: 36, alignSelf: 'center', letterSpacing: 2.13, color: '#414141', textAlign: 'center'}}>Onze vacatures</MakeText>
            {(!this.state.loading && this.state.vacatures.length === 0) &&
            <View style={{width: this.props.width < 600 ? this.props.width * 0.8 : this.props.width * 0.4, alignSelf: 'center'}}>
                <MakeText style={{fontSize: 14, lineHeight: 32, marginTop: 40, letterSpacing: 0.9275, color: '#414141'}}>
                    We hebben op dit moment geen openstaande vacatures. Ben jij enthousiast over onze sociale onderneming en wil jij ook samen met ons mens en natuur laten opbloeien? Laat ons weten welke rol je in onze familie zou willen spelen en stuur een mailtje naar: info@theflowerfamily.nl
                </MakeText>
            </View>}
            {this.state.loading ?
                <View>
                    <MakeText style={{fontSize: 14, lineHeight: 32, marginTop: 50, letterSpacing: 0.9275, color: '#414141', textAlign: 'center'}}>Vacatures ophalen...</MakeText>
                    <ActivityIndicator />
                </View>
            :
                <View style={{marginTop: 40}}>
                    {this.state.vacatures.map((vac) => { return this.renderVacature(vac)})}
                </View>
            }
        </ScrollView>;
    }
}
