import * as React from 'react';

import BasicPage from './Template/BasicPage';
import { Helmet } from "react-helmet";
import ProductDetails from '../Components/ProductDetails/ProductDetails';

export default class OrderFlowers extends React.Component<any> {

    render() {

        const jsonld = {
            "@context": "http://schema.org/",
            "@type": "Webpage",
            "name": "Product details",
            "description": "Sociaal, duurzaam en stijlvol - daar worden de bloemen n�g mooier van. Je kunt kiezen uit drie varianten, elk met zijn eigen charme. De designs wisselen wekelijks en spelen in op het seizoen.",
            "url": "https://www.theflowerfamily.nl/bestel"
        };

		return (
            <BasicPage navigation={this.props.navigation}>
                <Helmet>
                    <title> Product details </title>
                    {this.props.navigation.getParam('intname') ?
                        <link rel="canonical" href={`https://www.theflowerfamily.nl/product/${this.props.navigation.getParam('pId')}/${this.props.navigation.getParam('intname')}/${this.props.navigation.getParam('size')}`} />
                        :
                        <link rel="canonical" href={`https://www.theflowerfamily.nl/product`} />
                    }
                    <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
                </Helmet>
				<ProductDetails />
			</BasicPage>
		);
	}
}
