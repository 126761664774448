import * as React from 'react';
import { View, Image, StyleSheet, TouchableOpacity} from 'react-native';
import MakeText from '../Common/MakeText/MakeText';

export default class BackToTop extends React.Component<any, any>{
    constructor(props) {
        super(props);

        this.state = {
            intervalId: 0
        }
    }
    componentDidMount() {
        this.setState({
            intervalId: 0
        });
    }

    scrollStep() {
        const stepPx = 100;
        if (window.pageYOffset === 0) {
            clearInterval(this.state.intervalId);
        }
        window.scroll(0, window.pageYOffset - stepPx);
      }

      scrollToTop() {
        let delayInMs = 0;
        let intervalId = setInterval(this.scrollStep.bind(this), delayInMs);
        this.setState({ intervalId: intervalId });
      }

    render () {
        const styles = StyleSheet.create({
            logo: {
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
            },
        });

        return <View style={styles.logo}>
            <TouchableOpacity onPress={() => this.scrollToTop()} style={{flexDirection: 'row'}}>
	            <Image
	                style={{width: 13, height: 22, alignSelf: 'center'}}
	                source={require('./../../images/arrow_up.svg')}
	            />
				<MakeText style={{color: '#414141', marginLeft: 7}}>Back up</MakeText>
            </TouchableOpacity>
        </View >
    }
}
