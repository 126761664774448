import * as React from 'react';
import { ScrollView, View, Dimensions } from 'react-native';

import globalStyles from './../../../Styles/styles';
import FlowerPackage from './../FlowerPackage/FlowerPackage';
import MakeText from '../../Common/MakeText/MakeText';
import { phone, PHONE_TEXT_WIDTH_LARGE } from '../../../Util/screen/HandleLayout';

import { Helmet } from "react-helmet";
import moment from 'moment';

export default class OrderBundle extends React.Component<any> {

	render() {

	    const bundle = this.props.bundle;
	    const dimensions = {
	        width: Dimensions.get('window').width,
	        height: Dimensions.get('window').height
	    };

		const mobileView = phone(dimensions.width);

	    const images = {
	        'small': require('./../../../images/flower_small.jpg'),
	        'medium': require('./../../../images/flower_medium.jpg'),
	        'large': require('./../../../images/flower_large.jpg'),
	        'x-large': require('./../../../images/flower_x-large.jpg')
	    }

	    for (const pkg of bundle.packages) {
	        images[pkg.size] = `https://theff.nl/services/media/1a6eaa470c7d3699d530fa950aa6eb659eaa74c4260ca76f2def4842289ebc7d/${pkg.image}`;
	    }

        const flowerPackage = (flower, index) => {
            const jsonld = {
                "@context": "http://schema.org/",
                "@type": "Product",
                "name": bundle.intname + ' ' + flower.size,
                // "description": "Sociaal, duurzaam en stijlvol - daar worden de bloemen n�g mooier van. Je kunt kiezen uit drie varianten, elk met zijn eigen charme. De designs wisselen wekelijks en spelen in op het seizoen.",
                "url": "https://www.theflowerfamily.nl/bestel",
                "image": images[flower.size],
                // "description": "This is a really amazing product. In fact, we think you will be amazed.",
                "offers": {
                    "@type": "Offer",
                    "priceCurrency": "EUR",
                    "price": flower.price.toString(),
                    "availability": "http://schema.org/InStock",
                    "priceValidUntil": moment().endOf('week').format(),
                    "url": "https://www.theflowerfamily.nl/bestel",

                },
                "brand": "The Flower Family",
                "description": `${bundle.category} ${bundle.intname} ${flower.size}`,
            };

	        return (
                <View key={index}>
                    <Helmet>
                        <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
                    </Helmet>
					<FlowerPackage flower={flower} images={images} bundle={bundle} />
				</View>
			);
	    }

        let margin = (dimensions.width - 305) / 2;
     
	    return (
            <View>
	            <MakeText style={{textAlign: 'center', fontSize: 36, color: '#414141', marginTop: 49, letterSpacing: 2.2}} fontFamily="Crimson Text">
	                {this.props.category || bundle.category}
	            </MakeText>

				<MakeText style={{textAlign: 'center', fontSize: 14, color: '#414141', marginTop: 15, letterSpacing: 0.9275, width: mobileView ? dimensions.width * PHONE_TEXT_WIDTH_LARGE : undefined, alignSelf: mobileView ? 'center' : undefined}}>
					{this.props.text}
				</MakeText>

	            <ScrollView style={[globalStyles.row, {alignSelf: 'center', maxWidth: dimensions.width, marginTop: 30}]} horizontal={true} contentContainerStyle={mobileView ? {marginLeft: margin} : undefined}>
	                {bundle.packages.map((flower, index) => {return flowerPackage(flower, index)})}
	            </ScrollView>
	        </View>


	    );
	}
}
