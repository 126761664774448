import * as React from 'react';
import { ScrollView, Platform, View } from 'react-native';

import { watchResize, calculateMargin } from './../../Util/screen/HandleLayout';

import Footer from './../../Components/Common/Footer/Footer';
import Logo from './../../Components/Common/Logo';
import BackToTop from './../../Components/HomeNew/BackToTop';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';
import FacebookAnalytics from '../../Util/analytics/FacebookAnalytics.web';

export default class BasicPage extends React.Component<any> {

    state = {
        width: 0,
        height: 0
    }

    componentWillMount() {
        FacebookAnalytics.getInstance().pageView(this.props.navigation); // Call pixel event PageView on each BasicPagecomponent with this.props.navigation as an argument.
        GoogleAnalytics.getInstance().hit(this.props.navigation.state);
        watchResize(result => {
            this.setState(result);
        }, this.state.width);
    }

    render() {
        const margin = calculateMargin(this.state.width);
        const children = React.Children.map(this.props.children, (child, index) => {
            return React.cloneElement((child as JSX.Element), {
                width: this.state.width,
                height: this.state.height,
                navigation: this.props.navigation,
                key: `basic-page-child-${index}`
            });
        });

        return (
            <ScrollView style={{ width: this.state.width }}>

                {children}

                <View style={{ marginTop: margin }}>
                    <Footer navigation={this.props.navigation} width={this.state.width} height={this.state.height} />
                </View>

                {/* At the very bottom of the page, the app displays a logo, and the website a Back Up button. */}
                {Platform.OS !== 'web' ?
                    <View style={{ paddingVertical: margin }}>
                        <Logo />
                    </View>
                    :
                    <View style={{ marginVertical: margin, alignItems: 'center' }}>
                        <BackToTop />
                    </View>
                }

            </ScrollView>
        );
    }
}
