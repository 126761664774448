import * as React from 'react';
import { connect } from 'react-redux';
import Storage from '../CrossPlatform/Storage/Storage';
import { bindActionCreators } from 'redux';
import { getProducts } from '../../API/Http/Requests';
import { addProduct } from '../../store/actions/ProductActions';
import MakeText from '../Common/MakeText/MakeText';
import SelectableSizeProductCard from '../Common/ProductCards/SelectableSizeProductCard';
import { Bundle } from '../../API/Interfaces/Bundle';
import SingleProductCard from '../Common/ProductCards/SingleProductCard';
import { phone } from '../../Util/screen/HandleLayout';
import { EURO } from '../../Util/currency';
import configDefault from '../../config/config.selector';
import { sortPackages } from '../../Util/sizeOrdering';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';

const conf = configDefault as any;

class Assortiment extends React.Component<any> {
    state = {
        bundles: [] as Bundle[],
    };

    storage = new Storage;

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        const bundles = await getProducts().catch(e => {
			console.log(e);
        });
        if (bundles === undefined) {
            return;
        }
        for (const bundle of bundles) {
            sortPackages(bundle);
        }
        this.setState({ bundles });
    }

    addFlower(flower, bundle) : void {
		// Check if the product already exists in the store.
		let exists = false;
		for(const product of this.props.product.products) {
			if(product.flower.id === flower.id) {
				exists = true;
			}
		}

		// If it does not, add it to the store and the localstorage.
		if(!exists) {
            this.storage.storeDataToList('products', {flower: Object.assign(flower, {amount: 1}), bundle});
			this.props.addProduct({flower, bundle});
		}
    }

    renderFlowers(mobileView: boolean): {consumersitePackages: any[], gemAPackages: any[], notGemAPackages: any[], plantPackages: any[]} {
        const plantPackages: any[] = [];
        const consumersitePackages: any[] = [];
        const notGemAPackages: any[] = [];
        const gemAPackages: any[] = []; // Gem. A. packages
        const bundles = [...this.state.bundles];
        bundles.forEach(bundle => {
            // for old TFF bouquet categories (FB, SF, RR)
            if (routingName === 'amsterdam') {
                if (bundle.category.substring(0, 7) !== 'E-Flora' && bundle.isFlowers) {
                    if (bundle.id != 374) { // 388: old product FB for consumersite excluded for gem. A.
                        gemAPackages.push(<SelectableSizeProductCard key={'flower' + bundle.id} bundle={bundle} navigation={this.props.navigation} mobileView={mobileView}/>);
                    }
                    // consumersitePackages.push(<SelectableSizeProductCard key={'flower' + bundle.id} bundle={bundle} navigation={this.props.navigation} mobileView={mobileView}/>);
                }
            }
            // For new products (E-Flora categories), but not plants
            if (bundle.category.substring(0, 7) === 'E-Flora' && bundle.category.substring(0, 13) !== 'E-Flora-Plant') {
                if (bundles.filter(b => bundle.intname === b.intname) && bundles.filter(b => bundle.intname === b.intname).length > 1) {
                    // Replace a bundle (when 1 bundle's intname matches the intname of another bundle) for the right price category
                    if (bundle.packages[0] && 
                        bundle.packages[0].packageIntname
                    ) { // overwrite the previous (first) bundle, to add the new package prices (when the first package in the bundle, has a packageIntname with a 4th element)
                        if (bundle.packages[0].packageIntname.split('|')[3] &&
                            bundle.packages[0].packageIntname.split('|')[3].length > 1) {
                            if (Number(bundle.packages[0].packageIntname.split('|')[3].trim().slice(-1)) > 1) {
                                // portals (incl. consumersite) and not Gem. A. portal
                                if (bundle.category.substring(0, 9) === 'E-Flora-B') {
                                    notGemAPackages.push(<SelectableSizeProductCard key={'flower' + bundle.id} bundle={bundle} navigation={this.props.navigation} mobileView={mobileView}/>);
                                    consumersitePackages.push(<SelectableSizeProductCard key={'flower' + bundle.id} bundle={bundle} navigation={this.props.navigation} mobileView={mobileView}/>);
                                }
                                // Gem. A. portal
                                if (bundle.category.substring(0, 9) === 'E-Flora-A') {
                                    if (bundle.id != 435 && bundle.id != 452) { // exclude product Feest products (id's 435, 452)
                                        gemAPackages.push(<SelectableSizeProductCard key={'flower' + bundle.id} bundle={bundle} navigation={this.props.navigation} mobileView={mobileView}/>);
                                    }
                                }
                            }
                        }
                    }
                } else { // For all bundles that do not have a replacing bundle to change the pricecategory
                    // not Gem. A. portal and not consumersite
                    notGemAPackages.push(<SelectableSizeProductCard key={'flower' + bundle.id} bundle={bundle} navigation={this.props.navigation} mobileView={mobileView}/>);
                    // Gem. A. portal
                    gemAPackages.push(<SelectableSizeProductCard key={'flower' + bundle.id} bundle={bundle} navigation={this.props.navigation} mobileView={mobileView}/>);
                    // consumersite
                    consumersitePackages.push(<SelectableSizeProductCard key={'flower' + bundle.id} bundle={bundle} navigation={this.props.navigation} mobileView={mobileView}/>);
                }
            }
            // For plants
            if (bundle.category.substring(0, 13) === 'E-Flora-Plant') { // for category Plant
                bundle.packages.sort((p1, p2) => p1.price - p2.price);
                bundle.packages.forEach(p => plantPackages.push(<SingleProductCard key={bundle.id + ' ' + p.id} bundle={bundle} flower={p} navigation={this.props.navigation} mobileView={mobileView} />));
                bundle.packages.forEach(p => gemAPackages.push(<SingleProductCard key={bundle.id + ' ' + p.id} bundle={bundle} flower={p} navigation={this.props.navigation} mobileView={mobileView} />));
            }
        });
        // console.log('plantPackages', plantPackages)
        // console.log('notGemAPackages', notGemAPackages)
        // console.log('gemAPackages', gemAPackages)
        // console.log('consumersitePackages', consumersitePackages)
        return {plantPackages, notGemAPackages, gemAPackages, consumersitePackages};
    }

    render() {
        const mobileView = phone(this.props.width);
        const bannerHeightFigma = 400;
        const screenWidthFigma = 1600;
        let bannerHeight = bannerHeightFigma * this.props.width / screenWidthFigma;
        // Double height if mobileview because it looks better
        bannerHeight = bannerHeight * (mobileView ? 2 : 1);
        let packages = this.renderFlowers(mobileView);
        if (routingName === 'amsterdam') { // change order of products in webshop for gem. A.
            packages.gemAPackages.map((p, i) => { console.log (p) // Gem. A. packages
                let p1: any = '';
                let p2: any = '';
                if (i === 0) {
                    p1 = packages.gemAPackages.shift();
                }
                if (i === 1) {
                    p2 = packages.gemAPackages.shift();
                }
                if (typeof p2 === 'object' && p2.key === 'flower464') { // in the webshop show this old product of category 'Royal Roses' in front of the roses of category 'E-Flora'
                    packages.gemAPackages.splice(5, 0, p2);
                }
                if (typeof p1 === 'object' && p1.key === 'flower465') { // in the webshop show this old product of category 'Seasonal Flowers' after the roses of category 'E-Flora'
                    packages.gemAPackages.splice(8, 0, p1);
                }
            })
        }
        
        // const rows: any[] = [];
        // const productsPerRow = mobileView ? 1 : 3;
        // for (let i = 0; i < packages.length; i += productsPerRow) {
        //     rows.push(packages.slice(i, i + productsPerRow));
        // }
        // while (!mobileView && rows.length && rows[rows.length - 1].length < 3) {
        //     rows[rows.length - 1].push(<div style={{width: 279}} />);
        // }

        const style = {
            imageStyle: {
                width: '100%',
                height: bannerHeight,
                objectFit: 'cover'
            } as React.CSSProperties,
            titleStyle: {
                position: 'absolute',
                top: 0,
                left: '5%',
                width: '60%',
                height: bannerHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            } as React.CSSProperties,
        }

        return (
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{width: '100%', position: 'relative'}}>
                    <img src={'/images/Foto webshop bovenaan.jpg'} style={style.imageStyle} />
                    <div style={style.titleStyle} id='titleContainer'>
                        <MakeText fontFamily='Arno Pro' style={{fontSize: mobileView ? 24 : 48, color: 'rgb(65, 65, 65)'}}>Je bent nu in de webshop...</MakeText>
                    </div>
                </div>

                { routingName !== 'amsterdam' && // text about: prices are excl. transportcosts (only Gem. A. has them included in the price)
                    <div style={{width: '80%', marginLeft: '10%', marginRight: '10%', marginTop: 20, marginBottom: 20, flexWrap: 'wrap'}}>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>*De prijzen zijn exclusief bezorgkosten {EURO(conf.deliveryCost)} ... of voor brievenbuspakketten/-bloemen {EURO(conf.deliveryCostTulips)}.</MakeText>
                    </div>
                }

                { routingName !== 'amsterdam' && routingName !== 'consumersite' && // bouquets for all portals except Gem. A. and consumer website
                    <div key={'notGemARow'} style={{display: 'flex', flexDirection: mobileView ? 'row' : 'row', justifyContent: 'space-around', width: '80%', marginLeft: '10%', marginRight: '10%', marginTop: 40, marginBottom: 40, flexWrap: 'wrap'}}>
                        {packages.notGemAPackages.map(r => {
                            return r;
                        })}
                    </div>
                }
                { routingName === 'amsterdam' && // bouquets Gem. A.
                    <div key={'gemARow'} style={{display: 'flex', flexDirection: mobileView ? 'row' : 'row', justifyContent: 'space-around', width: '80%', marginLeft: '10%', marginRight: '10%', marginTop: 40, marginBottom: 40, flexWrap: 'wrap'}}>
                        {packages.gemAPackages.map(r => {
                            return r;
                        })}
                    </div>
                }                
                { routingName === 'consumersite' && // bouquets consumer website
                    <div key={'consumerRow'} style={{display: 'flex', flexDirection: mobileView ? 'row' : 'row', justifyContent: 'space-around', width: '80%', marginLeft: '10%', marginRight: '10%', marginTop: 40, marginBottom: 40, flexWrap: 'wrap'}}>
                        {packages.consumersitePackages.map(r => {
                            return r;
                        })}
                    </div>
                }

                { routingName !== 'amsterdam' && // plants
                    <div key={'plantRow'} style={{display: 'flex', flexDirection: mobileView ? 'row' : 'row', justifyContent: 'space-around', width: '80%', marginLeft: '10%', marginRight: '10%', marginTop: 40, marginBottom: 40, flexWrap: 'wrap'}}>
                        {packages.plantPackages.map(r => {
                            return r;
                        })}
                    </div>
                }

                { routingName !== 'consumersite' && // texts about: funeral products (extra services offered to business partners)
                    <div style={{width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: '3em', display: 'flex', flexDirection: 'column'}}>
                        <MakeText style={{alignSelf: 'flex-start', color: '#414141', fontSize: 36, letterSpacing: 2.13}}>
                            Rouwstuk bestellen?
                        </MakeText>
                        <br/>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                            Een rouw- of sympathieboeket kan worden besteld vanuit de boeketten die hierboven worden aangeboden in de webshop. Vaak gekozen: 'Tederheid' en 'Stijlvol'.
                        </MakeText>
                        <br/>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                            Wanneer je een passend rouwstuk wilt bestellen, neem dan even telefonisch of per mail contact met ons op om de wensen te bespreken. De rouwstukken dienen minimaal twee dagen van te voren worden besteld omdat hier extra aandacht voor nodig is.
                            De prijs van de rouwstukken gaat op afspraak, maar ze zijn vanaf €50,- exclusief €17.50 voor het rouwlint. De kleur van dit lint is wit/beige.
                        </MakeText>
                        <br/>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                            Telnr: +31 20 245 5887
                        </MakeText>
                        <br/>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                            E-mail: info@theflowerfamily.nl
                            De prijs van de rouwstukken gaat op afspraak, maar ze zijn vanaf €50,- exclusief €17.50 voor het rouwlint. De kleur van dit lint is wit/beige. Contact: +31 20 245 5887 / info@theflowerfamily.nl
                        </MakeText>
                        <div key={'funeralRow'} style={{display: 'flex', flexDirection: mobileView ? 'row' : 'row', justifyContent: 'space-around', width: '80%', marginLeft: '10%', marginRight: '10%', marginTop: 40, marginBottom: 40, flexWrap: 'wrap'}}>
                            <img src='/images/rouwboeket_1.jpg' style={{height: 461, width: 279, objectFit: 'cover', margin: 15}} />
                            <img src='/images/rouwboeket_2.jpg' style={{height: 461, width: 279, objectFit: 'cover', margin: 15}} />
                        </div>
                    </div>
                }

                { routingName !== 'consumersite' && // texts about: memberships (extra services offered to business partners)
                    <div style={{width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: '3em', display: 'flex', flexDirection: 'column'}}>
                        <MakeText style={{alignSelf: 'flex-start', color: '#414141', fontSize: 36, letterSpacing: 2.13}}>
                            Wil je een abonnement afsluiten?
                        </MakeText>
                        <br/>
                        { routingName === 'amsterdam' &&
                            <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                                Voor reguliere boeketten (Fam.B./Seiz.B./R.White Roses), de prijs per grootte: small €25, medium €30, large €35, x-large €40
                            </MakeText>
                        }
                        <br/>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                            Neem dan even telefonisch of per mail contact op.
                        </MakeText>
                        <br/>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                            Telnr: +31 20 245 5887
                        </MakeText>
                        <br/>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                            E-mail: info@theflowerfamily.nl
                        </MakeText>
                    </div>
                }

                { routingName !== 'consumersite' &&  // texts about: events (extra services offered to business partners)
                    <div style={{width: '80%', marginLeft: '10%', marginRight: '10%', marginBottom: '3em', display: 'flex', flexDirection: 'column'}}>
                        <MakeText style={{alignSelf: 'flex-start', color: '#414141', fontSize: 36, letterSpacing: 2.13}}>
                            Wil je een locatie van een evenement opfleuren met mooie bloemen?
                        </MakeText>
                        <br/>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                            Dat is ook zeker mogelijk bij The Flower Family.
                        </MakeText>
                        <br/>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                            Neem dan even telefonisch of per mail contact op.
                        </MakeText>
                        <br/>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                            Telnr: +31 20 245 5887
                        </MakeText>
                        <br/>
                        <MakeText style={{fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start'}}>
                            E-mail: info@theflowerfamily.nl
                        </MakeText>
                    </div>
                }
            </div>
        );
    }
};

const mapStateToProps = (state: any) => {
    const { product } = state
    return { product }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        addProduct,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Assortiment);