import * as React from 'react';
import TopBannerText from '../../../text/home/TopBanner/TopBannerText';
import { phone } from '../../../Util/screen/HandleLayout';
import MakeText from '../../Common/MakeText/MakeText';
import Button from '../../CrossPlatform/Button/Button';

export default (props: any) => {
    // Scale height relative to the screen height 900 that the figma has
    const bannerHeightFigma = 535;
    const screenWidthFigma = 1600
    const mobileView = phone(props.width);
    const h = mobileView ? 200 : bannerHeightFigma * props.width/screenWidthFigma;

    const styles = {
        container: {
            position: 'relative'
        } as React.CSSProperties,
        image: {
            height: h,
            width: '100%',
            objectFit: 'cover'
        } as React.CSSProperties,
        gradient: {
            position: 'absolute',
            top: 0,
            height: h,
            width: '70%',
            backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0.5), rgba(255,255,255,0))',
        } as React.CSSProperties,
        textContainer: {
            position: 'absolute',
            top: 0,
            height: h,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            width: '40%',
            marginLeft: '10%'
        } as React.CSSProperties,
        mobileTextContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 25
        } as React.CSSProperties,
    };
    const text = TopBannerText;
    const headerSize = mobileView ? 24 : 36;

    return (
        <div style={styles.container}>
            <img src={'/images/Foto banner 0 - HP.jpg'} style={styles.image} />
            <div style={styles.gradient} />
            {mobileView &&
                <div className='col' style={{position: 'absolute', height: h, width: '50%', top: 0, alignItems: 'center', justifyContent: 'center'}}>
                    <MakeText fontFamily='Arno Pro' style={{fontSize: headerSize, letterSpacing: 2.2}}>{text.title}</MakeText>
                </div>
            }
            <div style={mobileView ? styles.mobileTextContainer : styles.textContainer}>
                {!mobileView && <div className='mediumText' style={{letterSpacing: 2.2, fontSize: mobileView ? 15 : 24}}>{text.title}</div>}
                <div className='mediumText mt-2' style={{fontSize: mobileView ? 15 : 24}}>{text.text1}</div>
                <div className='mediumText mt-2' style={{fontWeight: 'bold', fontSize: mobileView ? 15 : 24}}>{text.text2Bold}</div>
                <div className='mediumText mt-2' style={{fontSize: mobileView ? 15 : 24}}>{text.text3}</div>
                <Button
                    color={'greenWhite'}
                    style={{marginTop: 25}}
                    text={text.button}
                    textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: mobileView ? 15 : 16, letterSpacing: 2}}
                    onPress={() => props.navigation.navigate('webshop')}
                    route='/bestel'
                />
            </div>
        </div>
    );
}