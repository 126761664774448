import * as React from 'react';
import HamburgerMenu from './HamburgerMenu/HamburgerMenu';
import Navbar from './Navbar/Navbar';
import { View } from 'react-native';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setProducts } from './../../../store/actions/ProductActions';

import { watchResize, phone } from './../../../Util/screen/HandleLayout';
import Storage from './../Storage/Storage';
import { getProducts } from '../../../API/Http/Requests';
import moment from 'moment';

class NavigationMenu extends React.Component<any> {

	storage = new Storage;

    state = {
        width: 0,
        height: 0
    }

    componentDidMount() {

		// Check localstorage to see if items were added to the shoppingcart.
		// If they were, add them to the store.
		this.storage.retrieveData("products").then( result => {

			if(result) {
				// This is a list of all active packages (the ctategories of products currently beeing sold).
				let activePackage: any[] = [];
				// These are products in the shoppingcart that are still for sale.
				let validProducts: any[]= [];

				// Loop over the newly fetched products and make sure the products in the shopping cart are still valid and beeing sold!!.
				getProducts().then( fetchedResult => {
					fetchedResult.map( active => {
						if (active.published) {
							activePackage.push(active.id);
						}
					});

					result.map( stored => {
						if(stored.bundle && activePackage.includes(stored.bundle.id) && stored.flower.delivery && moment(stored.flower.delivery.orderBefore).isAfter(moment())) {
							validProducts.push(stored);
						}
					});

					// After determining which products are valid. Push those products to the store, then clear the localstorage and fill it with the valid items.
					if(validProducts) {
						this.props.setProducts(validProducts);
						this.storage.removeData("products");
						this.storage.storeData("products", validProducts);
					}
				}).catch( e => console.log(e));
			}
		});

		watchResize( result => {
			this.setState(result);
		}, this.state.width);
    }

    render(){
        return (
			<View>
	            { phone(this.state.width, this.state.height) ?
					<HamburgerMenu {...this.props} width={this.state.width} height={this.state.height} />
	                :
	                <Navbar {...this.props} width={this.state.width} height={this.state.height} />
	            }
        	</View>
		);
    }
}

const mapStateToProps = (state: any) => {
    const { product, app } = state
    return { product, app }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        setProducts,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);


// console.log(fetchedResult);
// 					console.log('----------------------------------------------------------------')
// 					console.log(result);
// 					fetchedResult.active.map( active => {
// 						active.packages.map( activePackage => {
// 							activeProducts.push(activePackage);
// 						});
// 					});

// 					result.map( r => {
// 						storedProducts.push(r.flower);
// 					});

// 					// console.log(activeProducts);
// 					// console.log('----------------------------------------------------------------')
// 					// console.log(storedProducts);

// 					storedProducts.map( activeProduct => {
// 						// let contains: boolean = false;
// 						storedProducts.map( storedProduct => {
// 							if(activeProduct.id === storedProduct.id) {
// 								// if(activeProduct.name)
// 								// contains = true;
// 							}
// 						});
// 					})
