import * as React from 'react';

import BasicPage from './Template/BasicPage';
import FamilyMember from './../Components/FamilyMember/FamilyMember';


export default class FamilyMemberContainer extends React.Component<any> {

    render() {

        return (
			<BasicPage navigation={this.props.navigation}>
				<FamilyMember />
			</BasicPage>
        );
    }
}
