import ITranslations from './ITranslations';
import EN from './Languages/en';
import NL from './Languages/nl';

export default class LOC {

    public language : any = {
        nl: NL,
        en: EN,
    }

    public static current: ITranslations = NL;

    getLanguage() {
        return LOC.current;
    }

    setLanguage(chosenLanguage: string) : ITranslations {
        return LOC.current = this.language[chosenLanguage];
    }
};
