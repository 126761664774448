import * as React from 'react';

import BasicPage from './Template/BasicPage';
import BedanktVoorBestelling from '../Components/BedanktVoorBestelling';
import { Helmet } from "react-helmet";

export default (props: any) => {

    return (
        <BasicPage navigation={props.navigation}>
            <Helmet>
                <meta name="robots" content="noindex" />
                <title> The Flower Family - Bedankt </title>
                <link rel="canonical" href="https://www.theflowerfamily.nl/bedankt" />
            </Helmet>
            <BedanktVoorBestelling app={props.app} />
        </BasicPage>
    );
}
