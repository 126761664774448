import * as React from 'react';
import { View, Image, ActivityIndicator, ScrollView } from 'react-native';
import {getOrdersForUser, cancelOrder} from "../../API/Http/Requests";
import { watchResize } from '../../Util/screen/HandleLayout';
import MakeText from '../Common/MakeText/MakeText';
import Dash from '../CrossPlatform/Dash/Dash';

import globalStyles from '../../Styles/styles';
import subStyles from './../Subscription/styles';
import accountStyles from './../Account/AccountStyles';

import Button from '../CrossPlatform/Button/Button';
import statusToMessage from '../../Util/statusToMessage';
import moment from 'moment';

export default class SubscriptionDetails extends React.Component<any> {

	state = {
		order: undefined as any,
		width: 0,
		height: 0,

		// Message displayed when attempting to change the status of a subscription.
		cancelMessage: '',
		// The color of the statusSwitchMessage. Green for succes, red for an error.
		cancelMessageColor: 'green',
	}
	componentDidMount() {
		watchResize( result => {
			this.setState(result);
		}, this.state.width);

		let id = this.props.navigation.getParam('id');

		getOrdersForUser(id).then( order => {
			// Get the id from  the url.
			// Filter the list of subs and get the selected one based on the id.
			// let order = orders.filter(order => order.id ==  id)[0];
			this.setState({order});
		});
	}

	async cancelOrder(id: number) : Promise<void> {
		try {
			cancelOrder(id).then(() => {
				// await DataService.cancelOrder(id);
				this.setState({ cancelMessage: 'Uw bestelling is geannuleerd', cancelMessageColor: 'green' })
			});
		}
		catch(e) {
			this.setState({cancelMessage: 'Er is iets misgegaan, uw bestelling is nog actief.', cancelMessageColor: 'red'})
			console.log(e);
		}
	}

	getDeliveryTime(): string {
		if (!this.state.order.delivery.windowstart) {
			return '09:00 en 18:00';
		}

		return(
			moment(this.state.order.delivery.windowstart).format('H:ss')
			+ ' en ' +
			moment(this.state.order.delivery.windowend).format('H:ss')
		);
	}

	render() {
		let styles = subStyles(this.state.width);

		if(!this.state.order) {
			return (
                    <View style={styles.main}>

						<View>
							<MakeText fontFamily="Crimson Text" style={[globalStyles.h2, globalStyles.alignSelfCenter]}>Order Details</MakeText>
						</View>

						<View style={styles.dash}>
							<Dash />
						</View>

                        <View style={{ marginVertical: 26 }}>
                            <ActivityIndicator size="large" color="#B9DCBF" />
                        </View>

                    </View>
			);
		}

		const order = this.state.order;
		console.log(order);

		return (
            <ScrollView contentContainerStyle={styles.main}>

				{/* The title and dashed line */}
                <View>
                    <MakeText fontFamily="Crimson Text" style={[globalStyles.h2, globalStyles.alignSelfCenter]}>Order Details</MakeText>
                </View>

				<View style={styles.dash}>
					<Dash />
				</View>

				{/* The page content is displayed in here. */}
				<View style={styles.content}>

					{/* The left side with the image, only displayed on a wide screen. */}
					<View style={styles.left}>
						<Image style={styles.image} source={{uri: `https://theff.nl/services/media/1a6eaa470c7d3699d530fa950aa6eb659eaa74c4260ca76f2def4842289ebc7d/${this.state.order.package.image}`}} />
					</View>

					{/* The right side of the screen. On a small screen: the entire screen. */}
					<View style={styles.right}>
						<MakeText testID='order-details-title' style={[accountStyles.defaultAccountFont, {fontWeight: 'bold', marginTop: -10, textTransform: 'capitalize'}]} fontFamily="Open Sans">{order.amount}x {order.package.category}{order.category.toLowerCase().indexOf('plant') === -1 ? ' - ' + order.package.size : ''}{(order.vase === 'large' || order.vase === 'medium')  ? ` met vaas` : ` zonder vaas`}</MakeText>

						<View>
							<MakeText style={accountStyles.defaultAccountFont} fontFamily="Open Sans Italic">Bezorging op: {moment(order.delivery.windowStart).format('dddd DD MMMM')} tussen {this.getDeliveryTime()}</MakeText>
						</View>

						<MakeText style={accountStyles.defaultAccountFont} fontFamily="Open Sans Italic">Adres: {order.delivery.location.street} {order.delivery.location.housenumber}{order.delivery.location.addition !== '' && `-${order.delivery.location.addition}`}, {order.delivery.location.postalcode.toUpperCase()}, {order.delivery.location.city}</MakeText>

						<View style={globalStyles.row}>
							<MakeText style={accountStyles.defaultAccountFont} fontFamily="Open Sans Italic">Status: </MakeText>
							{ order.status !== 'delivery' ?
								<MakeText style={[accountStyles.defaultAccountFont, accountStyles.Persoonsgegevens]}> {statusToMessage(order.status)}</MakeText> :
								<MakeText style={[accountStyles.defaultAccountFont, accountStyles.Persoonsgegevens]}> {statusToMessage(order.delivery.status)}</MakeText>
							}
						</View>

						{ order.payment ?
							<View>
								<MakeText style={accountStyles.defaultAccountFont} fontFamily="Open Sans Italic">Betaling: {order.payment.payed ? 'Voldaan' : 'Openstaand'}</MakeText>
								<MakeText style={accountStyles.defaultAccountFont} fontFamily="Open Sans Italic">Betaald met: {order.payment.paytype}</MakeText>
							</View> : undefined
						}

						<MakeText style={accountStyles.defaultAccountFont} fontFamily="Open Sans Italic">Kaartje: {order.package.note && order.package.note.length > 1 ? `${order.package.note.substring(0, 149)}${order.package.note.length > 150 ? '... (etc.)' : ''}` : '-'}</MakeText>

						<MakeText style={accountStyles.defaultAccountFont} fontFamily="Open Sans Italic">Bezorgnotitie: {order.reference}</MakeText>

						<View style={[styles.dash, styles.rightDash]}>
							<Dash />
						</View>

					</View>

				</View>

				<View style={globalStyles.row}>

					<View style={{flex: 2}}>
						<Button testID='orderBack' text="Terug" color="greenWhite" route='/account' onPress={() => this.props.navigation.navigate('Account')}
						style={{marginTop: 30, marginBottom: 33}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} />
					</View>

					{/* {this.state.order.status !== 'cancelled' &&
						<View style={[globalStyles.alignSelfCenter, styles.buttonViewMarginOrderDetail, {flex: 2.6}]}>
							<Button testID='orderCancel' text="Annuleren" color="blackOpacity" onPress={() => this.cancelOrder(order.id)}
							style={{marginTop: 30, marginBottom: 33}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} />
						</View>
					} */}

				</View>

				<View style={globalStyles.alignSelfCenter}>
					{this.state.cancelMessage.length ?
						<MakeText style={[styles.statusSwitchMessage, {backgroundColor: this.state.cancelMessageColor}]}>{this.state.cancelMessage}</MakeText>
						:
						undefined
					}
				</View>

			</ScrollView>
		)
	}
}
