import * as React from 'react';

import BasicPage from './Template/BasicPage';
import OrderDetails from './../Components/OrderDetails/OrderDetails';

export default (props: any) =>  (
	<BasicPage navigation={props.navigation}>
		<OrderDetails />
	</BasicPage>
);
