import * as React from 'react';
import { View, StyleSheet, TextInput, ActivityIndicator } from 'react-native';
import {resetPasswordWithCode} from "../../API/Http/Requests";
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';
import globalStyles from '../../Styles/styles';

interface IEnterCode {
    email: string;
    code: string;
    navigation: any;
}

export default class EnterNewPassword extends React.Component<IEnterCode> {

    state = {
        password: '',
        repeatPassword: '',

        loading: false,
        errorMessage: '',
    }

    // Checks password strength
	checkPasswordStrength(password: string): boolean {
		return !(/^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$/).test(password);
	}

    // Clean the input and send a reste password request to the server.
    async setNewPassword(password: string, repeatPassword: string) {

        if(!this.props.code || !this.props.email) {
            this.setState({errorMessage: 'Er is een fout opgetreden, ga terug naar het begin van het wachtwoord herstel proces.'});
            return;
        }

        if (this.checkPasswordStrength(password)) {
            this.setState({errorMessage: 'Het wachtwoord moet minimaal bestaan uit 8 karakters, met minimaal één hoofdletter en één cijfer.'});
            return
        }

        if (password !== repeatPassword) {
            this.setState({errorMessage: 'De ingevulde wachtwoorden komen niet overeen'});
            return
        }

        this.setState({loading: true, errorMessage: ''});

        const result = await resetPasswordWithCode(this.props.email, this.props.code, password);

        if (result) {
            this.props.navigation.navigate('Account');
        }

        this.setState({loading: false, errorMessage: 'Er is iets misgegaan, probeer het later nog een keer.'});
    }

    render() {
        const styles = StyleSheet.create({
            text: {
                color: '#414141',
                alignSelf: 'center',
            },
            textInput: {
                flex: 1,
                alignSelf: 'center',
                borderColor: '#414141',
                borderWidth: 1,
                padding: 10,
                fontSize: 18,
                color: '#414141',
                marginTop: 15,
                fontWeight: 'bold',
                textAlign: 'center',
            },
            marginTop15: {
                marginTop: 15,
            }
        });

        return (

            <View>

                <MakeText style={[globalStyles.h2, globalStyles.alignSelfCenter]} fontFamily="Crimson Text">Nieuw wachtwoord</MakeText>
                <MakeText style={[globalStyles.alignSelfCenter]}>
                    Vul uw nieuwe wachtwoord in.
                </MakeText>

                <TextInput style={styles.textInput} value={this.state.password} onChangeText={(password) => this.setState({password})} secureTextEntry={true} />

                <TextInput style={styles.textInput} value={this.state.repeatPassword} onChangeText={(repeatPassword) => this.setState({repeatPassword})} secureTextEntry={true} />

                <View style={[globalStyles.alignSelfCenter, styles.marginTop15]}>
                    <Button
                        disabled={this.state.loading ? true : false}
                        text="Verstuur"
                        color="greenWhite"
                        onPress={() => this.setNewPassword(this.state.password, this.state.repeatPassword)}
                    />
                </View>

                {this.state.loading &&
                <View style={[styles.marginTop15, globalStyles.alignSelfCenter]}>
                    <ActivityIndicator size="large" color="#B9DCBF" />
                </View>
                }

                {this.state.errorMessage.length ?
                    <MakeText style={[globalStyles.alignSelfCenter, globalStyles.error, globalStyles.marginTop15]}>{this.state.errorMessage}</MakeText>
                    :
                    null
                }

            </View>
        );
    }
}
