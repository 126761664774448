import * as React from 'react';

import BasicPage from './Template/BasicPage';
import GrowerSaleComponent from './../Components/OrderFlowers/GrowerSale';
import { Helmet } from "react-helmet";

export default class GrowerSale extends React.Component<any> {

    render() {

        const jsonld = {
            "@context": "http://schema.org/",
            "@type": "Webpage",
            "name": "Onze Bloemen",
            "description": "Sociaal, duurzaam en stijlvol - daar worden de bloemen nog mooier van. Je kunt kiezen uit drie varianten, elk met zijn eigen charme. De designs wisselen wekelijks en spelen in op het seizoen.",
            "url": "https://www.theflowerfamily.nl/kwekersactie"
        };

		return (
            <BasicPage navigation={this.props.navigation}>
                <Helmet>
                    <title> Samen sterk </title>
                    <link rel="canonical" href="https://www.theflowerfamily.nl/kwekersactie" />

                    <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
                </Helmet>
				<GrowerSaleComponent app={this.props.app} />

			</BasicPage>
		);
	}
}
