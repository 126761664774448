import * as React from 'react';
import { NavigationScreenProp } from 'react-navigation';
import MakeText from '../../Common/MakeText/MakeText';
import { getStories } from '../../../API/Http/Requests';
import StoryThumbnailNew from './StoryThumbnailNew';
import { phone } from '../../../Util/screen/HandleLayout';


interface IStoriesProps {
	width : number;
	height : number;
	navigation : NavigationScreenProp<any, any>;
    title? : string;
    setStory?: (id) => void;
	buttonText? : string;
}

interface ISTories {
	body: string;
	card_button: string;
	card_desc: string;
	category: number,
	created_at: string;
	deleted_at: string;
    id: number;
    home_story: boolean;
	img_header: string;
	img_thumb: string;
	quote: string;
	title: string;
	updated_at: string;
}

export default class HomeStories extends React.Component<IStoriesProps> {

    _hasCanceled = false;
    state = {
        stories: [] as any,
        loading: true
    }

    componentDidMount() {
        this._hasCanceled = false;
        getStories().then((stories: ISTories[]) => {
            stories = stories.filter(s => s.home_story).sort((s1, s2) => s1.id - s2.id);
            if (!this._hasCanceled) {
                this.setState({
                    stories,
                    loading: false
                });
            }
        }).catch(() => {
            if (!this._hasCanceled) {
                this.setState({
                    loading: false
                });
            }
		})
    }

    render() {
        const mobileView = phone(this.props.width);
        const style = {
            container: {
                display: 'flex',
                flexDirection: 'column'
            } as React.CSSProperties,
            title: {
                fontSize: mobileView ? 24 : 36,
                alignSelf: 'center',
                letterSpacing: 2.2
            },
            storiesContainer: {
                display: 'flex',
                flexDirection: 'column',
                width: '80%',
                alignSelf: 'center',
            } as React.CSSProperties,
            allStoriesLink: {
                fontSize: mobileView ? 15 : 18,
                alignSelf: 'flex-end',
                color: 'black',
                textDecoration:'underline',
                fontFamily: 'Open Sans',
                marginBottom: 10,
            },
            story: {
                marginBottom: 25,
            },
        }

        if(this.state.loading) {
            return (
				<div style={{display: 'flex', flexDirection: 'column'}}>
	                <MakeText style={{textAlign: 'center', fontSize: 20, letterSpacing: 2.2}}>Stories laden...</MakeText>
            	</div>
			);
        }

        return (
            <div style={style.container}>
                <MakeText style={style.title} fontFamily="Arno Pro">
					Onze stories
				</MakeText>
                <div style={style.storiesContainer}>
                    {this.state.stories.map((s, i) => <div key={s.title + i} style={style.story}><StoryThumbnailNew key={'story-' + s.id} story={s} {...this.props} mobileView={mobileView} /></div>)}
                    <a onClick={() => this.props.navigation.navigate('Stories')} style={style.allStoriesLink}>Bekijk alle stories</a>
                </div>
            </div>
        )
    }
};
