export default {
    blocks: [
        {
            img: '/images/filosofie_2.jpg',
            title: 'Sociaal',
            text: 'Mensen met een afstand tot de arbeidsmarkt - onze talenten - helpen ons en bloeien op in de maatschappij.',
        },
        {
            img: '/images/filosofie_1.jpg',
            title: 'Duurzaam',
            text: 'Onze gecertificeerde kwekers zetten zich in voor natuur en milieu door op de meest duurzame manier te telen.',
        },
        {
            img: '/images/filosofie_3.jpg',
            title: 'Stijlvol',
            text: 'Floral design by Dennis Kneepkens. Een heel nieuwe bloemenervaring!',
        },
    ],
    button: 'Lees meer over onze filosofie',

    aboutBlocks: [
        {
            img: '/images/filosofie_2.jpg',
            title: 'Sociaal',
            text: 'Al onze bloemen worden samengesteld tot stijlvolle veldboeketten door mensen met een achterstand tot de arbeidsmarkt - onze ‘talenten’ genaamd. Deze talenten - denk aan mensen met lichamelijke of verstandelijke beperkingen, of vluchtelingen - worden bij ons opgeleid en kunnen op deze manier weer opbloeien in de maatschappij.',
        },
        {
            img: '/images/filosofie_1.jpg',
            title: 'Duurzaam',
            text: 'Onze bloemen zijn direct afkomstig van NL’se kwekers die allemaal op een duurzame manier telen. Zij gebruiken minimale en dierlijke bestrijdingsmiddelen en gaan circulair om met water en energie. Wij leveren de meest duurzame en dagverse bloemen direct van ‘t land.',
        },
        {
            img: '/images/filosofie_3.jpg',
            title: 'Stijlvol',
            text: 'De wekelijks veranderende en stijlvolle boeketten gevuld met bloemen van het seizoen worden ontworpen door onze huisstylist Dennis Kneepkens.',
        },
    ],
};