import * as React from 'react';

import BasicPage from './Template/BasicPage';
import FAQ from '../Components/FAQ/FAQ';
import { Helmet } from "react-helmet";



export default class About extends React.Component<any> {

    render() {

        const jsonld = {
            "@context": "http://schema.org/",
            "@type": "Webpage",
            "name": "Frequently Asked Questions",
            "description": "Hoe werkt het Family Membership The Flower Family?",
            "url": "https://www.theflowerfamily.nl/faq"
        };

        return (
            <BasicPage navigation={this.props.navigation}>
                <Helmet>
                    <title> The Flower Family - Frequently Asked Questions </title>
                    <link rel="canonical" href="https://www.theflowerfamily.nl/faq" />
                    <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
                </Helmet>
				<FAQ />
			</BasicPage>
        );
    }
}
