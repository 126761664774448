import * as React from 'react';

import Button from '../CrossPlatform/Button/Button';
import moment from 'moment';
import MakeText from '../Common/MakeText/MakeText';
import { DeliveryOption, deliveryOptionToString, recurrencyToString, SubscriptionRecurrency } from '../../API/Interfaces/DeliveryOption';
import SelectDelivery from './SelectDelivery';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';
import { Package } from "../../API/Interfaces/Bundle";

interface IDeliveryModalProps {
	options: DeliveryOption[];
	subscription: boolean;
	chosenDeliveryIndex: number;
	recurring: SubscriptionRecurrency | undefined;
	setDelivery: (delivery: number, recurring?: SubscriptionRecurrency) => void;
	prevPage: () => void;
	nextPage: () => void;
	spoed: boolean;
	setSpoed: (s: boolean) => void;
	mobileView: boolean;
	package: Package;
}

export default (props: IDeliveryModalProps) => {
	const mobileView = props.mobileView;
	let options = props.options;
	const isTulpenNL = props.package.packageIntname.toLowerCase().indexOf('tulpen') > -1;
	const smallText = mobileView ? 15 : 18;
	const style = {
		selectStyle: {
			fontSize: smallText,
			fontFamily: 'Open Sans',
			marginBottom: 25,
			width: mobileView ? '100%' : 500,
			paddingLeft: 5,
		} as React.CSSProperties,
	};
	
	return (
		<div style={{height: '100%', flexDirection: 'column', alignItems: 'center', position: 'relative', display: 'flex'}}>
			<MakeText style={{fontSize: mobileView ? 24 : 36, alignSelf: 'center', marginBottom: 30}} fontFamily='Arno Pro'>Kies je bezorgmoment</MakeText>
			{ isTulpenNL && 
				<MakeText style={{fontSize: mobileView ? 18 : 18, alignSelf: 'center', marginBottom: 30}} fontFamily='Arno Pro'>Brievenbustulpen worden voor 17u geleverd!</MakeText>
			}
			{props.spoed && routingName === "amsterdam" && props.package.packageIntname.toLowerCase().indexOf('tulpen') === -1 ?
				<div className='mb-2'>
					<MakeText style={{ fontSize: 15 }} fontFamily='Open Sans'>
						We streven ernaar om jouw spoedbestellingen binnen 4 uur te bezorgen.<br/>
						Als de bestelling voor 12:00 uur gedaan is wordt deze dezelfde dag nog bezorgd.<br/>
						Voor een spoedbestelling worden extra kosten in rekening gebracht. Deze kosten bedragen €15,- per boeket.<br/>
						Bel of mail nog even ter bevestiging van de spoedbestelling via +31 20 245 5887 of info@theflowerfamily.nl
					</MakeText>
				</div>
				:
				<div>
					{mobileView ?
						<div className='mb-2 borderBottom' style={{overflow: 'auto'}}>
							<SelectDelivery options={options} chosen={props.chosenDeliveryIndex} setDelivery={(i: number) => props.setDelivery(i)} />
						</div>
					:
						<select name="deliveryOption" id="deliveryOption" value={props.chosenDeliveryIndex} size={5} onChange={(e) => props.setDelivery(Number(e.target.value))} style={style.selectStyle}>
							{options.map((p, i) => <option key={'dropdown_' + p.windowStart + p.deliveryProvider} value={i}>{deliveryOptionToString(p)}</option>)}
						</select> // automatic update delivery options is every day at 12:40h
					}
				</div>
			}

			{(routingName === "amsterdam" && moment(props.options[props.chosenDeliveryIndex].windowEnd).hour() < 12) &&
				<div className='mt-1 mb-4' style={{width: '100%'}}>
					<MakeText style={{ fontSize: 13 }} fontFamily='Open Sans'>
						Het tijdsvenster tot 10.00 uur is alleen te gebruiken voor gemeentelijke en zakelijke bezorglocaties.<br/>
						Hoeft de bestelling niet perse vóór 10.00 uur bezorgd te worden, selecteer dan ook aub een later tijdsvenster.
					</MakeText>
				</div>

			}

			{routingName === "amsterdam" && props.package.packageIntname.toLowerCase().indexOf('tulpen') === -1 &&
				<div className='row alignCenter'>
					<input type="checkbox" id="spoed" style={{marginLeft: 20, marginRight: 10, marginBottom: 20}} name="spoed" checked={props.spoed} onChange={(e) => props.setSpoed(e.currentTarget.checked)} />
					<MakeText style={{ fontSize: 15, marginBottom: 20}} fontFamily='Open Sans'>
						Spoedbestelling (bezorging binnen 4 uur)
					</MakeText>
				</div>
			}

			{props.subscription ?
				[<MakeText style={{fontSize: mobileView ? 18 : 24, alignSelf: 'center', marginBottom: 15, marginTop: 10}}>Hoe vaak wil je bloemen ontvangen?</MakeText>,
				<select name="recurrency" id="recurrency" value={props.recurring} size={3} onChange={(e) => props.setDelivery(props.chosenDeliveryIndex, e.target.value as SubscriptionRecurrency)} style={style.selectStyle}>
					{Object.values(SubscriptionRecurrency).map(p => <option key={'dropdown_' + p} value={p}>{recurrencyToString(p)}</option>)}
				</select>]
				: undefined
			}
			<div className='mb-2' style={{display: 'flex', flexDirection: 'row', width:'100%', justifyContent: 'space-between'}}>
				<Button style={{width: 120}} textStyle={{fontSize: 18, width: '100%', height: 40, fontFamily: 'Open Sans'}} text={'Vorige'} color={'greenWhite'} onPress={() => props.prevPage()} />
				<Button testID='nextPage' style={{width: 120}} textStyle={{fontSize: 18, width: '100%', height: 40, fontFamily: 'Open Sans'}} text={'Volgende'} color={'greenWhite'} onPress={() => props.nextPage()} />
			</div>
		</div>
	);
}
