import * as React from 'react';
import { View, TextInput, TouchableOpacity, Linking, ActivityIndicator } from 'react-native';

import FooterWeeklyFlowers from './../Common/FooterWeeklyFlowers/FooterWeeklyFlowers';
import NewButton from '../CrossPlatform/Button/Button'
import MakeText from '../Common/MakeText/MakeText';
import { phone } from '../../Util/screen/HandleLayout';
import businessStyles from './styles';
import globalStyles from '../../Styles/styles';
import { Color } from '../../Util/Color';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';
import { businessRequest } from '../../API/Http/Requests';

export default class TFFForBusiness extends React.Component<any> {

	state = {
	    bname: '',
	    bemail: '',
	    bphone: '',
	    bcompany: '',

		loading: false,
		statusMessageAvailable: false,
		statusMessage: undefined,
	}

    async businessContactRequest() {

		let error = false;

		this.setState({loading: true, statusMessage: undefined, statusMessageAvailable: false,});

        await businessRequest(this.state.bname, this.state.bemail, this.state.bphone, this.state.bcompany).catch( e => {
			this.setState({statusMessage: this.createErrorMessage(), statusMessageAvailable: true, loading: false});
			error = true;
			return console.log(e);
		});

		if(!error) {
			GoogleAnalytics.getInstance().contactFromBusiness();
			this.setState({bname: '', bemail: '', bphone: '', bcompany: '', statusMessage: this.createSuccesMessage(), statusMessageAvailable: true, loading: false});
		}
    };

	createErrorMessage() {
		return (
			<View style={[globalStyles.row, globalStyles.alignSelfCenter, globalStyles.flex1, {backgroundColor: Color.Gray, padding: 4, borderRadius: 5}]}>
				<MakeText style={{color: Color.White}}>Er is iets fout gegaan tijdens het versturen van uw bericht. Uw bericht is niet verzonden. Klik </MakeText>
				<TouchableOpacity style={{borderBottomWidth: 1, borderBottomColor: 'white'}}
					onPress={() => Linking.openURL(`mailto:info@theflowerfamiliy.nl?subject=Bloemen voor kantoor formulier: ${this.state.bname}&body=${this.state.bname}%0D%0A${this.state.bemail}%0D%0A${this.state.bphone}%0D%0A${this.state.bphone}`)}
				>
					<MakeText style={{color: Color.White}}>Hier</MakeText>
				</TouchableOpacity>
				<MakeText style={{color: Color.White}}> om uw bericht per mail te verzenden.</MakeText>
			</View>
		);
	}

	createSuccesMessage() {
		return <MakeText style={{flex: 1, alignSelf: 'center', backgroundColor: Color.Green, color: Color.White, padding: 4, borderRadius: 5}}>Uw bericht is verzonden.</MakeText>
	}

	render() {

		const mobileView: boolean = phone(this.props.width);
		const styles = businessStyles(this.props.width, this.props.height);

	    return (

		    <View style={{maxWidth: this.props.width}}>

				<View>
		            <img src={'/images/Foto voor kantoor.jpg'} width='100%' height={styles.image.height} />
	                <MakeText fontFamily={'Arno Pro'} style={{backgroundColor: '#b9dcbf', color: '#414141', fontSize: this.props.width * 0.047, letterSpacing: 4, position: 'absolute', bottom: -10, right: 20, textAlign: 'right', padding: 5}}>
	                    {"Bloem en groen voor op kantoor"}
	                </MakeText>
		        </View>
				<div className='col' style={{width: mobileView ? '95%': '50%', alignSelf: 'center'}}>
					<View style={{marginTop: 50}}>
						<MakeText style={styles.headerText} fontFamily="Open Sans">
							The Flower Family bezorgt niet alleen thuis, maar ook op kantoor een glimlach. We doen dit op de volgende manieren:
						</MakeText>
					</View>
					<View>
						<MakeText fontFamily='Arno Pro' style={styles.header1}>Family Membership</MakeText>
						<MakeText style={styles.headerText} fontFamily="Open Sans">
							Als family member krijg je periodiek één of meerdere boeketten op jouw kantoor. Dit doen we op basis van een flexibel abonnement. Dus jij bepaalt wanneer en hoe vaak we langskomen en welke bloemen we voor kantoor meenemen.
						</MakeText>
					</View>
					<View>
						<MakeText fontFamily='Arno Pro' style={styles.header1}>Losse boeketten voor collega’s en relaties</MakeText>
						<MakeText style={styles.headerText} fontFamily="Open Sans">
							Voor een jubileum, verjaardag, zwangerschap, nieuwe collega… je noemt het maar op. The Flower Family maakt er een feestje van!
						</MakeText>
					</View>
					<View>
						<MakeText fontFamily='Arno Pro' style={styles.header1}>Het verfrissen van kantoor met groene kantoorplanten</MakeText>
						<MakeText style={styles.headerText} fontFamily="Open Sans">
							En de planten worden eventueel verzorgd door Alex, ons talent van de planten.
						</MakeText>
					</View>

					<div className='col' style={{width: mobileView ? '100%' : '50%', alignSelf: 'center', marginBottom: 30}}>
						<MakeText fontFamily='Arno Pro' style={{textAlign: 'center', fontSize: 36, letterSpacing: 2.13, color: '#414141', marginTop: 30}}>Contact</MakeText>
						<MakeText style={{fontSize: 14, letterSpacing: 0.9275, color: '#414141', marginTop: 30}}>Ben jij geïnteresseerd en wil jij ook sociaal en zo duurzaam mogelijk inkopen, of heb je nog andere wensen of vragen? Laten we dan even gezellig kennismaken! En wist jij trouwens dat je alle gemaakte kosten kan opvoeren als Social Return?</MakeText>
						<MakeText style={{fontSize: 14, letterSpacing: 0.9275, color: '#414141', marginTop: 40}}>Mail naar: info@theflowerfamily.nl</MakeText>
						<MakeText style={{fontSize: 14, letterSpacing: 0.9275, color: '#414141'}}>Bel naar: +31 20 245 5887 ​</MakeText>
						<MakeText style={{fontSize: 14, letterSpacing: 0.9275, color: '#414141', marginTop: 40, marginBottom: 20}}>​Of laat je gegevens achter, dan nemen we zo snel mogelijk contact op.</MakeText>
						<TextInput placeholder='Naam*' style={styles.textInput} value={this.state.bname} onChangeText={(bname) => { this.setState({bname})}}/>
						<TextInput placeholder='E-mail*' style={styles.textInput} value={this.state.bemail} onChangeText={(bemail) => { this.setState({bemail})}}/>
						<TextInput placeholder='Telefoon*' style={styles.textInput} value={this.state.bphone} onChangeText={(bphone) => { this.setState({bphone})}}/>
						<TextInput placeholder='Bedrijf*' style={styles.textInput} value={this.state.bcompany} onChangeText={(bcompany) => { this.setState({bcompany})}}/>

						{this.state.loading &&
							<View style={{display: 'flex', alignSelf: 'center', marginTop: 15}}>
								<ActivityIndicator size="large" color="#B9DCBF" />
							</View>
						}

						{ this.state.statusMessageAvailable &&
							this.state.statusMessage
						}

						<NewButton style={{ alignSelf: 'center', marginTop: 15 }} text='Verstuur' color='greenWhite' textStyle={{ paddingTop: 5, paddingBottom: 6, fontSize: 16, letterSpacing: 2 }} onPress={() => this.businessContactRequest()} />
					</div>
				</div>

		        <FooterWeeklyFlowers width={this.props.width} height={this.props.height} navigation={this.props.navigation}/>
		    </View>
	    );
	}
}
