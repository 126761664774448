export default {
    html: `
        <div class="c-richt-text col-8">
	        <p class="default">Versie 0.2
		        <br>Deze pagina is voor het laatst aangepast op 15-01-2019
		        <br>
		        <br>Wij zijn ons ervan bewust dat je vertrouwen stelt in ons. Wij zien het dan ook als onze verantwoordelijkheid om je privacy te beschermen. Op deze pagina laten we je weten welke gegevens we verzamelen als je onze website gebruikt, waarom we deze gegevens verzamelen en hoe we hiermee je gebruikservaring verbeteren. Zo snap je precies hoe wij werken.
		        <br>
		        <br>Dit privacybeleid is van toepassing op de diensten van The Flower Family. Je dient je ervan bewust te zijn dat The Flower Family niet verantwoordelijk is voor het privacybeleid van andere sites en bronnen. Door gebruik te maken van deze website geef je aan het privacy beleid te accepteren.
		        <br>
		        <br>The Flower Family respecteert de privacy van alle gebruikers van haar site en draagt er zorg voor dat de persoonlijke informatie die je ons verschaft vertrouwelijk wordt behandeld.
		        <br>
		        <br>
		        <br> <strong><i>Ons gebruik van verzamelde gegevens</i></strong>
		        <br>
		        <br> <strong>Gebruik van onze diensten</strong>
		        <br>Wanneer je je aanmeldt voor een van onze diensten vragen we je om persoonsgegevens te verstrekken. Deze gegevens worden gebruikt om de dienst uit te kunnen voeren. De gegevens worden opgeslagen op eigen beveiligde servers van The Flower Family of die van een derde partij. Wij zullen deze gegevens niet combineren met andere persoonlijke gegevens waarover wij beschikken.
		        <br>
		        <br> <strong>Communicatie</strong>
		        <br>Wanneer je een e-mail of andere berichten naar ons verzendt, is het mogelijk dat we die berichten bewaren. Soms vragen wij je naar jouw persoonlijke gegevens die voor de desbetreffende situatie relevant zijn. Dit maakt het mogelijk jouw vragen te verwerken en jouw verzoeken te beantwoorden. De gegevens worden opgeslagen op eigen beveiligde servers van The Flower Family of die van een derde partij. Wij zullen deze gegevens niet combineren met andere persoonlijke gegevens waarover wij beschikken.
		        <br>
                <br> <strong>Doeleinden</strong>
		        <br>We verzamelen of gebruiken geen informatie voor andere doeleinden dan de doeleinden die worden beschreven in dit privacy beleid tenzij we van tevoren jouw toestemming hiervoor hebben verkregen.
		        <br>
		        <br> <strong>Derden</strong>
		        <br>De informatie wordt niet met derden gedeeld met uitzondering van diensten welke wij gebruiken ten behoeve van onze webwinkel. Hieronder valt o.a. het WebwinkelKeur beoordelingssysteem. Deze gegevens zullen enkel gebruikt worden ten behoeve van het doel van de betreffende applicatie en zullen niet verder verspreid worden. Verder kan in enkele gevallen de informatie intern gedeeld worden. Onze werknemers zijn verplicht om de vertrouwelijkheid van jouw gegevens te respecteren.
		        <br>
		        <br> <strong>Veranderingen</strong>
		        <br>Deze privacyverklaring is afgestemd op het gebruik van en de mogelijkheden op deze site. Eventuele aanpassingen en/of veranderingen van deze site, kunnen leiden tot wijzigingen in deze privacyverklaring. Het is daarom raadzaam om regelmatig deze privacyverklaring te raadplegen.
		        <br>
		        <br> <strong>Keuzes voor persoonsgegevens</strong>
		        <br>Wij bieden alle bezoekers de mogelijkheid tot het inzien, veranderen, of verwijderen van alle persoonlijke informatie die op moment aan ons is verstrekt.
		        <br>
		        <br> <strong>Aanpassen/uitschrijven dienst nieuwsbrief</strong>
		        <br>Onderaan iedere mailing vind je de mogelijkheid om jouw gegevens aan te passen of om je af te melden.
		        <br>
		        <br> <strong>Aanpassen/uitschrijven communicatie</strong>
		        <br>Als je jouw gegevens aan wilt passen of jezelf uit onze bestanden wilt laten halen, kun je contact met ons op nemen. Zie onderstaande contactgegevens.
		        <br>
		        <br> <strong>Vragen en feedback</strong>
		        <br>We controleren regelmatig of we aan dit privacybeleid voldoen. Als je vragen hebt over dit privacybeleid, kun je contact met ons opnemen:
		        <br>
		        <br>The Flower Family
		        <br>Edisonstraat 117
		        <br>2316SJ Leiden
		        <br>info@theflowerfamily.nl
		        <br>+31 20 245 5887</p>
	        <br>
	        <br>
	        <br>
        </div>
    `,
}