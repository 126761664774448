import * as React from 'react';
import { View, ActivityIndicator, TouchableOpacity, ScrollView, StyleSheet, Linking, Image } from 'react-native';
import PersBericht from './PersBericht';
import MakeText from '../Common/MakeText/MakeText';
import { mobile } from '../../Util/screen/HandleLayout';
import { phone } from '../../Util/screen/HandleLayout';
import Button from '../CrossPlatform/Button/Button';
import { getPress } from '../../API/Http/Requests';

export default class Vacatures extends React.Component<any>{
    state = {
        press: [],
        loading: true
    }

    componentDidMount() {
        getPress().then((press) => {
            this.setState({
                press: press,
                loading: false
            })
        });
    }

    handleLink(file_pdf) {
        const url = file_pdf;
        Linking.canOpenURL(url).then(supported => {
            if (supported) {
              Linking.openURL(url);
            } else {
              console.log("Don't know how to open URI: " + url);
            }
          });
    }

    renderPersberichten(press) {
        return <TouchableOpacity onPress={() => this.handleLink(press.file_pdf)}><PersBericht
            {...press}
            height={this.props.height}
            width={this.props.width}
        /></TouchableOpacity>;
    }

    render() {
        const mobileView: boolean = phone(this.props.width);
        const bannerHeight = mobileView ? 310 : this.props.height * 0.6;
        const styles = StyleSheet.create({
            imgRow: {
                flexDirection: mobileView ? 'column' : 'row',
                justifyContent: 'center',
                alignItems: 'center'
            },
            imgContainer: {
                margin: 20
            },
            sizeText: {
                margin: 5
            }
        });
        const newStyles = {
            titleStyle: {
                position: 'absolute',
                top: 0,
                right: 0,
                width: '50%',
                height: bannerHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            } as React.CSSProperties,
        };
        return <ScrollView style={{flex: 1, alignSelf: 'center', marginBottom: '5%', width: this.props.width}}>
            <div style={{width: '100%', position: 'relative'}}>
                    <img src={'/images/Foto pers header.JPG'} height={bannerHeight} width={this.props.width} style={{objectFit: 'cover'}} />
                    <div style={newStyles.titleStyle}>
                        <MakeText fontFamily='Arno Pro' style={{fontSize: mobileView ? 24 : 48}}>In de pers...</MakeText>
                    </div>
                </div>
            <View style={{width: this.props.width < 600 ? this.props.width * 0.8 : this.props.width * 0.4, alignSelf: 'center'}}>
                <MakeText style={{fontSize: 14, lineHeight: 32, marginTop: 25, letterSpacing: 0.9275, color: '#414141'}}>
                    Op deze pagina vind je de persberichten van The Flower Family en verschillend beeldmateriaal. Als je nog meer informatie nodig hebt, neem dan contact met ons op door te mailen naar: info@theflowerfamily.nl
                </MakeText>
            </View>
            <MakeText fontFamily='Arno Pro' style={{fontSize: 28, alignSelf: 'center', letterSpacing: 2.2, color: '#414141', textAlign: 'center', marginTop: 25}}>Persberichten</MakeText>
            {this.state.loading ?
                <View>
                    <MakeText style={{fontSize: 14, lineHeight: 32, marginTop: 25, letterSpacing: 0.9275, color: '#414141', textAlign: 'center'}}>Persberichten ophalen...</MakeText>
                    <ActivityIndicator />
                </View>
            :
                <View style={{marginTop: 25}}>
                    {this.state.press.map((vac) => { return this.renderPersberichten(vac)})}
                </View>
            }

            <View>
                <MakeText fontFamily='Arno Pro' style={{fontSize: 28, alignSelf: 'center', letterSpacing: 2.2, color: '#414141', textAlign: 'center', marginTop: 50}}>Beeldmateriaal + logoset</MakeText>
                <View style={styles.imgRow}>
                    <View style={styles.imgContainer}>
                        <Image source={require('../../images/pers-foto-a.jpg')} style={{height: this.props.width < 880 ? 200: 500, width: this.props.width < 880 ? this.props.width * 0.8 : this.props.width * 0.4}} />
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/A_small_600x400.jpg')}><MakeText style={styles.sizeText}>Download Small - 600 x 400</MakeText></TouchableOpacity>
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/A_medium_1200x800.jpg')}><MakeText style={styles.sizeText}>Download Medium - 1200 x 800</MakeText></TouchableOpacity>
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/A_large_3000x2000.jpg')}><MakeText style={styles.sizeText}>Download Large - 3000 x 2000</MakeText></TouchableOpacity>
                    </View>
                    <View style={styles.imgContainer}>
                        <Image source={require('../../images/pers-foto-b.jpg')} style={{height: this.props.width < 880 ? 200: 500, width: this.props.width < 880 ? this.props.width * 0.8 : this.props.width * 0.4}} />
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/B_small_600x400.jpg')}><MakeText style={styles.sizeText}>Download Small - 600 x 400</MakeText></TouchableOpacity>
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/B_medium_1200x800.jpg')}><MakeText style={styles.sizeText}>Download Medium - 1200 x 800</MakeText></TouchableOpacity>
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/B_large_3000x2000.jpg')}><MakeText style={styles.sizeText}>Download Large - 3000 x 2000</MakeText></TouchableOpacity>
                    </View>
                </View>
                <View style={styles.imgRow}>
                    <View style={styles.imgContainer}>
                        <Image source={require('../../images/pers-foto-c.jpg')} style={{height: this.props.width < 880 ? 200: 500, width: this.props.width < 880 ? this.props.width * 0.8 : this.props.width * 0.4}} />
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/C_small_600x400.jpg')}><MakeText style={styles.sizeText}>Download Small - 600 x 400</MakeText></TouchableOpacity>
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/C_medium_1200x800.jpg')}><MakeText style={styles.sizeText}>Download Medium - 1200 x 800</MakeText></TouchableOpacity>
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/C_large_3000x2000.jpg')}><MakeText style={styles.sizeText}>Download Large - 3000 x 2000</MakeText></TouchableOpacity>
                    </View>
                    <View style={styles.imgContainer}>
                        <Image source={require('../../images/pers-foto-d.jpg')} style={{height: this.props.width < 880 ? 200: 500, width: this.props.width < mobile ? this.props.width * 0.8 : this.props.width * 0.4}} />
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/D_small_600x400.jpg')}><MakeText style={styles.sizeText}>Download Small - 600 x 400</MakeText></TouchableOpacity>
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/D_medium_1200x800.jpg')}><MakeText style={styles.sizeText}>Download Medium - 1200 x 800</MakeText></TouchableOpacity>
                        <TouchableOpacity onPress={() => this.handleLink('https://theflowerfamily.nl/static/pers/D_large_3000x2000.jpg')}><MakeText style={styles.sizeText}>Download Large - 3000 x 2000</MakeText></TouchableOpacity>
                    </View>
                </View>
            </View>

            <Button
                text='Download logoset'
                color='blackOpacity'
                style={{alignSelf: 'center', marginTop: 10}}
                textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: 16, textAlign: 'center', letterSpacing: 2}}
                onPress={() => {
                    this.handleLink('https://theflowerfamily.s3.eu-west-3.amazonaws.com/website/public/logoset.zip');
                }}
            />
        </ScrollView>;
    }
}
