import { StyleSheet } from 'react-native';

export default StyleSheet.create( {
    mainView: {
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative'
    },
    justify: {
        justifyContent: 'center'
    },
    accountType: {
        alignSelf: 'flex-start',
        marginVertical: 20,
        marginLeft: 30,
    },
	pickDeliveryMainView: {
		flexDirection: 'column',
	},
    textInput: {
        borderColor: '#414141',
        borderWidth: 1,
        fontSize: 16,
        margin: 5,
		padding: 7,
        // width: '80%'
    },
    flexTextInput: {
        borderColor: 'black',
        borderWidth: 1,
        fontSize: 20,
        margin: 5,
    },
    header: {
        fontSize: 30,
		letterSpacing: 2.2,
		color: '#414141',
        marginBottom: 20,
        textAlign: 'center'
    },
    inputView: {
        flexDirection: 'column',
        alignItems: 'center'
    },
    butt: {
        padding: 10,
        alignSelf: 'center',
        margin: 5,
        letterSpacing: 0.9275,
        fontSize: 14,
        paddingLeft: 35,
        paddingRight: 35
    },
    backButt: {
        width: '40%',
        marginTop: 10,
    },
    buttonSeparator: {
        width: '20%',
        marginTop: 10,
    },
    forwardButt: {
        width: '40%',
        marginTop: 10,
    },
    orText: {
        textAlign: 'center',
        fontSize: 15,
		marginVertical: 10,
    },
    forgotPW: {
        alignSelf: 'flex-end'
    },
    addressRow: {
        alignSelf: 'center',
        flexDirection: 'row',
        marginLeft: 15,
        marginBottom: 5,
        marginRight: 15
    },
    dottedLine: {
        borderStyle: 'dashed',
        borderBottomColor: 'black',
        borderBottomWidth: 1,
        margin: 5
    },
    summaryBlock: {
        flexDirection: 'column',
    },
    bold: {
        fontWeight: 'bold'
    },
    backForwardButts: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    korting: {
        flexDirection: 'row',
        marginTop: 15.5,
		marginBottom: 21,
    },
    absoluteBottom: {
        width: '100%',
        position: 'absolute',
        bottom: 0,
        flexDirection: 'row',
        alignSelf: 'center'
    },
	pickLoginMobileTopMargin: {
		paddingTop: 50,
	}
});
