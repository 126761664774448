import * as React from 'react';
import { Text } from 'react-native';

/**
 * @example
 * // This example shows text with the font family: Open Sans.
 *
 * <MakeText style={{fontSize: 16}} fontFamily="Open Sans">
 *      Some Text with a specific font.
 * </MakeText>
 *
 * Default font: Open Sans.
 */
export default class MakeText extends React.Component<any> {

    state = {
        fontLoaded: false,
    }

    async componentDidMount() {
        // Load the selected font for both web and native.
        const font = this.props.fontFamily ? this.props.fontFamily : (this.props.style && this.props.style.fontFamily);

        switch(font) {
            case 'Open Sans':
                require(`./../../../assets/LoadFonts/OpenSans/OpenSans`);
				break;

			case 'Open Sans Light':
				require(`./../../../assets/LoadFonts/OpenSansLight/OpenSansLight`);
				break;

			case 'Open Sans Italic':
				require(`./../../../assets/LoadFonts/OpenSansItalic/OpenSansItalic`);
				break;

			case 'Open Sans Light Italic':
				require(`./../../../assets/LoadFonts/OpenSansLightItalic/OpenSansLightItalic`);
				break;

			case 'Crimson Text':
				require(`./../../../assets/LoadFonts/CrimsonText/CrimsonText`);
                break;

            case 'Open Sans Semibold':
                require(`./../../../assets/LoadFonts/OpenSansSemiBold/OpenSansSemibold`);
                break;

            default:
				require(`./../../../assets/LoadFonts/OpenSans/OpenSans`);
				break;
        }

        this.setState({fontLoaded: true});
    }

    render() {
        if(!this.state.fontLoaded) {
            return null;
        }

        const font = this.props.fontFamily ? this.props.fontFamily : (this.props.style && this.props.style.fontFamily);

		if(!this.props.style && !font) {
			return <Text testID={this.props.testID} style={{fontFamily: 'Open Sans'}}>{this.props.children}</Text>
		}

        if(!this.props.style && font) {
            return <Text testID={this.props.testID} style={{fontFamily: font}}>{this.props.children}</Text>
        }

		if(!font && this.props.style) {
            return <Text testID={this.props.testID} style={[this.props.style, {fontFamily: 'Open Sans'}]}>{this.props.children}</Text>
        }

        return (
            <Text testID={this.props.testID} style={[this.props.style, {fontFamily: font}]}>{this.props.children}</Text>
        )
    }
}
