import * as React from 'react';
import validator from 'validator';
import {createUser, getMe, isLoggedIn} from "../../API/Http/Requests";
import Button from './../CrossPlatform/Button/Button';
import MakeText from '../Common/MakeText/MakeText';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';
import moment from 'moment';

export default class Register extends React.Component<any> {
	state = {
        business: false,
        fname: '',
		lname: '',
        email: '',
        phone: '',
        birth_date: '',
        news_flag: false,

        password: '',
		repeatPassword: '',

		companyName: '',
		vat_number: '',

		errorText: '',
		loggedInTemp: false,
		userExists: false,
	}

	async componentDidMount() {
		this.props.store.setActiveStep('pickLogin');
        if (this.props.navigation.getParam('business')) {
            this.setState({business: this.props.navigation.getParam('business')});
		}
		// If the user already is logged, the user is probably on a temp account so should see the registration field filled in already
		if (await isLoggedIn().catch(e => console.log(e))) {
			try {
				const user = await getMe();
				console.log(user);
				const isBusiness = user.businesses !== undefined && user.businesses.length > 0;
				this.setState({
					fname: user.firstName,
					lname: user.lastName,
					email: user.email,
					phone: user.phone ? user.phone : '',
					birth_date: user.birthDate ? moment(user.birthDate).format('DD-MM-YYYY') : '',
					news_flag: user.emails === null,
					companyName: isBusiness ? user.businesses[0].company : '',
					vat_number: isBusiness ? user.businesses[0].vatNumber : '',
					loggedInTemp: true,
				});
			} catch {}
        }

		GoogleAnalytics.getInstance().hit(this.props.navigation.state);
	}

	async prepareRegisterRequest(cartHasSubscriptionItem: boolean) {
		this.setState({userExists: false});
		// Escape input, check if valid data has been entered.
		let cleanInput = this.checkInput(cartHasSubscriptionItem);

		// return if an error was found.
		if(cleanInput.error) return 0;

		// Finally, if the data is correct and clean, attempt the login request.
		this.state.business ?
			this.attemptRegister(cleanInput.name, cleanInput.fname, cleanInput.lname, cleanInput.email, cleanInput.password, cleanInput.phone, cleanInput.birth_date, cleanInput.newsFlag, cleanInput.companyData)
		:
			this.attemptRegister(cleanInput.name, cleanInput.fname, cleanInput.lname, cleanInput.email, cleanInput.password, cleanInput.phone, cleanInput.birth_date, cleanInput.newsFlag);

		return 1;
	}

	// Takes data needed from the state for creating an account and cleans it.
	checkInput(cartHasSubscriptionItem: boolean) {
		let cleaned: any;

		if(this.state.business) {
			cleaned = this.checkCompanyInput(cartHasSubscriptionItem);
		} else {
			cleaned = this.checkPersonInput(cartHasSubscriptionItem);
		}

		return cleaned;

	}

	checkCompanyInput(cartHasSubscriptionItem: boolean) {
		let name = validator.escape(this.state.fname + ' ' + this.state.lname);
		let fname = validator.escape(this.state.fname);
        let lname = validator.escape(this.state.lname);
        let birth_date = validator.escape(this.state.birth_date);
		let company_name = validator.escape(this.state.companyName);
		let email = validator.escape(this.state.email);
		let phone = validator.escape(this.state.phone);
		let vat_number = validator.escape(this.state.vat_number.replace(/\s/g, ''));
		let password = validator.escape(this.state.password);
		let repeatPassword = validator.escape(this.state.repeatPassword);

		if(!validator.isLength(name, {min: 2, max: 128})) {
			this.setState({errorText: 'Ongeldige voornaam'});
			return {error: true};
		}
		else if(!validator.isLength(company_name, {min: 1, max: 128})) {
			this.setState({errorText: 'Ongeldige Bedrijfsnaam'});
			return {error: true};
		}
		else if(cartHasSubscriptionItem && !validator.isLength(password, {min: 1, max: 128})) {
			this.setState({errorText: 'Ongeldig wachtwoord'});
			return {error: true};
		}
		else if(!validator.isLength(email, {min: 2, max: 128})) {
			this.setState({errorText: 'Ongeldig e-mail adres'});
			return {error: true};
		}
		else if(!validator.isLength(phone, {min:8, max: 15})) {
			this.setState({errorText: 'Ongeldig telefoonnummer'});
			return {error: true};
		}
		else if(!validator.isLength(vat_number, {min: 10, max: 128})) {
			this.setState({errorText: 'Ongeldig BTW-nummer'});
			return {error: true};
		}

		if(this.checkEmail(email)) {
			return {error: true};
		}

		// Make sure the passwords that were entered are the same.
		if(cartHasSubscriptionItem && !this.checkIfPasswordsMatch(password, repeatPassword)) {
			return {error: true};
		}

		const companyData = {
			is_company: true,
			company: company_name,
			vatNumber: vat_number,
		};

		return {name, fname, lname, birth_date, email, password, companyData, phone, newsFlag: this.state.news_flag, error: false}
	}

	checkPersonInput(cartHasSubscriptionItem: boolean) {
		// Removed HTML from input.
		let name = validator.escape(this.state.fname + ' ' + this.state.lname);
		let fname = validator.escape(this.state.fname);
		let lname = validator.escape(this.state.lname);
		let email = validator.escape(this.state.email);
		let phone = validator.escape(this.state.phone);
        let birth_date = validator.escape(this.state.birth_date);
		let password = validator.escape(this.state.password);
		let repeatPassword = validator.escape(this.state.repeatPassword);

		if(this.checkEmail(email)) {
			return {error: true};
		}

		// Checks the min and max lengths of the input.
		if(!validator.isLength(name, {min: 2, max: 128}) || (cartHasSubscriptionItem &&  !validator.isLength(password, {min: 1, max: 128})) || !validator.isLength(email, {min: 2, max: 128}) || !validator.isLength(phone, {min: 2, max: 20})) {
			this.setState({loadingWheel: null, errorText: 'Er is verkeerde data ingevoerd. Controleer je gegevens.'});
			return {error: true};
		}

		// Make sure the passwords that were entered are the same.
		if(cartHasSubscriptionItem && !this.checkIfPasswordsMatch(password, repeatPassword)) {
			return {error: true};
		}

		return {name, fname, lname, email, birth_date, password, phone, newsFlag: this.state.news_flag, error: false}
	}

	checkEmail(email: string): boolean {
		if(!email.includes('@')) {
			this.setState({loadingWheel: null, errorText: 'Ongelding e-mail adres'});
			return true;
		}

		return false;
	}

	// Make sure the passwords that were entered are the same.
	checkIfPasswordsMatch(password: string, repeatPassword: string): boolean {
		if(password !== repeatPassword) {
			this.setState({loadingWheel: null, errorText: 'De ingevoerde wachtwoorden komen niet overeen'});
			return false;
		}

		return true;
	}

	attemptRegister(name: string, firstName: string, lastName: string, email: string, password: string, phone: string, birthDate: string, emails: boolean, companyData?: any) {

		createUser({email, name, firstName, lastName, password, phone, birthDate, companyData, emails}).then( profile => {
			// If the login was succesfull, continue to the next page.
			if (profile.email) {
                return this.props.navigation.navigate('deliveryAddress');
			}

			// If something went wrong, display the correct error message.
			this.setErrorMessage();

		}).catch( e => {
			if (e.response.status === 409) {
				this.setState({loadingWheel: null, errorText: 'Er bestaat al een gebruiker met dit e-mailadres.', userExists: true})
			} else {
				this.setState({loadingWheel: null, errorText: 'Er lijkt iets fout te gaan, probeer het later opnieuw.', userExists: false});
			}
		});
	}

	setErrorMessage() : void {
		return this.setState({errorText: 'Sorry, er is iets foutgegaan!'});
	}

    render() {
		// Go back to overview page if the totalprice is 0. This scenario happens when a user refreshes the page on this screen.
		if(this.props.store.orderProcess.totalPrice === 0) {
			this.props.navigation.navigate('overview');
		}
		const cartHasSubcriptionItem = this.props.store.product.products.findIndex(p => p.flower.subscription) > -1;
		const mobileView = this.props.mobileView;
		const largeText = mobileView ? 24 : 36;
		// const mediumText = mobileView ? 18 : 24;
		const smallText = mobileView ? 15 : 18;

        return (
			<div style={{display:'flex', flexDirection: 'column', width: '100%', height: '95%', justifyContent: 'space-between'}}>
				<div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-around', width: '100%'}}>
					{/* Left block */}
					<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '90%' : 475, padding: mobileView ? '5%' : undefined}}>
						<MakeText style={{fontSize: largeText}} fontFamily={'Arno Pro'}>{this.state.business ? 'Gegevens contactpersoon' : 'Persoonlijke gegevens'}</MakeText>
						{/* Name */}
						<div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-between', marginTop: 10}}>
							{/* First name */}
							<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Voornaam*</MakeText>
								<input data-testId='voornaam' type="text" style={{fontSize: smallText, padding: 5}} value={this.state.fname} onChange={(e) => this.setState({fname: e.target.value})}></input>
							</div>
							{/* Last name */}
							<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Achternaam*</MakeText>
								<input data-testId='achternaam' type="text" style={{fontSize: smallText, padding: 5}} value={this.state.lname} onChange={(e) => this.setState({lname: e.target.value})}></input>
							</div>
						</div>
						{/* Email */}
						<div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
							<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>E-mail*</MakeText>
							<input data-testId='email' type="email" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}></input>
						</div>
						{/* Phone */}
						<div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
							<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Telefoonnummer*</MakeText>
							<input data-testId='telefoon' type="text" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.phone} onChange={(e) => this.setState({phone: e.target.value})}></input>
						</div>
						<MakeText fontFamily={'Open Sans'} style={{fontSize: 14, marginTop: 10}}>Velden met * zijn verplicht</MakeText>
						{/* Checkbox */}
						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
							<input type='checkbox' checked={this.state.news_flag} onChange={(e) => this.setState({news_flag: e.target.checked})}></input>
							<MakeText style={{fontSize: smallText, marginLeft: 10}}>Houd mij op de hoogte van nieuws en acties!</MakeText>
						</div>
					</div>
					{/* Right block */}
					<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '90%' : 475, padding: mobileView ? '5%' : undefined}}>
						{/* Optional business information block */}
						{this.state.business &&
							<div style={{marginBottom: 25}}>
								<MakeText style={{fontSize: largeText}} fontFamily={'Arno Pro'}>Bedrijfsgegevens</MakeText>
								{/* Naam */}
								<div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
									<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Naam bedrijf / organisatie*</MakeText>
									<input data-testId='bedrijf' type="text" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.companyName} onChange={(e) => this.setState({companyName: e.target.value})}></input>
								</div>
								{/* BTW-nummer */}
								<div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
									<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>BTW-nummer*</MakeText>
									<input data-testId='btw' type="text" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.vat_number} onChange={(e) => this.setState({vat_number: e.target.value})}></input>
								</div>
							</div>
						}
						{/* Make account / password */}
						<div>
							<MakeText style={{fontSize: largeText}} fontFamily={'Arno Pro'}>Maak account aan{cartHasSubcriptionItem ? '' : ' (optioneel)'}</MakeText>
							{/* Wachtwoord */}
							<div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Wachtwoord</MakeText>
								<input data-testId='password' type="password" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.password} onChange={(e) => this.setState({password: e.target.value})}></input>
							</div>
							{/* Herhaal wachtwoord */}
							<div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Wachtwoord bevestigen</MakeText>
								<input data-testId='confirmPassword' type="password" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.repeatPassword} onChange={(e) => this.setState({repeatPassword: e.target.value})}></input>
							</div>
							{/* Birth date */}
							<div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Geboortedatum (dd-mm-yyyy)</MakeText>
								<input type="text" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.birth_date} onChange={(e) => this.setState({birth_date: e.target.value})}></input>
							</div>
						</div>
						{/* Error text */}
						{this.state.errorText && <MakeText fontFamily={'Open Sans'} style={{fontSize: smallText, color: 'red', marginTop: 10}}>{this.state.errorText}</MakeText>}
						{this.state.userExists &&
							<div style={{marginTop: 10}}>
								<MakeText style={{fontSize: smallText}}>Klik </MakeText><a style={{fontFamily: 'Open Sans', fontSize: smallText, textDecoration: 'underline', cursor: 'pointer'}} onClick={() => this.props.navigation.navigate('pickLogin')}>hier</a><MakeText style={{fontSize: smallText}}> om in te loggen of klik </MakeText>
								<a onClick={() => window.open('/wachtwoordVergeten')} style={{fontFamily: 'Open Sans', fontSize: smallText, textDecoration: 'underline', cursor: 'pointer'}}>hier</a><MakeText style={{fontSize: smallText}}> als je je wachtwoord bent vergeten.</MakeText>
							</div>
						}
					</div>
				</div>
				<div style={{width: '100%', display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginBottom: mobileView ? 25 : 0}}>
					<Button testID='previousPage' onPress={() => this.props.navigation.navigate('pickLogin')} color={'greenWhite'} text={'Terug'} textStyle={{fontSize: mobileView ? 15 : 18, height: mobileView ? undefined : 50, fontFamily: 'Open Sans'}} style={{marginLeft: mobileView ? 25 : 0}} />
					<Button testID='nextPage' style={{marginRight: mobileView ? 25 : 0}} textStyle={{fontSize: smallText, fontFamily: 'Open Sans', height: mobileView ? undefined : 50}} text={'Naar adresgegevens'} onPress={() => this.prepareRegisterRequest(cartHasSubcriptionItem)} color={'greenWhite'} />
				</div>
			</div>
        );
    }
}
