import * as React from 'react';
import { SceneView } from "@react-navigation/core";

import arrowUp from './../../../images/popup_arrow.svg'; // Tell Webpack this JS file uses this image

import './OrderProcess.css';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleOrderDrawer } from './../../../store/actions/ProductActions';
import { watchResize, calculatePanelWidth, SHOPPING_CART_HEIGHT } from '../../../Util/screen/HandleLayout';
import OrderProcessNavbar from '../../OrderProcessnew/OrderProcessNavbar/OrderProcessNavbar';

class WebDisplayWindow extends React.Component<any> {

	state = {
		width: 0,
		height: 0,
	}

	componentDidMount() {
		watchResize( result => {
			this.setState(result);
		}, this.state.width);
	}

	render() {
		let rotateArrow = this.props.product.maxHeight === 0 ? '' : 'rotateArrow';

		const { descriptors, navigation } = this.props;
		const activeKey = navigation.state.routes[navigation.state.index].key;
		const descriptor = descriptors[activeKey];
		const panelOpen = this.props.product.maxHeight === SHOPPING_CART_HEIGHT;
		const panelProps = !panelOpen ? {onClick: () => this.props.toggleOrderDrawer()} : {};
		const arrowProps = panelOpen ? {onClick:  () => this.props.toggleOrderDrawer()} : {};
		// console.log(navigation);
		const headerText = (width: number) : JSX.Element => {
            return (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <div style={{width: width * 2, marginLeft: 50, display: 'flex', justifyContent: 'center'}}>
						<OrderProcessNavbar navigation={descriptor.navigation} panelOpen={panelOpen} activeStep={this.props.orderProcess.activeStep} />
					</div>
                </div>
            );
		}

	    return (

			<div className="collapsible" style={{backgroundColor: this.props.product.maxHeight === 0 ? '#E3F0E4' : 'white'}}>
				<div className={`row ${!panelOpen && 'openBar'}`} style={{marginBottom: 20}} data-testid='orderProcess' {...panelProps}>
					<div className="headerFont" style={{width: calculatePanelWidth(this.state.width) * 2}}>{headerText(calculatePanelWidth(this.state.width))}</div>

					<div className="floatRight" >
						<img className={"upArrow " + rotateArrow} {...arrowProps} src={arrowUp} alt="Toggle window" width="24" height="14" />
					</div>
				</div>

				{/* This window can be toggled on or off. The maxHeight value is stored in the products store. */}
				{this.props.product.products.length ?
					<div style={{height: this.props.product.maxHeight}} className="content">
						{/* The page is rendered inside the SceneView */}
						<SceneView
							component={descriptor.getComponent()}
							navigation={descriptor.navigation}
						/>
					</div>
				:
					<div style={{height: this.props.product.maxHeight}} className="content">
						{/* Als de winkelwagen leeg is, laat dan dit bericht zien. */}
						{/* console.log(this.props) */}
							<p style={{ margin: 50, padding: 50, textAlign: 'center', fontFamily: 'Open Sans' }}>Je bloemenmand is op dit moment leeg. Klik bovenaan op webshop. </p>
								{/*Begin <a className="startShopping" onClick={() => this.props.mainNavigation.navigate('webshop')}>hier</a> met shoppen!</p>()*/}
					</div>
				}

			</div>
	    );
	}
};

const mapStateToProps = (state: any) => {
    const { product, orderProcess } = state
    return { product, orderProcess }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
		toggleOrderDrawer
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(WebDisplayWindow);
