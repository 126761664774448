import moment from 'moment';
import 'moment/locale/nl';
import * as React from 'react';
import {ActivityIndicator, Image, ScrollView, View} from 'react-native';
import {getSubscriptionsForUser, updateSubscriptionStatus} from "../../API/Http/Requests";
import {SubscriptionRepetition} from "../../API/Interfaces/Enums/SubscriptionRepetition";
import {SubscriptionStatusType} from "../../API/Interfaces/Enums/SubscriptionStatusType";

import globalStyles from '../../Styles/styles';
import {watchResize} from '../../Util/screen/HandleLayout';
import statusToMessage from '../../Util/statusToMessage';
import OrderData from '../Account/OrderData';
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';
import Dash from '../CrossPlatform/Dash/Dash';
import accountStyles from './../Account/AccountStyles';
import subStyles from './styles';

export default class SubscriptionDetails extends React.Component<any> {

	state = {
		subscription: undefined as any,
		orders: undefined as any,
		width: 0,
		height: 0,

		// Message displayed when attempting to change the status of a subscription.
		statusSwitchMessage: '',
		// The color of the statusSwitchMessage. Green for succes, red for an error.
		statusSwitchMessageColor: 'green',
	}

	componentDidMount() {
		watchResize( result => {
			this.setState(result);
		}, this.state.width);

		// Get the id from  the url.
		let id = this.props.navigation.getParam('id');

		getSubscriptionsForUser(id).then( subscription => {
			// Filter the list of subs and get the selected one based on the id.
			// let subscription = subscriptions.filter(sub => sub.id ==  id)[0];
			// Set that sub to the state.
			this.setState({subscription, orders: subscription.orders});
		});
	}

	async pauseSubscription(id: number) : Promise<void> {
		let newStatus = SubscriptionStatusType.PAUSED;
		if (this.state.subscription.status === SubscriptionStatusType.PAUSED) {
			newStatus = SubscriptionStatusType.IN_PROGRESS;
		}
		try {
			await updateSubscriptionStatus(id, newStatus);
			this.setState({statusSwitchMessage: `Jouw abonnement is ${newStatus === SubscriptionStatusType.PAUSED ? 'gepauzeerd' : 'vervolgd'}`, statusSwitchMessageColor: 'green'})
		}
		catch(e) {
			this.setState({statusSwitchMessage: 'Er is iets misgegaan, uw abonnement is nog actief.', statusSwitchMessageColor: 'red'})
			console.log(e);
		}
	}

	async cancelSubscription(id: number) : Promise<void> {
		try {
			await updateSubscriptionStatus(id, SubscriptionStatusType.CANCELLED);
			this.setState({statusSwitchMessage: 'Jouw abonnement is geannuleerd, er worden geen nieuwe orders meer aangemaakt.', statusSwitchMessageColor: 'green'})
		}
		catch(e) {
			this.setState({statusSwitchMessage: 'Er is iets misgegaan, uw abonnement is nog actief.', statusSwitchMessageColor: 'red'})
			console.log(e);
		}
	}

	getDeliveryTime(): string {
		if (!this.state.subscription.windowstart) {
			return '09:00 en 18:00';
		}

		return(
			moment(this.state.subscription.windowstart).format('H:ss')
			+ ' en ' +
			moment(this.state.subscription.windowend).format('H:ss')
		);
	}

	getRepetition(rep: SubscriptionRepetition): string {
		switch(rep) {
			case SubscriptionRepetition.SINGLE:
				return ''
			case SubscriptionRepetition.WEEKLY:
				return 'Wekelijks op';
			case SubscriptionRepetition.BIWEEKLY:
				return 'Tweewekelijks op';
			case SubscriptionRepetition.MONTHLY:
				return '1x per 4 weken op';
			default:
				return '';
		}
	}

	render() {
		moment.locale('nl');

		console.log(this.state.subscription);

		let styles = subStyles(this.state.width);

		if(!this.state.subscription || !this.state.orders) {

			return (
                    <View style={styles.main}>

						<View>
							<MakeText fontFamily="Crimson Text" style={[globalStyles.h2, globalStyles.alignSelfCenter]}>Membership Details</MakeText>
						</View>

						<View style={styles.dash}>
							<Dash />
						</View>

                        <View style={{ marginVertical: 26 }}>
                            <ActivityIndicator size="large" color="#B9DCBF" />
                        </View>

                    </View>
			);
		}

		const sub = this.state.subscription;

		let text = '';
		if(moment(sub.nextdelivery).diff(moment()) <= 0) {
			text = 'geleden';
		}

		console.log(sub);

		return (
            <ScrollView contentContainerStyle={styles.main}>

				{/* The title and dashed line */}
                <View>
                    <MakeText fontFamily="Crimson Text" style={[globalStyles.h2, globalStyles.alignSelfCenter]}>Membership details</MakeText>
                </View>

				<View style={styles.dash}>
					<Dash />
				</View>

				{/* The page content is displayed in here. */}
				<View style={styles.content}>

					{/* The left side with the image, only displayed on a wide screen. */}
					<View style={styles.left}>
						<Image style={styles.image} source={{uri: `https://theff.nl/services/media/1a6eaa470c7d3699d530fa950aa6eb659eaa74c4260ca76f2def4842289ebc7d/${this.state.subscription.package.image}`}} />
					</View>

					{/* The right side of the screen. On a small screen: the entire screen. */}
					<View style={styles.right}>
						<MakeText style={[accountStyles.defaultAccountFont, {fontWeight: 'bold', marginTop: -10, textTransform: 'capitalize'}]} fontFamily="Open Sans">{sub.category} - {sub.size}</MakeText>
						<MakeText style={accountStyles.defaultAccountFont} fontFamily="Open Sans Italic">
							{this.getRepetition(sub.repetition)} {moment(sub.windowStart).format('dddd')}
							{' tussen'} {this.getDeliveryTime()}
						</MakeText>

						{(this.state.subscription.status !== 'cancelled' && this.state.subscription.status !== 'paused') &&
							<View style={globalStyles.row}>
								<MakeText style={accountStyles.defaultAccountFont} fontFamily="Open Sans Italic">Volgende levering over</MakeText>
								<MakeText style={[accountStyles.defaultAccountFont, accountStyles.Persoonsgegevens]}> {moment.duration(moment(sub.nextdelivery).diff(moment())).humanize()} {text}</MakeText>
							</View>
						}

						<MakeText style={accountStyles.defaultAccountFont} fontFamily="Open Sans Italic">{sub.location.street} {sub.location.housenumber}</MakeText>

						<View style={globalStyles.row}>
							<MakeText style={accountStyles.defaultAccountFont} fontFamily="Open Sans Italic">Status: </MakeText>
							<MakeText style={[accountStyles.defaultAccountFont, accountStyles.Persoonsgegevens]}>{statusToMessage(sub.status)}</MakeText>
						</View>

						<View style={[styles.dash, styles.rightDash]}>
							<Dash />
						</View>

						<View>
							<View style={styles.memershipDetailsHeaderMargin}>
								<MakeText fontFamily="Crimson Text" style={[globalStyles.h3]}>Bijbehorende orders</MakeText>
							</View>
							{this.state.orders &&
								<OrderData width={600} height={this.props.height} navigation={this.props.navigation} orders={this.state.orders} />
							}
						</View>

					</View>

				</View>

				{this.state.width > 1100 ?

					<View style={[globalStyles.row]}>

						<View style={[styles.buttonViewMargin, {flex: 2}]}>
							<Button testID='membershipBack' text="Terug" color="greenWhite" route='/account' onPress={() => this.props.navigation.navigate('Account')}
							style={{marginTop: 30, marginBottom: 33}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} />
						</View>

						<View style={[globalStyles.row, styles.buttonViewMargin, {flex: 3.5}]}>
							{this.state.subscription.status !== 'cancelled' &&
								<Button testID='cancel' text="Annuleren" color="blackOpacity" onPress={() => this.cancelSubscription(sub.id)}
								style={{alignSelf: 'center', marginTop: 30, marginBottom: 33}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17, marginRight: 19}} />
							}
							{this.state.subscription.status !== 'cancelled' &&
								<Button text={this.state.subscription.status === 'paused' ? 'Vervolgen' : "Pauzeren"} color="blackOpacity" onPress={() => this.pauseSubscription(sub.id)}
								style={{alignSelf: 'center', marginTop: 30, marginBottom: 33}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}}/>
							}
						</View>
					</View>

				:

					<View>
						<View style={[globalStyles.row, globalStyles.alignSelfCenter]}>
							<Button testID='cancel' text="Annuleren" color="blackOpacity" onPress={() => this.cancelSubscription(sub.id)}
							style={{alignSelf: 'center', marginTop: 30, marginBottom: 17}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17, marginRight: 19}} />
							<Button text="Pauzeren" color="blackOpacity" onPress={() => this.pauseSubscription(sub.id)}
							style={{alignSelf: 'center', marginTop: 30, marginBottom: 17}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}}/>
						</View>

						<View style={[globalStyles.alignSelfCenter]}>
							<Button testID='membershipBack' text="Terug" color="greenWhite" route='/account' onPress={() => this.props.navigation.navigate('Account')}
							style={{marginBottom: 33}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} />
						</View>
					</View>

				}

				<View style={globalStyles.alignSelfCenter}>
					{this.state.statusSwitchMessage.length ?
						<MakeText style={[styles.statusSwitchMessage, {backgroundColor: this.state.statusSwitchMessageColor}]}>{this.state.statusSwitchMessage}</MakeText>
						:
						undefined
					}
				</View>

			</ScrollView>
		)
	}
}
