import React from 'react';
import ReactDom from 'react-dom';

import App from './App';

import './index.css';

import { hotjar } from 'react-hotjar';
hotjar.initialize(815028, 6);

require(`./assets/LoadFonts/OpenSans/OpenSans`);
require(`./assets/LoadFonts/OpenSansLight/OpenSansLight`);
require(`./assets/LoadFonts/OpenSansItalic/OpenSansItalic`);
require(`./assets/LoadFonts/OpenSansLightItalic/OpenSansLightItalic`);
require(`./assets/LoadFonts/CrimsonText/CrimsonText`);
require(`./assets/LoadFonts/OpenSansSemiBold/OpenSansSemibold`);
require('./assets/LoadFonts/ArnoPro/ArnoPro');

ReactDom.render(<App />, document.getElementById('root'));

// import { AppRegistry } from "react-native";
// import App from "./App";

// AppRegistry.registerComponent("App", () => App);

// AppRegistry.runApplication("App", {
//   rootTag: document.getElementById("root")
// });
