import * as React from 'react';

import BasicPage from './Template/BasicPage';
import LoginPage from './../Components/Login/LoginPage';

export default (props: any) =>  (
	<BasicPage navigation={props.navigation}>
		<LoginPage />
	</BasicPage>
);
