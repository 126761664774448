import * as React from 'react';
import PrivacyHtml from '../../assets/privacy'
import WebView from '../CrossPlatform/WebView/WebView';

export default (props: any) => {
    return (
        <WebView
            html={PrivacyHtml.html}
            width={props.width} height={props.height * 0.9}
            filter={() => undefined}
        />
    )
}