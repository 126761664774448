import * as React from 'react';
import GroenOpKantoorText from '../../text/home/GroenOpKantoor/GroenOpKantoorText';
import { phone } from '../../Util/screen/HandleLayout';
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';

export default (props: any) => {
    // Scale height relative to the screen height 900 that the figma has
    const bannerHeightFigma = 535;
    const screenWidthFigma = 1600;
    const mobileView = phone(props.width);
    const h = mobileView ? 200 :  bannerHeightFigma * props.width/screenWidthFigma;

    const styles = {
        container: {
            position: 'relative'
        } as React.CSSProperties,
        image: {
            height: h,
            width: '100%',
            objectFit: 'cover',
        } as React.CSSProperties,
        gradient: {
            position: 'absolute',
            top: 0,
            height: h,
            width: '70%',
            backgroundImage: 'linear-gradient(to right, rgba(255,255,255,0.5), rgba(255,255,255,0))',
        } as React.CSSProperties,
        textContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            height: h,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
            marginLeft: '10%'
        } as React.CSSProperties,
        mobileTextContainer: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            margin: 25
        } as React.CSSProperties,
    };
    const text = GroenOpKantoorText;
    const titleSize = mobileView ? 24 : 36;
    const textSize = mobileView ? 15 : 24

    return (
        <div style={styles.container}>
            <img src={'/images/boekettenKantoor.jpg'} style={styles.image} />
            <div style={styles.gradient} />
            {mobileView &&
                <div className='col' style={{position: 'absolute', height: h, width: '60%', top: 0, left: 10, alignItems: 'center', justifyContent: 'center'}}>
                    <MakeText fontFamily='Arno Pro' style={{fontSize: titleSize, letterSpacing: 2.2}}>{text.title}</MakeText>
                </div>
            }
            <div style={mobileView ? styles.mobileTextContainer : styles.textContainer}>
            {!mobileView && <MakeText fontFamily='Arno Pro' style={{fontSize: titleSize, textAlign: 'center', width: '100%', letterSpacing: 2.2}}>{text.title}</MakeText>}
                <MakeText fontFamily='Open Sans' style={{fontSize: textSize, marginTop: 30}}>{text.body1}</MakeText>
                <MakeText fontFamily='Open Sans' style={{fontSize: textSize, marginTop: 15}}>{text.body2}</MakeText>
                <MakeText fontFamily='Open Sans' style={{fontSize: textSize, marginTop: 15}}>{text.body3}</MakeText>
                <Button
                    color={'greenWhite'}
                    style={{marginTop: 50, alignSelf: 'center'}}
                    text={text.button}
                    textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: mobileView ? 14 : 16, paddingLeft: 40, paddingRight: 40}}
                    onPress={() => props.navigation.navigate('TFF for business')}
                    route='/kantoor'
                />
            </div>
        </div>
    );
}