import * as React from 'react';

import BasicPage from './Template/BasicPage';
import { Helmet } from "react-helmet";
import Assortiment from '../Components/Assortiment/Assortiment';

export default class AssortimentContainer extends React.Component<any> {

    render() {

        const jsonld = {
            "@context": "http://schema.org/",
            "@type": "Webpage",
            "name": "The Flower Family - webshop",
            "description": "Bestel hier je bloemen van The Flower Family!",
            "url": "https://www.theflowerfamily.nl/webshop"
        };

        return (
            <BasicPage navigation={this.props.navigation}>
                <Helmet>
                    <title> The Flower Family - webshop </title>
                    <link rel="canonical" href="https://www.theflowerfamily.nl/webshop" />
                    <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
                </Helmet>
	           <Assortiment />
			</BasicPage>
        );
    }
}
