import * as React from 'react';
import { View, Image, TouchableOpacity, StyleSheet } from 'react-native';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toggleOrderDrawer } from './../../store/actions/ProductActions';
import MakeText from '../Common/MakeText/MakeText';
import { orderProcessMobile, phone } from '../../Util/screen/HandleLayout';

class LanguageSwitcher extends React.Component<any> {

    render() {

		const mobileView : boolean = phone(this.props.width);
		const mobileOrderProcess = orderProcessMobile(this.props.width, this.props.height);

		const styles = StyleSheet.create({
			bigView: {
				position: 'absolute',
				top: 1,
				right: 0,
				left: 20,
				bottom: 0,
			},
			smallView: {
				position: 'absolute',
				top: 0,
				right: 0,
				left: 17,
				bottom: 0,
			}
		});

        return (
			<View style={{margin: 10}}>
				<TouchableOpacity testID={this.props.testID} onPress={mobileOrderProcess ? () => this.props.navigate('overview') : this.props.toggleOrderDrawer}>
					{/* Het plaatje van het winkelmandje. */}
					<Image resizeMode="contain" source={require('./../../images/ShoppingCart.svg')} style={{ position: 'relative', top: 5, height: 30, width: 30}}></Image>

					{/* De hoeveelheid producten die zijn toegevoegd aan het winkelmandje. */}
					{this.props.product.products.length ?
						<MakeText style={[mobileView ? styles.smallView : styles.bigView, {width: 20, height: 20,  color: 'white', backgroundColor: '#B9DCBF', textAlign: 'center', borderRadius: 25, fontSize: 13, lineHeight: 18, fontWeight: '600'}]}>
							{this.props.product.products.length}
						</MakeText>
					: null
					}
				</TouchableOpacity>
			</View>
        );
    }
}

const mapStateToProps = (state: any) => {
    const { product } = state
    return { product }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        toggleOrderDrawer,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(LanguageSwitcher);
