import * as React from 'react';

const getUrl = (partial: string) => {
    if (/^(https)/g.test(partial)) {
        return partial;
    } else {
        return 'https://theff.nl/services/media/1a6eaa470c7d3699d530fa950aa6eb659eaa74c4260ca76f2def4842289ebc7d/' + partial;
    }
}

export default class ProductImages extends React.Component<{images: string[], mobileView: boolean, category: string}> {
    state = {
        selectedIndex: 0,
    }

    render() {
        const images = this.props.images;
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}>
                <img src={getUrl(images[this.state.selectedIndex])} style={{width: '100%', height: this.props.mobileView ? 350 : 800, objectFit: this.props.category.substring(0, 7) === 'E-Flora' ? 'contain' : 'cover'}} />
                {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                    {images.map(i => <img src={`https://theff.nl/services/media/1a6eaa470c7d3699d530fa950aa6eb659eaa74c4260ca76f2def4842289ebc7d/${i}`} style={{width: 250, height: 250, objectFit :'cover', margin: 20}} />)}
                </div> */}
            </div>
        )
    }
}