import * as React from 'react';

import BasicPage from './Template/BasicPage';
import Contact from '../Components/Contact/Contact';
import { Helmet } from "react-helmet";

export default class About extends React.Component<any> {

    render() {

        const jsonld = {
            "@context": "http://schema.org/",
            "@type": "Webpage",
            "name": "Contact",
            "description": "Wil je meer weten over onze missie, de herkomst van onze bloemen of je membership? Of heb je een andere vraag?",
            "url": "https://www.theflowerfamily.nl/contact"
        };

        return (
            <BasicPage navigation={this.props.navigation}>
                <Helmet>
                    <title> Contact </title>
                    <link rel="canonical" href="https://www.theflowerfamily.nl/contact" />
                    <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
                </Helmet>
				<Contact />
			</BasicPage>
        );
    }
}
