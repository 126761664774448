export const addProduct = (product : any) => (
	{
		type: 'ADD_PRODUCT',
		payload: product,
	}
);

export const removeProduct = (id : number) => (
	{
		type: 'REMOVE_PRODUCT',
		payload: id,
	}
);

export const setProducts = (products : any) => (
	{
		type: 'SET_PRODUCTS',
		payload: products,
	}
);

export const updateProduct = (product : any) => (
	{
		type: 'UPDATE_PRODUCT',
		payload: product,
	}
);

export const setProductAmount = (productIdWithAmount : any) => (
	{
		type: 'SET_PRODUCT_AMOUNT',
		payload: productIdWithAmount,
	}
);

export const toggleOrderDrawer = (maxHeight : string) => (
	{
		type: 'TOGGLE_DRAWER',
		payload: maxHeight,
	}
);
