import * as React from 'react';
import { Image, View, StyleSheet} from 'react-native';
import Button from '../../CrossPlatform/Button/Button'
import MakeText from '../MakeText/MakeText';
import { calculateMargin, phone } from '../../../Util/screen/HandleLayout';
import { routingName } from '../../CrossPlatform/OrderProcess/NewOrderProcess.web';

export default (props: any) => {

	const mobileView: boolean = phone(props.width);

	const height = mobileView ? 100 : props.height * 0.3;

    const styles = StyleSheet.create({

        doeMee: {
            width: '100%',
            height: height,
        },
        doeMeeTekst: {
            position: 'relative',
            textAlign: 'center',
            color: '#414141',
            textShadowColor: 'rgba(255, 255, 255, 0.71)',
            textShadowOffset: {width: 0, height: 2},
            textShadowRadius: 21,
            fontSize: props.width < 900 ? props.width < 630 ? props.width < 380 ? 8 : 15 : 25 : 36,
            lineHeight: 42,
            letterSpacing: 4.2,
            bottom: height / 1.5,
        },
        bekijkBloemenButton: {
            position: 'relative',
            alignSelf: 'center',
            width: 200,
            bottom: height / 1.5 - (mobileView ? 0 : 19),
        },
    });

    const margin = calculateMargin(props.width);

    return (
            <View style={{height, width: props.width - 2 * margin, marginLeft: margin}}>
                <Image source={require('./../../../images/doeMee.jpg')} style={styles.doeMee}/>
                {(!mobileView && !props.noText) && <MakeText fontFamily='Arno Pro' style={styles.doeMeeTekst}>WEKELIJKS NIEUWE STIJLVOLLE BOEKETTEN</MakeText>}
                {(!props.noText && routingName !== "amsterdam") &&
                    <View style={styles.bekijkBloemenButton}>
                        <Button color={ "greenWhite" } text="Bekijk onze bloemen" route='/webshop' onPress={() => props.navigation.navigate('webshop')} style={{alignSelf: 'center', width: 230}} textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: 14, letterSpacing: 2}} />
                    </View>
                }
            </View>
    );
}
