import * as React from 'react';
import { View, TouchableOpacity } from 'react-native';
import MakeText from '../Common/MakeText/MakeText';

import styles from './AccountStyles';
import globalStyles from '../../Styles/styles';

interface IAccountItemHeader {
	title: string;
	onPress?: Function;
	message? : string;
	onPress2?: () => void;
	message2?: string;
	testID?: string;
	showMessage2?: boolean;
}

export default (props: IAccountItemHeader) => (
	<View style={globalStyles.row}>
		<MakeText testID={`${props.testID}-title`} style={[styles.defaultAccountFont, {fontWeight: 'bold'}]}>{props.title}</MakeText>
		{props.onPress && <TouchableOpacity testID={props.testID} style={{marginLeft: 10}}  onPress={() => (props as any).onPress()}>
			<MakeText style={[styles.detailLink, {marginTop: 3}]}>{props.message ? props.message : ''}</MakeText>
		</TouchableOpacity>}
		{(props.onPress2 && props.message2 && props.showMessage2) &&
			<TouchableOpacity style={{marginLeft: 6}}  onPress={props.onPress2}>
				<MakeText style={[styles.detailLink, {marginTop: 3}]}>{props.message2}</MakeText>
			</TouchableOpacity>
		}
	</View>
);
