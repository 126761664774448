import { IAddress, IDeliveryWindow, IOrder, IPayment, ICreateOrderOrder } from "../actions/OrderProcessActions";
import * as Actions from './../ActionTypes';

interface IState {
    deliveryAddress: IAddress;
    billingAddress: IAddress;
    deliveryWindow: IDeliveryWindow;
    orders: IOrder[];
    payment: IPayment;
	createOrderOrder: ICreateOrderOrder;
    deliveryText: any;
    deliveryNote: string;
    po_number: string | undefined;
	titles: {
        left: string;
        right: string;
        center: string;
	};
	totalPrice: number;
	discount: number;
    shippingCosts: number;
    originalPrice: number;
    activeStep: string;
}

const INITIAL_STATE: IState = {
	deliveryAddress: {
		id: 0,
        postalcode: '',
        street: '',
        housenumber: '',
        city: '',
        phone: '',
        isPublicLocation: false,
    },
    billingAddress: {
		id: 0,
        postalcode: '',
        street: '',
        housenumber: '',
        city: '',
        phone: '',
        isPublicLocation: false,
    },
    deliveryWindow: {
        day: 0,
        deliveryTimes: -1,
        deliveryDate: 0,
        recurring: '',
    },
	createOrderOrder: {
	    product: 0,
        packageId: 0,
        delivery: {
            day: 0,
            date: 0,
            windowstart: 0,
            windowend: 0,
            comment: '',
            ticket: ''
        },
		// de type is de repitition.
	    type: 'weekly',
	    amount: 0,
	    vase: false,
	},
    orders: [],
    payment: {
        coupon: '',
        paytype: 'ideal',
		issuer: '',
		// ing: ideal_INGBNL2A,
		// ASN: ideal_ASNBNL21,
		// ABNAMRO: ideal_ABNANL2A,
    },
    deliveryText: {},
    deliveryNote: '',
    po_number: undefined,
	titles: {
		left: '',
		right: '',
		center: 'Mijn bestelling',
    },
    activeStep: 'overview',
	totalPrice: 0,
	discount: 0,
    shippingCosts: 0, // 7.10,
    originalPrice: 0,
};

const OrderProcessReducer = (state = INITIAL_STATE, action: any) => {
	switch (action.type) {

		case Actions.setBillingAddress:
			let newState = {...state};
            newState.billingAddress = action.payload;
			return newState;

		case Actions.setDeliveryAddress:
            let newState2 = {...state};
            newState2.deliveryAddress = action.payload;
            return newState2;

		case Actions.setDeliveryWindow:
            let newState3 = {...state};
            newState3.deliveryWindow.day = action.payload.day;
			newState3.deliveryWindow.deliveryDate = action.payload.deliveryDate;
			newState3.deliveryWindow.deliveryTimes = action.payload.deliveryTimes;
			newState3.deliveryWindow.recurring = action.payload.recurring;
            newState3.createOrderOrder.type = action.payload.recurring;
            return newState3;

        case Actions.setDeliveryNote:
            const newStateMofo = {...state};
            newStateMofo.deliveryNote = action.payload;
            return newStateMofo;

		case Actions.setDeliveryText:
			let newState11 = {...state};
			newState11.deliveryText = action.payload;
			return newState11;

		case Actions.setCreateOrderOrder:
			let newState9 = {...state};
			newState9.createOrderOrder = action.payload;
            return newState9;

        case Actions.setActiveStep:
            let newState69 = {...state};
            newState69.activeStep = action.payload;
            return newState69;

        case Actions.addOrder:

			// If order already exists in state.
            for(const order of state.orders) {
                if(order.product === action.payload.product) {
                    return state;
                }
            }
            let newState4 = {...state};
            newState4.orders.push(action.payload);
            return newState4;

		case Actions.setOrders:

			let newState10 = {...state};
			newState10.orders = []
			newState10.orders = action.payload.products;

			action.payload.callback(newState10.orders);

			return newState10;

        case Actions.updateOrder:
            let newState5 = Object.assign({}, state);
            newState5.orders = state.orders.map((el) => {
                if(el.product === action.payload.product) {
                    return action.payload;
                } else {
                    return el;
                }
            });
            return newState5;

        case Actions.removeOrder:
            let newState6 = Object.assign({}, state);
            let index = newState6.orders.findIndex((order) => action.payload.product === order.product)
            if(index === -1) { return state; }
            newState6.orders.splice(index, 1);
            return newState6;

        case Actions.updateCoupon:
            let newState7 = Object.assign({}, state);
            newState7.payment.coupon = action.payload;
            return newState7;

        case Actions.updatePaymentType:
            let newState8 = Object.assign({}, state);
            newState8.payment.paytype = action.payload;
            return newState8;

		case Actions.setLeftTitle:
			let newState12 = {...state};
            newState12.titles.left = action.payload;
			return newState12;

		case Actions.setRightTitle:
			let newState13 = {...state};
            newState13.titles.right = action.payload;
			return newState13;

		case Actions.setCenterTitle:
			let newState14 = {...state};
            newState14.titles.center = action.payload;
			return newState14;

		case Actions.setTotalPrice:
			let newState15 = {...state};
            newState15.totalPrice = action.payload;
			return newState15;

		case Actions.setDiscount:
			let newState16 = {...state};
            newState16.discount = action.payload;
			return newState16;

        case Actions.setShippingCosts:
			let newState17 = {...state};

			// Make sure the shipping const is at least 7.10.
			if (action.payload === 0) {
                newState17.shippingCosts = 8.10;
                return newState17;
            }

            newState17.shippingCosts = action.payload;
            return newState17;

        case Actions.setOriginalPrice:
            let newState18 = { ...state };
            newState18.originalPrice = action.payload;
            return newState18;

        case Actions.setPONumber:
            let newState19 = { ...state };
            newState19.po_number = action.payload;
            return newState19;

    	default:
      		return state
  	}
};

export default OrderProcessReducer;
