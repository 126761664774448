import * as React from 'react';
import { View, ActivityIndicator } from 'react-native';
import MakeText from '../Common/MakeText/MakeText';
import moment from 'moment';

import styles from './AccountStyles';
import { getMe } from '../../API/Http/Requests';
import { TffPointsStatusType } from '../../API/Interfaces/Enums/PointsStatus';
import { TffPoints } from '../../API/Interfaces/TFFPoints';

export default class TFFExclusiveData extends React.Component<any, {finishedLoading: boolean, totalPoints: number, amountSpent: number, nextExpiration: TffPoints | undefined, availablePoints: number}> {

	state = {
        finishedLoading: false,
        totalPoints: 0,
        amountSpent: 0,
        nextExpiration: undefined as any,
        availablePoints: 0,
	}

	componentDidMount() {
		getMe().then( user => {
            let totalPoints = 0;
            let availablePoints = 0;
            let amountSpent = 0;
            let nextExpiration: any = undefined;
			user.points.forEach(p => {
				if (p.status === TffPointsStatusType.AVAILABLE) {
                    availablePoints += p.amount - p.amountSpent;
                }
                if (p.status !== TffPointsStatusType.CANCELLED) {
                    totalPoints += p.amount;
                }
                amountSpent += p.amountSpent;
                if (nextExpiration === undefined || moment(p.expirationDate).isBefore(nextExpiration.expirationDate)) {
                    nextExpiration = p;
                }
			})
			this.setState({totalPoints, availablePoints, amountSpent, nextExpiration, finishedLoading: true});
		})
	}

	render() {
        if (!this.state.finishedLoading) {
            return (
                <View style={{ flexDirection: 'column', alignItems: 'center', margin: 5, width: this.props.width }}>
                    <View style={{ marginTop: 20, marginBottom: 20 }}>
                        <ActivityIndicator size="large" color="#B9DCBF" />
                    </View>
                </View>
            );
        }

        const nextExp = this.state.nextExpiration as TffPoints;
        const nextExpirationString = nextExp ? moment(nextExp.expirationDate).format('DD-MM-YYYY') : '';
        const nextExpirationAmount = nextExp ? nextExp.amount - nextExp.amountSpent : 0;

		return (
            <View style={styles.adresBottomMargin}>
                <MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Huidig puntentegoed: {this.state.availablePoints}</MakeText>
                {nextExp &&
                    <MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Op {nextExpirationString} verlopen {nextExpirationAmount} punten</MakeText>
                }
                <MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Totale punten gespaard: {this.state.totalPoints}</MakeText>
                <MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Totaal verzilverd: {this.state.amountSpent}</MakeText>

                {this.state.totalPoints < 40 &&
                    <div className='mt-2'>
                        <MakeText style={styles.defaultAccountFont}>Je hebt niet genoeg punten om een mooi cadeau te verzilveren. Het minimum is 40. <a href='/bestel' style={{textDecoration: 'underline'}}>Koop hier wat moois</a> en spaar zo voldoende punten!</MakeText>
                    </div>
                }
            </View>
		)
	}
}
