import * as React from 'react';
import validator from 'validator';

import {addLocationToMe, getMe} from "../../API/Http/Requests";
import { ILocation, shippingCountries } from '../../API/IDataService';
import checkZipCode from '../../Util/validation/ZipCodeChecker';
import Button from '../CrossPlatform/Button/Button';
import MakeText from '../Common/MakeText/MakeText';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';


export default class AddBillingAddress extends React.Component<any> {

	state ={
		billingAddress: {
			id: undefined,
	        postalcode: '',
			street: '',
			addition: '',
	        housenumber: '',
	        city: '',
			fname: '',
			lname: '',
			phone: '',
			country: 'NL',
	    },

		loadingWheel: null,
		errorText: '',
		checkingZipBill: false,
		zipCheckBillingStatus: undefined,
		zipCodeWarningBill: undefined,
		autoCompletedBill: false,
    }

	componentWillMount() {
		this.setState({billingAddress: Object.assign(this.props.store.orderProcess.billingAddress, {country: 'NL'})});
	}

	async componentDidMount() {
		this.props.store.setActiveStep('pickDeliveryAddress');
		GoogleAnalytics.getInstance().hit(this.props.navigation.state.key);
		// Set fname and last name if possible
		try {
			const me = await getMe();
			const bill = this.state.billingAddress;
			bill.fname = me.firstName;
			bill.lname = me.lastName;
			this.setState({billingAddress: bill});
		} catch {}
	}

	nextPage() {
		this.setState({errorText: ''});

		let billingLocation : ILocation = this.createLocation(this.state.billingAddress);

		if(!this.verifyInput(billingLocation)) return this.setErrorText();

        this.sendLocationToBackend(billingLocation, result => {
            billingLocation.id = result.id;
            this.props.store.setBillingAddress(billingLocation);
            this.props.navigation.navigate('pickDeliveryAddress');
        });
	}

	// Send a new location object to the backend.
	sendLocationToBackend(location: any, callback: Function) {
		addLocationToMe(location).then( result => {
			return callback(result);
		}).catch( () => {
			this.setState({errorText: 'Het lijkt erop dat er iets fout gaat. Excuses voor het ongemak! Probeer het later opnieuw.', loadingWheel: null})
		});
	}

	// Remove the loading wheel and set an error text for the user.
	setErrorText() : void {
		return this.setState({errorText: 'Een of meerdere van de velden zijn niet correct ingevuld.', loadingWheel: null});
	}

	/**
	 * Takes ILocation object.
	 * Checks if all required fields have a value.
	 * Returns true if so, false if not.
	 *
	 * @param  location The location for which input is checked.
	 * @return true or false, based on wether the check succeeds.
	 */
	verifyInput(location: ILocation) : boolean {
		if(validator.isLength(location.city, {min: 1, max: 128})
			&& validator.isLength(location.street, {min: 1, max: 128})
			&& validator.isLength(location.housenumber, {min: 1, max: 128})
			&& validator.isLength(location.postalcode, {min: 1, max: 128})
			&& validator.isLength(location.fname, {min: 1, max: 128})
			&& validator.isLength(location.lname, {min: 1, max: 128})
		) return  true;

		return false;
	}

	/**
	 * Creates a location that can be send and saved by the backend.
	 * @return returns a location based on data in the state of the class.
	 */
	createLocation(address: ILocation) : ILocation {
		let location: ILocation = {
			street: address.street,
			housenumber: address.housenumber,
			addition: address.addition,
			postalcode: address.postalcode.replace(' ', ''),
			city: address.city,
			country: address.country,
			fname: address.fname,
            lname: address.lname,
            invoice: true,
		}

		return location;
	}

	onChange(name: string, value: string) : void {
		let autoCompleteKey = 'autoCompletedBill';
		let autoCompleteValue;
		if (name === 'street' || name === 'city') {
			autoCompleteValue = false;
		} else {
			autoCompleteValue = this.state[autoCompleteKey]
		}
		let tmpState = Object.assign({}, this.state.billingAddress);

		tmpState[name] = value;

		this.setState({billingAddress: tmpState, [autoCompleteKey]: autoCompleteValue});
	}

	checkZipCodeBill() {
		if(this.state.billingAddress.postalcode === '' || this.state.billingAddress.housenumber === '') {
			return;
		}
		this.setState({checkingZipBill: true});
		setTimeout(async () => {
			const foundLoc = await checkZipCode(this.state.billingAddress);
			if(foundLoc === undefined) {
				this.setState({zipCheckBillingStatus: 'Geen adres gevonden bij deze postcode en dit huisnummer', checkingZipBill: false, autoCompletedBill: false});
				return;
			}
			this.setState({
				billingAddress: Object.assign(this.state.billingAddress, foundLoc),
				checkingZipBill: false,
				zipCheckBillingStatus: undefined,
				autoCompletedBill: true,
			});
		}, 0);
	}

	checkPostalCode() {
		const postalCode = this.state.billingAddress.postalcode.replace(' ', '');
		const stateKey = 'zipCodeWarningBill';
		if (new RegExp(/^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/, 'i').test(postalCode)) {
			this.setState({[stateKey]: undefined});
		} else {
			this.setState({
				[stateKey]: 'Deze postcode wordt niet herkend.'
			})
		}
	}

    render() {
		// Go back to overview page if the totalprice is 0. This scenario happens when a user refreshes the page on this screen.
		if(this.props.store.orderProcess.totalPrice === 0) {
			this.props.navigation.navigate('overview');
		}
		const mobileView = this.props.mobileView;
		const largeText = mobileView ? 24 : 36;
		// const mediumText = mobileView ? 18 : 24;
		const smallText = mobileView ? 15 : 18;

        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', height: '95%', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '90%' :  475, marginTop: mobileView ? 25 : undefined}}>
                    <MakeText style={{fontSize: largeText, alignSelf: 'center'}} fontFamily={'Arno Pro'}>Factuuradres</MakeText>
                    {/* Name */}
                    <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-between', marginTop: 10}}>
                        {/* First name */}
                        <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
                            <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Voornaam</MakeText>
                            <input type="text" style={{fontSize: smallText, padding: 5}} value={this.state.billingAddress.fname} onChange={(e) => this.onChange('fname', e.target.value)}></input>
                        </div>
                        {/* Last name */}
                        <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
                            <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Achternaam</MakeText>
                            <input type="text" style={{fontSize: smallText, padding: 5}} value={this.state.billingAddress.lname} onChange={(e) => this.onChange('lname', e.target.value)}></input>
                        </div>
                    </div>
                    {/* Postcode en nummer */}
                    <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-between', marginTop: 10}}>
                        {/* Postcode */}
                        <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
                            <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Postcode</MakeText>
                            <input type="text" style={{fontSize: smallText, padding: 5}} onBlur={() => { this.checkPostalCode(); this.checkZipCodeBill();}} value={this.state.billingAddress.postalcode} onChange={(e) => this.onChange('postalcode', e.target.value)}></input>
                        </div>
                        {/* Huisnummer */}
                        <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
                            <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Huisnummer</MakeText>
                            <input type="text" style={{fontSize: smallText, padding: 5}} onBlur={() => { this.checkZipCodeBill();}} value={this.state.billingAddress.housenumber} onChange={(e) => this.onChange('housenumber', e.target.value)}></input>
                        </div>
                    </div>
                    {/* Straat */}
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                        <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Straat</MakeText>
                        <input type="text" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.billingAddress.street} onChange={(e) => this.onChange('street', e.target.value)}></input>
                    </div>
                    {/* Stad */}
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                        <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Stad</MakeText>
                        <input type="text" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.billingAddress.city.replace('&#x27;', "'")} onChange={(e) => this.onChange('city', e.target.value)}></input>
                    </div>
					<div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                        <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Land</MakeText>
                        <select style={{fontSize: smallText, padding: 5, marginTop: 10}} onChange={(e) => this.onChange('country', e.target.value)}>
							{Object.values(shippingCountries).map(c => <option value={c}>{c}</option>)}
						</select>
                    </div>
					{this.state.errorText &&
						<MakeText style={{fontSize: 15, color: 'red', marginTop: 10}}>{this.state.errorText}</MakeText>
					}
					{this.state.zipCheckBillingStatus &&
						<MakeText style={{fontSize: 15, marginTop: 10}}>{this.state.zipCheckBillingStatus}</MakeText>
					}
                </div>
				<div style={{width: '100%', display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginBottom: mobileView ? 25 : 0, marginTop: mobileView ? 50 : 0}}>
					<Button onPress={() => this.props.navigation.navigate('pickDeliveryAddress')} color={'greenWhite'} text={'Terug'} textStyle={{fontSize: mobileView ? 15 : 18, height: mobileView ? undefined : 50, fontFamily: 'Open Sans'}} style={{marginLeft: mobileView ? 25 : 0}} />
					<Button style={{marginRight: mobileView ? 25 : 0}} textStyle={{fontSize: smallText, fontFamily: 'Open Sans', height: mobileView ? undefined : 50}} text={'Voeg adres toe'} onPress={() => this.nextPage()} color={'greenWhite'} />
				</div>
            </div>
        );
    }
}
