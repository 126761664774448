import * as React from 'react';

import { watchResize, calculatePanelWidth } from './../../Util/screen/HandleLayout';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLogin } from './../../store/actions/AppActions';
import { addProduct, removeProduct, setProducts } from './../../store/actions/ProductActions';
import { setActiveStep, setDeliveryAddress, setBillingAddress, setDeliveryWindow, setDeliveryText, updatePayment, updateCoupon, addOrder, setOrders, setLeftTitle, setRightTitle, setCenterTitle, setTotalPrice, setDiscount, setShippingCosts, setOriginalPrice, setPONumber, setDeliveryNote } from './../../store/actions/OrderProcessActions';
import { setProfile } from './../../store/actions/ProfileActions';

import Button from './../../Components/CrossPlatform/Button/Button';

import './style.css';

/**
 * This template recieves two children components and displays them on the left and right side next to each other.
 *
 * When no children props are passed, the element displays nothing.
 *
 * This Component only works on web, not on native.
 *
 * @WARNING: Giving this component less then two children will cause an error.
 */
class NewOrderProcessWebTemplate extends React.Component<any> {

	state = {
		width: 0,
		height: 0,

		open: false,
	}

	componentDidMount() {
		watchResize( result => {
			this.setState(result);
		}, this.state.width);
	}

	/**
	 * Calculate the height of the popup window.
	 * This is done based on height data in the state.
	 */
	calculatePanelHeight() : number {
		return Math.max(this.state.height / 1.7, 800);
	}

	// Displays a back button if specified, and popup is open.
	backButton() : JSX.Element | null {
		if(this.props.back && this.props.product.maxHeight) {
			return (
				<div className="bottom">
					<div className="left"><Button color='greenWhite' text='terug' onPress={() => this.props.navigation.navigate(this.props.back)} /></div>
				</div>
			);
		}

		return null;
	}

	render() {

		if(this.props.product.maxHeight !== 0 && !this.state.open) {
			this.setState({open: true});
		}

		if(this.props.product.maxHeight === 0 && this.state.open) {
			this.props.setLeftTitle('');
			this.props.setRightTitle('');
			this.props.setCenterTitle('Mijn bestelling');

			this.setState({open: false});
		}

		if(!this.props.children) {
			return null;
		}

		const panelWidth: number = calculatePanelWidth(this.state.width);

		const panelHeight = this.calculatePanelHeight();
		// console.log('ready');
		// console.log(this.props.mainNavigation);
		const props = {
			navigation: this.props.navigation,
			back: this.props.back,
			width: panelWidth,
			height: panelHeight,
			store: {
				app: this.props.app,
				profile: this.props.profile,
				product: this.props.product,
				orderProcess: this.props.orderProcess,
				setActiveStep: this.props.setActiveStep.bind(this),
				addProduct: this.props.addProduct.bind(this),
				setProducts: this.props.setProducts.bind(this),
				removeProduct: this.props.removeProduct.bind(this),
				addOrder: this.props.addOrder.bind(this),
				setLogin: this.props.setLogin.bind(this),
				setProfile: this.props.setProfile.bind(this),
				setOrders: this.props.setOrders.bind(this),
				setDeliveryAddress: this.props.setDeliveryAddress.bind(this),
				setBillingAddress: this.props.setBillingAddress.bind(this),
				setDeliveryWindow: this.props.setDeliveryWindow.bind(this),
				setDeliveryText: this.props.setDeliveryText.bind(this),
				setPONumber: this.props.setPONumber.bind(this),
				updatePayment: this.props.updatePayment.bind(this),
				updateCoupon: this.props.updateCoupon.bind(this),
				setLeftTitle : this.props.setLeftTitle.bind(this),
				setRightTitle: this.props.setRightTitle.bind(this),
				setCenterTitle: this.props.setCenterTitle.bind(this),
				setTotalPrice: this.props.setTotalPrice.bind(this),
				setDiscount: this.props.setDiscount.bind(this),
                setShippingCosts: this.props.setShippingCosts.bind(this),
				setOriginalPrice: this.props.setOriginalPrice.bind(this),
				setDeliveryNote: this.props.setDeliveryNote.bind(this),
			}
		}
		return (
			<div className="row" style={{height: '100%'}}>
					{this.props.children && React.cloneElement(this.props.children as React.ReactElement<any>, props)}
			</div>
		);
	}
};

const mapStateToProps = (state: any) => {
    const { product, orderProcess, profile, app } = state
    return { product, orderProcess, profile, app }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
		setLogin,
        addProduct,
		setProducts,
		removeProduct,
		setDeliveryAddress,
		setBillingAddress,
		setDeliveryWindow,
		setDeliveryText,
		setPONumber,
		setActiveStep,
		updatePayment,
		updateCoupon,
		addOrder,
		setOrders,
		setProfile,
		setLeftTitle,
		setRightTitle,
		setCenterTitle,
		setTotalPrice,
		setDiscount,
        setShippingCosts,
		setOriginalPrice,
		setDeliveryNote,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(NewOrderProcessWebTemplate);
