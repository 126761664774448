import * as React from 'react';
import { View } from 'react-native';

// The store
import { connect } from 'react-redux';

import LOC from '../../../Localization/Translations';

import { createNavigator, SwitchRouter } from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";

import Home from '../../../Containers/Home';
import About from '../../../Containers/About';
import Stories from '../../../Containers/Stories';
import Privacy from '../../../Containers/Privacy';
import Voorwaarden from '../../../Containers/Voorwaarden';
import GrowerSale from '../../../Containers/GrowerSale';
import NavigationMenu from './NavigationMenu';
import Login from '../../../Containers/Login';
import CreateNewAccount from '../../../Containers/CreateNewAccount';
import Account from '../../../Containers/Account';
import ForgotPassword from '../../../Containers/ForgotPassword';
import TffForBusiness from '../../../Containers/TffForBusiness';
import Verzorgingstips from '../../../Containers/Verzorgingstips';
import TheFamilyContainer from '../../../Containers/TheFamily';
import FamilyMemberContainer from '../../../Containers/FamilyMember';
import Vacatures from '../../../Containers/Vacatures';
import Vacature from '../../../Containers/Vacature';
import Pers from '../../../Containers/Pers';
import Contact from '../../../Containers/Contact';
import FAQ from '../../../Containers/FAQ';
import Subscription from '../../../Containers/SubscriptionDetails';
import OrderDetails from '../../../Containers/OrderDetails';

import * as NewNativeCombinations from '../../OrderProcessnew/NewNativeCombinations';
import StoryPage from '../../../Containers/StoryPage';
import BedanktVoorBestelling from '../../../Containers/BedanktVoorBestelling';
// import TffExclusive from '../../../Containers/TffExclusive';
import Assortiment from '../../../Containers/Assortiment';
import ProductDetails from '../../../Containers/ProductDetails';
import config from '../../../config/config.selector';
import  { routingName }  from '../OrderProcess/NewOrderProcess.web';


class Routing extends React.Component<any> {

    loc = new LOC;
    App: any = undefined;

    createOrderProcess() {
        let result = {};
        for(let key in NewNativeCombinations) {
            const comp = NewNativeCombinations[key];
            result[key] = {
                    screen: ({navigation}) => React.cloneElement(comp({navigation: this.addScrollWrap(navigation)})),
                    path: key + 'mobile',
                    navigationOptions: () => ({
                        title: key,
                        linkName: key
                    })
            };
        }
        return result;
    }

    //Changes the navigation.navigate to make the page scroll to the top when a link is clicked
    //First gets the old function, then creates the new function that uses the old one but adds scroll to top
    addScrollWrap(navigation) {
        const nav = navigation.navigate;
        const newNav = (route, params?) => {
            window.scrollTo(0,0);
            nav(route, params);
        }
        navigation.navigate = newNav;
        return navigation;
    }

    componentWillMount() {
        let shop = routingName;
        const homeScreen = {
            screen: ({navigation}) => <Home {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
            path: `s/${shop}/`,
            navigationOptions: () => ({
                title: "Home - The Flower Family",
                linkName: ""
            }),
        };
        const loginScreen = {
            screen: ({navigation}) => <Login key='login' {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
            path: `s/${shop}/login`,
            navigationOptions: () => ({
                title: "login - The Flower Family",
                linkName: "login"
            }),
        }
        const switchRouter = SwitchRouter({
            ...((config as any).startingPage === 'Home' ? {Home: homeScreen} : {Home: loginScreen}),
            Login: loginScreen,
            CreateNewAccount: {
                screen: ({navigation}) => <CreateNewAccount {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/nieuw-account`,
                navigationOptions: () => ({
                    title: "Account aanmaken - The Flower Family",
                    linkName: "createNewAccount"
                }),
            },
            Account: {
                screen: ({navigation}) => <Account {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/account`,
                navigationOptions: () => ({
                    title: "Mijn account - The Flower Family",
                    linkName: "Account"
                }),
            },
            ForgotPassword: {
                screen: ({navigation}) => <ForgotPassword {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/wachtwoordVergeten`,
                navigationOptions: () => ({
                    title: "Wachtwoord vergeten - The Flower Family",
                    linkName: "wachtwoordVergeten"
                }),
            },
            Stories: {
                screen: ({navigation}) => <Stories {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/stories`,
                navigationOptions: () => ({
                    title: "Stories",
                    linkName: "Stories Pagina"
                })
            },
            About: {
                screen: ({navigation}) => <About {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/filosofie`,
                navigationOptions: () => ({
                    title: "Onze filosofie",
                    linkName: "about"
                })
            },
            'TFF for business': {
                screen: ({navigation}) => <TffForBusiness {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/kantoor`,
                navigationOptions: () => ({
                    title: "TFF voor kantoor",
                    linkName: "business"
                })
            },
            Verzorgingstips: {
                screen: ({navigation}) => <Verzorgingstips {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/verzorging`,
                navigationOptions: () => ({
                    title: "Verzorgingstips",
                    linkName: "verzorging"
                })
            },
            'The Family': {
                screen: ({navigation}) => <TheFamilyContainer {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/family`,
                navigationOptions: () => ({
                    title: "Meet the family",
                    linkName: "The Family"
                })
            },
            'Family Member': {
                screen: ({navigation}) => <FamilyMemberContainer {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/familymember/:groupId/:title`,
                navigationOptions: () => ({
                    title: "Family Member",
                    linkName: "The Family"
                })
            },
            'Grower Sale': {
                screen: ({navigation}) => <GrowerSale app={this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/samensterk`,
                navigationOptions: () => ({
                    title: "Samen Sterk",
                    linkName: "Grower Sale"
                })
            },
            // 'Order Flowers': {
            //     screen: ({navigation}) => <OrderFlowers app={this.props.app} navigation={this.addScrollWrap(navigation)} />,
            //     path: "bestel",
            //     navigationOptions: () => ({
            //         title: "Bestel bloemen",
            //         linkName: "Flower Order Page"
            //     })
            // },
            "Product details": {
                screen: ({navigation}) => <ProductDetails app={this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/product`,
                navigationOptions: () => ({
                    title: "Bestel bloemen",
                    linkName: "Flower Order Page"
                })
            },
            "Product details noquery": {
                screen: ({navigation}) => <ProductDetails app={this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/product/:pId/:intname/:size`,
                navigationOptions: () => ({
                    title: "Bestel bloemen",
                    linkName: "Flower Order Page"
                })
            },
            webshop: {
                screen: ({navigation}) => <Assortiment app={this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/bestel`,
                navigationOptions: () => ({
                    title: "Bestel bloemen",
                    linkName: "Flower Order Page"
                })
            },
            Privacy: {
                screen: ({navigation}) => <Privacy {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/privacy`,
                navigationOptions: () => ({
                    title: "Privacy",
                    linkName: "privacy"
                })
            },
            Voorwaarden: {
                screen: ({navigation}) => <Voorwaarden {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/voorwaarden`,
                navigationOptions: () => ({
                    title: "Voorwaarden",
                    linkName: "voorwaarden"
                })
            },
            Vacatures: {
                screen: ({navigation}) => <Vacatures {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/vacatures`,
                navigationOptions: () => ({
                    title: "Vacatures",
                    linkName: "Vacatures"
                })
            },
            Vacature: {
                screen: ({navigation}) => <Vacature {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/vacature/:id/:title`,
                navigationOptions: () => ({
                    title: "Vacature",
                    linkName: "Vacature"
                })
            },
            Pers: {
                screen: ({navigation}) => <Pers {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/pers`,
                navigationOptions: () => ({
                    title: "Pers",
                    linkName: "Pers"
                })
            },
            Contact: {
                screen: ({navigation}) => <Contact {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/contact`,
                navigationOptions: () => ({
                    title: "Contact",
                    linkName: "Contact"
                })
            },
            FAQ: {
                screen: ({navigation}) => <FAQ {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/faq`,
                navigationOptions: () => ({
                    title: "F.A.Q.",
                    linkName: "Faq"
                })
            },
            Subscription: {
                screen: ({navigation}) => <Subscription {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/membership`,
                navigationOptions: () => ({
                    title: "Subscription Details",
                    linkName: "subscription"
                })
            },
            'Order Details': {
                screen: ({navigation}) => <OrderDetails {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/order`,
                navigationOptions: () => ({
                    title: "Order Details",
                    linkName: "order"
                })
            },
            // 'Coupon Details': {
            //     screen: ({navigation}) => <CouponDetails {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
            //     path: "coupon",
            //     navigationOptions: () => ({
            //         title: "Coupon Details",
            //         linkName: "coupon"
            //     })
            // },
            Story: {
                screen: ({navigation}) => <StoryPage {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/story/:storyID/:title`,
                navigationOptions: () => ({
                    title: "Story Details",
                    linkName: "story"
                })
            }/*,
            Exclusive: {
                screen: ({navigation}) => <TffExclusive {...this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/exclusive`,
                navigationOptions: () => ({
                    title: "TFF Exclusive",
                    linkName: "exclusive"
                })
            }*/,
            Bedankt: {
                screen: ({navigation}) => <BedanktVoorBestelling app={this.props.app} navigation={this.addScrollWrap(navigation)} />,
                path: `s/${shop}/bedankt`,
                navigationOptions: () => ({
                    title: "Bedankt voor uw bestelling!",
                    linkName: "bedankt"
                })
            },
            ...this.createOrderProcess()
        });

        const AppNavigator = createNavigator(
            NavigationMenu,
            switchRouter,
            {
                params: {...this.props.app}
            }
        );

        this.App = createBrowserApp(AppNavigator);
    }

	render() {
        this.loc.setLanguage(this.props.app.current);

		return (
            <View>

                <this.App />

            </View>
		);
	}
}

const mapStateToProps = (state: any) => {
    const { app } = state
    return { app }
};

export default connect(mapStateToProps)(Routing);
