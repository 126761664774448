import * as React from 'react';
import { View, Image, StyleSheet} from 'react-native';
import { routingName } from './../CrossPlatform/OrderProcess/NewOrderProcess.web';

export default (props: any) => {

    const styles = StyleSheet.create({
        logo: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            alignSelf: 'center',
            marginBottom: 0,
        },
        text: {
            fontSize: 20,
            marginLeft: 10,
            color: props.color ? props.color : 'black',
        }
    })

    const small = props.small;

    return (
        <View style={styles.logo}>
            { routingName !== "consumersite" && routingName !== "bloemist" &&
                <Image
                    style={{width: small ? 145 : 290, height: small ? 20 : 41, alignSelf: 'center'}}
                    source={require('./../../images/logo-FC-dark_horizontal.svg')}
                />
            }
            { routingName === "consumersite" &&
                <Image
                    style={{width: small ? 145 : 290, height: small ? 20 : 41, alignSelf: 'center'}}
                    source={require('./../../images/logo-FC-dark_horizontal_consumer3.svg')}
                />
            }
            { routingName === "bloemist" &&
                <Image
                    style={{width: small ? 145 : 290, height: small ? 20 : 41, alignSelf: 'center'}}
                    source={require('./../../images/logo-FC-dark_horizontal_bloemisten.svg')}
                />
            }
        </View >
    )
};
