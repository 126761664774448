import * as React from 'react';
import USPNavbarText from '../../../../text/USPNavbar/USPNavbarText';
import MakeText from '../../../Common/MakeText/MakeText';

export default () => {

    return (
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', backgroundColor: 'rgba(196, 196, 196, 0.31)', height: 50, paddingLeft: '10%', paddingRight: '10%'}}>
            {USPNavbarText.map((t, i) => {
                return <div key={t + i} style={{display: 'flex', alignItems: 'center'}}>
                    <img src={'/images/done_24px.svg'} />
                    <MakeText fontFamily={'Open Sans'}>{t}</MakeText>
                </div>
            })}
        </div>
    );
}