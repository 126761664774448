import * as React from 'react';

import FooterWeeklyFlowers from './../Common/FooterWeeklyFlowers/FooterWeeklyFlowers';
import MakeText from '../Common/MakeText/MakeText';

import { createStylesOld, createStylesNew } from './AboutStyles';
import { phone } from '../../Util/screen/HandleLayout';
import SociaalDuurzaamEtcText from "../../text/home/SociaalDuurzaamEtc/SociaalDuurzaamEtcText";
// import globalStyles from '../../Styles/styles';

export default (props: any) => {

	const stylesOld = createStylesOld(props.width, props.height);
	const stylesNew = createStylesNew(props.width);
	const bannerHeightFigma = 535;
    const screenWidthFigma = 1600
    const imgHeight = bannerHeightFigma * props.width/screenWidthFigma;
	// const imgHeight = props.height / 10 * 8;
	const mobileView = phone(props.width);
	const text = SociaalDuurzaamEtcText;

    return (

	    <div style={{maxWidth: props.width}}>

			<div style={{height: imgHeight}}>
	            <img src={'/images/Foto filosofie.jpg'} style={stylesNew.imgStyle}/>
				<div style={stylesNew.doeMeeStyle}>
					<MakeText style={{color: 'white', fontSize: props.width * 0.047, lineHeight: props.width * 0.047, letterSpacing: 5}} fontFamily="Arno Pro">{"\"FLOWERING A\r\nBETTER WORLD\""}</MakeText>
				</div>
	        </div>

	        <div style={{marginTop: 25, display: 'flex', flexDirection: 'column'}}>
	            <MakeText style={stylesOld.header1} fontFamily='Crimson Text'>Onze filosofie</MakeText>
	            <MakeText style={stylesOld.headerText} fontFamily="Open Sans Light Italic">
					The Flower Family is de nieuwe online bloemenservice van Nederland. Wij bezorgen alleen niet zomaar bloemen, maar bloemen met een verhaal!
					Binnen The Flower Family zijn we ervan overtuigd dat ondernemen in de nabije toekomst alleen nog maatschappelijk geaccepteerd wordt als de onderneming effectief bijdraagt aan de bloei van mens, milieu en maatschappij - flowering a better world!
	            </MakeText>
	        </div>

	        <div style={{marginTop: 27, display: 'flex', flexDirection: 'column', marginBottom: 27}}>

				<MakeText style={stylesOld.header2} fontFamily='Crimson Text'>Zo doen wij dat:</MakeText>

	            <div style={{alignSelf: 'center'}}>

		            <div style={{flexDirection: mobileView ? 'column' : 'row', ...stylesNew.zoDoenWij}}>

						{text.aboutBlocks.map((b, i) => {
							return <div key={b.title + i} style={stylesNew.zoBlock}>
								<img src={b.img} style={stylesNew.zoPicca}/>
								<MakeText style={stylesOld.header2} fontFamily='Arno Pro'>{b.title}</MakeText>
								<div style={{fontSize: mobileView ? 16 : 18, letterSpacing: 0.9275, color: '#414141', fontFamily: 'Open Sans'}}>{b.text}</div>
							</div>
						})}
		            </div>
					<iframe
						style={{width: '100%', height: '50vw'}}
						src="https://www.youtube.com/embed/kUjc-VBig7M"
						title="YouTube video player"
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
					></iframe>
	            </div>
	        </div>
	        <FooterWeeklyFlowers width={props.width} height={props.height} navigation={props.navigation} />
	    </div>
    );
}
