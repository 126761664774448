import React from "react";
import Pagination from "react-js-pagination";
import './Pagination.css';
import { IPagination } from './IPagination';


export default (props: IPagination) => (
    <div>
        <Pagination
            activePage={props.activePage}
            itemsCountPerPage={props.itemsCountPerPage}
            totalItemsCount={props.totalItemsCount}
            pageRangeDisplayed={props.pageRangeDisplayed}
            onChange={e => props.onChange(e)}
        />
    </div>
);
