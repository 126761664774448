import * as React from 'react';
import { bindActionCreators } from 'redux';
import { getFavorites } from '../../../API/Http/Requests';
import MakeText from '../../Common/MakeText/MakeText';
import FavorietProduct from '../../Common/ProductCards/SingleProductCard';
import { addProduct } from './../../../store/actions/ProductActions';
import { connect } from 'react-redux';
import Button from '../../CrossPlatform/Button/Button';
import FavorieteProductenText from '../../../text/home/FavorieteProducten/FavorieteProductenText';
import { phone } from '../../../Util/screen/HandleLayout';

class FavorieteProducten extends React.Component<any> {
    state = {
        bundles: [],
    };

    componentDidMount() {
        this.fetchData();
    }

    async fetchData() {
        const bundles = await getFavorites().catch(e => {
			console.log(e);
        });
        this.setState({bundles});
    }

    render() {
        const text = FavorieteProductenText;
        const mobileView = phone(this.props.width);
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <MakeText style={{textAlign: 'center', margin: mobileView ? 25 : 0, marginTop: 10, marginBottom: 0, color: '#414141', fontSize: mobileView ? 24 : 36, letterSpacing: 2.2}} fontFamily='Arno Pro'>
                    {text.title}
				</MakeText>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', width: '80%', marginLeft: '10%', marginRight: '10%', flexWrap: 'wrap'}}>
                    {this.state.bundles.map((b: any) => b.packages.map(f => <FavorietProduct key={'pkg' + f.id} flower={f} bundle={b} navigation={this.props.navigation} mobileView={mobileView} />))}
                </div>
                <MakeText style={{marginTop: 20, fontSize: mobileView ? 15 : 18, alignSelf: 'flex-start', marginLeft: '10%'}}>{text.disclaimer}</MakeText>
                <Button style={{marginTop: 20,}} textStyle={{fontSize: mobileView ? 15 : 18, paddingTop: 5, paddingBottom: 5}} text={text.button} color={'greenWhite'} onPress={() => this.props.navigation.navigate('webshop')} />
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    const { product } = state
    return { product }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        addProduct,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(FavorieteProducten);