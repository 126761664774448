import * as React from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';

import FooterWeeklyFlowers from './../Common/FooterWeeklyFlowers/FooterWeeklyFlowers';
import Story from './Story/Story';
import Button from './../CrossPlatform/Button/Button';
import MakeText from '../Common/MakeText/MakeText';
import MakeTabs from '../CrossPlatform/MakeTabs/MakeTabs';
import { phone, PHONE_TEXT_WIDTH_LARGE } from '../../Util/screen/HandleLayout';

import { Helmet } from "react-helmet";
import { getStories } from '../../API/Http/Requests';

const categories = {
    0: 'Alle stories',
    1: 'De verhalen van',
    2: 'Tips & Tricks',
    4: 'Op bloemenavontuur met',
};

export default class Stories extends React.Component<any> {

	increaseStoryAmount: number = 6;

	state = {
        stories: undefined as any,
        loading: true,
        activeTab: 0,

		storyAmount: 6,
    }

    styles = StyleSheet.create({
        header1: {
            fontSize: 36,
            textAlign: 'center',
            marginTop: 30,
            letterSpacing: 2.13
        },
        headerText: {
            fontSize: 14,
            marginTop: 30,
			letterSpacing: 0.9275,
			lineHeight: 32,
			alignSelf: 'center',
        },
        header2: {
            fontSize: 35,
            textAlign: 'center'
        },
        header3: {
            fontSize: this.props.width > 1010 ? 30 : 20,
        },
        headerText2: {
            fontSize: 14,
            letterSpacing: 0.9275,
            textAlign: 'center'
        },
        zoDoenWij: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
            alignItems: 'flex-start',
        },
        zoBlock: {
            flexGrow: 1,
            textAlign: 'center',
            maxWidth: 300,
            margin: 20
        },
        zoPicca: {
            width: 300,
            height: 300,
            margin: 'auto',
        },
        marginTop7: {
            marginTop: 70,
        }
    });

    componentDidMount() {
        getStories().then((res) => {
            this.setState({
                stories: res,
                loading: false
            });
        }).catch( e => {
			console.log(e);
		});
    }

	renderStories(amount: number): JSX.Element[] {

		// The number of stories that will be rendered for the selected category.
        let storiesInTab: number = 0;
		storiesInTab;

		const stories = this.state.stories.map((story, index: number) => {

			if(storiesInTab >= amount) return;

			if (this.state.activeTab !== 0 && story.category !== this.state.activeTab){
				return;
			}
            storiesInTab++;
            const ldStory = {
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "https://www.theflowerfamily.nl/story/" + story.id + "/" + encodeURIComponent(story.title.replace(/\s/g, '-')),
                },
                "headline": story.title,
                "image": [
                    story.img_thumb,
                    story.img_header,
                ],
                "datePublished": "2019-05-16T08:37:44.000Z",
                "dateModified": "2019-05-16T08:37:44.000Z",
                "author": {
                    "@type": "Person",
                    "name": "Jos van Beuningen"
                },
                "publisher": {
                    "@type": "Organization",
                    "name": "The Flower Family",
                    "logo": {
                        "@type": "ImageObject",
                        "url": require('../../images/logo_FC_green_small.png')
                    }
                },
                "description": story.card_desc,
            }


			return (
                <View key={index}>
                    <Helmet>
                        <script className='structured-data-list' type="application/ld+json">{JSON.stringify(ldStory)}</script>
                    </Helmet>
					<Story width={this.props.width} height={this.props.height} navigation={this.props.navigation} story={story}/>
				</View>
			);
		});

		return stories;
	}

	// Simply change the state to a higher number. It will trigger a rerender which will show more stories.
	loadMoreStories() {
		this.setState({storyAmount: this.state.storyAmount + this.increaseStoryAmount});
    }

    findCategoryKey(option: string) {
        for (const k of Object.keys(categories)) {
            if (categories[k] === option) {
                return Number(k);
            }
        }
    }

    changeTab(option: string): void {
        const newTab = this.findCategoryKey(option);
        this.setState({
            activeTab: newTab,
        });
    }

    render() {
        const mobileView: boolean = phone(this.props.width);

        if(this.state.loading) {
            return (
				<View style={{marginVertical: this.props.height / 4}}>
	                <MakeText style={{fontSize: 14, lineHeight: 32, marginTop: 50, letterSpacing: 0.9275, color: '#414141', textAlign: 'center'}}>Stories ophalen...</MakeText>
	                <ActivityIndicator size="large" color="#B9DCBF" />
            	</View>
			);
        }

        return (
            <View style={{maxWidth: this.props.width}}>
                <View>
                    <img src={'/images/Story header.jpg'} style={{width: '100%', objectFit: 'cover'}} />
                    <MakeText style={this.styles.header1} fontFamily="Crimson Text">Family Stories</MakeText>
                    <MakeText style={[this.styles.headerText, {width: mobileView ? this.props.width * PHONE_TEXT_WIDTH_LARGE : this.props.width / 2}]}>
                    Net als in elke familie vinden we het leuk om onze stories te vertellen en te delen met jou. Bekijk en lees hier onze mooie familieverhalen.
                    </MakeText>
                    <MakeTabs
                        textStyles={{
                            fontSize: 22,
                            letterSpacing: 0.06,
                            color: '#414141',
                            marginHorizontal: 15,
                        }}
                        activeTab={categories[this.state.activeTab]}
                        options={Object.values(categories)}
                        style={{
                            flexDirection: !phone(this.props.width) ? 'row' : 'column',
                            alignSelf: 'center',
                            justifyContent: 'space-evenly',
                            flexWrap: 'wrap',
                            marginTop: 30,
                            marginBottom: 30,
                            display: 'flex'
                        }}
                        route='stories'
                        changeTab={(option) => this.changeTab(option)}
                    />

					{this.renderStories(this.state.storyAmount)}

                </View>

                <View style={{flex: 1, alignItems: 'center', marginBottom: 40}}>
                    <Button style={{alignSelf: 'center', marginTop: 30}} textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: 16, textAlign: 'center', letterSpacing: 2}} text="laad meer" color="blackOpacity" onPress={() => this.loadMoreStories()} />
                </View>

                <FooterWeeklyFlowers width={this.props.width} height={this.props.height} navigation={this.props.navigation}/>

            </View>
        );
    }
}
