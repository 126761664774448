import { StyleSheet } from 'react-native';
import { phone, calculateMargin } from '../../Util/screen/HandleLayout';

export const createStylesOld = (width, _) => {
	const mobileView = phone(width);
	const aboutStyles = StyleSheet.create({
		header1: {
			textAlign: 'center',
			marginBottom: 30,
			color: '#414141',
			fontSize: 36,
			letterSpacing: 2.13,
		},
		headerText: {
			width: width < 600 ? width * 0.9 : width * 0.5,
			alignSelf: 'center',
			marginBottom: 30,
			color: '#414141',
			lineHeight: 32,
			fontSize: 18,
			letterSpacing: 1.1925
		},
		header2: {
			fontSize: mobileView ? 24: 36,
			textAlign: 'center',
			marginBottom: 15,
			color: '#414141',
			lineHeight: 42,
			letterSpacing: 2.2,
		},
		headerText2: {
			fontSize: 20,
			textAlign: 'center'
		},
		imgText: {
			lineHeight: 32,
			fontSize: 14,
			textAlign: 'center',
			letterSpacing: 0.9275,
			color: '#414141'
		}
	});

	return aboutStyles;
}

export const createStylesNew = (width) => {
	const bannerHeightFigma = 535;
    const screenWidthFigma = 1600
    const imgHeight = bannerHeightFigma * width/screenWidthFigma;
	const mobileView = phone(width);

	const style = {
		imgStyle : {
			width: width,
			height: imgHeight,
			objectFit: "cover"
		} as React.CSSProperties,
		doeMeeStyle: {
			position: 'absolute',
			display: 'flex',
			alignItems: 'flex-end',
			justifyContent: 'flex-end',
			top: 0,
			left: 0,
			margin: calculateMargin(width),
			height: imgHeight - 2 * calculateMargin(width),
			width: width - 2 * calculateMargin(width),
		} as React.CSSProperties,
		zoPicca: {
			width: 325,
			height: 300,
			alignSelf: 'center',
		},
		zoBlock: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			maxWidth: mobileView ? width - (calculateMargin(width) * 2) : 325,
			margin: 20,
			marginLeft: 25,
		} as React.CSSProperties,
		zoDoenWij: {
			display: 'flex',
			flexDirection: mobileView ? 'column' : 'row',
			flexWrap: 'wrap',
			justifyContent: 'center',
		} as React.CSSProperties,
	};
	return style;
}
