import * as React from 'react';
import { View, ScrollView, StyleSheet, Platform, ActivityIndicator } from 'react-native';
import { NavigationScreenProp } from 'react-navigation';

import Button from './../../CrossPlatform/Button/Button';
import MakeText from '../../Common/MakeText/MakeText';

import globalStyles from '../../../Styles/styles';
import HomeStoryThumbnail from '../../CrossPlatform/HomeStoryThumbnail/HomeStoryThumbnail';
import { phone } from '../../../Util/screen/HandleLayout';
import { getStories } from '../../../API/Http/Requests';


interface IStoriesProps {
	width : number;
	height : number;
	navigation : NavigationScreenProp<any, any>;
    title? : string;
    setStory?: (id) => void;
	buttonText? : string;
}

interface ISTories {
	body: string;
	card_button: string;
	card_desc: string;
	category: number,
	created_at: string;
	deleted_at: string;
	id: number;
	img_header: string;
	img_thumb: string;
	quote: string;
	title: string;
	updated_at: string;
}

export default class HomeStories extends React.Component<IStoriesProps> {

    _hasCanceled = false;
    state = {
        stories: [] as any,
        loading: true
    }


    componentDidMount() {
        this._hasCanceled = false;
        getStories().then((stories: ISTories[]) => {
            stories = stories.slice(0, 3);
            if (!this._hasCanceled) {
                this.setState({
                    stories,
                    loading: false
                });
            }
        }).catch(() => {
            if (!this._hasCanceled) {
                this.setState({
                    loading: false
                });
            }
		})
    }
    render() {

		const mobileView = phone(this.props.width);

        const styles = StyleSheet.create({
            main: {
                ...Platform.select({
                    web: {
                        alignSelf: 'center' as any,
                    }
                }),
            },
            firstImage: {
                marginLeft: 25,
            },
            image: {
                width: 320,
                height: 320,
                alignSelf: 'center',
                marginHorizontal: 12,

                ...Platform.select({
                    web: {
                        width: 350,
                        height: 350,
                        overflowX: this.props.width < 1145 ? 'hidden' : 'visible',
                    }
                }),
            },
            scrollViewMargin: {
                marginBottom: 25,
                marginTop: 41,
            },
            zoDoenWij: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'flex-start',
            },
            zoPicca: {
                width: 300,
                height: 300,
                margin: 'auto',
            }
        });

        if(this.state.loading) {
            return (
				<View>
	                <MakeText style={{textAlign: 'center', fontSize: 20}}>Stories laden...</MakeText>
	                <ActivityIndicator size="small" color="#B9DCBF" />
            	</View>
			);
        }

        if ((() => false)()) {
            return (
				<View>
	                <MakeText style={{textAlign: 'center', fontSize: 20}}>Stories laden...</MakeText>
	                <ActivityIndicator size="small" color="#B9DCBF" />
            	</View>
			);
        }

        return (
            <View style={styles.main}>
                <MakeText style={[globalStyles.textAlignCenter, globalStyles.title, {lineHeight: 42, fontSize: 36, textAlign: 'center', letterSpacing: 2.2, color: '#414141'}]} fontFamily="Crimson Text">
					{this.props.title ? this.props.title : "Onze stories"}
				</MakeText>

                <ScrollView style={[globalStyles.row, styles.scrollViewMargin, {maxWidth: this.props.width}]} horizontal={true}>
                    {this.state.stories.map((story, index) => {
                        return <HomeStoryThumbnail key={index} setStory={this.props.setStory} navigation={this.props.navigation} styles={styles.image} story={story} />
                    })}
                </ScrollView>

                <View style={[globalStyles.row, globalStyles.justifyCenter, globalStyles.marginTop2, globalStyles.marginBottom1]}>
                    <Button textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: mobileView ? 14 : 16, letterSpacing: 2}} color="blackOpacity" text={this.props.buttonText ? this.props.buttonText : "Lees de stories"} route='/stories' onPress={() => this.props.navigation.navigate('Stories')}/>
                </View>

            </View>
        )
    }
};
