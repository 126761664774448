import * as React from 'react';
import './TouchableOpacity.css';

export default class TouchableOpacity extends React.Component<any> {
    state = {
        touched: false,
    }

    toggleTouched = () => {
        this.setState({
            touched: !this.state.touched,
        });
    }

    handleMouseUp = () => {
        // Handle smooth animation when clicking without holding
        setTimeout( () => {
            this.setState({ touched: false });
        }, 150);
    }

    handleOnClick = (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (this.props.route && ev.button === 1) {
            window.open(this.props.route, '_blank');
        } else if (ev.button === 0) {
            this.props.onPress();
        }
    }

    render () {
        const { touched } = this.state;
        const className = touched ? 'btn touched' : 'btn';
        return (
            <button
                className={className}
                aria-label={this.props.ariaLabel ? this.props.ariaLabel : 'link button'}
                onMouseDown={this.toggleTouched}
                onMouseUp={this.handleMouseUp}
                style={this.props.style}
                onClick={(event) => this.handleOnClick(event)}
                data-testid={this.props.testID ? this.props.testID : null}
            >
                {this.props.children}
            </button>
        )
    }
}
