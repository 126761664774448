import * as React from 'react';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setProductAmount } from './../../../store/actions/ProductActions';

// The packages needed to create a new router.
import { createNavigator, SwitchRouter } from "@react-navigation/core";
import { createBrowserApp } from "@react-navigation/web";

import NewWebDisplayWindow from './NewWebDisplayWindow';
import Overview from '../../OrderProcessnew/1-Overview';
import NewOrderProcessLogin from '../../OrderProcessnew/2-Login';
import Register from '../../OrderProcessnew/3-Register';
import PickAddress from '../../OrderProcessnew/4-PickAddress';
import NewAddress from '../../OrderProcessnew/5-NewAddress';
import AddDeliveryAddress from '../../OrderProcessnew/AddDeliveryAddress';
import AddBillingAddress from '../../OrderProcessnew/AddBillingAddress';
import Payment from '../../OrderProcessnew/6-Payment';
import NewOrderProcessWebTemplate from '../../../Containers/Template/NewOrderProcessWebTemplate';

let shop = 'consumersite';
console.log(window.location.pathname);
if(window){
	if(window.location){
		if(window.location.pathname){
			if(window.location.pathname.split('/').length > 2){
				shop = window.location.pathname.split('/')[2]
			}
		}

	}
}

export const routingName = shop;

class OrderProcess extends React.Component<any> {
	AppNavigator = createNavigator(
		NewWebDisplayWindow,
		SwitchRouter({
			overview: {
				screen: (props: any) => <NewOrderProcessWebTemplate navigation={props.navigation} ><Overview mainNavigation={props.mainNavigation} /></NewOrderProcessWebTemplate>,
				path: `s/${shop}/overzicht`,
				navigationOptions: () => ({
					title: "Bloemen bestellen - Overzicht",
					linkName: ""
				}),
			},
			pickLogin: {
				screen: (props: any) => <NewOrderProcessWebTemplate navigation={props.navigation} mainNavigation={props.mainNavigation}><NewOrderProcessLogin /></NewOrderProcessWebTemplate>,
				path: `s/${shop}/kies-login`,
				navigationOptions: () => ({
					title: "Bloemen bestellen - Login",
					linkName: "pick delivery"
				}),
			},
			createAccount: {
				screen: (props: any) => <NewOrderProcessWebTemplate navigation={props.navigation} mainNavigation={props.mainNavigation}><Register /></NewOrderProcessWebTemplate>,
				path: `s/${shop}/order-account-aanmaken`,
				navigationOptions: () => ({
					title: "Bloemen bestellen - Account aanmaken",
					linkName: "pick delivery"
				}),
			},
			pickDeliveryAddress: {
				screen: (props: any) => <NewOrderProcessWebTemplate navigation={props.navigation} mainNavigation={props.mainNavigation}><PickAddress /></NewOrderProcessWebTemplate>,
				path: `s/${shop}/kies-adres`,
				navigationOptions: () => ({
					title: "Bloemen bestellen - Bezorgadres",
					linkName: "pick delivery"
				}),
			},
			deliveryAddress: {
				screen: (props: any) => <NewOrderProcessWebTemplate navigation={props.navigation} mainNavigation={props.mainNavigation}><NewAddress /></NewOrderProcessWebTemplate>,
				path: `s/${shop}/order-adres`,
				navigationOptions: () => ({
					title: "Bloemen bestellen - Nieuw adres",
					linkName: "pick delivery"
				}),
			},
			addDeliveryAddress: {
				screen: (props: any) => <NewOrderProcessWebTemplate navigation={props.navigation} mainNavigation={props.mainNavigation}><AddDeliveryAddress /></NewOrderProcessWebTemplate>,
				path: `s/${shop}/order-bezorgadres`,
				navigationOptions: () => ({
					title: "Bloemen bestellen - Nieuw adres",
					linkName: "pick delivery"
				}),
			},
			addBillingAddress: {
				screen: (props: any) => <NewOrderProcessWebTemplate navigation={props.navigation} mainNavigation={props.mainNavigation}><AddBillingAddress /></NewOrderProcessWebTemplate>,
				path: `s/${shop}/order-factuuradres`,
				navigationOptions: () => ({
					title: "Bloemen bestellen - Nieuw adres",
					linkName: "pick delivery"
				}),
			},
			payment: {
				screen: (props: any) => <NewOrderProcessWebTemplate navigation={props.navigation} mainNavigation={props.mainNavigation}><Payment /></NewOrderProcessWebTemplate>,
				path: `s/${shop}/order-betaling`,
				navigationOptions: () => ({
					title: "Bloemen bestellen - Betaling",
					linkName: "pick delivery"
				}),
			},
		}),
		{
			params: {...this.props.app, mainNavigation: this.props.mainNavigation}
		}
	);

    App = createBrowserApp(this.AppNavigator);

	render() {
		
		// console.log(this.props.mainNavigation);
		// setTimeout(() => this.props.mainNavigation.navigate('webshop'), 5000)
		return (
			<this.App />
		);
	}
}

const mapStateToProps = (state: any) => {
    const { product } = state
    return { product }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
        setProductAmount,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(OrderProcess);
