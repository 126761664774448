import * as React from 'react';
import { View, Image } from 'react-native';

import Button from './../../CrossPlatform/Button/Button';
import MakeText from '../../Common/MakeText/MakeText';
import checkImageUrl from './../../../Util/checkImageUrl';
import { calculateMargin, phone, PHONE_TEXT_WIDTH_LARGE } from '../../../Util/screen/HandleLayout';


export default (props: any) => {

	const mobileView: boolean = phone(props.width);

    let width : number;
    const margin = calculateMargin(props.width);
    width = mobileView ? props.width * PHONE_TEXT_WIDTH_LARGE : props.width - 2 * margin;

    const imgWidth = props.width < 1100 ? width - 2 * margin : width / 2 - 2 * margin;
    //Height of the image hardcoded on the right aspect ratio
    const imgHeight = (870 / 1680) * imgWidth;

    return (
        <View style={{marginBottom: margin, backgroundColor: '#B9DCBF', width, alignSelf: 'center'}}>
            <View style={{flexDirection: width > 1100 ? 'row' as any : 'column' as any}}>

				<View>
                    <Image source={{uri: checkImageUrl(props.story.img_header)}} style={{width: imgWidth, height: imgHeight, alignSelf: 'center', margin}} resizeMode='cover' />
                </View>

                <View style={{width: width > 1100 ? width / 2 - 102 : imgWidth, marginVertical: width > 700 ? 30 : 5, marginRight: mobileView ? 0 : 10, alignSelf: width > 1650 ? 'auto' : 'center', marginHorizontal: mobileView ? 0 : 51, paddingRight: mobileView ? 0 : 26}}>

					<MakeText style={{marginBottom: 40, color: 'white', letterSpacing: 4.2, fontSize: width > 1100 ? 32 : 24}} fontFamily="Crimson Text">
                        {props.story.title}
                    </MakeText>

                    <MakeText style={{fontSize: 14, color: 'white', letterSpacing: 0.9275, lineHeight: 32}}>
                        {props.story.card_desc}
                    </MakeText>

                    <Button
						color="whiteOpacity"
                        text={props.story.card_button}
                        style={{alignSelf: 'center', marginTop: 30, marginBottom: 10}}
                        textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: 16, textAlign: 'center', letterSpacing: 2}}
                        onPress={() => props.navigation.navigate('Story', {storyID: props.story.id, title: props.story.title.replace(/\s/g, '-')})}
                        route={`/story/${props.story.id}/${props.story.title.replace(/\s/g, '-')}`}
					/>

                </View>

            </View>
        </View>
    );
}
