import * as React from 'react';
import { View, Image } from 'react-native';
import MakeText from '../Common/MakeText/MakeText';
import { calculateMargin, phone, PHONE_TEXT_WIDTH_LARGE } from '../../Util/screen/HandleLayout';
import { checkFamilyUrl } from './../../Util/checkFamilyGroupsUrl';


export default (props: any) => {

	const mobileView: boolean = phone(props.width);

    const margin = calculateMargin(props.width);
    const image = <Image source={{uri: checkFamilyUrl(props.profile.img)}} style={props.styles.panelImage} resizeMode='contain' />;
    const textArea = <View style={{flexDirection: 'column', width: mobileView ? props.width * 0.9 : props.width * 0.4, marginHorizontal: margin, marginLeft: mobileView ? 0 : 35}}>
                        <MakeText fontFamily='Crimson Text' style={props.styles.quote}>
                            {props.profile.quote}
                        </MakeText>
                        <MakeText style={props.styles.person}>
                            &#8212;	{props.profile.title}
                        </MakeText>
                        <MakeText style={props.styles.werkplekText}>
                            {props.profile.desc}
                        </MakeText>
                    </View>

    return (
        <View>
            {props.index % 2 === 0 || mobileView ?
                <View style={{ flexDirection: mobileView ? 'column' : 'row', justifyContent: 'center', margin, alignSelf: 'center', width: props.width * PHONE_TEXT_WIDTH_LARGE }}>
                    {image}
                    {textArea}
                </View>
                :
                <View style={{ flexDirection: mobileView ? 'column' : 'row', justifyContent: 'center', margin, alignSelf: 'center' }}>
                    {textArea}
                    {image}
                </View>
            }
         </View>
    );
}
