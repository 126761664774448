import * as React from 'react';

import MakeText from '../../../Common/MakeText/MakeText';
import TouchableOpacity from '../../TouchableOpacity/TouchableOpacity';
import './Link.css';

export default (props: any) => (
    <div style={{position: 'relative', justifyContent: 'center', marginRight: 20, marginLeft: 20}}><div className='underline'>
    <TouchableOpacity route={props.route} onPress={() => {props.navigation.navigate(props.name)}} testID={props.testID}>
        <MakeText style={{fontSize: 16, letterSpacing: 2, lineHeight: 22, color: '#414141', position: 'relative'}}>{props.text}</MakeText>
    </TouchableOpacity>
    </div></div>
);
