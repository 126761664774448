import * as React from 'react';
import { View, Image } from 'react-native';
import Button from '../CrossPlatform/Button/Button';
import { calculateMargin, phone } from '../../Util/screen/HandleLayout';
import { checkTheFamilyUrl } from './../../Util/checkFamilyGroupsUrl';
import stylo from './TheFamily.module.css';


export default (props: any) => {

	const mobileView = phone(props.width);

    const margin = calculateMargin(props.width);
    const image = <Image source={{uri: checkTheFamilyUrl(props.family.card_img)}} style={props.styles.panelImage} resizeMode='contain' />;
    const textArea = <View style={{flexDirection: 'column', justifyContent: 'center', width: mobileView ? props.width * 0.9 : props.width * 0.3, marginHorizontal: 2 * margin}}>
                        <div className={`${stylo.werkplekHeader}`}>
                            {props.family.card_title}
                        </div>
                        <div className={`${stylo.werkplekText}`}>
                            {props.family.card_desc}
                        </div>
                        <Button style={{alignSelf: 'center', marginTop: 30}} textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: 16, textAlign: 'center', letterSpacing: 2}} color="blackOpacity" text={props.family.card_button} route={props.route} onPress={props.onClick} />

                    </View>

    return (
        props.index % 2 === 0 || mobileView ?
            <View style={{flexDirection: mobileView ? 'column' : 'row', justifyContent: 'center', margin, alignSelf: 'center'}}>
                {image}
                {textArea}
            </View>
            :
            <View style={{flexDirection: mobileView ? 'column' : 'row', justifyContent: 'center', margin, alignSelf: 'center'}}>
                {textArea}
                {image}
            </View>
    );
}
