import { combineReducers } from 'redux';

import AppReducer from './reducers/AppReducer';
import ProductReducer from './reducers/ProductReducers';
import OrderProcessReducer from './reducers/OrderProcessReducers';
import ProfileReducer from './reducers/ProfileReducer';

export default combineReducers({
  	app: AppReducer,
	product: ProductReducer,
	orderProcess: OrderProcessReducer,
	profile: ProfileReducer,
});
