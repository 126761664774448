import * as React from 'react';
import { View, ActivityIndicator, TextInput } from 'react-native';
import Button from './../../Components/CrossPlatform/Button/Button';
import MakeText from '../Common/MakeText/MakeText';

import styles from './AccountStyles';
import globalStyles from '../../Styles/styles';
import {getMe, updateUser} from '../../API/Http/Requests';
import { IProfile } from '../../API/Interfaces/IProfile';
import NieuwsBriefSwitch from './ProfileData/NieuwsBriefSwitch';

interface IState {
	profile: IProfile;
	edit: boolean;
	editedFields: any;
	errorText: string;
	loading: boolean;
}

export default class CompanyData extends React.Component<any> {

    state : IState = {
		profile: undefined as any,
		editedFields: {businesses: [{}]} as any,

		edit: false,
		errorText: '',
		loading: false,
    }

	async componentDidMount() {
		this.setState({ profile: await getMe() });
    }

	onChange(varName: string, value: any) : void {
		let tmpProfile = Object.assign({}, this.state.profile);

		tmpProfile[varName] = value;

		const edited = this.state.editedFields;
		edited[varName] = value;

		this.setState({profile: tmpProfile, editedFields: edited});
	}

	onChangeBusiness(varName: string, value: any) : void {
		let tmpProfile = Object.assign({}, this.state.profile);

		tmpProfile.businesses[0][varName] = value;

		const edited = this.state.editedFields;
		edited.businesses[0][varName] = value;

		this.setState({profile: tmpProfile, editedFields: edited});
	}

	async updateData(): Promise<void> {
		this.setState({loading: true});
		const edited = this.state.editedFields;
		edited.businesses[0].id = this.state.profile.businesses[0].id;
		updateUser({id: this.state.profile.id, ...edited}).then( () => {
			this.setState({errorText: '', loading: false});
			this.flipEdit();
		}).catch( e => {
			this.setState({errorText: 'Woops, something went wrong!', loading: false});
			console.log(e);
		})
	}

	flipEdit() : void {
		this.setState({edit: !this.state.edit});
	}

    render() {
        if (!this.state.profile) {
            return (
                <View style={styles.columnWebView}>
                    <View style={{ marginTop: 20, marginBottom: 20 }}>
                        <ActivityIndicator size="large" color="#B9DCBF" />
                    </View>
                </View>
            );
        }


		if(this.state.edit) return this.editableProfile();

        return (
            <View style={{width: '100%'}}>
				<View style={[globalStyles.column, globalStyles.fullWidth]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Bedrijfsnaam: </MakeText><MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{this.state.profile.businesses[0].company}</MakeText>
				</View>

                <View style={[globalStyles.column, globalStyles.fullWidth]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">BTW-nummer: </MakeText>
					<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{this.state.profile.businesses[0].vatNumber}</MakeText>
				</View>

				<View style={[globalStyles.column, globalStyles.fullWidth]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">E-mail: </MakeText>
					<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{this.state.profile.email}</MakeText>
				</View>

				<View style={[globalStyles.column, globalStyles.fullWidth]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Telefoonnummer: </MakeText>
					<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{this.state.profile.phone}</MakeText>
				</View>

				<View style={[globalStyles.column, globalStyles.fullWidth]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Wachtwoord: </MakeText>
					<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>***********</MakeText>
				</View>

				<View style={[globalStyles.column, globalStyles.fullWidth]}>
					<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Nieuwsbrief: </MakeText>
					<MakeText style={[styles.defaultAccountFont, styles.Persoonsgegevens]}>{this.state.profile.emails ? 'Ja' : 'Nee'}</MakeText>
				</View>

            	<Button text="Bewerken" color="blackOpacity" style={{alignSelf: 'center', marginTop: 30, marginBottom: 33}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} onPress={() => this.flipEdit()} />

            </View>
        )
    }

	editableProfile() {

		return (
			<View>

				{this.state.errorText.length ? <MakeText style={styles.errorText}>{this.state.errorText}</MakeText> : undefined}

				<View>

					<View style={[globalStyles.column, {backgroundColor: '#E3F0E4', width: this.props.width}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Bedrijfsnaam: </MakeText>
						<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, { fontFamily: 'Open Sans' }]} onChangeText={(e) => this.onChangeBusiness("company", e)} value={this.state.profile.businesses[0].company}></TextInput>
					</View>

                    <View style={[globalStyles.column, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">BTW-nummer: </MakeText>
						<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, { fontFamily: 'Open Sans' }]} onChangeText={(e) => this.onChangeBusiness("vatNumber", e)} value={this.state.profile.businesses[0].vatNumber}></TextInput>
					</View>

					<View style={[globalStyles.column, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Telefoonnummer: </MakeText>
						<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("phone", e)} value={this.state.profile.phone}></TextInput>
					</View>

					<View style={[globalStyles.column, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Wachtwoord: </MakeText>
						<TextInput secureTextEntry={true} style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans'}]} onChangeText={(e) => this.onChange("password", e)} value={this.state.profile.password ? this.state.profile.password : ""}></TextInput>
					</View>

					<View style={[globalStyles.row, {backgroundColor: '#E3F0E4', width: this.props.width, marginTop: 3}]}>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Nieuwsbrief: </MakeText>
						<NieuwsBriefSwitch
							setNieuwsbrief={(n: boolean) => {
								let tmpState = Object.assign({}, this.state.profile);
								tmpState.emails = n;
								const edited = this.state.editedFields;
								edited.emails = tmpState.emails;
								this.setState({profile: tmpState, editedFields: edited});
							}}
							nieuwsbrief={this.state.profile.emails}
						/>
					</View>
				</View>

				<View style={[globalStyles.row, {alignSelf: 'center', marginTop: 30, marginBottom: 33}]}>
					{this.state.edit && <Button color="blackOpacity" text="Annuleren" textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} onPress={() => this.flipEdit()} />}
					<Button text={this.state.edit ? "Opslaan" : "Bewerken"} color="blackOpacity" style={{marginLeft: 10}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} onPress={() => this.updateData()} />
				</View>

				{this.state.loading ? <ActivityIndicator size='large' style={{marginBottom: 33}} /> : undefined}

			</View>
		)
	}
}
