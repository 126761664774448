import * as React from 'react';
import { View, StyleSheet, Platform, Image } from 'react-native';

import { SceneView } from "@react-navigation/core";
import globalStyles from './../../../../Styles/styles';
import Button from './../../../CrossPlatform/Button/Button';
import ShoppingCart from '../../../ShoppingCart/ShoppingCart';
import { routingName } from './../../../CrossPlatform/OrderProcess/NewOrderProcess.web';

// The store
import { connect } from 'react-redux';

import './styles.css';
import { calculateMargin } from '../../../../Util/screen/HandleLayout';
import TouchableOpacity from '../../TouchableOpacity/TouchableOpacity';
import config from '../../../../config/config.selector';
import { isLoggedIn } from '../../../../API/Http/Requests';

class HamburgerMenu extends React.Component<any> {

    state = {
        sceneDisplay: 'flex',
        linkDisplay: 'none',
        isLoggedIn: false,
    }

    openCloseMenu() : void {
        if(this.state.linkDisplay === 'none') this.setState({linkDisplay: 'flex', sceneDisplay: 'none'});
        else this.setState({linkDisplay: 'none', sceneDisplay: 'flex'});
    }

    async componentDidMount() {
        try {
            const loggedIn = await isLoggedIn();
            this.setState({isLoggedIn: loggedIn});
        } catch {}
    }

    async componentDidUpdate() {
        try {
            const loggedIn = await isLoggedIn();
            if (loggedIn !== this.state.isLoggedIn) {
                this.setState({isLoggedIn: loggedIn});
            }
        } catch {}
    }

    render() {
        const { descriptors, navigation } = this.props;
        const activeKey = navigation.state.routes[navigation.state.index].key;
        const descriptor = descriptors[activeKey];

        const styles = StyleSheet.create({
            menuButton: {
                zIndex: 1,
                ...Platform.select({
                    ios: {
                        marginTop: 25
                    },
                    android: {
                        marginTop: 25
                    },
                    web: {
                        marginTop: 0
                    },
                  }),
            },
            sidenav: {
                width: this.props.width,
                height: this.props.height,
                position: 'absolute',
                zIndex: 0,
            },
            link: {
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
                display: this.state.linkDisplay as any,
            },
            sceneView: {
                display: this.state.sceneDisplay as any,
            },
            butt: {
                marginBottom: 20
            },
			buttontext: {
				fontSize: 42,
			},
			sceneMarginTop: {
				marginTop: 58,
			},
			headerText: {
                position: 'absolute',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                margin: calculateMargin(this.props.width),
                height: this.props.height / 10 * 8 - 2 * calculateMargin(this.props.width),
                width: this.props.width - 1.1 * calculateMargin(this.props.width),
            },
        });

        const menuActive = this.state.linkDisplay === 'flex';
        const hamburgerIcon = menuActive ? require('./../../../../images/close.svg') : require('./../../../../images/hamburger.svg');
        const conf = config as any;
        return (
            <View>
				<div className={"sticky"} style={{zIndex: 100}}>
	                <View style={[styles.menuButton, globalStyles.row, {overflow: 'hidden'}]}>
                        <View style={{paddingVertical: 19, paddingLeft: 13, paddingRight: 5}}>
                            <TouchableOpacity testID='hamburgerMenu' onPress={() => this.openCloseMenu()}>
                                <Image source={hamburgerIcon} style={{width: 25, height: 20}}/>
                            </TouchableOpacity>
                        </View>
                        <View style={{margin: 'auto'}}>
                            <TouchableOpacity route='/' onPress={() => {this.props.navigation.navigate('Home'); if(this.state.linkDisplay === 'flex') {this.openCloseMenu();}}}>
                                <Image source={require('./../../../../images/logo_FC_green_horizontal.png')} style={{width: 200, height: 28}} />
                            </TouchableOpacity>
                        </View>
                        {/*  routingName === "amsterdam" &&
							<div className='row' style={{margin: 'auto'}}>
                                <img src='/images/logo_amsterdam.png' height={25} width={80} />
							</div>
                        */}
                        { routingName !== "consumersite" && window.location.pathname.indexOf('/s/') > -1 &&
							<div id='illy' className='row' style={{margin: 'auto'}}>
                                <img src={routingName === "amsterdam" || routingName === "bloemist" ? `/portals/logo_amsterdam_leeg.png` : `/portals/logo_${window.location.pathname.split('/')[2]}.png`} height={25} width={80} />
							</div>
						}
                        {(!conf.loginOnly || this.state.isLoggedIn) &&
                            <View style={{marginBottom: 4}}>
                                <ShoppingCart testID='cart' navigate={this.props.navigation.navigate} width={this.props.width} height={this.props.height} />
                            </View>
                        }
	                </View>
				</div>

                <View style={styles.sidenav}>

                    <View style={styles.link}>
                        {(!conf.loginOnly || this.state.isLoggedIn) && conf.navbarItems.map(item => (
                            <Button key={`navbar-${item.testID}`} testID={item.testID} color="white" textStyle={{fontSize: 22, marginTop: 20}} route={item.route} onPress={() => {this.props.navigation.navigate(item.navigation); this.openCloseMenu();}} text={item.text} />
                        ))}

						<Button testID='hamburgerAccount' color="white" style={{marginTop: 20}} route='/account' textStyle={{fontSize: 22}} onPress={() => {this.props.navigation.navigate('Account'); this.openCloseMenu();}} text={'Mijn account'} />
                    </View>

                </View>

                <View style={[styles.sceneView, globalStyles.flex1, globalStyles.alignCenter, styles.sceneMarginTop]}>

                    {/* The page is rendered inside the SceneView */}
                    <SceneView
                        component={descriptor.getComponent()}
                        navigation={descriptor.navigation}
                    />

                </View>

            </View>
        );
    }
};

const mapStateToProps = (state: any) => {
    const { app } = state
    return { app }
};

export default connect(mapStateToProps)(HamburgerMenu);
