import * as React from 'react';
import { View, Image, StyleSheet } from 'react-native';

import CreateAccount from './../OrderProcess/CreateAccount';
import { phone } from '../../Util/screen/HandleLayout';

// The store
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setLogin } from './../../store/actions/AppActions';
import Button from '../CrossPlatform/Button/Button';

class CreateAccountPage extends React.Component<any> {

	render() {

		const mobileView = phone(this.props.width);
		const imgHeight = mobileView ? 0 : this.props.height / 10 * 8;
		const styles = StyleSheet.create({
			backgroundImage: {
				position: 'absolute',
				width: this.props.width,
				top: 0,
				alignSelf: 'center',
				height: imgHeight,
			},
			orText: {
				textAlign: 'center',
        		fontSize: 12,
				marginVertical: 10,
			}
		});

		return (

			<View style={{ flex: 1, alignItems: 'center', justifyContent: 'center', minHeight: imgHeight }}>
				<Image
					style={styles.backgroundImage}
					source={require('./../../images/TffForBusiness.jpg')}
				/>

				<View style={{borderWidth: 1, borderColor: 'white', shadowOffset: {width: 1, height: 1}, shadowOpacity: 0.2, shadowRadius: 5, paddingVertical: 50, paddingHorizontal: 50, marginHorizontal: 0, marginTop: mobileView ? 30 : 0, backgroundColor: 'white', width: mobileView ? this.props.width * 0.7 : 500}}>
					<CreateAccount navigation={this.props.navigation} store={{setLogin: this.props.setLogin.bind(this)}} route="Account" width={mobileView ? this.props.width * 0.7 : 489} />
					<Button text='Terug' color='greenWhite' route='/login' style={{alignSelf: 'center'}} onPress={() => this.props.navigation.navigate('Login')} />
				</View>
			</View>
		)
	}
}

const mapStateToProps = (state: any) => {
    const { app } = state;
    return { app }
};

const mapDispatchToProps = (dispatch: any) => (
    bindActionCreators({
		setLogin,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccountPage);
