import * as React from 'react';
import { Image, View, StyleSheet, ActivityIndicator } from 'react-native';

import FooterWeeklyFlowers from './../Common/FooterWeeklyFlowers/FooterWeeklyFlowers';
import MakeText from '../Common/MakeText/MakeText';
import FamilyMemberCard from './FamilyMemberCard';
import Button from '../CrossPlatform/Button/Button';
import { phone } from '../../Util/screen/HandleLayout';
import { getFamilygroups, getFamilygroups_profiles } from '../../API/Http/Requests';

export default class FamilyMember extends React.Component<any> {

    staticStyles = StyleSheet.create({
            zoDoenWij: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'flex-start',
            },
            zoBlock: {
                flexGrow: 1,
                maxWidth: 300,
                margin: 20
            },
            zoPicca: {
                width: 300,
                height: 300,
                margin: 'auto',
            },
            werkplekText: {
                lineHeight: 32,
                fontSize: 14,
                letterSpacing: 0.9275
            },
            quote: {
                fontSize: 30,
                marginBottom: 15,
                color: '#414141',
                lineHeight: 35,
                letterSpacing: 2.38875,
                fontStyle: 'italic'
            },
            person: {
                fontStyle: 'italic',
                fontSize: 18,
                lineHeight: 38,
                letterSpacing: 0.045,
            },
            imgButton: {
                alignSelf: 'center',
                margin: 20,
                marginBottom: 50
            },
            imgButtonText: {
                // fontFamily: 'Open Sans',
                color: '#414141',
                lineHeight: 22,
                fontSize: 16,
                textAlign: 'center',
                letterSpacing: 2,
            },
            werkplekSubHeader: {
                fontStyle: 'italic',
                fontSize: 18,
                letterSpacing: 0.045,
            },
            header2: {
                fontSize: 36,
                textAlign: 'center',
                marginBottom: 15,
                color: '#414141',
                lineHeight: 42,
                letterSpacing: 4.2
            },
            headerText2: {
                fontSize: 20,
                textAlign: 'center'
            },
            header1: {
                textAlign: 'center',
                marginBottom: 30,
                color: '#414141',
                fontSize: 36,
                letterSpacing: 2.13,
            },
    });

    state = {
        group: undefined as any,
        profiles: [],
        loading: true,
    }

    componentDidMount() {
        const groupId = this.props.navigation.state.params.groupId
        getFamilygroups_profiles().then( (res) => {
			let profiles = res.filter((item) => item.familygroups_id === parseInt(groupId));
            this.setState({
                    profiles,
                },
                () => getFamilygroups().then((familyGroups) => {
                    this.setState({
                        group: familyGroups.filter((group) => group.id === parseInt(groupId))[0],
                        loading: false,
                    })
                })
            );
        });
    }

    render() {

		const mobileView = phone(this.props.width);

		const styles = StyleSheet.create({
			image: {
                height: mobileView ? 250 : this.props.height * 0.6,
                width: this.props.width
			},
			headerText: {
				width: this.props.width < 600 ? this.props.width * 0.9 : this.props.width * 0.5,
				alignSelf: 'center',
				marginBottom: 30,
				color: '#414141',
				fontStyle: 'italic',
				lineHeight: 32,
				fontSize: 18,
				letterSpacing: 1.1925
			},
			headerText3: {
				fontSize: 20,
				maxWidth: this.props.width,
				margin: 10,
				color: '#414141'
			},
			panelImage: {
				height: mobileView ? 300 : 500,
				width:  mobileView ? this.props.width * 0.9 : this.props.width * 0.2,
				alignSelf: 'center'
			}
		});

		if(this.state.loading) {
			return (
				<View>
					<View>
						<Image source={require('./../../images/FamilyMemberHeader.jpg')} style={styles.image}/>
					</View>

					<View style={{marginVertical: this.props.height / 6, alignSelf: 'center'}}>
						<ActivityIndicator size="large" color="#B9DCBF" />
					</View>
				</View>
			);
		}

        return (

            <View>
                <View>
                    <Image source={{uri: this.state.group.img_header}} style={styles.image} />
                </View>

                <View>
                    <View style={{alignSelf: 'center', marginTop: 30}}>
                        <MakeText fontFamily='Crimson Text' style={this.staticStyles.header1}>Leer {this.state.group.title.toLowerCase()} kennen</MakeText>
                        <MakeText style={styles.headerText}>
                            {this.state.group.intro}
                        </MakeText>
                    </View>

                    {
                        this.state.profiles.map((profile, index) => {
                            return <FamilyMemberCard
									key={index}
                                    width={this.props.width}
                                    height={this.props.height}
                                    styles={{...styles, ...this.staticStyles}}
                                    profile={profile}
                                    index={index}
                                />
                        })
                    }
                </View>

                <Button style={{alignSelf: 'center', marginTop: 30}} textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: 16, textAlign: 'center', letterSpacing: 2, marginBottom: 80}} color="blackOpacity" text="Terug naar the Family" route='/family' onPress={() => this.props.navigation.navigate('The Family')} />

                <FooterWeeklyFlowers width={this.props.width} height={this.props.height} navigation={this.props.navigation}/>

            </View>
        );
    }
}
