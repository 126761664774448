
/**
 * Deze functie krijgt een status string uit de backend en vertaald deze naar status berichten die aan klanten
 * kunnen worden weergegeven.
 *
 * @param  status Een string met een status, voor subscription: in_progress paused pending cancelled, voor order: pending processed delivery cancelled completed
 * @return        Er komt een string terug die de status vertaald naar een bericht dat door klanten kan worden gelezen.
 */
export default function statusToMessage(status: string) {

	switch(status) {
		case "pending":
			return "in afwachting";
		case "processed":
			return "verwerkt";
		case "delivery":
			return "onderweg";
		case "completed":
			return "bezorgd";
		case "cancelled":
			return "geannuleerd";

		case "in_progress":
			return "wordt verwerkt";
		case "paused":
			return "gepauzeerd";
		case "cancelled":
			return "geannuleerd";
		default:
			return "Onbekende status";
	}
}
