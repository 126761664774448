import React from "react";
import { StyleSheet } from 'react-native';
import MakeText from '../../Common/MakeText/MakeText';
import { calculateMargin, phone } from "../../../Util/screen/HandleLayout";
import Button from "../../CrossPlatform/Button/Button";
import SociaalDuurzaamEtcText from "../../../text/home/SociaalDuurzaamEtc/SociaalDuurzaamEtcText";

export default (props: any) => {
    const mobileView = phone(props.width);

    const stylesOld = StyleSheet.create({
		header2: {
			fontSize: mobileView ? 24 : 36,
			textAlign: 'center',
			marginBottom: 15,
			color: '#414141',
			lineHeight: 42,
			letterSpacing: 2.2,
		},
		imgText: {
			fontSize: mobileView ? 15 : 18,
			textAlign: 'center',
			letterSpacing: 0.9275,
			color: '#414141'
		}
	});
    const stylesNew = {
		zoPicca: {
			width: mobileView ? 216 : 325,
			height: mobileView ? 200 : 300,
			alignSelf: 'center',
		},
		zoBlock: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			maxWidth: mobileView ? props.width - (calculateMargin(props.width) * 2) : 350,
            margin: 20,
            marginLeft: 40,
			marginRight: 40,
		} as React.CSSProperties,
		zoDoenWij: {
			display: 'flex',
			flexDirection: mobileView ? 'column' : 'row',
			flexWrap: 'wrap',
			justifyContent: 'center',
		} as React.CSSProperties,
	};
	const text = SociaalDuurzaamEtcText;

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>

            <div style={{alignSelf: 'center'}}>

                <div style={{flexDirection: mobileView ? 'column' : 'row', ...stylesNew.zoDoenWij}}>

					{text.blocks.map((b, i) => {
						return <div key={b.title + i} style={stylesNew.zoBlock}>
							<img src={b.img} style={stylesNew.zoPicca}/>
							<MakeText style={stylesOld.header2} fontFamily='Arno Pro'>{b.title}</MakeText>
							<div style={{fontSize: mobileView ? 15 : 18, letterSpacing: 0.9275, color: '#414141', fontFamily: 'Open Sans'}}>{b.text}</div>
						</div>
					})}
                </div>
            </div>
			<Button
                    color={'greenWhite'}
                    style={{alignSelf: 'center', marginTop: '10px'}}
                    text={text.button}
                    textStyle={{paddingTop: 5, paddingBottom: 5, fontSize: mobileView ? 14 : 16}}
                    onPress={() => props.navigation.navigate('About')}
                    route='/filosofie'
                />
        </div>
    );
}