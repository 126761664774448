import * as React from 'react';
import checkImageUrl from '../../../Util/checkImageUrl';
import { Helmet } from "react-helmet";
import MakeText from '../../Common/MakeText/MakeText';

export default (props: any) => {
    const mobileView = props.mobileView;
    // const imgWidthFigma = 400;
    // const screenWidthFigma = 1600;
    // const imgDimenensions = imgWidthFigma * props.width/screenWidthFigma;
    const imageStyle = {
        objectFit: 'cover',
        display: 'block',
        width: '100%',
        height: '100%'
        // maxWidth: '50%',
    } as React.CSSProperties;
    const story = props.story;

    const style = {
        container: {
            display: 'flex',
            flexDirection: mobileView ? 'column' : 'row',
            backgroundColor: '#B9DCBF',
            position: 'relative',
        } as React.CSSProperties,
        textContainer: {
            display: 'flex',
            flexDirection: 'column',
            margin: mobileView ? 20 : 50,
            position: 'relative',
        } as React.CSSProperties,
        link: {
            display: 'inline-block',
            fontSize: mobileView ? 15 : 18,
            // position: 'absolute',
            // bottom: 0,
            color: 'black',
            textDecoration:'underline',
            marginLeft: mobileView ? 20 : 50,
            marginBottom: 20,
            fontFamily: 'Open Sans',
        } as React.CSSProperties,
    };

    const ldStory = {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "https://www.theflowerfamily.nl/story/" + story.id + "/" + encodeURIComponent(story.title.replace(/\s/g, '-')),
        },
        "headline": story.title,
        "image": [
            story.img_thumb,
            story.img_header,
        ],
        "datePublished": "2019-05-16T08:37:44.000Z",
        "dateModified": "2019-05-16T08:37:44.000Z",
        "author": {
            "@type": "Person",
            "name": "Jos van Beuningen"
        },
        "publisher": {
            "@type": "Organization",
            "name": "The Flower Family",
            "logo": {
                "@type": "ImageObject",
                "url": require('../../../images/logo_FC_green_small.png')
            }
        },
        "description": story.card_desc,
    }

    const titleSize = mobileView ? 18 : 24;
    const textSize = mobileView ? 15 : 18;

    return (
        <div>
            <Helmet>
                <script className='structured-data-list' type="application/ld+json">{JSON.stringify(ldStory)}</script>
            </Helmet>
            <div style={style.container}>
                <div style={{minHeight: mobileView ? undefined : '100%', minWidth: '30%', maxWidth: mobileView ? undefined : '30%'}}>
                    <img src={checkImageUrl(story.img_thumb)} style={imageStyle} alt='Story' />
                </div>
                <div>
                    <div style={style.textContainer}>
                        <MakeText fontFamily='Arno Pro' style={{fontSize: titleSize, letterSpacing: 2.2}}>
                            {story.title}
                        </MakeText>
                        <MakeText style={{fontSize: textSize, marginTop: 15, letterSpacing: 1}}>
                            {story.card_desc}
                        </MakeText>
                        <MakeText style={{fontSize: textSize, marginTop: 15, marginBottom: mobileView ? 20 : 0, letterSpacing: 1}}>
                            "{story.quote}"
                        </MakeText>
                    </div>
                    <a href='#' onClick={() => props.setStory ? props.setStory(story.id) : props.navigation.navigate(`Story`, {storyID: story.id, title: story.title.replace(/\s/g, '-')})} style={style.link}>{story.card_button}</a>
                </div>
            </div>
		</div>
	);
}
