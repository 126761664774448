import * as React from 'react';

import Voorwaarden from '../Components/Voorwaarden/Voorwaarden';
import BasicPage from './Template/BasicPage';

export default (props: any) => (
    <BasicPage navigation={props.navigation}>
		<Voorwaarden />
	</BasicPage>
)
