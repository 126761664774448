import moment from 'moment';
import * as React from 'react';
import { DeliveryOption } from '../../API/Interfaces/DeliveryOption';
import Button from '../CrossPlatform/Button/Button';

interface ISelectDeliveryProps {
    options: DeliveryOption[],
    chosen: number,
    setDelivery: (i: number) => void,
}

export default (props: ISelectDeliveryProps) => {
    const groupedByDay: DeliveryOption[][] = [];
    groupedByDay.push([Object.assign({optionIndex: 0}, props.options[0])]);
    let counter = 0;
    let indexCounter = 1;
    for (const option of props.options.slice(1)) {
        option.optionIndex = indexCounter++;
        if (moment(groupedByDay[counter][0].windowStart).isSame(moment(option.windowStart), 'day')) {
            groupedByDay[counter].push(option);
        } else {
            counter += 1;
            groupedByDay.push([option]);
        }
    }

    return (
        <div>
            {groupedByDay.map((day: DeliveryOption[]) => (
                <div className='col mb-4'>
                    <span className='mediumText mb-2'>{moment(day[0].windowStart).format('dddd, DD-MM-YYYY')}</span>
                    <div className='row justifyBetween'>
                        {day.map((d: DeliveryOption) => (
                            <Button testID='selectDeliveryOption' textStyle={{fontSize: 15}} style={{width: '40%'}} color={props.chosen === d.optionIndex ? 'whiteGreen' : 'greenWhite'} text={`${moment(d.windowStart).format('HH:mm')} - ${moment(d.windowEnd).format('HH:mm')}`} onPress={() => props.setDelivery(d.optionIndex as number)} />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    )
};