import * as React from 'react';

import BasicPage from './Template/BasicPage';
import AboutComponent from './../Components/About/About';
import { Helmet } from "react-helmet";


export default class About extends React.Component<any> {

    render() {

        const jsonld = {
            "@context": "http://schema.org/",
            "@type": "Webpage",
            "name": "Onze Filesofie",
            "description": "Onze maatschappij heeft zich lang op groei en geld verdienen gefocust. Binnen The Flower Family zijn we ervan overtuigd dat een nieuw tijdperk is aangebroken waarbij een betekenisvolle missie en aandacht voor mens, milieu en maatschappij centraal staan.",
            "url": "https://www.theflowerfamily.nl/"
        };

        return (
            <BasicPage navigation={this.props.navigation}>
                <Helmet>
                    <title> The Flower Family - Onze Filosofie </title>
                    <link rel="canonical" href="https://www.theflowerfamily.nl/filosofie" />
                    <script className='structured-data-list' type="application/ld+json">{JSON.stringify(jsonld)}</script>
                </Helmet>
				<AboutComponent />
			</BasicPage>
        );
    }
}
