import { Bundle } from "../API/Interfaces/Bundle";

const sizeOrdering = (size: string) => {
    switch (size) {
        case 'small':
            return 1;
        case 'medium':
            return 2;
        case 'large':
            return 3;
        case 'x-large':
            return 4;
        case 'xx-large':
            return 5;
        default:
            return 0;
    }
}

export const sortPackages = (bundle: Bundle) => {
    bundle.packages.sort((a, b) => sizeOrdering(a.size) - sizeOrdering(b.size))
}