import * as React from 'react';
import { View, StyleSheet} from 'react-native';

import IFooterLink from './IFooterLink';
import MakeText from '../MakeText/MakeText';
import TouchableOpacity from '../../CrossPlatform/TouchableOpacity/TouchableOpacity';

export default (props: IFooterLink) => {

    const styles = StyleSheet.create({
        touch: {
            width: '80%',
            borderTopWidth: props.width > 650 ? 1 : 0,
            borderBottomWidth: props.bottom ? props.width > 650 ? 1 : 0 : 0
        },
        text: {
            paddingTop: 10,
            paddingBottom: 10
        }
    });

    const mobileView = props.width <= 580;

    return (
        <View style={{marginHorizontal: 20, width: mobileView ? 150 : 250 }}>
            <View style={[styles.touch, styles.text]}>
                <TouchableOpacity route={props.route} onPress={() => props.link()}>
                    <MakeText style={styles.text}>{props.text}</MakeText>
                </TouchableOpacity>
            </View>
        </View>
    )

}