import * as React from 'react';

import BasicPage from './Template/BasicPage';
import Pers from './../Components/Pers/Pers';

export default (props: any) => (
    <BasicPage navigation={props.navigation}>
			<Pers />
		</BasicPage>
)
