import * as React from 'react';

import BasicPage from './Template/BasicPage';
import Verzorgingstips from './../Components/Verzorgingstips/Verzorgingstips';


export default class VerzorgingstipsContainer extends React.Component<any> {

    render() {

        return (
			<BasicPage navigation={this.props.navigation}>
				<Verzorgingstips />
			</BasicPage>
        );
    }
}
