
export const setLeftTitle = 'SET_LEFT_TITLE';
export const setRightTitle = 'SET_RIGHT_TITLE';
export const setCenterTitle = 'SET_CENTER_TITLE'
export const setBillingAddress = 'SET_BILLING_ADDRESS';
export const setDeliveryAddress = 'SET_DELIVERY_ADDRESS';
export const setDeliveryWindow = 'SET_DELIVERY_WINDOW';
export const setDeliveryText = 'SET_DELIVERY_TEXT';
export const setCreateOrderOrder = 'SET_CREATE_ORDER_ORDER';
export const setActiveStep = 'SET_ACTIVE_STEP';
export const setPONumber = 'SET_PO_NUMBER';
export const addOrder = 'ADD_ORDER';
export const setOrders = 'SET_ORDERS';
export const updateOrder = 'UPDATE_ORDER';
export const removeOrder = 'REMOVE_ORDER';
export const updateCoupon = 'SET_COUPON';
export const updatePaymentType = 'SET_PAYMENT';
export const setTotalPrice = 'SET_TOTAL_PRICE';
export const setDiscount = 'SET_DISCOUNT';
export const setShippingCosts = 'SET_SHIPPING_COSTS';
export const setOriginalPrice = 'SET_ORIGINAL_PRICE';
export const setDeliveryNote = 'SET_DELIVERY_NOTE';
