import * as React from 'react';
import validator from 'validator';
import Button from './../CrossPlatform/Button/Button';
import MakeText from '../Common/MakeText/MakeText';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';
import { authenticate, getMe, isLoggedIn, logout } from '../../API/Http/Requests';
import Storage from './../CrossPlatform/Storage/Storage';
import { ActivityIndicator } from 'react-native';

export default class Login extends React.Component<any> {
    state = {
        email: '',
        password: '',
        loading: true,
        errorText: '',
        loggedIn: false,
    };

    storage = new Storage;

	async componentDidMount() {
        GoogleAnalytics.getInstance().startOrderProcess();
        this.props.store.setActiveStep('pickLogin');
        if (await isLoggedIn().catch(e => {this.setState({loading: false}); console.log(e)})) {
            const user = await getMe();
            this.setState({loggedIn: user.account, loading: false})
        } else {
            this.setState({loading: false});
        }
        GoogleAnalytics.getInstance().hit(this.props.navigation.state);
    }

    async attemptLogin() {
		// Removed HTML from input.
		let username = this.escapeInput(this.state.email);
		let password = this.escapeInput(this.state.password);

		// Checks the min and max lengths of the input.
		if (validator.isLength(username, { min: 1, max: 128 }) && validator.isLength(password, { min: 1, max: 128 })) {
			// If the validation checks out, attempt to login.
			// DataService.login(username, password).then( result => {
			authenticate({ username, password }).then(async (result) => {
				await getMe();
				// If the login was succesfull, continue to the next page.
				if (result.code === 200) {
                    this.setState({ loadingWheel: null });
					this.props.navigation.navigate('pickDeliveryAddress');
				}
				else if (result.code === 408) {
					this.setState({ errorText: 'Er lijkt iets fout te gaan, probeer het later opnieuw.', loadingWheel: null });
				}
				else if (result.code === 500) {
					this.setState({ errorText: 'Verkeerde e-mail of wachtwoord', loadingWheel: null }); // If the validation failed, show an error message to the user.
                }
			}).catch(() => {
				this.setState({
                    errorText: 'Verkeerde e-mail en/of wachtwoord', loadingWheel: null });
			});
		} else {
			this.setState({
				errorText: 'Geen gegevens ingevuld', loadingWheel: null
			});
		}
    }

    async attemptLogout() {
		logout().then( () => {
            this.setState({loggingOut: false});
            // Navigate to the home screen.
            this.props.navigation.navigate('Home');
            // Remove login data from localStorage.
            this.storage.removeData('user');
            this.storage.removeData('auth');
        }).catch( e => {
            console.log(e);
        });
        this.setState({loggedIn: false});
	}

	escapeInput(input: any) {
		return validator.escape(input);
	}

	render() {
        // Go back to overview page if the totalprice is 0. This scenario happens when a user refreshes the page on this screen.
		if(this.props.store.orderProcess.totalPrice === 0) {
			this.props.navigation.navigate('overview');
		}

        if (this.state.loading) {
            return <div className='row justifyCenter mt-4' style={{width: '100%'}}>
                <ActivityIndicator size="large" color="#B9DCBF" />
            </div>;
        }

        const mobileView = this.props.mobileView;
        if(this.state.loggedIn) {
            return <div style={{width: '100%', display: 'flex' , flexDirection: 'column', alignItems: 'center', marginTop: mobileView ? 50 : 0}}>
                <MakeText style={{fontSize: mobileView ? 24 : 36}} fontFamily={'Arno Pro'}>Je bent al ingelogd</MakeText>
                <Button onPress={() => this.props.navigation.navigate('pickDeliveryAddress')} color={'greenWhite'} text={'Verder naar bezorgadres'} textStyle={{fontSize: 18, height: 50, width: 300, fontFamily: 'Open Sans'}} style={{marginTop: 25}} />
                <Button onPress={async () => {this.props.navigation.navigate('overview')}} color={'greenWhite'} text={'Terug naar bloemenmand'} textStyle={{fontSize: 18, height: 50, width: 300, fontFamily: 'Open Sans'}} style={{marginTop: 25}} />
                <Button onPress={async () => {this.attemptLogout()}} color={'greenWhite'} text={'Uitloggen'} textStyle={{fontSize: 18, height: 50, width: 300, fontFamily: 'Open Sans'}} style={{marginTop: 25}} />
            </div>
        }
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-around', width: '100%'}}>
                    {/* Log-in blok */}
                    <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? undefined : 350, height: mobileView ? undefined : 375, backgroundColor: 'rgba(196,196,196,0.2)', padding: mobileView ? 25 : 50, margin: mobileView ? 25 : undefined}}>
                        <MakeText style={{fontSize: mobileView ? 24 : 36}} fontFamily={'Arno Pro'}>Inloggen</MakeText>
                        {/* Email field */}
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 25}}>
                            <MakeText style={{fontSize: mobileView ? 15 : 18}} fontFamily={'Open Sans'}>E-mail</MakeText>
                            <input data-testId='username' type="email" style={{fontSize: mobileView ? 15 : 18, padding: 5, marginTop: 10}} value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}></input>
                        </div>
                        {/* Password field */}
                        <div style={{display: 'flex', flexDirection: 'column', marginTop: 25}}>
                            <MakeText style={{fontSize: mobileView ? 15 : 18}} fontFamily={'Open Sans'}>Wachtwoord</MakeText>
                            <input data-testId='password' type="password" style={{fontSize: mobileView ? 15 : 18, padding: 5, marginTop: 10}} value={this.state.password} onChange={(e) => this.setState({password: e.target.value})}></input>
                        </div>
                        <a className='smallText link' style={{marginTop: 5}} onClick={() => window.open('/wachtwoordVergeten')}>Wachtwoord vergeten?</a>
                        <Button testID='login' onPress={() => this.attemptLogin()} color={'greenWhite'} text={'Inloggen'} textStyle={{fontSize: mobileView ? 15 : 18, height: 50, fontFamily: 'Open Sans'}} style={{marginTop: 25}} />
                        {this.state.errorText && <MakeText fontFamily={'Open Sans'} style={{fontSize: mobileView ? 15 : 18, color: 'red', marginTop: 10, width: mobileView ? 200 : undefined}}>{this.state.errorText}</MakeText>}
                    </div>
                    {/* Geen account blok */}
                    <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : 350, height: mobileView ? undefined : 375, alignItems: 'center', marginBottom: mobileView ? 50 : undefined}}>
                        <MakeText style={{fontSize: mobileView ? 24 : 36, marginTop: 50}} fontFamily={'Arno Pro'}>Bestel zonder account</MakeText>
                        <Button testID='nieuwParticulier' onPress={() => this.props.navigation.navigate('createAccount', {business: false})} color={'greenWhite'} text={'Ik bestel als particulier'} textStyle={{fontSize: mobileView ? 15 : 18, width: mobileView ? 200 : 300, height: mobileView ? undefined : 50, fontFamily: 'Open Sans'}} style={{marginTop: 50}} />
                        <Button testID='nieuwZakelijk' onPress={() => this.props.navigation.navigate('createAccount', {business: true})} color={'greenWhite'} text={'Ik bestel zakelijk'} textStyle={{fontSize: mobileView ? 15 : 18, width: mobileView ? 200 : 300, height: mobileView ? undefined : 50, fontFamily: 'Open Sans'}} style={{marginTop: 25}} />
                    </div>
                </div>
                <div style={{width: '100%', display: 'flex', flexDirection:'row', justifyContent: 'flex-start', marginBottom: mobileView ? 25 : 0}}>
                    {/* <Button onPress={() => this.props.navigation.navigate('overview')} color={'greenWhite'} text={'Terug'} textStyle={{fontSize: mobileView ? 15 : 18, height: mobileView ? undefined : 50, fontFamily: 'Open Sans', marginLeft: mobileView ? 25 : 0}} style={{}} /> */}
                    <a className='mediumText link' onClick={() => this.props.navigation.navigate('overview')} style={{height: mobileView ? undefined : 50, marginLeft: mobileView ? 25 : 0}}>Terug</a>
                </div>
            </div>
        );
    }
}
