import * as React from 'react';
import { View, TextInput } from 'react-native';

import styles from './AccountStyles';
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';
import { getOrdersForUser, sendOrderComment } from '../../API/Http/Requests';

export default class OrderComments extends React.Component<any> {
    state = {
        comment: undefined,
        orderId: undefined,
        freeOrderId: undefined,

        orders: [] as any[],

        msg: undefined
    }

    componentDidMount() {
		if (this.props.orders) {
			this.setState({orders: this.props.orders});
		} else {
			getOrdersForUser().then( user => {
				this.setState({orders: user.orders, orderId: user.orders.length ? user.orders[0].id : 'ander ordernummer'});
			});
		}
	}

    async sendComment() {
        if (!this.state.comment || !this.state.orderId || (this.state.orderId === 'ander ordernummer' && !this.state.freeOrderId)) {
            this.setState({msg: 'Eén of meerdere velden zijn leeg.'});
            return;
        }
        const res = await sendOrderComment(this.state.comment as any, this.state.orderId === 'ander ordernummer' ? this.state.freeOrderId : this.state.orderId as any);
        this.setState({msg: res});
    }

	render() {
		return (
			<View style={styles.adresBottomMargin}>
                <MakeText style={styles.defaultAccountFont} fontFamily="Open Sans">Ordernummer:</MakeText>

                <select className='smallText selectStyle' style={{width: '100%'}} value={this.state.orderId} onChange={(e) => this.setState({orderId: e.target.value})}>
                    {this.state.orders.map(o => (
                        <option value={o.id}>{o.id}</option>
                    ))}
                    <option value={'ander ordernummer'}>Ander ordernummer</option>
                </select>

                {this.state.orderId === 'ander ordernummer' &&
                    [<MakeText style={[styles.defaultAccountFont, {marginTop: 10}]} fontFamily="Open Sans">Ander ordernummer:</MakeText>,
                    <TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans', borderWidth: 1, padding: 5, marginTop: 5}]} onChangeText={(e) => this.setState({freeOrderId: e})} value={this.state.freeOrderId}></TextInput>]
                }

				<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans">Opmerking:</MakeText>
				<TextInput style={[styles.defaultAccountFont, styles.Persoonsgegevens, {fontFamily: 'Open Sans', borderWidth: 1, padding: 5}]} onChangeText={(e) => this.setState({comment: e})} value={this.state.comment} multiline numberOfLines={4}></TextInput>

				<Button text="Versturen" color="blackOpacity" style={{alignSelf: 'center', marginTop: 30, marginBottom: 10}} textStyle={{fontSize: 16, letterSpacing: 2, padding: 4, paddingLeft: 17, paddingRight: 17}} onPress={() => this.sendComment()} />

                {this.state.msg &&
                    <span className='smallText mt-3'>{this.state.msg}</span>
                }
			</View>
		);
	}
}
