export default [
    "Overig",
    "Afval & Grondstoffen",
    "Auditdienst ACAM",
    "Basisinformatie",
    "Belastingen",
    "Bestuursadvisering en Strategie",
    "Bestuurscommissies",
    "Bureau Integriteit",
    "Bureau Interim en Advies",
    "Burgemeester & Wethouders",
    "CTO Innovatieteam",
    "Clusterstaf Bedrijfsvoering",
    "Clusterstaf Dienstverlening en Informatie",
    "Clusterstaf Ruimte en Economie",
    "Clusterstaf Sociaal",
    "Clusterstaf Stadswerken",
    "Communicatiebureau",
    "De Raadsgriffie",
    "Dienstverlening",
    "Directie Communicatie",
    "Directie Juridische Zaken",
    "Directie Middelen en Control",
    "Directie Openbare Orde en Veiligheid",
    "Directie Parkeren ",
    "Directie Personeel en Organisatie",
    "Directie Verkeer en Openbare Ruimte",
    "Economische Zaken",
    "Facilitair Bureau",
    "Financiële Dienstverlening ",
    "GGD",
    "Gemeentelijk Vastgoed",
    "Gemeenteraad",
    "Gemeentesecretaris en GMT",
    "Grond en Ontwikkeling",
    "Handhaving en Toezicht",
    "ICT",
    "Informatievoorziening Bedrijfsvoering",
    "Informatievoorziening Dienstverlening en Informatie",
    "Informatievoorziening Ruimte en Economie",
    "Informatievoorziening Sociaal",
    "Informatievoorziening Stadsdelen",
    "Informatievoorziening Stadswerken",
    "Ingenieursbureau",
    "Inkomen",
    "Juridisch Bureau",
    "Kunst en Cultuur",
    "Materiaalbureau",
    "Metro en Tram",
    "Monumenten en Archeologie",
    "Ombudsman Metropool Amsterdam",
    "Onderwijs, Jeugd en Zorg",
    "Onderzoek, Informatie en Statistiek",
    "Openbare Verlichting",
    "Parkeren",
    "Participatie",
    "Personeel en Organisatieadvies",
    "Projectmanagementbureau",
    "Rekenkamer Metropool Amsterdam",
    "Ruimte en Duurzaamheid",
    "Sport en Bos",
    "Stadsarchief",
    "Stadsbank van Lening",
    "Stadsdeel Centrum",
    "Stadsdeel Nieuw West",
    "Stadsdeel Noord",
    "Stadsdeel Oost",
    "Stadsdeel West",
    "Stadsdeel Zuid",
    "Stadsdeel Zuidoost",
    "Stadswerken",
    "Strategie Ruimte en Economie",
    "Subsidies, Inkoop en Juridisch Bureau Sociaal",
    "Toezicht & Handhaving Openbare Ruimte",
    "VGA Verzekeringen",
    "Verkeer Informatie Systemen",
    "Verkeer en Openbare Ruimte",
    "Verkeersregel Automaat",
    "Werk",
    "Wonen",
    "Zuidas"
];