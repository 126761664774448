import * as React from 'react';
import { Image, View, StyleSheet } from 'react-native';

import FooterWeeklyFlowers from './../Common/FooterWeeklyFlowers/FooterWeeklyFlowers';
import FamilyGroup from './FamilyGroupCard';
import MakeText from '../Common/MakeText/MakeText';
import { mobile, phone } from '../../Util/screen/HandleLayout';
import { getFamilygroups } from '../../API/Http/Requests';
import stylo from './TheFamily.module.css';

export default class TheFamily extends React.Component<any> {
    state = {
        loading: true,
        family: [] as any,
        familyProfiles: [] as any,
    }

    componentDidMount() {
        getFamilygroups().then( fam => {
            this.setState({
                family: fam,
                loading: false
            });
        }).catch( e => {
			console.log(e);
		});
    }

    render() {

		const mobileView: boolean = phone(this.props.width);

        const panelImageWidth = mobileView ? this.props.width * 0.9 : this.props.width * 0.5;
        const panelImageHeight = (350 / mobile) * panelImageWidth;
        const styles = StyleSheet.create({
            image: {
                flex: 0,
                width: this.props.width,
                height: mobileView ? 250 : 900 * (this.props.width / 1600),
            },
            header1: {
                textAlign: 'center',
                marginBottom: 30,
                color: '#414141',
                fontSize: 36,
                letterSpacing: 2.13,
            },
            header2: {
                fontSize: 36,
                textAlign: 'center',
                marginBottom: 15,
                color: '#414141',
                lineHeight: 42,
                letterSpacing: 4.2
            },
            headerText2: {
                fontSize: 20,
                textAlign: 'center'
            },
            headerText3: {
                fontSize: 20,
                maxWidth: this.props.width,
                margin: 10,
                color: '#414141'
            },
            zoDoenWij: {
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'flex-start',
            },
            zoBlock: {
                flexGrow: 1,
                maxWidth: 300,
                margin: 20
            },
            zoPicca: {
                width: 300,
                height: 300,
                margin: 'auto',
            },
            imgButton: {
                alignSelf: 'center',
                marginTop: 20
            },
            imgButtonText: {
                // fontFamily: 'Open Sans',
                color: '#414141',
                lineHeight: 22,
                fontSize: 16,
                textAlign: 'center',
                letterSpacing: 2
            },
            panelImage: {
                height: panelImageHeight,
                width:  panelImageWidth,
                alignSelf: 'center'
            }
        });

        return (
        <View>

			<View style={{height: Math.min(this.props.height * 0.6, 900 * (this.props.width / 1600))}}>
	            <Image source={require('./../../images/TheFamilyHeader.jpg')} style={styles.image} resizeMode='cover' />
	        </View>

			<View style={{width: this.props.width, backgroundColor: 'white'}}>
                <View style={{alignSelf: 'center', marginTop: 30}}>
                    <MakeText style={styles.header1} fontFamily='Arno Pro'>Meet the Family</MakeText>
                    <div className={`${stylo.headerText}`}>
                        Bij The Flower Family staan mens en natuur centraal. Binnen onze familie zijn we allemaal gelijkwaardig en werken we gezamenlijk aan onze missie: Flowering a better world! Ben jij benieuwd wie je nou telkens aan de telefoon hebt, wie jouw bundels samenstellen, of wie de nieuwe bundels ontwerpen. Ontmoet dan hier the Family - we stellen ze graag aan je voor.
                    </div>
                </View>

                {!this.state.loading &&
                    this.state.family.map((group, index) => {
                        return <FamilyGroup
									key={index}
                                    width={this.props.width}
                                    height={this.props.height}
                                    styles={styles}
                                    family={group}
                                    onClick={() => this.props.navigation.navigate('Family Member', {groupId: group.id, title: group.title.replace(/\s/g, '-')})}
                                    route={`/familymember/${group.id}/${group.title.replace(/\s/g, '-')}`}
                                    index={index}
                                />
                    })
                }

                <FooterWeeklyFlowers width={this.props.width} height={this.props.height} navigation={this.props.navigation}/>

            </View>
        </View>
        );
    }
}
