import * as React from 'react';
import { View, StyleSheet } from 'react-native';
import MakeText from '../Components/Common/MakeText/MakeText';
import Logo from '../Components/Common/Logo';

export default () => (
	<View>
		<Logo />
		<MakeText style={styles.text}>Er is iets foutgegaan bij het opzetten van de verbinding naar onze servers. Probeer het later opnieuw.</MakeText>
	</View>
);

const styles = StyleSheet.create({
	text: {
		color: '#414141',
		marginTop: 15,
	},
});
