import * as React from 'react';

import CreateAccountPage from './../Components/CreateAccount/CreateAccountPage';
import BasicPage from './Template/BasicPage';

export default (props: any) =>  (
	<BasicPage navigation={props.navigation}>
		<CreateAccountPage />
	</BasicPage>
);
