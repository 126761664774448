import * as React from 'react';
import { ActivityIndicator, TouchableOpacity, View, Image } from 'react-native';
import { getDashboardOrderCsvToken, getInkoopDashboard, getInkoopOrders } from "../../API/Http/Requests";
import MakeText from '../Common/MakeText/MakeText';

import moment from 'moment';
import styles from './AccountStyles';
import globalStyles from '../../Styles/styles';
import Button from '../CrossPlatform/Button/Button';
import { makeUrl } from '../../API/Http/HttpSetup';
// import { IUser } from '../../API/IDataService';
/*
interface IState {
	users: any[];
	edit: boolean;
	errorText: string;
	loading: boolean;
}
*/

moment.locale('nl');

export default class InkoopDashbaord extends React.Component<any> {
	state: any = {
		orders: [],
		metrics: undefined,
		errorText: '',
		updating: false,

		startDate: moment().startOf('quarter').toDate(),
		endDate: moment().endOf('quarter').toDate(),
		orderDate: true,
		afdelingen: [],

		url: undefined,
	}

	async componentDidMount() {
		this.getOrders(this.state.startDate, this.state.endDate);
		this.getMetrics(this.state.startDate, this.state.endDate);
	}

	orderRow(order) {
		return (
			<View style={[globalStyles.row, globalStyles.fullWidth, globalStyles.spaceBetween]}>
				<MakeText style={[styles.defaultAccountFont, {width: '20%'}]} fontFamily="Open Sans Italic">{moment(order.createdAt).format('DD-MM-YYYY')} </MakeText>
				<MakeText style={[styles.defaultAccountFont, {width: '20%'}]} fontFamily="Open Sans Italic">{order.package.category} </MakeText>
				<MakeText style={[styles.defaultAccountFont, {width: '20%'}]} fontFamily="Open Sans Italic">{order.inkoperInfo.afdeling} </MakeText>
				<MakeText style={[styles.defaultAccountFont, {width: '20%'}]} fontFamily="Open Sans Italic">{order.status} </MakeText>
				<MakeText style={[styles.defaultAccountFont, {width: '20%'}]} fontFamily="Open Sans Italic">{order.amount} </MakeText>
			</View>
		);
	}

	async downloadCSV() {
		const token = await getDashboardOrderCsvToken();
		const url = makeUrl(`orders/reports/orders.csv?token=${token}&start=${this.state.startDate.toISOString()}&end=${this.state.endDate.toISOString()}`);
		window.open(url);
	}

	async getMetrics(startDate: Date, endDate: Date): Promise<void> {
		const metrics = await getInkoopDashboard({startDate, endDate});
		this.setState({ metrics, updating: false });
	}

	async getOrders(startDate: Date, endDate: Date): Promise<void> {
		const orders = await getInkoopOrders({startDate, endDate});
		this.setState({orders});
	}

	changeQuarter(up: boolean) {
		const startDate = moment(this.state.startDate).add(up ? 1 : -1, 'quarter').startOf('quarter').toDate();
		const endDate = moment(this.state.endDate).add(up ? 1 : -1, 'quarter').endOf('quarter').toDate();
		this.setState({
			startDate,
			endDate,
			updating: true,
		});
		this.getOrders(startDate, endDate);
		this.getMetrics(startDate, endDate);
	}

	render() {
		const metrics = this.state.metrics;
		if (!metrics) {
			return (
				<View style={styles.columnWebView}>
					<View style={{ marginTop: 20, marginBottom: 20 }}>
						<ActivityIndicator size="large" color="#B9DCBF" />
					</View>
				</View>
			);
		}

		return (
			<View>
				<div style={{display: 'flex', flexDirection: 'row', marginBottom: 15, alignItems: 'center'}}>
					<TouchableOpacity onPress={() => this.changeQuarter(false)}>
						<Image style={[styles.dateSelectorArrow, styles.dateArrowPositionFlipped]} source={require('./../../images/popup_arrow.svg')} />
                    </TouchableOpacity>
					<div style={{marginLeft: 15, marginRight: 15, minWidth: 300, display: 'flex', justifyContent: 'center'}}>
						Q{moment(this.state.startDate).quarter()} {this.state.startDate.getFullYear()}: {this.state.startDate.toLocaleDateString()} - {this.state.endDate.toLocaleDateString()}
					</div>
					<TouchableOpacity onPress={() => this.changeQuarter(true)}>
						<Image style={styles.dateSelectorArrow} source={require('./../../images/popup_arrow.svg')} />
                    </TouchableOpacity>
					{this.state.updating && <ActivityIndicator color="#B9DCBF" />}
				</div>
				<Button text="Downloaden als CSV" color="blackOpacity" textStyle={{paddingTop: 5, paddingBottom: 6, fontSize: 16, letterSpacing: 2, marginTop: 15}} onPress={() => this.downloadCSV()} />
				{this.state.metrics &&
					<div className='col mb-4'>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Aantal bestellingen: {metrics.totalOrders}</MakeText>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Aantal bloem bestellingen: {metrics.flowerOrders}</MakeText>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Aantal bloem producten besteld: {metrics.flowerAmount}</MakeText>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Aantal plant bestellingen: {metrics.plantOrders}</MakeText>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Aantal plant producten besteld: {metrics.plantAmount}</MakeText>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Bloemen al bezorgd: {metrics.deliveredFlowers}</MakeText>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Planten al bezorgd: {metrics.deliveredPlants}</MakeText>
						<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Aantal lopende abonnementen: {metrics.runningSubscriptions}</MakeText>
						{Object.keys(metrics.ordersPerAfdeling).map(afdeling => (
							<MakeText style={styles.defaultAccountFont} fontFamily="Open Sans Italic">Aantal bestellingen door afdeling '{afdeling}': {metrics.ordersPerAfdeling[afdeling]}</MakeText>
						))}
					</div>
				}
				<View style={[globalStyles.row, globalStyles.fullWidth, globalStyles.spaceBetween]}>
					<MakeText style={[styles.defaultAccountFont, {width: '20%'}]} fontFamily="Open Sans Italic">Besteldatum </MakeText>
					<MakeText style={[styles.defaultAccountFont, {width: '20%'}]} fontFamily="Open Sans Italic">Categorie </MakeText>
					<MakeText style={[styles.defaultAccountFont, {width: '20%'}]} fontFamily="Open Sans Italic">Afdeling </MakeText>
					<MakeText style={[styles.defaultAccountFont, {width: '20%'}]} fontFamily="Open Sans Italic">Status </MakeText>
					<MakeText style={[styles.defaultAccountFont, {width: '20%'}]} fontFamily="Open Sans Italic">Aantal </MakeText>
				</View>
				{this.state.orders.map((usr) => this.orderRow(usr))}
			</View>
        );
	}
}
