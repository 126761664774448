import * as React from 'react';

import './DashedLine.css';

export default (props: any) => {

	let classes: string = "border-spaced";

	if(props.vertical) {
		classes = "border-spaced left";
	}

	return <span className={classes}></span>
};
