import moment from 'moment';
import * as React from 'react';
import styles from '../AccountStyles';
import { ActivityIndicator, Image } from 'react-native';
import TouchableOpacity from '../../CrossPlatform/TouchableOpacity/TouchableOpacity';
import { getDeliveryReportDeliveries } from '../../../API/Http/Requests';
import DeliveryReportDelivery from './DeliveryReportDelivery';

interface IDeliveryReportProps {

}

interface IDeliveryReportState {
    selectedDate: moment.Moment,
    deliveries: any[],
    loading: boolean,
}

export default class DeliveryReport extends React.Component<IDeliveryReportProps, IDeliveryReportState> {

    state = {
        selectedDate: moment().subtract(1, 'day'),
        deliveries: [],
        loading: true,
    }

    async changeDate(up: boolean): Promise<void> {
        const selectedDate = this.state.selectedDate.add(up ? 1 : -1, 'day');
        this.setState({loading: true, selectedDate, deliveries: []});
        const deliveries = await this.getDeliveries(selectedDate)
        this.setState({loading: false, deliveries});
    }

    async getDeliveries(date: moment.Moment): Promise<any> {
        return await getDeliveryReportDeliveries(date.toDate());
    }

    async componentDidMount() {
        // Get deliveries for date in state, call separate function
        const deliveries = await this.getDeliveries(this.state.selectedDate);
        this.setState({deliveries, loading: false})
    }

    render() {
        return (
            <div style={{alignSelf: 'center', textAlign: 'center'}}>
                <h2>Gerealiseerde bezorgtijden</h2>

                {/* Date selector */}
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: 5, justifyContent: 'center'}}>
					<TouchableOpacity onPress={() => this.changeDate(false)}>
						<Image style={[styles.dateSelectorArrow, styles.dateArrowPositionFlipped]} source={require('./../../../images/popup_arrow.svg')} />
                    </TouchableOpacity>
					<div style={{marginLeft: 15, marginRight: 15, minWidth: 300, display: 'flex', justifyContent: 'center'}}>
						{this.state.selectedDate.toDate().toLocaleDateString()}
					</div>
					<TouchableOpacity onPress={() => this.changeDate(true)}>
						<Image style={styles.dateSelectorArrow} source={require('./../../../images/popup_arrow.svg')} />
                    </TouchableOpacity>
				</div>
                <div style={{display: 'flex', flexDirection: 'row', marginBottom: 15, justifyContent: 'center'}}>
                    {this.state.loading && <ActivityIndicator color="#B9DCBF" />}
                </div>

                {/* Delivery overview */}
                {this.state.deliveries.map(d => <DeliveryReportDelivery delivery={d} />)}
                {/* Lijst van deliveries met bekende informatie, openklappen voor het toevoegen van de informatie en daar een save knop bij zetten */}
            </div>
        )
    }
}
