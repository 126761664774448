import { Dimensions, Platform } from 'react-native';
import Bowser from 'bowser';

const browser = Platform.OS === 'web' ? Bowser.getParser(window.navigator.userAgent) : {satisfies: (_: any) => true};
const deviceData = Platform.OS === 'web' ? Bowser.parse(window.navigator.userAgent) : {platform: 'mobile'};

export const handleLayout = (prevWidth: number) => {

	let width: number, height: number;

	if (Platform.OS === 'web') {
		width = window.innerWidth;// window.outerWidth ? window.outerWidth : window.innerWidth;
		height = window.innerHeight;
		// If browser is firefox, take innerwidth
		if(navigator.userAgent.includes('Firefox')) width = document.body.clientWidth;
	} else {
		width = Dimensions.get('window').width;
		height = Dimensions.get('window').height;
	}

	if (prevWidth !== width){
		return {width, height};
	}
}

/**
 * This function returns a width and height attribute for both native and web in a callback function.
 * Example implementation:
 *
	 state = {
		 width: 0,
		 height: 0,
	 }

	 componentDidMount() {
		 watchResize( result => {
			 this.setState(result);
		 }, this.state.width);
	 }

 * Using the function in this way will set the width and height of the screen in the state every 250ms.
 */
export const watchResize = (callBack: Function, prevWidth: number) => {
	if(Platform.OS === 'web') {
		let timeout : any = 0, // holder for timeout id
		delay = 250; // delay after event is "complete" to run callback

		// window.resize event listener
		window.addEventListener('resize', function() {
			// clear the timeout
			clearTimeout(timeout);

			const handle = () => {
				const result = handleLayout(prevWidth);
				callBack(result);
			}
			// start timing for event "completion"
			timeout = setTimeout(handle, delay);
		});
		window.addEventListener('orientationchange', function() {
			setTimeout(handleLayout, 1000);
		});
	}

	const result = handleLayout(prevWidth);
	callBack(result);
}

export const calculateMargin = (width: number) : number => {
	return Math.max(25, (25 / 1600) * width);
}

// Calculate the with of one panel for the OrderProcess popup on web.
export const calculatePanelWidth = (width: number) : number => {

	let panelWidth: number;

	if(width > 1920) panelWidth = width / 3.4;
	else if(width > 1750) panelWidth = width / 3;
	else panelWidth = width / 2.2;

	return panelWidth;
}

const PHONESIZE : number = 800;
export const PHONE_TEXT_WIDTH = 0.97;
export const PHONE_TEXT_WIDTH_LARGE = 0.9;

export const SHOPPING_CART_HEIGHT = 600;
export const SHOPPING_CART_HEIGHT_4K = 720;
/**
 * From this screen size, the website switches to mobile view.
 */
export const mobile : number = PHONESIZE;

const MOBILEORDERPROCESS = 1100;

export const orderProcessMobile = (width: number, height?: number) : boolean => {
	// If smaller than 1100 then the order process doesnt fit anymore
	if(width < MOBILEORDERPROCESS) {
		return true
	}

	if (height && height < 725) {
		return true;
	}

	// Check if device is using a mobile browser.
	if (
		deviceData.platform === 'mobile' ||
		((deviceData.platform as any).type && (deviceData.platform as any).type === 'tablet')
	) {
	    return true;
	}

	return false;
}

export const phone = (width: number, _?: number) : boolean => {

	// If smaller than 800, it's a phone.
	if(width < PHONESIZE) {
		return true
	}

	// Check if device is using a mobile browser.
	if (
		deviceData.platform === 'mobile' //||
		// ((deviceData.platform as any).type && (deviceData.platform as any).type === 'tablet')
	) {
	    return true;
	}

	return false;
}

export const mobileOrderProcess = (width: number): boolean => {
	return width < MOBILEORDERPROCESS;
}

const SMALLLOGO = 1050;
export const smallNavbarLogos = (width: number) => {
	return width < SMALLLOGO
}

export const validBrowserCheck = () : boolean | undefined => {
	return browser.satisfies({
		// declare browsers per OS
		windows: {
		   "internet explorer": ">10",
		},
		macos: {
		  safari: ">10.1"
		},

		// per platform (mobile, desktop or tablet)
		mobile: {
		  'android browser': '>3.10'
		},
        chromium: ">30",
		// or in general
		chrome: ">60",
		firefox: ">54",
		opera: ">=50",
		edge: ">=1",
	    safari: '>=9',
	});
}
