import ReactPixel from 'react-facebook-pixel';

export default class FacebookAnalytics {
    private static facebookAnalytics: FacebookAnalytics;

    private locked = false;

    private constructor() { // initialize pixel-ID
        ReactPixel.init('221683395048579'); // pixelID TFF: 221683395048579 pixelID test oud: 565298144422363 pixelID test oud: 270171261012059
    }

    public static getInstance() {
        if (FacebookAnalytics.facebookAnalytics === undefined) {
            FacebookAnalytics.facebookAnalytics = new FacebookAnalytics();
        }
        return FacebookAnalytics.facebookAnalytics;
    }

    public pageView(navigation) { // Gets executed 1x per 250ms, and only for components in the router and orderprocess router: as soon the URL is used or the route changes.
        if (!this.locked) {
            this.locked = true;
            if (navigation) { // Only trigger a pageview for components that have 'navigation'.
                ReactPixel.pageView();
            }

            setTimeout(() => this.locked = false, 250);
        }
    }

    public addToCart(flower) { // Gets executed every time a package is added to the shoppingcart
        if (flower) {
            let params: any = {};
            if (flower.id && flower.price) {
                params = { // Prepare pixel parameters
                  content_name: '',
                  content_ids: [flower.id],
                  content_type: 'product',
                  contents: [{
                    'id': flower.id,
                    'quantity': 1
                  }],
                  currency: 'EUR',
                  value: flower.price
                }
            }
            if (flower.locale && flower.locale.length > 0 && flower.locale[0] && flower.locale[0].text !== '' && flower.size) {
                params.content_name = `${flower.category} - ${flower.size} - ${flower.locale[0].text}`;
            } else { params.content_name = `${flower.category} - ${flower.size}`; }
            ReactPixel.track('AddToCart', params);
        }
    }

    public initiateCheckout(products) { // Gets executed when the user clicks de 'Order'-button on the orderoverview popup
        if (products) {
            let params: any = {
                content_category: 'products',
                content_ids: [],
                contents: [],
                currency: 'EUR',
                num_items: 0,
                value: 0
            };
            products.map((prod) => {
                if (prod && prod.flower) {
                    if (prod.flower.id && prod.flower.amount && prod.flower.price) {
                        params.content_ids.push(prod.flower.id);
                        params.contents.push({
                            'id': prod.flower.id,
                            'quantity': prod.flower.amount
                        });
                        params.num_items += prod.flower.amount;
                        params.value += Number((prod.flower.price * prod.flower.amount).toFixed(2));
                    }
                }
            });
            ReactPixel.track('InitiateCheckout', params);
        }
    }

    public purchase(products) { // Gets executed 1x per 2500ms, as soon as the BedanktVoorBestelling-component opens with payment-status 'paid'.
        if (!this.locked) {
            this.locked = true;
            if (products) {
                let params: any = {
                    content_name: '',
                    content_ids: [],
                    content_type: 'product',
                    contents: [],
                    currency: 'EUR',
                    num_items: 0,
                    value: 0
                };
                products.map((prod) => {
                    if (prod && prod.flower) {
                        if (prod.flower.id && prod.flower.amount && prod.flower.price && prod.flower.size) {
                            if (prod.flower.locale && prod.flower.locale.length > 0 && prod.flower.locale[0] && prod.flower.locale[0].text !== '') {
                                params.content_name += `${prod.flower.category} - ${prod.flower.size} - ${prod.flower.locale[0].text}, `;
                            } else {
                                params.content_name += `${prod.flower.category} - ${prod.flower.size}, `;
                            }
                            params.content_ids.push(prod.flower.id);
                            params.contents.push({
                                'id': prod.flower.id,
                                'quantity': prod.flower.amount
                            });
                            params.num_items += prod.flower.amount;
                            if (prod.flower.price && prod.flower.amount) {
                                params.value += Number((prod.flower.price * prod.flower.amount).toFixed(2));
                            }
                        }
                    }
                });
                ReactPixel.track('Purchase', params);
            }
            setTimeout(() => this.locked = false, 2500);
        }
    }
}
