import axios from 'axios';
import configDefault from './../../config/config.selector';
const conf = configDefault as any

// const PORT = '';
// const API_BASE = 'api';

export const makeUrl = (url: string): string => {
    if (conf.local) {
        return `http://${conf.localhost}/${url}`;
    } else {
        return `https://${conf.host}/${url}`;
    }
};

import Storage from '../../Components/CrossPlatform/Storage/Storage';
const storage = new Storage;

export const instance = axios.create({
    baseURL: makeUrl(''),
    timeout: 20000,
    withCredentials: true,
    headers: {
        'Accept': 'application/json; charset=utf-8'
    }
});

/**
 * This function runs before any axios request is executed.
 * We use it to set the bearer token.
 */
instance.interceptors.request.use(async (config) => {
    const auth = await storage.retrieveData('auth');
    if (auth === null) {
        return config;
    }
    config.headers.Authorization = auth;
    return config;
}, error => {
    // handle the error
    return Promise.reject(error);
});
