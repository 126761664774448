import ITranslations from './../ITranslations';

var NL: ITranslations = {
    welcomeMessage: "Welkom op de MakeTek starter kit app!",
    currentLanguage: "De huidig geslecteerde taal is:",
    counterPage: "Dit is de todos pagina.",

    // Todo component
    id: "id",
    userid: "gebruiker id",
    title: "titel",
}

export default NL;