import * as React from 'react';

import BasicPage from './Template/BasicPage';
import Vacatures from './../Components/Vacatures/Vacatures';

export default (props: any) => (
    <BasicPage navigation={props.navigation}>
			<Vacatures />
		</BasicPage>
)
