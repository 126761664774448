import * as React from 'react';
import validator from 'validator';

import {addLocationToMe, getMe} from "../../API/Http/Requests";
import { ILocation, shippingCountries } from '../../API/IDataService';
import checkZipCode from '../../Util/validation/ZipCodeChecker';
import Button from '../CrossPlatform/Button/Button';
import MakeText from '../Common/MakeText/MakeText';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics';
import { routingName } from '../CrossPlatform/OrderProcess/NewOrderProcess.web';


export default class AddDeliveryAddress extends React.Component<any> {

	state = {
		deliveryAddress: {
			id: undefined,
	        postalcode: '',
	        street: '',
			housenumber: '',
			addition: '',
	        city: '',
			fname: '',
			lname: '',
			country: 'NL',
			phone: '',
			isPublicLocation: false,
	    },

		errorText: '',
		checkingZipShip: false,
		zipCheckShippingStatus: undefined,
		zipCodeWarningShip: undefined,
		autoCompletedShip: false,
	}

	componentWillMount() {
		this.setState({deliveryAddress: Object.assign(this.props.store.orderProcess.deliveryAddress, {country: 'NL'})});
        GoogleAnalytics.getInstance().hit(this.props.navigation.state.key)
	}

	async componentDidMount() {
		this.props.store.setActiveStep('pickDeliveryAddress');
		GoogleAnalytics.getInstance().hit(this.props.navigation.state.key);
		try {
			const me = await getMe();
			const deliv = this.state.deliveryAddress;
			deliv.fname = me.firstName;
			deliv.lname = me.lastName;
			this.setState({deliveryAddress: deliv});
		} catch {}
	}

	nextPage() {
		this.setState({errorText: ''});

		let deliverylocation : ILocation = this.createLocation(this.state.deliveryAddress);

		if(!this.verifyInput(deliverylocation)) return this.setErrorText();

        this.sendLocationToBackend(deliverylocation, result => {
            deliverylocation.id = result.id;
            this.props.store.setDeliveryAddress(deliverylocation);
            this.props.navigation.navigate('pickDeliveryAddress');
        });
	}

	// Send a new location object to the backend.
	sendLocationToBackend(location: any, callback: Function) {
		addLocationToMe(location).then( result => {
			return callback(result);
		}).catch( () => {
			this.setState({errorText: 'Het lijkt erop dat er iets fout gaat. Excuses voor het ongemak! Probeer het later opnieuw.', loadingWheel: null})
		});
	}

	// Remove the loading wheel and set an error text for the user.
	setErrorText() : void {
		return this.setState({errorText: 'Een of meerdere van de velden zijn niet correct ingevuld. Alle velden zijn verplicht.', loadingWheel: null});
	}

	/**
	 * Takes ILocation object.
	 * Checks if all required fields have a value.
	 * Returns true if so, false if not.
	 *
	 * @param  location The location for which input is checked.
	 * @return true or false, based on wether the check succeeds.
	 */
	verifyInput(location: ILocation) : boolean {
		if (validator.isLength(location.city, {min: 1, max: 128})
			&& validator.isLength(location.street, {min: 1, max: 128})
			&& validator.isLength(location.housenumber, {min: 1, max: 128})
			&& validator.isLength(location.postalcode, {min: 1, max: 128})
			&& validator.isLength(location.fname, {min: 0, max: 128})
			&& validator.isLength(location.lname, {min: 1, max: 128})
			&& (routingName !== 'amsterdam' || validator.isLength(location.phone, {min: 6, max: 16}))
		) return  true;

		return false;
	}

	/**
	 * Creates a location that can be send and saved by the backend.
	 * @return returns a location based on data in the state of the class.
	 */
	createLocation(address: any) : ILocation {
		let location: any = {
			street: address.street,
			housenumber: address.housenumber,
			addition: address.addition,
			postalcode: address.postalcode.replace(' ', ''),
			city: address.city,
			country: address.country,
			fname: address.fname,
            lname: address.lname,
			phone: address.phone,
			invoice: false,
			isPublicLocation: address.isPublicLocation,
		}

		return location;
	}

	onChange(name: string, value: string) : void {
		let autoCompleteKey = 'autoCompletedShip';
		let autoCompleteValue;
		if (name === 'street' || name === 'city') {
			autoCompleteValue = false;
		} else {
			autoCompleteValue = this.state[autoCompleteKey]
		}
		let tmpState : ILocation;

		tmpState = Object.assign({}, this.state.deliveryAddress);

		tmpState[name] = value;

		this.setState({deliveryAddress: tmpState, [autoCompleteKey]: autoCompleteValue});
	}

	checkZipCodeShip() {

		if(this.state.deliveryAddress.postalcode === '' || this.state.deliveryAddress.housenumber === '') {
			return;
		}
		this.setState({checkingZipShip: true});
		setTimeout(async () => {
			const foundLoc = await checkZipCode(this.state.deliveryAddress);
			if(foundLoc === undefined) {
				this.setState({zipCheckShippingStatus: 'Even check dubbel check - heb je het juiste bezorgadres ingevoerd?', checkingZipShip: false, autoCompletedShip: false,});
				return;
			}
			this.setState({
				deliveryAddress: Object.assign(this.state.deliveryAddress, foundLoc),
				checkingZipShip: false,
				zipCheckShippingStatus: undefined,
				autoCompletedShip: true,
			});
		}, 0);
	}

	checkPostalCode() {
		const postalCode = this.state.deliveryAddress.postalcode.replace(' ', '');
		const stateKey = 'zipCodeWarningShip';
		if (new RegExp(/^[1-9][0-9]{3}[\s]?[A-Za-z]{2}$/, 'i').test(postalCode)) {
			this.setState({[stateKey]: undefined});
		} else {
			this.setState({
				[stateKey]: 'Deze postcode wordt niet herkend.'
			})
		}
	}

    render() {
		// Go back to overview page if the totalprice is 0. This scenario happens when a user refreshes the page on this screen.
		if(this.props.store.orderProcess.totalPrice === 0) {
			this.props.navigation.navigate('overview');
		}
		const mobileView = this.props.mobileView;
		const largeText = mobileView ? 24 : 36;
		// const mediumText = mobileView ? 18 : 24;
		const smallText = mobileView ? 15 : 18;

        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', height: '95%', justifyContent: 'space-between'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '90%' :  475, marginTop: mobileView ? 25 : undefined}}>
                    <MakeText style={{fontSize: largeText, alignSelf: 'center'}} fontFamily={'Arno Pro'}>Bezorgadres</MakeText>

					{/* Voor amsterdam portal, laat naam locatie zien ipv naam. Naam wordt opgeslage in het lname veld */}
					{ routingName === 'amsterdam' ?
						<div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-between', marginTop: 10}}>
							{/* First name */}
							<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Naam</MakeText>
								<input data-testid='voornaam' type="text" style={{fontSize: smallText, padding: 5}} value={this.state.deliveryAddress.lname} onChange={(e) => this.onChange('lname', e.target.value)}></input>
							</div>
							{/* Phone number */}
							<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Telefoonnummer</MakeText>
								<input data-testid='phone' type="text" style={{fontSize: smallText, padding: 5}} value={this.state.deliveryAddress.phone} onChange={(e) => this.onChange('phone', e.target.value)}></input>
							</div>
						</div>
						:
						<div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-between', marginTop: 10}}>
							{/* First name */}
							<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Voornaam</MakeText>
								<input data-testid='voornaam' type="text" style={{fontSize: smallText, padding: 5}} value={this.state.deliveryAddress.fname} onChange={(e) => this.onChange('fname', e.target.value)}></input>
							</div>
							{/* Last name */}
							<div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
								<MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Achternaam</MakeText>
								<input data-testid='voornaam' type="text" style={{fontSize: smallText, padding: 5}} value={this.state.deliveryAddress.lname} onChange={(e) => this.onChange('lname', e.target.value)}></input>
							</div>
						</div>
					}
                    {/* Postcode en nummer */}
                    <div style={{display: 'flex', flexDirection: mobileView ? 'column' : 'row', justifyContent: 'space-between', marginTop: 10}}>
                        {/* Postcode */}
                        <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
                            <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Postcode</MakeText>
                            <input type="text" style={{fontSize: smallText, padding: 5}} onBlur={() => { this.checkPostalCode(); this.checkZipCodeShip();}} value={this.state.deliveryAddress.postalcode} onChange={(e) => this.onChange('postalcode', e.target.value)}></input>
                        </div>
                        {/* Huisnummer */}
                        <div style={{display: 'flex', flexDirection: 'column', width: mobileView ? '100%' : '48%'}}>
                            <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Huisnummer</MakeText>
                            <input type="text" style={{fontSize: smallText, padding: 5}} onBlur={() => { this.checkZipCodeShip();}} value={this.state.deliveryAddress.housenumber} onChange={(e) => this.onChange('housenumber', e.target.value)}></input>
                        </div>
                    </div>
                    {/* Straat */}
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                        <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Straat</MakeText>
                        <input type="text" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.deliveryAddress.street} onChange={(e) => this.onChange('street', e.target.value)}></input>
                    </div>
                    {/* City */}
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                        <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Stad</MakeText>
                        <input type="text" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.deliveryAddress.city.replace('&#x27;', "'")} onChange={(e) => this.onChange('city', e.target.value)}></input>
                    </div>
					{/* Country */}
                    {/* <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                        <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Land</MakeText>
                        <input type="text" style={{fontSize: smallText, padding: 5, marginTop: 10}} value={this.state.deliveryAddress.country.replace('&#x27;', "'")} onChange={(e) => this.onChange('country', e.target.value)}></input>
                    </div> */}
					{/* Country */}
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 10}}>
                        <MakeText style={{fontSize: smallText}} fontFamily={'Open Sans'}>Land</MakeText>
						<select style={{fontSize: smallText, padding: 5, marginTop: 10}} onChange={(e) => this.onChange('country', e.target.value)}>
							{Object.values(shippingCountries).map(c => <option value={c}>{c}</option>)}
						</select>
                    </div>
					{ routingName === 'amsterdam' &&
						<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 10}}>
							<input type='checkbox' checked={!this.state.deliveryAddress.isPublicLocation} onChange={(e) => this.setState({deliveryAddress: Object.assign({}, this.state.deliveryAddress, {isPublicLocation: !e.target.checked})})}></input>
							<MakeText style={{fontSize: smallText, marginLeft: 10}}>Dit is een privé adres</MakeText>
						</div>
					}
					{this.state.errorText &&
						<MakeText style={{fontSize: 15, color: 'red', marginTop: 10}}>{this.state.errorText}</MakeText>
					}
					{this.state.zipCheckShippingStatus &&
						<MakeText style={{fontSize: 15, marginTop: 10}}>{this.state.zipCheckShippingStatus}</MakeText>
					}
                </div>
				<div style={{width: '100%', display: 'flex', flexDirection:'row', justifyContent: 'space-between', marginBottom: mobileView ? 25 : 0, marginTop: mobileView ? 50 : 0}}>
					<Button onPress={() => this.props.navigation.navigate('pickDeliveryAddress')} color={'greenWhite'} text={'Terug'} textStyle={{fontSize: mobileView ? 15 : 18, height: mobileView ? undefined : 50, fontFamily: 'Open Sans'}} style={{marginLeft: mobileView ? 25 : 0}} />
					<Button style={{marginRight: mobileView ? 25 : 0}} textStyle={{fontSize: smallText, fontFamily: 'Open Sans', height: mobileView ? undefined : 50, marginBottom: mobileView ? 25 : undefined}} text={'Voeg adres toe'} onPress={() => this.nextPage()} color={'greenWhite'} />
				</div>
            </div>
        );
    }
}
