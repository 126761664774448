import * as React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import MakeText from './../../Components/Common/MakeText/MakeText';

import styles from './../../Components/Account/AccountStyles';
import Dash from './../../Components/CrossPlatform/Dash/Dash';

export default class AccountColumnTemplate extends React.Component<any> {

    state = {
        // collapsed: Platform.OS !== 'web',
		collapsed: true,
    }

    collapse() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {

        return (
			<View style={[styles.columnWebView, {width: this.props.width}]}>
				<View style={[styles.columnWebHeader, {width: this.props.width}]}>
                    <TouchableOpacity testID={this.props.testID} onPress={() => this.collapse()} style={[styles.columnHeaderText, {width: this.props.width}]}>
                        <MakeText fontFamily="Crimson Text" style={{fontSize: 36, letterSpacing: 2.2}}>{this.props.title}</MakeText>
						<Image style={[styles.arrow, !this.state.collapsed && styles.arrowPositionFlipped]} source={require('./../../images/popup_arrow.svg')} />
                    </TouchableOpacity>
                </View>

				<View style={{ marginVertical: 11, height: 1, width: this.props.width }}>
					<Dash />
				</View>

                {!this.state.collapsed &&
					<View style={{width: '100%'}}>
	                    {this.props.children}

						<View style={{ marginVertical: 10, height: 1, width: this.props.width }}>
							<Dash />
						</View>
					</View>
                }
            </View>
        )
    }
}
