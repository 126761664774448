import * as React from 'react';
import { View, StyleSheet, TextInput, ActivityIndicator } from 'react-native';
import {forgotPasswordEmail} from "../../API/Http/Requests";
import MakeText from '../Common/MakeText/MakeText';
import Button from '../CrossPlatform/Button/Button';
import { escape } from './../../Util/validation/cleanInput';
import globalStyles from '../../Styles/styles';

interface IEnterEmail {
	setEmailEntered: Function;
}

export default class EnterEmail extends React.Component<IEnterEmail> {

	state = {
		email: '',

		loading: false,
		errorMessage: '',
	}

	// Clean the input and send a reste password request to the server.
	async sendEmail(email: string) {

		if(email.length < 3) {
			return this.setState({errorMessage: 'Geen email ingevuld.'});
		}

		this.setState({loading: true, errorMessage: ''});

		email = escape(email);

		let result;
		try {
			result = await forgotPasswordEmail(email);
		} catch (e) {
			if (e.response.status === 404) {
				this.setState({loading: false, errorMessage: 'Er is geen account met dit e-mailadres.'});
				return;
			}
		}

		if(result) {
			this.props.setEmailEntered(true, email);
		}

		this.setState({loading: false, errorMessage: 'Er is iets misgegaan, probeer het later nog een keer.'});

	}

	render() {

		const styles = StyleSheet.create({
			text: {
				color: '#414141',
				alignSelf: 'center',
			},
			textInput: {
				width: '100%',
				alignSelf: 'center',
				borderColor: '#414141',
				borderWidth: 1,
				padding: 10,
				fontSize: 18,
				color: '#414141',
				marginTop: 15,
			},
			marginTop15: {
				marginTop: 15,
			}
		});

		return (

			<View>

				<MakeText style={styles.text}>Je wachtwoord vergeten? Vul hier je e-mailadres in om een nieuw wachtwoord aan te vragen</MakeText>

				<TextInput style={styles.textInput} value={this.state.email} placeholder="E-mail..." onChangeText={(email) => this.setState({email})} />

				<View style={[globalStyles.alignSelfCenter, styles.marginTop15]}>
					<Button
						disabled={this.state.loading ? true : false}
						text="Verstuur"
						color="greenWhite"
						onPress={() => this.sendEmail(this.state.email)}
					/>
				</View>

				{this.state.loading &&
					<View style={[styles.marginTop15, globalStyles.alignSelfCenter]}>
						<ActivityIndicator size="large" color="#B9DCBF" />
					</View>
				}

				{this.state.errorMessage.length ?
					<MakeText style={[globalStyles.alignSelfCenter, globalStyles.error, globalStyles.marginTop15]}>{this.state.errorMessage}</MakeText>
				:
					null
				}

			</View>
		);
	}
}
