import * as React from 'react'

import './Button.css';
import { IButton } from './IButton';

/**
 *
 * This function is only uses on web ---
 * A button with a certain styling is returned. Custom styles are not possible.
 *
 * @param  text    Text to be displayed inside the button.
 * @param  color   The color pallet the button uses.
 * @param  onPress The action that is taken when the button is pressed.
 * @param  style   Custom styles for the overlaying div, NOT the text inside the button.
 * @param  textStyle Custom style for the text inside the button, NOT the outer div.
 * @return         Returns a button that can take a certain action with styling.
 *
 * @Example
 * <Button text="Click me" color="white-green" onPress={() => console.log('clicked!')} />
 */
export default (props: IButton) => (
	<div style={{...props.style, position: 'relative'}}>
		<button disabled={props.disabled} style={{...props.textStyle, position: 'relative'}} className={`button ${props.color}  ${props.className ? " " + props.className : ""}`} onClick={(ev) => handleOnClick(ev, props)} data-testid={props.testID ? props.testID : null}>{capitalizeFirstLetter(props.text)}</button>
	</div>
);

function capitalizeFirstLetter(text: string) : string {
	 return text.charAt(0).toUpperCase() + text.slice(1);
}

function handleOnClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>, props: IButton) {
	// Middle mouse click
	if(props.route && event.nativeEvent.button === 1) {
		window.open(props.route, '_blank');
	} else if (event.nativeEvent.button === 0){ // Left click
		props.onPress();
	}
}
