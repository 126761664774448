import * as React from 'react';
import MakeText from '../../Common/MakeText/MakeText';

import './MakeTabs.css';
import { TouchableOpacity } from 'react-native';

export default (props: any) => {
    const tabs = props.options.map((option) => {
        return (
            <TouchableOpacity testID={option} onPress={() => props.changeTab(option)}>
                <MakeText style={{ ...props.textStyles, position: 'relative', fontWeight: option === props.activeTab ? 'bold' : 'normal' }}>
                    {option}
                </MakeText>
            </TouchableOpacity>
        );
    })

    return (
        <div style={props.style} className='tabs'>
            {tabs[0]}
            {tabs[1]}
            {tabs[2]}
            {tabs[3]}
            {tabs[4]}
        </div>
    )
}
