import * as React from 'react';

import BasicPage from './Template/BasicPage';
import ForgotPassword from './../Components/ForgotPassword/ForgotPassword';

export default (props: any) =>  (
	<BasicPage navigation={props.navigation}>
		<ForgotPassword back='Login' />
	</BasicPage>
);
