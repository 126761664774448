import * as React from 'react';
import LazyLoad from 'react-lazyload';

import HomeTopBanner from './HomeTopBanner/HomeTopBanner';
import SociaalDuurzaamEtc from './SociaalDuurzaam/SociaalDuurzaamEtc';
import QuoteBanner from './QuoteBanner';
import FavorieteProducten from './FavorieteProducten/FavorieteProducten';
import StoriesNew from './StoriesNewHome/StoriesNew';

import GroenOpKantoor from './GroenOpKantoor';
// import TFFExclusiveBanner from './TFFExclusiveBanner';
import Testimonials from './Testimonials/Testimonials';
import QuoteBannerText from '../../text/home/QuoteBanner/QuoteBannerText';

export default (props: any) => {
    const width = props.width;
    return (
        <div>
            <HomeTopBanner width={width} height={props.height} navigation={props.navigation} />
            <LazyLoad height={1200}>
                <SociaalDuurzaamEtc width={width} height={props.height} navigation={props.navigation} />
                <div style={{marginTop: 50}}>
                    <QuoteBanner width={width} height={props.height} navigation={props.navigation} quote={QuoteBannerText.quote} quoter={QuoteBannerText.quoter} />
                </div>
            </LazyLoad>
            <LazyLoad height={800}>
                <div style={{marginTop: 50}}>
                    <FavorieteProducten width={width} height={props.height} navigation={props.navigation} />
                </div>
                {/* <div style={{marginTop: 50}}>
                    <TFFExclusiveBanner width={width} height={props.height} navigation={props.navigation} />
                </div> */}
                <div style={{ marginTop: 50, display: 'flex', flexDirection: 'column' }}>
                    <StoriesNew width={props.width} height={props.height} navigation={props.navigation} />
                </div>
                <div style={{marginTop: 50}}>
                    <GroenOpKantoor width={width} height={props.height} navigation={props.navigation} />
                </div>
                <div style={{marginTop: 50}}>
                    <Testimonials width={width} />
                </div>
            </LazyLoad>

        </div>
    );
}
