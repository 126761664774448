import ReactGA from 'react-ga';
import configDefault from './../../config/config.selector';
import { IOrder } from '../../API/IDataService';
import Storage from '../../Components/CrossPlatform/Storage/Storage';

const analyticsId = (configDefault as any).analyticsID;

interface ITransactionProduct {
    id: string;
    name: string;
    sku: string;
    price: string;
    category: string;
    quantity: number;
}

export default class GoogleAnalytics {
    private static googleAnalytics: GoogleAnalytics;

    private storage = new Storage;

    private constructor() {
        ReactGA.initialize(analyticsId);
        ReactGA.plugin.require('ecommerce');
    }

    public static getInstance() {
        if(GoogleAnalytics.googleAnalytics === undefined) {
            GoogleAnalytics.googleAnalytics = new GoogleAnalytics();
        }
        return GoogleAnalytics.googleAnalytics;
    }

    public hit(state) {
        const page = state.routeName;
        const params = state.params;
        if(params === undefined) {
            ReactGA.pageview(page);
            return;
        }
        let pageParams = page;
        Object.keys(params).forEach((key) => {
            pageParams += '/' + params[key];
        })
        ReactGA.pageview(pageParams);
    }

    public startOrderProcess() {
        ReactGA.event({
            category: 'OrderProcess',
            action: 'started'
        });
    }

    public finishedOrderProcess() {
        ReactGA.event({
            category: 'OrderProcess',
            action: 'finished'
        });
    }

    public subscribedToNewsletter() {
        ReactGA.event({
            category: 'Newsletter',
            action: 'Subscribed'
        });
    }

    public contactFromBusiness() {
        ReactGA.event({
            category: 'Business',
            action: 'Contactformulier verstuurd'
        });
    }

    public mqttError() {
        ReactGA.event({
            category: 'Error',
            action: 'Mqtt connection failed'
        })
    }

    public oldBrowserError() {
        ReactGA.event({
            category: 'Error',
            action: 'Users browser is unsupported'
        })
    }

    public createTransaction(store: any) {
        const id = Math.random().toString(10).substring(5);
        const products = store.product.products.map((pr) => {
            return {
                id,
                name: pr.flower.category + ' ' + pr.flower.size,
                sku: '--',
                price: pr.flower.price.toFixed(2),
                category: pr.flower.category,
                quantity: pr.flower.amount,
            }
        });
        this.sendTransaction(products, store.orderProcess.totalPrice);
    }

    public async transactionFromOrderRef(orders: IOrder[], reference: string) {
        const storedRef = await this.storage.retrieveData(reference);
        if (storedRef !== null) {
            return;
        }

        const transactionId = reference;
        let totalPrice = 0;
        const products = orders.reduce((arr, order) => {
            const pr = order.package;
            if (order.vase) {
                const vasePrice = order.package.vasePrice;
                arr.push({
                    id: reference,
                    name: pr.category + ' ' + pr.size + ' vaas',
                    sku: '--',
                    price: vasePrice.toFixed(2),
                    category: 'vaas',
                    quantity: 1,
                })
                totalPrice += vasePrice;
            }
            const price = pr.price;
            arr.push({
                id: reference,
                name: pr.category + ' ' + pr.size,
                sku: '--',
                price: price.toFixed(2),
                category: pr.category,
                quantity: 1,
            });
            totalPrice += price;
            return arr;
        }, [] as ITransactionProduct[]);
        this.sendTransaction(products, totalPrice, transactionId);
        this.storage.storeData(reference, true);
    }

    private sendTransaction(products: ITransactionProduct[], total: number, id?: string) {
        const transactionId = id !== undefined ? id : Math.random().toString(10).substring(5);
        for (const pr of products) {
            ReactGA.plugin.execute('ecommerce', 'addItem', pr);
        }

        ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            id: transactionId,
            revenue: total.toFixed(2),
        });
        ReactGA.plugin.execute('ecommerce', 'send', undefined);
        ReactGA.plugin.execute('ecommerce', 'clear', undefined);
    }
}